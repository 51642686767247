import { yupResolver } from '@hookform/resolvers/yup';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { SchemaOf, object, string, number } from 'yup';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../../types/wash_cycle.type';
import { IJetWashCycleLaser } from '../../../../types/wash_cycle_laser.type';
import WashLaser from './WashLaser';

interface WashLaserFormProps {
  washCycle: IJetWashCycle;
  laser: IJetWashCycleLaser | undefined;
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleLasers', undefined>;
  currentAggregator: ICompleteWashInfoAggregators | undefined;
  recipeName: "currentRecipe" | "greenRecipe" | undefined;
}

export const WashLaserForm: React.FC<WashLaserFormProps> = ({
  washCycle,
  laser,
  navigation,
  currentAggregator,
  recipeName,
}) => {
  const schema: SchemaOf<IJetWashCycleLaser> = object({
    id: string().optional().notRequired(),
    laser_id: string().required(t('domain.analysis.step7.input.laser_scraping.label')),
    laser_labor_operation_time: number().required(
      t('domain.analysis.step7.input.labor_operation_time.label')
    ),
    laser_machine_operation_time: number().required(
      t('domain.analysis.step7.input.machine_operation_time.label')
    ),
  });

  const methods = useForm<IJetWashCycleLaser>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      ...laser,
      laser_id: laser?.laser_id || undefined,
      laser_labor_operation_time: laser?.laser_labor_operation_time || undefined,
      laser_machine_operation_time: laser?.laser_machine_operation_time || undefined,
      id: laser?.id || undefined,
    },
    resolver: yupResolver(schema),
  });

  return (
    <>
      <FormProvider {...methods}>
        <WashLaser
          washCycle={washCycle}
          laser={laser}
          navigation={navigation}
          currentAggregator={currentAggregator || {} as ICompleteWashInfoAggregators}
          recipeName={recipeName || "currentRecipe"}
        />
      </FormProvider>
    </>
  );
};

export default WashLaserForm;
