import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { t } from 'i18n-js';
import { Heading, Text } from 'native-base';
import { Page } from '../../../../components/Page';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { SignupSuccess } from '../../../customer/components/Card';
import { SignUpForm2 } from '../../../professional/components/SignUpForm2';

interface WaitingApprovalInterface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
}

export function WaitingApproval({ navigation }: WaitingApprovalInterface) {
  return (
    <Page>
      <SignupSuccess navigation={navigation} />
    </Page>
  );
}
