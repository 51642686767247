import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ICompleteWashInfo, IJetWashCycle } from '../../types/wash_cycle.type';
import { IJetWashRecipeAggregatorComplete } from '../../types/wash_recipe_aggregator.type';
import { ISelectRecipe } from '../washRecipeAggregator/services/cycle.service';
import WashCycle from './WashCycle';
import { WashRecipeAggregatorContext } from '../washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import { getWashCycleSchema } from './washCycle.scheme';
import { getWashCycleDefaultValues } from './washCycle.default';

interface WashCycleFormProps {
  washRecipeAggregator: IJetWashRecipeAggregatorComplete;
  washCycle: IJetWashCycle;
  iSelectRecipe: ISelectRecipe;
}

export const WashCycleForm: React.FC<WashCycleFormProps> = ({
  washRecipeAggregator,
  washCycle,
  iSelectRecipe,
}) => {
  const { completeWashInformation } = useContext(WashRecipeAggregatorContext);
  const WASHCYCLE_SCHEMA = getWashCycleSchema(completeWashInformation || {} as ICompleteWashInfo)

  const defaultValues = getWashCycleDefaultValues(washCycle, completeWashInformation?.washingPlant)
  const methods = useForm<IJetWashCycle>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(WASHCYCLE_SCHEMA),
  });

  return (
    <FormProvider {...methods}>
      <WashCycle
        washRecipeAggregator={washRecipeAggregator}
        selectedRecipe={iSelectRecipe}
        washCycle={washCycle}
      />
    </FormProvider>
  );
};

export default WashCycleForm;
