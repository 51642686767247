import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetTypeOfCycle, IJetTypeOfCycleID } from '../../../types/type-of-cycle.type';
import { IJetWashCycle } from '../../../types/wash_cycle.type';

export interface IJetTypeOfCyclesList extends IJetAdminResponseProtocol<IJetTypeOfCycle[]> {}

export async function getTypeOfCycles(): Promise<IJetTypeOfCyclesList> {
  const res = await jetAdmin.list<IJetTypeOfCycle[]>(JetAdminCollection.TypeOfCycles, {
    _order_by: 'order',
  });

  return res;
}

export async function getTypeOfCyclesById(
  washCycle: IJetWashCycle
): Promise<IJetTypeOfCycle | undefined> {
  if (!washCycle.type_of_cycle) return undefined;
  const res = await jetAdmin.retrieve<IJetTypeOfCycle>(
    JetAdminCollection.TypeOfCycles,
    washCycle.type_of_cycle
  );
  return res;
}
