import { yupResolver } from '@hookform/resolvers/yup';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { SchemaOf, object, string, number } from 'yup';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../../types/wash_cycle.type';
import { IJetWashCycleChemical } from '../../../../types/wash_cycle_chemical.type';
import WashChemical from './WashChemical';

interface WashChemicalFormProps {
  washCycle: IJetWashCycle;
  chemical: IJetWashCycleChemical | undefined;
  currentAggregator: ICompleteWashInfoAggregators | undefined;
  recipeName: "currentRecipe" | "greenRecipe" | undefined;
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleChemicals', undefined>;
}

export const WashChemicalForm: React.FC<WashChemicalFormProps> = ({
  washCycle,
  chemical,
  currentAggregator,
  recipeName,
  navigation,
}) => {
  const schema: SchemaOf<IJetWashCycleChemical> = object({
    id: string().optional().notRequired(),
    chemical_id: string().required().label(t('domain.analysis.step5.input.chemical.label')),
    chemical_dose: number().required().label(t('domain.analysis.step5.input.dose.label')),
    chemical_unit_id: string().required().label(t('domain.analysis.step5.input.unit.label')),
    chemical_time: number().notRequired().optional(),
    chemical_bath_ratio: number().notRequired().optional(),
    chemical_temperature: number().notRequired().optional(),
  });

  const methods = useForm<IJetWashCycleChemical>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      ...chemical,
      id: chemical?.id || undefined,
      chemical_id: chemical?.chemical_id || undefined,
      chemical_dose: chemical?.chemical_dose || undefined,
      chemical_unit_id: chemical?.chemical_unit_id || undefined,
      chemical_time: chemical?.chemical_time || undefined,
      chemical_bath_ratio: chemical?.chemical_bath_ratio || undefined,
      chemical_temperature: chemical?.chemical_temperature || undefined,
    },
    resolver: yupResolver(schema),
  });

  return (
    <>
      <FormProvider {...methods}>
        <WashChemical
          washCycle={washCycle}
          chemical={chemical}
          currentAggregator={currentAggregator || {} as ICompleteWashInfoAggregators}
          recipeName={recipeName || "currentRecipe"}
          navigation={navigation}
        />
      </FormProvider>
    </>
  );
};

export default WashChemicalForm;
