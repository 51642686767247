import { Box, Column, HStack, Row, Spacer, Text, VStack } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { Lamp, People, Tree, Washing, Wheat } from '../svgs/Icons';

export interface CardEnvironmentProps {
  imageType: string;
  calc: number | string;
  unit?: string;
  text: string;
  savings: string;
  savingsColor: string;
}

export const CardEnvironment: React.FC<CardEnvironmentProps> = ({
  imageType,
  calc,
  text,
  unit,
  savings,
  savingsColor
}) => {
  const tagIcon = (value: string) => {
    switch (value) {
      case 'tree':
        return <Tree />;
      case 'people':
        return <People />;
      case 'wheat':
        return <Wheat />;
      case 'lamp':
        return <Lamp />;
      case 'washing':
        return <Washing />;
      default:
        return <Tree />;
    }
  };

  return (
    <VStack
      borderWidth="1px"
      backgroundColor="#E5EEED"
      borderColor="blue"
      borderRadius="4px"
      paddingX="24px"
      paddingY="16px"
      height={125}
      w={Platform.OS === "web" ? 400 : '100%'}
      space={4}
    >
      <Text style={{ fontWeight: 'bold', color: savingsColor, marginBottom: -7 }}>{savings}</Text>
      <HStack space={2} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
        <HStack space={4} alignItems={'center'} alignContent={'center'}>
          <Box>{tagIcon(imageType)}</Box>
          <Text lineHeight={'36px'} color="#368D7D" fontWeight="bold" fontSize="36px" mt={2}>
            {calc}
          </Text>
        </HStack>
        <Text w={150} flexWrap={'wrap'} textAlign={'left'} fontSize={14}>
          {text}
        </Text>
      </HStack>
    </VStack>
  );
};
