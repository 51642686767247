import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { CustomButton } from '../../../../components/CustomButton';
import { Page } from '../../../../components/Page';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../../types/wash_cycle.type';
import { IJetWashCycleAuxiliary } from '../../../../types/wash_cycle_auxiliary.type';
import { toast } from '../../../../utils/toast';
import RecipeFormStep6 from '../../../standardRecipe/form/RecipeFormStep6';
import { createOrEditWashCycleAuxiliary } from '../../services/auxiliarys.service';
import { WashRecipeAggregatorContext } from '../../../washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import { Platform } from 'react-native';

interface WashAuxiliaryProps {
  washCycle: IJetWashCycle;
  auxiliary: IJetWashCycleAuxiliary | undefined;
  currentAggregator: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe";
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleAuxiliarys', undefined>;
}

export const WashAuxiliary: React.FC<WashAuxiliaryProps> = ({
  washCycle,
  auxiliary,
  navigation,
  currentAggregator,
  recipeName,
}) => {
  const { updateAggregatorInfo, allAuxilliaryEquipments } = useContext(WashRecipeAggregatorContext);
  const { goBack } = navigation;
  const { handleSubmit, formState, reset } = useFormContext<IJetWashCycleAuxiliary>();

  const auxiliaryMutation = useMutation(createOrEditWashCycleAuxiliary);

  const onSubmit = async (data: IJetWashCycleAuxiliary) => {
    auxiliaryMutation.mutate(
      { data, washCycle },
      {
        onSuccess: (value) => {
          auxiliary === undefined
            ? insertNewAuxilliaryEquipmentsInAggregator(value)
            : editAuxilliaryInAggregator(data)
          Platform.OS !== 'web' && toast(t('domain.generic.success'));
          reset();
          goBack();
        },
        onError: (error) => toast(t('domain.generic.error')),
      }
    );
  };

  function insertNewAuxilliaryEquipmentsInAggregator(
    data: IJetWashCycleAuxiliary | undefined
  ){
    const currentAuxilliary =
      allAuxilliaryEquipments.find(item => item.id === data?.auxiliary_equipment_id);

    const newAuxilliary = {
      ...data,
      auxiliary_equipment: currentAuxilliary,
      equipment: {
        ...currentAuxilliary,
        brand: currentAuxilliary?.brand_detail,
      },
    };

    const updatedAggregator: ICompleteWashInfoAggregators = {
      ...currentAggregator,
      [recipeName]: {
        ...currentAggregator[recipeName],
        add_auxiliary_equipment: [
          ...currentAggregator[recipeName]?.add_auxiliary_equipment || [],
          data?.id
        ],
        cycle_auxiliary_equipments_details: [
          ...currentAggregator[recipeName]?.cycle_auxiliary_equipments_details || [],
          newAuxilliary,
        ],
      },
    };
    updateAggregatorInfo(updatedAggregator);
  }

  function editAuxilliaryInAggregator(value: IJetWashCycleAuxiliary){
    const newAuxilliary =
      allAuxilliaryEquipments.find(item => item.id == value?.auxiliary_equipment_id);
    const updatedAggregator: ICompleteWashInfoAggregators = {
      ...currentAggregator,
      [recipeName]: {
        ...currentAggregator[recipeName],
        cycle_auxiliary_equipments_details: currentAggregator[recipeName]?.cycle_auxiliary_equipments_details?.map(item => {
          if(item.id == value.id){
            return {
              ...item,
              auxiliary_inparallel: value.auxiliary_inparallel,
              auxiliary_time: value.auxiliary_time,
              auxiliary_equipment: newAuxilliary,
              equipment: {
                ...newAuxilliary,
                brand_detail: newAuxilliary?.brand_detail,
              },
            }
          }else{
            return item;
          }
        }),
      },
    };
    updateAggregatorInfo(updatedAggregator);
  }

  return (
    <Page>
      <RecipeFormStep6
        auxiliary={auxiliary}
        washCycle={washCycle}
        isSubmitting={auxiliaryMutation.isLoading}
        currentAggregator={currentAggregator}
        recipeName={recipeName}
        navigation={navigation}
      />

      <CustomButton
        variant={'solid'}
        onPress={handleSubmit(onSubmit)}
        isDisabled={auxiliaryMutation.isLoading || formState.isSubmitting}
      >
        {t('domain.generic.save')}
      </CustomButton>
    </Page>
  );
};

export default WashAuxiliary;
