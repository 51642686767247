import React, { ReactNode } from 'react';
import { Button, IButtonProps, useContrastText } from 'native-base';

interface CustomButtonProps extends Partial<IButtonProps> {
  variant: 'link' | 'outline' | (string & {}) | 'ghost' | 'solid' | 'subtle' | 'unstyled';
  isDisabled?: boolean;
  bgColor?: string;
  onPress: () => void;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  children: ReactNode;
  width?: number | string;
  border?: number | string;
  color?: string;
  isLoading?: boolean;
  isLoadingText?: string;
}

export function CustomButton({
  variant,
  isDisabled,
  onPress,
  children,
  bgColor,
  leftIcon,
  rightIcon,
  width,
  border = 2,
  color,
  isLoading,
  isLoadingText,
}: CustomButtonProps) {
  const textColor = color ?? '#fff';

  return (
    <Button
      variant={variant}
      isDisabled={isDisabled}
      // h="40px"
      w={width || undefined}
      isLoading={isLoading}
      isLoadingText={isLoadingText}
      _loading={{
        bg: 'gray',
        color: 'white',
      }}
      borderWidth={variant !== 'solid' ? border || undefined : 0}
      borderRadius="8px"
      bgColor={bgColor || undefined}
      onPress={onPress}
      leftIcon={leftIcon || undefined}
      rightIcon={rightIcon || undefined}
      paddingTop="12px"
      paddingBottom="12px"
      fontSize={'18px'}
      _text={{
        color: textColor || undefined,
      }}
    >
      {children}
    </Button>
  );
}
