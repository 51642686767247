import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import WashAuxiliaryForm from './WashAuxiliaryForm';

type WashAuxiliaryRootProps = NativeStackScreenProps<
  HomeStackParamList,
  'washRecipeCycleAuxiliarys'
>;

export const WashAuxiliaryRoot: React.FC<WashAuxiliaryRootProps> = ({
  route,
  navigation
}) => {
  const { cycle, auxiliary, currentAggregator, recipeName, } = route.params;

  return <WashAuxiliaryForm
    washCycle={cycle}
    auxiliary={auxiliary}
    currentAggregator={currentAggregator}
    recipeName={recipeName}
    navigation={navigation}
  />;
};

export default WashAuxiliaryRoot;
