import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Box, HStack, ScrollView, Text, VStack } from 'native-base';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import BackHeader from '../../components/BackHeader';
import { CustomButton } from '../../components/CustomButton';
import { Page } from '../../components/Page';
import SectionTitle from '../../components/SectionTitle';
import { t } from '../../languages';
import { HomeStackParamList } from '../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../types/wash_cycle.type';
import { IJetWashRecipeAggregatorComplete } from '../../types/wash_recipe_aggregator.type';
import { WashRecipeAggregatorContext } from '../washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import {
  createOrEditWashCycle,
  ISelectRecipe
} from '../washRecipeAggregator/services/cycle.service';
import WashCycleGeneralInformation from './components/WashCycleGeneralInformation';
import WashCycleStep4Add from './components/WashCycleStep4Add';
import WashCycleStep4Extras from './components/WashCycleStep4Extras';


interface WashCycleProps {
  washRecipeAggregator: IJetWashRecipeAggregatorComplete;
  washCycle: IJetWashCycle;
  selectedRecipe: ISelectRecipe;
}

export const WashCycle: React.FC<WashCycleProps> = ({
  washRecipeAggregator,
  washCycle,
  selectedRecipe,
}) => {
  const { setLastTimeChanged, completeWashInformation, updateAggregatorInfo } = useContext(WashRecipeAggregatorContext);
  const { goBack } = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();
  const { handleSubmit, formState, reset } = useFormContext<IJetWashCycle>();
  const currentAggregatorToEdit = completeWashInformation?.aggregators.find(
    (item) => item.aggregator_id === washRecipeAggregator.id
  ) || {} as ICompleteWashInfoAggregators;
  const RECIPE_NAME = selectedRecipe === 'current_recipe' ? 'currentRecipe' : 'greenRecipe'

  const createOrEditCycleMutation = useMutation(createOrEditWashCycle);

  const onSubmit = async (data: IJetWashCycle) => {
    formatAndUpdateAggregatorInfo(data);

    delete data.chemicals;
    delete data.add_auxiliary_equipment;
    delete data.add_laser_scrapings;    

    createOrEditCycleMutation.mutate(
      {
        data: {
          ...data,
          amount_of_rinses: data.extras_rinses_add ? data.amount_of_rinses : undefined,
          rinses_amount_time: data.extras_rinses_add ? data.rinses_amount_time : undefined,
          rinses_amount_bath_ratio: data.extras_rinses_add ? data.rinses_amount_bath_ratio : undefined,
          extras_centrifuge_pickup: data.extras_centrifuge_add ? data.extras_centrifuge_pickup : undefined,
          extras_centrifuge_run_time: data.extras_centrifuge_add ? data.extras_centrifuge_run_time : undefined,
          extras_drying_temperature: data.should_add_drying ? data.extras_drying_temperature : undefined,
          extras_drying_time: data.should_add_drying ? data.extras_drying_time : undefined,
          id: washCycle.id,
        },
        type: selectedRecipe,
        washAggregator: washRecipeAggregator,
      },
      {
        onSuccess: () => {
          reset();
          setLastTimeChanged(new Date());
          goBack();
        },
      }
    );
  };

  const formatAndUpdateAggregatorInfo = (data: IJetWashCycle) => {
    const newAggregatorInfo: ICompleteWashInfoAggregators = {
      ...currentAggregatorToEdit,
      aggregator_id: currentAggregatorToEdit?.aggregator_id || '',
      [RECIPE_NAME]: {
        ...currentAggregatorToEdit[RECIPE_NAME],
        add_auxiliary_equipment: data.add_auxiliary_equipment,
        add_laser_scrapings: data.add_laser_scrapings,
        chemicals: data.chemicals,
        cycle_chemicals_details: washCycle.cycle_chemicals_details,
        cycle_auxiliary_equipments_details: washCycle.cycle_auxiliary_equipments_details,
        cycle_laser_scrapings_details: washCycle.cycle_laser_scrapings_details,
        general_time: data.general_time,
        general_temperature: data.general_temperature,
        general_bath_ratio: data.general_bath_ratio,
        amount_of_rinses: data.extras_rinses_add ? data.amount_of_rinses : "",
        rinses_amount_time: data.extras_rinses_add ? data.rinses_amount_time : "",
        rinses_amount_bath_ratio: data.extras_rinses_add ? data.rinses_amount_bath_ratio : "",
        extras_centrifuge_pickup: data.extras_centrifuge_add ? data.extras_centrifuge_pickup : "",
        extras_centrifuge_run_time: data.extras_centrifuge_add ? data.extras_centrifuge_run_time : "",
        extras_drying_temperature: data.should_add_drying ? data.extras_drying_temperature : "",
        extras_drying_time: data.should_add_drying ? data.extras_drying_time : "",
        should_add_drying: data.should_add_drying,
        extras_centrifuge_add: data.extras_centrifuge_add,
        extras_rinses_add: data.extras_rinses_add
      }
    };

    updateAggregatorInfo(newAggregatorInfo);
  }

  return (
    <Page bgColor={selectedRecipe === 'new_recipe' ? '#EDFCFA' : '#e0e0e0'}>
      <HStack justifyContent="space-between">
        <BackHeader
          backTitle
          buttonText={currentAggregatorToEdit?.cycle_type?.name || t('domain.generic.back')}
        />
        <Text mt={2} fontSize="xs" color={selectedRecipe === 'new_recipe' ? '#409d27' : undefined}>
          {selectedRecipe === 'new_recipe'
            ? t('domain.analysis.step4.header.green_recipe')
            : t('domain.analysis.step4.header.your_recipe')
          }
        </Text>
      </HStack>

      <ScrollView
        style={{backgroundColor: selectedRecipe === 'new_recipe' ? '#EDFCFA' : '#e0e0e0'}}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <VStack
          justifyContent="space-between"
          flex={1}
          space="32px"
          onStartShouldSetResponder={() => true}
        >
          <SectionTitle
            first
            title={t('domain.analysis.step4.section.general_information.title')}
            color={selectedRecipe !== 'new_recipe' ? '#5a5a5a' : undefined}
          >
            <WashCycleGeneralInformation />
          </SectionTitle>

          <SectionTitle
            title={t('domain.analysis.step4.section.add.title')}
            color={selectedRecipe !== 'new_recipe' ? '#5a5a5a' : undefined}
          >
            <WashCycleStep4Add
              cycle={washCycle}
              currentAggregator={currentAggregatorToEdit}
              recipeName={RECIPE_NAME}
            />
          </SectionTitle>

          <SectionTitle
            title={t('domain.analysis.step4.section.extras.title')}
            color={selectedRecipe !== 'new_recipe' ? '#5a5a5a' : undefined}
          >
            <WashCycleStep4Extras />
          </SectionTitle>

          <Box height={1} />
        </VStack>
      </ScrollView>

      <CustomButton
        variant={'solid'}
        onPress={handleSubmit(onSubmit)}
        isDisabled={formState.isSubmitting || createOrEditCycleMutation.isLoading}
        isLoading={formState.isSubmitting || createOrEditCycleMutation.isLoading}
      >
        {t('domain.generic.save')}
      </CustomButton>
    </Page>
  );
};

export default WashCycle;
