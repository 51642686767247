import {
  Box,
  Card,
  Divider,
  HStack,
  IconButton,
  Pressable,
  Text,
  VStack,
} from "native-base";
import React, { useState } from "react";
import { t } from "../src/languages";
import ArrowUp from "../src/assets/svgs/icon-up";
import ArrowDown from "../src/assets/svgs/icon-down";
import { Platform } from "react-native";

interface AccordionProps {
  id: string;
  title: string;
  left?: React.ReactNode;
  isValid?: boolean;
  px?: string | number;
  py?: string | number;
  titleColor?: string;
  titleWeight?: string;
  open?: boolean;
  children?: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  left = null,
  isValid = true,
  px = "20px",
  py = "14px",
  titleColor,
  titleWeight,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Pressable
      onPress={() => {
        if (Platform.OS !== "web") {
          setIsOpen(!isOpen);
        }
      }}
    >
      <Card
        width="100%"
        bgColor="white"
        borderRadius="8px"
        px={px}
        py={py}
        shadow={1}
        borderWidth={isValid ? 0 : 1}
        borderBottomColor={"red.600"}
        borderColor="red.600"
      >
        <VStack>
          <HStack
            alignItems="center"
            justifyContent="space-between"
            alignContent="center"
          >
            <HStack space="12px">
              {left}
              <Text fontWeight={titleWeight} color={titleColor ?? undefined}>
                {title}
              </Text>
            </HStack>
            {isOpen ? (
              <IconButton onPress={() => setIsOpen(false)} icon={<ArrowUp />} />
            ) : (
              <IconButton
                onPress={() => setIsOpen(true)}
                icon={<ArrowDown />}
              />
            )}
          </HStack>
          
            <Box style={{ display: isOpen ? "flex" : "none" }}>
              <Box mx={-5} my={4}>
                <Divider />
              </Box>

              <Box minHeight="50px">{children}</Box>
            </Box>
        </VStack>
      </Card>

      {!isValid && (
        <Text fontSize="xs" color="red.600" mt={1}>
          {t("domain.form.validation.error.generic")}
        </Text>
      )}
    </Pressable>
  );
};

export default Accordion;
