export enum QueryKeyCache {
  GetAllPackages = 'getAllPackages',
  GetOneProfessional = 'getOneProfessional',
  GetPackagesByUser = 'getPackagesByUser',
  GetServicesByUser = 'getServicesByUser',
  GetAllProfessionals = 'getAllProfessionals',
  GetAllServices = 'getAllServices',
  GetAllCountries = 'getAllCountries',
  GetAllWashingMachines = 'getAllWashingMachines',
  GetCentrifuges = 'getCentrifuges',
  GetBoilers = 'getBoilers',
  GetDryers = 'getDryers',
  GetCountries = 'getCountries',
  GetWashingPlants = 'getWashingPlants',
  GetWashRecipes = 'getWashRecipes',
  GetTypeOfCycles = 'getTypeOfCycles',
  GetChemicals = 'getChemicals',
  GetUnits = 'getUnits',
  getAuxiliaryEquipments = 'getAuxiliaryEquipments',
  getLaserScrapings = 'getLaserScrapings',
  GetWashes = 'getWashes',
  GetCycle = 'getCycle',
  GetCycleChemical = 'GetCycleChemicals',
  GetHocs = 'GetAllCombustibles',
  GetHelp = 'GetHelps',
}
