import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Boiler = (props: SvgProps) => (
  <Svg width={27} height={33} fill="none" {...props}>
    <Path
      d="M13.526 32C23.268 32 25.9 22.369 26 17.553c-1.9 1.927-4.752 2.007-5.94 1.806 0-1.806-.834-9.628-3.564-10.835C13.77 7.32 14.318 3.307 14.12 1 8.417 4.13 7.19 14.544 7.289 19.36c-1.664.24-4.851-2.71-6.237-4.214C.755 19.96 1.349 32 13.526 32Z"
      fill="#CFE8E5"
      stroke="#368D7D"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Boiler;
