import { Box, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { t } from '../../../languages';
import { FlatList, RefreshControl } from 'react-native';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import { QueryKeyCache } from '../../../config/query-cache';
import { IJetTypeOfCycle } from '../../../types/type-of-cycle.type';
import StandardFormNextButton from '../components/StandardFormNextButton';
import { useStandardRecipeForm } from '../hooks/useStandardRecipeForm';
import { getTypeOfCycles } from '../services/getTypeOfCycles';
import { IStandardRecipeForm } from '../types/standardRecipeForm.type';
import MachineCard from '../../washingPlant/components/MachineCard';
import Loading from '../../../../components/Loading';

interface RecipeFormStep3Props {}

export const RecipeFormStep3: React.FC<RecipeFormStep3Props> = () => {
  const [showModal, setShowModal] = useState(false);
  const { watch, setValue, control, formState } = useFormContext<IStandardRecipeForm>();
  const { nextStep, selectedRecipe, cycleIndex } = useStandardRecipeForm(control);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    QueryKeyCache.GetTypeOfCycles,
    getTypeOfCycles
  );

  const isQueryLoading = isLoading || isRefetching;

  const selectRecipe = (recipe: IJetTypeOfCycle) => {
    const formPath =
      selectedRecipe === 'currentRecipe'
        ? `cycles.${cycleIndex}.currentRecipe.selectedTypeOfCycle`
        : `cycles.${cycleIndex}.newRecipe.selectedTypeOfCycle`;

    // @ts-ignore
    setValue(formPath, recipe.id, { shouldDirty: true });
    setShowModal(true);
  };

  if (!selectedRecipe) return <></>;

  const formSelectedRecipeID = watch('cycles')[cycleIndex][selectedRecipe].selectedTypeOfCycle;
  const selectedFormRecipe = data?.results?.find((recipe) => recipe.id === formSelectedRecipeID);

  return (
    <>
      <VStack justifyContent="space-between" flex={1}>
        <Box flex={1}>
          <Text fontSize={22} fontWeight="bold" color="primary.500">
            {t('domain.analysis.step3.title_part_one')}
            <Text>
              {' '}
              <Text fontSize={22} fontWeight={500}>
                {t('domain.analysis.step3.title_part_two')}
              </Text>
            </Text>
          </Text>

          <Box mt={8} display="flex" flexGrow={1}>
            <FlatList
              data={data?.results || []}
              ListEmptyComponent={() => (!isQueryLoading ? <EmptyPlaceholder /> : <Loading />)}
              refreshControl={
                <RefreshControl
                  colors={['#9Bd35A', '#689F38']}
                  refreshing={isQueryLoading}
                  onRefresh={refetch}
                />
              }
              renderItem={({ item, index }) => {
                const isLast = data?.results.length ? index === data?.results.length - 1 : false;

                return (
                  <Box key={`recipe-${selectedRecipe}-card-${item.id}`} mx={0.75} mb={'8px'}>
                    <MachineCard
                      name={`${item.name || ''}`}
                      onPress={() => selectRecipe(item)}
                      isSelected={formSelectedRecipeID === item.id}
                    />
                    {isLast && <Box height={10} />}
                  </Box>
                );
              }}
            />
          </Box>
        </Box>
        <Box height="24px" />
      </VStack>
      {/* <Step4ModalConfirm
        name={selectedFormRecipe?.name}
        showModal={showModal}
        setShowModal={setShowModal}
        onConfirm={() => {
          nextStep();
        }}
      /> */}
    </>
  );
};

export default RecipeFormStep3;
