import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import { Box, Button, Checkbox, Text, View } from 'native-base';
import React, { useContext, useState } from 'react';
import { Platform, ScrollView } from 'react-native';
import SectionTitle from '../../components/SectionTitle';
import { IJetWashRecipe } from '../../types/wash-recipe.type';
import { IJetWashingPlant } from '../../types/washing-plants.type';
import { useAuth } from '../auth/hooks/useAuth';
import { generatePDFHtml } from './functions/generatePDFHtml';
import { Iresponse } from './services/getWashToPDF';
import { WashRecipeAggregatorContext } from '../washRecipeAggregator/contexts/WashRecipeAggregatorContext';

interface ReportProps {
  washingPlant: IJetWashingPlant;
  washRecipe: IJetWashRecipe;
}

export const Report: React.FC<ReportProps> = ({
  washRecipe,
}) => {
  const { completeWashInformation } = useContext(WashRecipeAggregatorContext);
  const [washingData, setWashingData] = useState(false);
  const [currentRecipe, setCurrentRecipe] = useState(false);
  const [greenRecipe, setGreenRecipe] = useState(false);
  const [results, setResults] = useState(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);
  const { user } = useAuth();

  function formatDataToPdf(){
    const washCycles = completeWashInformation?.aggregators.map((aggregator) => ({
      washCurrentCycles: aggregator.currentRecipe,
      washNewCycles: aggregator.greenRecipe,
    })) 

    const data: Iresponse = {
      washingPlantsComplete: {
        id: completeWashInformation?.washingPlant.id || '',
        name: completeWashInformation?.washingPlant?.name || '',
        active: completeWashInformation?.washingPlant?.active || false,
        air_temperature: completeWashInformation?.washingPlant?.air_temperature || '',
        description: completeWashInformation?.washingPlant?.description || '',
        number_of_garments: completeWashInformation?.washingPlant?.number_of_garments || '',
        overall_load: completeWashInformation?.washingPlant?.overall_load || '',
        user: completeWashInformation?.washingPlant?.user || '',
        water_temperature: completeWashInformation?.washingPlant?.water_temperature || '',
        washing_machine: completeWashInformation?.washingPlant?.washing_machine || '',
        country: completeWashInformation?.washingPlant?.country || '',
        boiler: completeWashInformation?.washingPlant?.boiler || '',
        centrifuge: completeWashInformation?.washingPlant?.centrifuge || '',
        dryer: completeWashInformation?.washingPlant?.dryer || '',
        created_at: completeWashInformation?.washingPlant?.created_at || '',
        updated_at: completeWashInformation?.washingPlant?.updated_at || '',
        boilerInfo: completeWashInformation?.boiler,
        countryInfo: completeWashInformation?.country,
        centrifugeInfo: completeWashInformation?.centrifuge,
        dryerInfo: completeWashInformation?.dryer,
        washingMachineInfo: completeWashInformation?.washingMachine,
      },
      washingPlants: {
        id: completeWashInformation?.washingPlant?.id || '',
        name: completeWashInformation?.washingPlant.name || '',
        description: completeWashInformation?.washingPlant.description,
        active: completeWashInformation?.washingPlant.active || false,
        country: completeWashInformation?.washingPlant.country || '',
        overall_load: completeWashInformation?.washingPlant.overall_load || '',
        number_of_garments: completeWashInformation?.washingPlant.number_of_garments || '',
        washing_machine: completeWashInformation?.washingPlant.washing_machine || '',
        water_temperature: completeWashInformation?.washingPlant.water_temperature || '',
        centrifuge: completeWashInformation?.washingPlant.centrifuge || '',
        boiler: completeWashInformation?.washingPlant.boiler || '',
        dryer: completeWashInformation?.washingPlant.dryer || '',
        user: completeWashInformation?.washingPlant.user || '',
        created_at: completeWashInformation?.washingPlant.created_at || '',
        updated_at: completeWashInformation?.washingPlant.updated_at || '',
        air_temperature: completeWashInformation?.washingPlant.air_temperature || '',
      },
      washRecipes: completeWashInformation?.wash_recipe_info,
      washCycles,
    };

    return data;
  }

  async function handleExportPDF(){
    setIsGeneratingPdf(true);
    try{
      const response = formatDataToPdf();
      return Platform.OS === 'web' ? printToWeb(response) : printToMobile(response);
    }catch(error){
      console.log(error);
    }
  };

  const printToMobile = async (data: Iresponse) => {
    if (data !== undefined && user !== undefined) {
      const html = generatePDFHtml(
        data,
        washingData,
        currentRecipe,
        greenRecipe,
        results,
        user
      );
      const response = await Print.printToFileAsync({ html, margins: { top: 10, bottom: 10, left: 10, right: 10 } });

      const _name = convertStringTolowerCaseAndRemoveSpecialCharacters(washRecipe.name);
      const pdfName = `${response.uri.slice(0, response.uri.lastIndexOf('/') + 1)}washing_plant_recipe_${_name}.pdf`

      await FileSystem.moveAsync({
        from: response.uri,
        to: pdfName,
      });

      setIsGeneratingPdf(false);

      await Sharing.shareAsync(pdfName, {
        UTI: '.pdf',
        mimeType: 'application/pdf',
      });
    }
  };

  const printToWeb = (data: Iresponse) => {
    if (data !== undefined && user !== undefined) {
      const pW = window.open('', '', 'height=2480, width=3508');
      pW!.document.write(
        generatePDFHtml(data as Iresponse, washingData, currentRecipe, greenRecipe, results, user)
      );
      setIsGeneratingPdf(false);
    }
  };

  return (
    <ScrollView>
      <SectionTitle showDivider={false} first title={'Report'} />

      <View>
        <Box flexDirection="row" mb={2}>
          <Checkbox
            isDisabled={isGeneratingPdf}
            value="washing-data"
            onChange={() => setWashingData(!washingData)}
            accessibilityState={{ checked: washingData }}
            accessibilityLabel="Washing Data"
          />
          <Text ml={2}>Washing Data</Text>
        </Box>

        <Box flexDirection="row" mb={2}>
          <Checkbox
            isDisabled={isGeneratingPdf}
            value="current-recipe"
            onChange={() => setCurrentRecipe(!currentRecipe)}
            accessibilityState={{ checked: currentRecipe }}
            accessibilityLabel="Current Recipe"
          />
          <Text ml={2}>Current Recipe</Text>
        </Box>
        <Box flexDirection="row" mb={2}>
          <Checkbox
            isDisabled={isGeneratingPdf}
            value="greenofchange-recipe"
            onChange={() => setGreenRecipe(!greenRecipe)}
            accessibilityState={{ checked: greenRecipe }}
            accessibilityLabel="Greenofchange Recipe"
          />
          <Text ml={2}>Greenofchange Recipe</Text>
        </Box>
        <Box flexDirection="row" mb={2}>
          <Checkbox
            isDisabled={isGeneratingPdf}
            value="results"
            onChange={() => setResults(!results)}
            accessibilityState={{ checked: results }}
            accessibilityLabel="Results"
          />
          <Text ml={2}>Results</Text>
        </Box>

        <Button
          isLoading={isGeneratingPdf}
          isLoadingText='Loading your data, please wait...'
          mt={5}
          disabled={isGeneratingPdf}
          onPress={handleExportPDF}
        >
          Generate PDF
        </Button>
      </View>
    </ScrollView>
  );
};


function convertStringTolowerCaseAndRemoveSpecialCharacters(str: string) {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
}