import { MaterialIcons } from '@expo/vector-icons';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Icon,
  IInputProps,
  Stack,
  Text,
  WarningOutlineIcon,
} from 'native-base';
import React, { createRef, useState } from 'react';
import { CustomButton } from '../../../../components/CustomButton';
import { Input } from '../../../../components/Input';
import { firebaseErrorsCodeTranslated } from '../../../../config/firebase-errors';
import { t } from '../../../../languages';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { useAuth } from '../../hooks/useAuth';

interface SignInFormInterface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
}

export function SignInForm({ navigation }: SignInFormInterface) {
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const passwordInputRef = createRef<IInputProps | undefined>();

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  async function handleSignIn(){
    setIsLoading(true)
    try{
      await login(email, password)
      setIsLoading(false)
    }catch(error: any){
      console.log(error)
      setError(firebaseErrorsCodeTranslated.en[error.code])
      setIsLoading(false)
    }
  }

  return (
    <HStack flex={1} pt="24px">
      <FormControl isRequired>
        <Stack height="100%" justifyContent="space-between">
          <Box>
            <Box>
              <FormControl>
                <Input
                  placeholder={t('domain.signIn.inputs.email.placeholder')}
                  value={email}
                  onChangeText={(text) => setEmail(text)}
                  returnKeyType="next"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  //@ts-expect-error
                  onSubmitEditing={() => passwordInputRef.current.focus()}
                  blurOnSubmit={false}
                />
              </FormControl>
            </Box>
            <Box mt="16px">
              <FormControl isInvalid={!!error}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('domain.signIn.inputs.password.placeholder')}
                  value={password}
                  ref={passwordInputRef}
                  onChangeText={(text) => setPassword(text)}
                  autoCapitalize="none"
                  inputRightElement={
                    <Button
                      bgColor="#E5EEED"
                      height={'100%'}
                      onPress={handleToggleShowPassword}
                      m={0}
                    >
                      <Icon
                        color="primary.500"
                        as={
                          <MaterialIcons
                            color="primary.500"
                            name={showPassword ? 'visibility-off' : 'visibility'}
                          />
                        }
                        size={5}
                        m="2"
                      />
                    </Button>
                  }
                />
                <FormControl.ErrorMessage
                  color="red.300"
                  leftIcon={<WarningOutlineIcon mr={1} size="xs" />}
                  textAlign="left"
                  wordBreak="break-all"
                >
                  {error}
                </FormControl.ErrorMessage>
              </FormControl>
              <Box mt={'12px'}>
                <Center>
                  <Button
                    variant="link"
                    justifyContent="flex-start"
                    onPress={() => navigation.navigate('ResetPassword')}
                  >
                    {t('domain.signIn.buttons.forgotPassword')}
                  </Button>
                </Center>
              </Box>
            </Box>
          </Box>
          <Box display="flex">
            <Box
              mb={'12px'}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Text mr={-2} fontSize="md">
                {t('domain.signIn.dontHaveAccount')}
              </Text>
              <Button
                variant="link"
                justifyContent="flex-start"
                onPress={() => navigation.navigate('SignUp')}
              >
                {t('domain.signIn.signUp')}
              </Button>
            </Box>
            <Box display="flex">
              <CustomButton
                isDisabled={!email || !password || isLoading}
                isLoading={isLoading}
                isLoadingText={t('domain.generic.loading')}
                variant="solid"
                onPress={handleSignIn}
              >
                {t('domain.signIn.buttons.signIn')}
              </CustomButton>
            </Box>
          </Box>
        </Stack>
      </FormControl>
    </HStack>
  );
}
