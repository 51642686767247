import {
  IJetWashCycleAuxiliaryComplete,
} from '../../types/wash_cycle_auxiliary.type';

interface ICalcAuxiliaryEnergy {
  auxEqp: IJetWashCycleAuxiliaryComplete | undefined;
}

interface ICalcAuxiliaryLitLoaded {
  equipment_auxiliary_time: number;
  auxiliary_equipment_energy: number;
};

export function calcAuxiliaryEnergy(props: ICalcAuxiliaryEnergy): number {
  const _aTime = parseFloat(`${props.auxEqp?.auxiliary_time || 0}`);
  const _energy = parseFloat(`${props.auxEqp?.auxiliary_equipment?.energy || 0}`);

  return (_aTime * _energy) / 60;
}

export function calcAuxiliaryLitLoaded(props: ICalcAuxiliaryLitLoaded): number {
  const _aTime = parseFloat(`${props.equipment_auxiliary_time || 0}`);
  const _litPerMin = parseFloat(`${props.auxiliary_equipment_energy || 0}`);

  return _aTime * _litPerMin;
}
