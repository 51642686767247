import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const EmptyDefault = (props: SvgProps) => (
  <Svg {...props} width={291} height={136} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M164.75 20.84 146.4 93.71s6 3 8.57-5.5c2.57-8.5 9.78-67.37 9.78-67.37Z"
        fill="#368D7D"
      />
      <Path
        d="M.4 6.02 4.9.78s59.75 16.93 144.5 16.77c84.75-.16 136.73-17 136.73-17l4.13 5.62M190.85 128.26l-1.25 6.49-84.73-.33-.56-5.98 86.54-.18Z"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="m190.86 128.26 11-106.86s1.26-5.77-4-6.07c-5.26-.3-29.58.45-29.58.45s-3.09.4-4 7.25c-.91 6.85-7.88 56.34-7.88 56.34s-.58 15.64-7.87 14.81a8.8 8.8 0 0 1-8-7.56l-8.89-68.18a5.108 5.108 0 0 0-4.8-4.07c-4.11-.22-28.8-.7-28.8-.7s-4.13.64-3.87 6c.26 5.36 10.06 108.79 10.06 108.79l86.63-.2Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M192.89 108.58s-11 .32-15 5-6.38 14.71-6.38 14.71"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M193.4 103.47s-10.18-1.39-17.59 5.06c-7.41 6.45-9.77 19.78-9.77 19.78M102.4 107.92s10.8-.11 16.34 6.41a25.191 25.191 0 0 1 5.81 14.07"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M101.9 102.93s12-.71 20.53 7.75c8.53 8.46 7.59 17.71 7.59 17.71"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="m93.8 18.64-6.78 71.81s0 6 4.29 6.7c4.29.7 10.23 2 10.23 2L93.8 18.64Z"
        fill="#368D7D"
      />
      <Path
        d="M183.49 24.57a6.06 6.06 0 0 0 .52-2.26l.39-9.6s.18-3.87-3.19-4.23a3.351 3.351 0 0 0-3.15 1.49 6.22 6.22 0 0 0-1.16 3.77v8.06a5.994 5.994 0 0 0 .68 2.83 3.53 3.53 0 0 0 2.86 2 3.052 3.052 0 0 0 3.05-2.06Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M180.15 8.21s-4.2-.53-5 2.66c-.8 3.19 1.82 10.25 1.82 10.25v-8.13a4.905 4.905 0 0 1 .819-2.903 4.904 4.904 0 0 1 2.361-1.877Z"
        fill="#368D7D"
      />
      <Path
        d="M118.09 23.39a6.112 6.112 0 0 0 .52-2.27l.44-9.63s.17-3.87-3.2-4.24a3.369 3.369 0 0 0-3.15 1.5 6.279 6.279 0 0 0-1.16 3.77v8.06a6.002 6.002 0 0 0 .67 2.83 3.517 3.517 0 0 0 2.87 2 3.003 3.003 0 0 0 3.01-2.02Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M114.75 7.03s-4.19-.53-5 2.66c-.81 3.19 1.82 10.24 1.82 10.24v-8.12a4.905 4.905 0 0 1 3.18-4.78Z"
        fill="#368D7D"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h290.66v135.25H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default EmptyDefault;
