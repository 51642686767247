import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
  Box,
  Button,
  Center,
  Flex,
  ScrollView,
} from 'native-base';
import React, { useContext, useRef } from 'react';
import { useMutation } from 'react-query';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import Loading from '../../../../components/Loading';
import EmptyCycles from '../../../assets/svgs/empty-cycles';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { IJetWashRecipe } from '../../../types/wash-recipe.type';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { toast } from '../../../utils/toast';
import { AggregatorAddCycleButton } from '../components/AggregatorAddCycleButton';
import { WashRecipeAggregatorContext } from '../contexts/WashRecipeAggregatorContext';
import {
  deleteWashRecipeAggregator,
} from '../services/washRecipeAggregator.service';

interface CycleTabProps {
  navigation: NativeStackNavigationProp<HomeStackParamList, "WashRecipeAggregator", undefined>
  washingPlant: IJetWashingPlant;
  washRecipe: IJetWashRecipe;
}

const CycleTab: React.FC<CycleTabProps> = ({
  navigation,
  washingPlant,
  washRecipe,
}) => {
  const { cycleTypesQuery, isLoading, washingCycleCompleteInfoQuery, aggregators } = useContext(WashRecipeAggregatorContext);
  const scrollViewRef = useRef<HTMLInputElement>();

  const addNewCycle = () => {
    if (aggregators.length === 0)
      navigation.push('washRecipeCycleOnboarding', {
        washRecipe,
        washingPlant,
      });
    else
      navigation.push('washRecipeCycleSelect', {
        washRecipeAggregator: null,
        washingPlant,
        washRecipe,
      });
  };

  const deleteMutation = useMutation(deleteWashRecipeAggregator, {
    onSuccess: () => {
      washingCycleCompleteInfoQuery.refetch()
    },
    onError: () => toast('Error deleting washing recipe'),
  });

  if (isLoading) return (
    <Flex grow={1} mt={5}>
      <Center>
        <Loading />
      </Center>
    </Flex>
  )

  if (aggregators.length === 0) return (
    <Flex grow={1} mt={5}>
      <Center>
        <EmptyPlaceholder
          emptyText={t('domain.analysis.step2.no_cycles_empty')}
          marginTop={10}
          imgComponent={<EmptyCycles />}
          action={{
            text: t('domain.analysis.step2.add_cycle'),
            onPress: addNewCycle,
          }}
        />
      </Center>
    </Flex>
  )

  return (
    <Box mt={2}>
      <>
        <ScrollView ref={scrollViewRef}>
          {aggregators.map((aggregator, index) => {
            const _shouldOpen = aggregators.length === 1 && index === 0;
            return (
              <Box key={`cycle-${aggregator.id}`} mb={4}>
                <AggregatorAddCycleButton
                  shouldBeOpen={_shouldOpen}
                  washAggregator={aggregator}
                  index={index}
                  typeOfCycles={cycleTypesQuery.data?.results || []}
                  isLoading={isLoading}
                  deleteMutation={deleteMutation}
                  washRecipe={washRecipe}
                  washingCycleCompleteInfoQuery={washingCycleCompleteInfoQuery}
                  washingPlant={washingPlant}
                />
              </Box>
            );
          })}
          <Button onPress={addNewCycle} isDisabled={isLoading}>
            {t('domain.analysis.step2.add_cycle')}
          </Button>
        </ScrollView>
      </>
    </Box>
  );
};

export default CycleTab;