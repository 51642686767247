import { Box, ScrollView, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { t } from '../../../languages';
import { FlatList, RefreshControl } from 'react-native';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import { QueryKeyCache } from '../../../config/query-cache';
import { IJetTypeOfCycle } from '../../../types/type-of-cycle.type';
import StandardFormNextButton from '../components/StandardFormNextButton';
import { useStandardRecipeForm } from '../hooks/useStandardRecipeForm';
import { getTypeOfCycles } from '../services/getTypeOfCycles';
import { IStandardRecipeForm } from '../types/standardRecipeForm.type';
import MachineCard from '../../washingPlant/components/MachineCard';
import SectionTitle from '../../../components/SectionTitle';
import RecipeFormStep4GeneralInformation from './step4/RecipeFormStep4GeneralInformation';
import RecipeFormStep4Add from './step4/RecipeFormStep4Add';
import RecipeFormStep4Extras from './step4/RecipeFormStep4Extras';

interface RecipeFormStep4Props {}

export const RecipeFormStep4: React.FC<RecipeFormStep4Props> = () => {
  const { watch, setValue, control } = useFormContext<IStandardRecipeForm>();
  const { nextStep, selectedRecipe, cycleIndex } = useStandardRecipeForm(control);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    QueryKeyCache.GetTypeOfCycles,
    getTypeOfCycles
  );

  return (
    <ScrollView>
      <VStack justifyContent="space-between" flex={1} space="32px">
        <SectionTitle first title={t('domain.analysis.step4.section.general_information.title')}>
          <RecipeFormStep4GeneralInformation />
        </SectionTitle>

        <SectionTitle title={t('domain.analysis.step4.section.add.title')}>
          <RecipeFormStep4Add />
        </SectionTitle>

        <SectionTitle title={t('domain.analysis.step4.section.extras.title')}>
          <RecipeFormStep4Extras />
        </SectionTitle>

        <Box height={1} />
      </VStack>
    </ScrollView>
  );
};

export default RecipeFormStep4;
