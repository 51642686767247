import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { IJetTypeOfCycle } from '../../../types/type-of-cycle.type';
import { IJetWashRecipeID, IJetWashRecipe } from '../../../types/wash-recipe.type';
import {
  IJetWashCycle,
  IJetWashCycleComplete,
  IJetWashCycleCreate,
  IJetWashCycleEdit,
  IJetWashCycleForm,
  IJetWashCycleID,
} from '../../../types/wash_cycle.type';
import {
  IJetWashRecipeAggregator,
  IJetWashRecipeAggregatorComplete,
} from '../../../types/wash_recipe_aggregator.type';
import { getTypeOfCyclesById } from '../../standardRecipe/services/getTypeOfCycles';
import { listWashCycleAuxiliarys } from '../../washCycle/services/auxiliarys.service';
import {
  getWashCycleChemical,
  listWashCycleChemicals,
} from '../../washCycle/services/chemicals.service';
import { listWashCycleLasers } from '../../washCycle/services/lasers.service';
import { editWashRecipe, getWashRecipe } from '../../washRecipe/services/washRecipe.service';
import { editWashRecipeAggregator } from './washRecipeAggregator.service';

type ICurrentRecipe = Pick<IJetWashRecipeAggregator, 'current_recipe'>;
type INewRecipe = Pick<IJetWashRecipeAggregator, 'new_recipe'>;
export type ISelectRecipe = keyof ICurrentRecipe | keyof INewRecipe;
interface ICreateWashCycleParams {
  data: IJetWashCycle;
  washAggregator: IJetWashRecipeAggregator | IJetWashRecipeAggregatorComplete;
  type: ISelectRecipe;
}

export async function getWashCycle(
  id: IJetWashCycleID | undefined
): Promise<IJetWashCycleComplete | undefined> {
  if (!id) return undefined;
  const response = await jetAdmin.retrieve<IJetWashCycle>(JetAdminCollection.WashCycles, id);

  if (!response) return undefined;

  const [
    cycle_chemicals_details,
    cycle_auxiliary_equipments_details,
    cycle_laser_scrapings_details,
    cycle_type_of_cycle_details,
  ] = await Promise.all([
    listWashCycleChemicals(response),
    listWashCycleAuxiliarys(response),
    listWashCycleLasers(response),
    typeOfCyclesDescription(response),
  ]);

  return {
    cycle_chemicals_details,
    cycle_auxiliary_equipments_details,
    cycle_laser_scrapings_details,
    cycle_type_of_cycle_details,
    ...response,
  };
}

export async function typeOfCyclesDescription(params: IJetWashCycle) {
  const obj: IJetTypeOfCycle = {
    id: '',
    name: '',
    active: false,
    standard_cycle: '',
  };
  const typeOfCyclePromice = await getTypeOfCyclesById(params);
  if (typeOfCyclePromice == undefined) return obj;
  return typeOfCyclePromice;
}

export async function listWashCycles({ aggregatorsIDS }: { aggregatorsIDS: IJetWashCycleID[] }) {
  try {
    const aggregatorIDPromiseArr = aggregatorsIDS.map((aggregatorID) => getWashCycle(aggregatorID));
    const aggregatorsResponse = await Promise.all(aggregatorIDPromiseArr);
    // remove all undefined values from the array
    const aggregators = aggregatorsResponse.filter(
      (aggregator) => !!aggregator
    ) as IJetWashCycleComplete[];

    return aggregators;
  } catch (error) {
    return [];
  }
}

export async function createOrEditWashCycle(
  params: ICreateWashCycleParams
): Promise<IJetWashCycle | undefined> {
  const { data, washAggregator, type } = params;

  if (data?.id) {
    const res = await editWashCycle(data);
    return res;
  } else {
    return await createWashCycle({ data, washAggregator, type });
  }
}

export async function createWashCycle(
  props: ICreateWashCycleParams
): Promise<IJetWashCycle | undefined> {
  const { data, washAggregator, type } = props;
  const washCycleCreate = convertWashCycleFormToWashCycleCreate(data);

  const res = await jetAdmin.create<IJetWashCycle>(JetAdminCollection.WashCycles, washCycleCreate);

  if (!res?.id) return res;

  await editWashRecipeAggregator({
    ...washAggregator,
    [type]: res.id,
  });

  return res;
}

export async function editWashCycle(
  props: Partial<IJetWashCycle>
): Promise<IJetWashCycle | undefined> {
  if (!props.id) throw new Error('Wash recipe id is required');

  const res = await jetAdmin.update<IJetWashCycle>(JetAdminCollection.WashCycles, props.id, props);

  return res;
}

function convertWashCycleFormToWashCycleCreate(data: IJetWashCycleCreate): IJetWashCycleCreate {
  return {
    ...data,
    chemicals: data.chemicals || [],
    add_auxiliary_equipment: data.add_auxiliary_equipment || [],
    add_laser_scrapings: data.add_laser_scrapings || [],
  };
}

interface IDeleteWashCycle {
  id: IJetWashCycleID;
  washRecipeID: IJetWashRecipeID;
}

export async function deleteWashCycle(props: IDeleteWashCycle): Promise<void> {
  const _wash = await getWashRecipe(props.washRecipeID);

  if (!_wash) throw new Error('Wash recipe not found');

  // Remove only the id from washCycleIDs array
  const _newArr = _wash?.wash_recipe_aggregators?.filter((washCycleID) => washCycleID !== props.id);

  await editWashRecipe({
    ..._wash,
    wash_recipe_aggregators: _newArr,
  });

  await jetAdmin.delete(JetAdminCollection.WashCycles, props.id);
}
