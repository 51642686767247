import { enUS } from './enUS';
import i18n from 'i18n-js';

export enum Languages {
  ptBR = 'pt-BR',
  enUS = 'en-US',
}

export const DefaultLanguage = Languages.enUS;

type I18NMapSchema<T extends Record<string, keyof any>> = {
  -readonly [K in keyof T]: string;
};

// export type I18N = I18NMapSchema<typeof enUS>;

// export const t = (str: keyof I18N) => i18n.t(str);

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
];

type NonObjectPropertiesOf<T> = Id<{
  [K in keyof T as T[K] extends object ? never : K]: T[K];
}>;

type ValuesOf<T> = T[keyof T];
type ObjectValuesOf<T> = Extract<ValuesOf<T>, object>;

type UnionToIntersection<U> = (U extends unknown ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

type Id<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

type Flatten<T> = NonObjectPropertiesOf<T> & UnionToIntersection<ObjectValuesOf<T>>;

type DeepFlatten<T, D extends number = 20> = [D] extends [never]
  ? never
  : T extends unknown
  ? NonObjectPropertiesOf<T> & UnionToIntersection<DeepFlatten<ObjectValuesOf<T>, Prev[D]>>
  : never;

type RemapKeys<T, D extends number = 20, P extends string = 'domain'> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T as K extends string ? `${P}.${K}` : never]: RemapKeys<
        T[K],
        Prev[D],
        K extends string ? `${P}.${K}` : never
      >;
    }
  : T;

type test = typeof enUS;

/*
type Remapped = {
    "Object.Ab": {
        "Object.Ab.Cd": number;
        "Object.Ab.Ef": {
            "Object.Ab.Ef.Gh": string;
        };
    };
    "Object.Ij": boolean;
}
*/
type Remapped = RemapKeys<test>;

/*
type FlattenOneLevelRemapped = {
    "Object.Ij": boolean;
} & {
    "Object.Ab.Cd": number;
    "Object.Ab.Ef": {
        "Object.Ab.Ef.Gh": string;
    };
}
*/
type FlattenOneLevelRemapped = Flatten<Remapped>;

/*
type DeepFlattenRemapped = {
    "Object.Ij": boolean;
} & {
    "Object.Ab.Cd": number;
} & {
    "Object.Ab.Ef.Gh": string;
}
*/
type DeepFlattenRemapped = DeepFlatten<Remapped>;

type IFilterBack<T> = {
  Object: DeepFlatten<RemapKeys<T>>;
};

export type I18N = DeepFlattenRemapped;

export const t = (str: keyof I18N) => i18n.t(str.split('domain.')[1]);
t('domain.washingPlants.step.four.title_part_two');
