import React, { useEffect, useMemo, useState } from 'react';
import { Box, Text, HStack, Divider, FlatList, VStack, Icon, Button, Pressable } from 'native-base';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MaterialIcons } from '@expo/vector-icons';
import { CustomButton } from '../../../components/CustomButton';
import { useAuth } from '../../auth/hooks/useAuth';
import { Input } from '../../../components/Input';
import { t } from '../../../languages';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { RefreshControl } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeyCache } from '../../../config/query-cache';
import { getWashingPlants } from '../../washingPlant/services/getWashingPlants';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import WashingPlantCard from '../components/WashingPlantCard';
import EmptyAvatar from '../../../../src/assets/svgs/empty-user-avatar';
import { useNavigation } from '@react-navigation/native';
import EmptyWashingPlant from '../../../assets/svgs/empty-washing-plant';
import Loading from '../../../../components/Loading';
interface HomeInterface {
  navigation: NativeStackNavigationProp<HomeStackParamList>;
}

export function Home({ navigation }: HomeInterface) {
  const { navigate } = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();
  const [divHeight, setDivHeight] = useState(0);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const { user, logout } = useAuth();

  const { data, isLoading, isRefetching, refetch } = useQuery(QueryKeyCache.GetWashingPlants, () =>
    getWashingPlants(user?.id!)
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refetch();
    });
    return unsubscribe;
  }, []);

  const isQueryLoading = isLoading || isRefetching;

  const onLayout = (event: any) => {
    const { height } = event.nativeEvent.layout;
    setDivHeight(height);
  };

  const results = useMemo(() => {
    return search
      ? data?.results?.filter((result) => {
        return result.name.toLowerCase().includes(search.toLowerCase());
      }) || []
      : data?.results || []
  }, [search]);

  const arrFlatListDataOrFiltered = search ? results : data?.results || [];

  return (
    <VStack flex={1} justifyContent="space-between">
      <Box>
        {/* <Avatar backgroundColor={'transparent'} size={8} source={{ uri: undefined }} > */}
        <HStack mt="16px" justifyContent="space-between">
          <Pressable onPress={() => navigate('Profile')}>
            <EmptyAvatar />
          </Pressable>
          <Button variant="ghost" onPress={logout}>
            {t('domain.generic.logout')}
          </Button>
        </HStack>
        {/* </Avatar> */}

        <Box mt={4}>
          <HStack>
            <Text fontSize={'xl'}>Hello</Text>
            <Text fontSize={'xl'}> </Text>
            <Text fontSize={'xl'} fontWeight="bold" color="primary.500">
              {user?.name},
            </Text>
          </HStack>
        </Box>

        <Box mt={4}>
          <Input
            type={'text'}
            placeholder={`${t('domain.home.search.placeholder')}`}
            value={search || ''}
            onChangeText={(text) => setSearch(text)}
            autoCapitalize="none"
            InputLeftElement={<Icon as={<MaterialIcons name="person" />} />}
          />
        </Box>

        <Box mt={4}>
          <Text fontSize={14} fontWeight={700} color="primary.500">
            {t('domain.home.washingPlants')}
          </Text>
          <Box width={'100%'} mt={3}>
            <Divider />
          </Box>
        </Box>
      </Box>

      <Box mt={'16px'} flexGrow={1} onLayout={onLayout}>
        <Box height={divHeight} pb="8px">
          {isQueryLoading ? (
            <Loading />
          ) : (
            <FlatList
              data={arrFlatListDataOrFiltered}
              keyExtractor={(item) => item.id}
              ListEmptyComponent={() => (
                <EmptyPlaceholder imgComponent={<EmptyWashingPlant />} marginTop={'70px'} />
              )}
              refreshControl={
                <RefreshControl
                  colors={['#9Bd35A', '#689F38']}
                  refreshing={isLoading || isRefetching}
                  onRefresh={refetch}
                />
              }
              renderItem={({ item, index }: { item: IJetWashingPlant; index: number }) => {
                const isLast = data?.results.length ? index === data?.results.length - 1 : false;
                return (
                  <Box mx={1} pb={'8px'} key={`${index}-washing-plant-card-${item.id}`}>
                    <WashingPlantCard washingPlant={item} onDelete={refetch} />
                    {isLast && <Box height={10} />}
                  </Box>
                );
              }}
            />
          )}
        </Box>
      </Box>

      <Box mt="16px">
        <CustomButton
          color="primary.500"
          variant={'outline'}
          onPress={() => navigation.push('WashingPlant', { washingPlant: undefined })}
        >
          {t('domain.home.button.addNew')}
        </CustomButton>
      </Box>
    </VStack>
  );
}
