import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Text, Box, FormControl, VStack, Center, Flex } from 'native-base';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import Loading from '../../../../components/Loading';
import { Input } from '../../../components/Input';
import FormInput from '../../../components/Input/FormInput';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { toast } from '../../../utils/toast';
import { createOrEditWashingPlant } from '../../washingPlant/services/createWashingPlant';
import StandardFormNextButton from '../components/StandardFormNextButton';
import { useStandardRecipeForm } from '../hooks/useStandardRecipeForm';
import { createWashRecipe } from '../../washRecipe/services/washRecipe.service';
import { IStandardRecipeForm } from '../types/standardRecipeForm.type';

interface RecipeFormStep1Props {}

export const RecipeFormStep1: React.FC<RecipeFormStep1Props> = () => {
  const { navigate } = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();
  const methods = useFormContext<IStandardRecipeForm>();
  const { nextStep, step, selectedWashingPlant, selectWashRecipe } = useStandardRecipeForm(
    methods.control
  );

  const washRecipeName = methods.watch('washName');
  const isWashNameValid = washRecipeName !== '' && washRecipeName !== undefined;

  const { isLoading, mutate } = useMutation(createWashRecipe, {
    onSuccess: (response) => {
      if (!response) return;

      selectWashRecipe(response);
      nextStep();
    },
    onError: () => toast('Error creating washing recipe'),
  });

  const onPressButton = () => {
    mutate({
      data: { washRecipeName },
      washingPlant: selectedWashingPlant,
    });
  };

  return (
    <VStack justifyContent="space-between" flex={1}>
      <VStack flexGrow={1}>
        <Text fontSize={22} fontWeight="bold" color="primary.500">
          {t('domain.analysis.step1.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={22} fontWeight={500}>
              {t('domain.analysis.step1.title_part_two')}
            </Text>
          </Text>
        </Text>

        <Box mt={8}>
          <FormInput
            isLoading={isLoading}
            methods={methods}
            name={'washName'}
            placeholder={t('domain.analysis.step1.input.placeholder')}
          />
        </Box>

        {isLoading && (
          <Flex justifyContent="center" grow={1}>
            <Loading />
          </Flex>
        )}
      </VStack>

      <StandardFormNextButton onPress={onPressButton} isDisabled={!isWashNameValid || isLoading} />
    </VStack>
  );
};

export default RecipeFormStep1;
