import { FieldPath, WatchObserver } from 'react-hook-form';
import { ISelectRecipe } from '../../hooks/useStandardRecipeForm';
import { ICycle as IJetCycle, IStandardRecipeForm } from '../../types/standardRecipeForm.type';

export type IStandardRecipeFormInputName = FieldPath<IJetCycle>;

export function getInputName(
  cycleIndex: number,
  selectedRecipe: ISelectRecipe,
  inputName: keyof IJetCycle
): IStandardRecipeFormInputName {
  // @ts-ignore
  return `cycles.${cycleIndex}.${selectedRecipe}.${inputName}`;
}
