import { useCallback, useContext, useMemo } from 'react';
import { Control, useFieldArray, useFormContext, WatchObserver } from 'react-hook-form';
import { IJetWashRecipe } from '../../../types/wash-recipe.type';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { StandardRecipeContext } from '../contexts/StandardRecipeContext';
import { getInputName } from '../form/utils/getFormInputName';
import {
  IStandardAuxiliaryEquipment,
  IStandardChemicals,
  IStandardLaserScraping,
  ICycle,
  IStandardRecipeCycleForm,
  IStandardRecipeForm,
} from '../types/standardRecipeForm.type';

export type ICurrentRecipe = Pick<IStandardRecipeCycleForm, 'currentRecipe'>;
export type INewRecipe = Pick<IStandardRecipeCycleForm, 'newRecipe'>;
export type ISelectRecipe = keyof ICurrentRecipe | keyof INewRecipe;

export type ISpecificInput =
  | keyof Pick<ICycle, 'add_auxiliary_equipment'>
  | keyof Pick<ICycle, 'add_chemicals'>
  | keyof Pick<ICycle, 'add_laser_scraping'>;

export type IChemicalsInput = keyof IStandardChemicals;
export type IAuxiliaryInput = keyof IStandardAuxiliaryEquipment;
export type ILaserScrapInput = keyof IStandardLaserScraping;

type ISpecificInputNames = IChemicalsInput | IAuxiliaryInput | ILaserScrapInput;

interface CycleConfiguration {
  cycleIndex: number;
}

export enum StandardRecipeFormStep {
  Step1WashName = 1,
  Step2Cycles = 2,
  Step3WhatTypeOfCycle = 3,
  Step4Cycle = 4,
  Step5Chemicals = 5,
  Step6AuxiliaryEquipment = 6,
  Step7LaserScraping = 7,
}

interface UseStandardRecipeForm {
  nextStep: () => void;
  backStep: () => void;
  goToStep: (step: StandardRecipeFormStep) => void;
  goToDeepStep: (step: StandardRecipeFormStep, index: number) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  isSubmitting: boolean;
  step: StandardRecipeFormStep;
  selectedRecipe: ISelectRecipe | null;
  selectedWashingPlant: IJetWashingPlant;
  canShowBackStep: boolean;
  getInputName: (inputName: keyof ICycle) => string;
  getSpecificInputName: (
    input: ISpecificInput,
    index: number,
    input2: ISpecificInputNames
  ) => string;
  selectRecipe: (recipe: ISelectRecipe, cycleIndex: number) => void;
  selectWashingPlant: (washingPlant: IJetWashingPlant) => void;
  selectWashRecipe: (washRecipe: IJetWashRecipe) => void;
  addNewCycle: () => void;
  removeCycle: (cycleIndex: number) => void;

  cycleIndex: number;

  chemicalIndex: number;
  auxiliaryEquipmentIndex: number;
  laserScrapingIndex: number;

  createNewAddCycle: (addCycle: ISpecificInput) => void;
}

export function useStandardRecipeForm(
  control: Control<IStandardRecipeForm, any>
): UseStandardRecipeForm {
  const {
    step,
    setStep,
    isSubmitting,
    setIsSubmitting,
    selectedRecipe,
    setSelectedRecipe,
    selectedWashingPlant,
    setSelectedWashingPlant,
    selectedWashRecipe,
    setSelectedWashRecipe,
    cycleIndex,
    setCycleIndex,
    chemicalIndex,
    setChemicalIndex,
    auxiliaryEquipmentIndex,
    setAuxiliaryEquipmentIndex,
    laserScrapingIndex,
    setLaserScrapingIndex,
  } = useContext(StandardRecipeContext);

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'cycles', // unique name for your Field Array
  });

  const canShowBackStep = useMemo(() => {
    if (step === StandardRecipeFormStep.Step1WashName && selectedWashRecipe !== null) return false;
    if (step === StandardRecipeFormStep.Step2Cycles) return false;

    return [
      step !== StandardRecipeFormStep.Step5Chemicals,
      step !== StandardRecipeFormStep.Step6AuxiliaryEquipment,
      step !== StandardRecipeFormStep.Step7LaserScraping,
    ].every((isOnDeepStep) => isOnDeepStep);
  }, [step]);

  const nextStep = () => {
    // check if step + 1 is one of type AvailableSteps
    if (step + 1 <= StandardRecipeFormStep.Step4Cycle) {
      setStep(step + 1);
    }
  };

  const backStep = () => {
    setStep(step - 1);
  };

  const goToStep = (step: StandardRecipeFormStep) => {
    setStep(step);
  };

  const goToDeepStep = (step: StandardRecipeFormStep, index: number) => {
    if (step === StandardRecipeFormStep.Step5Chemicals) setChemicalIndex(index);
    if (step === StandardRecipeFormStep.Step6AuxiliaryEquipment) setAuxiliaryEquipmentIndex(index);
    if (step === StandardRecipeFormStep.Step7LaserScraping) setLaserScrapingIndex(index);

    setStep(step);
  };

  const selectRecipe = (recipe: ISelectRecipe, cycleIndex: number) => {
    setCycleIndex(cycleIndex);
    setSelectedRecipe(recipe);
  };

  const selectWashingPlant = (washingPlant: IJetWashingPlant) => {
    setSelectedWashingPlant(washingPlant);
  };

  const selectWashRecipe = (washRecipe: IJetWashRecipe) => {
    setSelectedWashRecipe(washRecipe);
  };

  const _getInputName = useCallback(
    (inputName: keyof ICycle) => {
      if (!selectedRecipe) return '';

      return getInputName(cycleIndex, selectedRecipe, inputName);
    },
    [cycleIndex, selectedRecipe]
  );

  const _getSpecificInput = useCallback(
    (input: ISpecificInput, index: number, input2: ISpecificInputNames) => {
      if (!selectedRecipe) return '';

      const _input = getInputName(cycleIndex, selectedRecipe, input);
      return `${_input}.${index}.${input2}`;
    },
    [cycleIndex, selectedRecipe]
  );

  const addNewCycle = () => {
    setCycleIndex(cycleIndex + 1);
    append({ currentRecipe: {}, newRecipe: {} });
  };

  const removeCycle = (index: number) => {
    remove(index);
  };

  function createNewAddCycle(addCycle: ISpecificInput) {
    if (addCycle === 'add_chemicals') setChemicalIndex(chemicalIndex + 1);
    if (addCycle === 'add_auxiliary_equipment')
      setAuxiliaryEquipmentIndex(auxiliaryEquipmentIndex + 1);
    if (addCycle === 'add_laser_scraping') setLaserScrapingIndex(laserScrapingIndex + 1);
  }

  return {
    nextStep,
    backStep,
    canShowBackStep,
    step,
    goToStep,
    goToDeepStep,
    setIsSubmitting,
    isSubmitting,
    selectedRecipe,
    selectWashingPlant,
    selectedWashingPlant: selectedWashingPlant!,
    selectWashRecipe,
    selectRecipe,
    cycleIndex,
    chemicalIndex,
    auxiliaryEquipmentIndex,
    laserScrapingIndex,
    addNewCycle,
    removeCycle,
    getInputName: _getInputName,
    getSpecificInputName: _getSpecificInput,
    createNewAddCycle,
  };
}
