import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetTChemical, IJetTChemicalID } from '../../../types/chemical.type';

export interface IJetChemicalsList extends IJetAdminResponseProtocol<IJetTChemical[]> {}

export async function getChemicals(): Promise<IJetChemicalsList> {
  const res = await jetAdmin.list<IJetTChemical[]>(JetAdminCollection.Chemicals, {
    _per_page: 9999,
  });

  return res;
}

export async function getChemical(chemicalID: IJetTChemicalID): Promise<IJetTChemical | undefined> {
  const res = await jetAdmin.retrieve<IJetTChemical>(JetAdminCollection.Chemicals, chemicalID);

  return res;
}
