import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetCountry } from '../../../types/country.type';
import {
  IJetWashingMachine,
  IJetWashingMachineBrand,
  IJetWashingMachineWithBrand,
} from '../../../types/washing-machine.type';
import { IJetWashingMachineID } from '../../auth/types/IJetWashingMachine';

export interface IJetWashingMachineList
  extends IJetAdminResponseProtocol<IJetWashingMachineWithBrand[]> {}

export async function getWashingMachine(
  id: IJetWashingMachineID | undefined
): Promise<IJetWashingMachine | undefined> {
  if (!id) return undefined;
  const response = await jetAdmin.retrieve<IJetWashingMachine>(
    JetAdminCollection.WashingMachines,
    id
  );

  return response;
}

export async function getAllWashingMachines(): Promise<IJetWashingMachineList> {
  const resBrands = await jetAdmin.list<IJetWashingMachineBrand[]>(
    JetAdminCollection.WashingMachineBrands
  );

  const res = await jetAdmin.list<IJetWashingMachine[]>(JetAdminCollection.WashingMachines);

  return {
    ...res,
    results: res.results.map((machine: IJetWashingMachine) => {
      const brand = resBrands.results.find(
        (brand: IJetWashingMachineBrand) => Number(brand.id) === Number(machine.brand)
      );

      return {
        ...machine,
        brand_detail: brand,
      };
    }),
  };
}


// "results": Array [
//   Object {
//     "active": true,
//     "id": 7,
//     "name": "MAGIK-STIR",
//   },
//   Object {
//     "active": true,
//     "id": 5,
//     "name": "BRONGO",
//   },
//   Object {
//     "active": true,
//     "id": 4,
//     "name": "TONELLO",
//   },
//   Object {
//     "active": true,
//     "id": 3,
//     "name": "JMC",
//   },
//   Object {
//     "active": true,
//     "id": 2,
//     "name": "OMI",
//   },
// ],
// }