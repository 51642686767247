import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg {...props} width={26} height={31} fill="none">
    <Rect width={26} height={31} rx={2} fill="#CFE8E5" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.932.35a.35.35 0 0 1 .35.35v6.656a.35.35 0 1 1-.7 0V.7a.35.35 0 0 1 .35-.35Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.22 8.057H.7v21.542a.7.7 0 0 0 .701.7H24.52a.7.7 0 0 0 .7-.7V8.057Zm.7-.701v22.243c0 .774-.626 1.401-1.4 1.401H1.4A1.401 1.401 0 0 1 0 29.599V7.356h25.92Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m25.22 1.356-.005-.043A.7.7 0 0 0 24.52.7H1.4a.7.7 0 0 0-.695.612l-.005.043v5.825h24.52V1.356ZM.01 1.226H0v6.655h25.92V1.226h-.01A1.401 1.401 0 0 0 24.52 0H1.4C.687 0 .097.535.011 1.226Z"
      fill="#368D7D"
    />
    <Path
      d="M2.977 3.678c0-.484.392-.876.876-.876H5.78a.876.876 0 1 1 0 1.752H3.853a.876.876 0 0 1-.876-.876ZM19.791 3.678c0-.484.392-.876.876-.876h1.926a.876.876 0 0 1 0 1.752h-1.926a.876.876 0 0 1-.876-.876Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.96 27.847a8.582 8.582 0 1 0 0-17.164 8.582 8.582 0 0 0 0 17.164Zm0 .7a9.282 9.282 0 1 0 0-18.564 9.282 9.282 0 0 0 0 18.565Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.96 23.644a4.379 4.379 0 1 0 0-8.757 4.379 4.379 0 0 0 0 8.757Zm0 .7a5.079 5.079 0 1 0 0-10.157 5.079 5.079 0 0 0 0 10.158Z"
      fill="#368D7D"
    />
  </Svg>
);

export default SvgComponent;
