import { Text } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import FormInput from '../../../components/Input/FormInput';
import InputAddon from '../../../components/Input/InputAddon';
import { t } from '../../../languages';
import { IJetWashCycle } from '../../../types/wash_cycle.type';
import { useStandardRecipeForm } from '../../standardRecipe/hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../../standardRecipe/types/standardRecipeForm.type';

interface Step4TimeInputProps {}

export const Step4TimeInput: React.FC<Step4TimeInputProps> = () => {
  const methods = useFormContext<IJetWashCycle>();

  return (
    <>
      <FormInput
        methods={methods}
        name={'general_time'}
        label={t('domain.analysis.step4.section.general_information.input.time.label')}
        placeholder={t('domain.analysis.step4.section.general_information.input.time.placeholder')}
        inputProps={{
          keyboardType: 'numeric',
          maxLength: 6,
          InputRightElement: <InputAddon str={t('domain.units.time.minutes')} />,
        }}
      />
    </>
  );
};

export default Step4TimeInput;
