import React, { createContext, useState } from 'react';

interface WashingPlantProviderInterface {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WashingPlantContext = createContext<WashingPlantProviderInterface>({
  step: 1,
  setStep: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},
});

const WashingPlantProvider: React.FC = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const values: WashingPlantProviderInterface = {
    step,
    setStep,
    isSubmitting,
    setIsSubmitting,
  };

  return <WashingPlantContext.Provider value={values}>{children}</WashingPlantContext.Provider>;
};

export default WashingPlantProvider;
