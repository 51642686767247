import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetWashRecipe, IJetWashRecipeID } from '../../../types/wash-recipe.type';
import { IJetWashingPlant } from '../../../types/washing-plants.type';

export interface IJetWashRecipesList extends IJetAdminResponseProtocol<IJetWashRecipe[]> {}

export async function getWashRecipes(washingPlant: IJetWashingPlant): Promise<IJetWashRecipesList> {
  const res = await jetAdmin.list<IJetWashRecipe[]>(JetAdminCollection.WashRecipes, {
    washing_plant: washingPlant.id,
  });

  return res;
}

export async function deleteWashRecipe(id: IJetWashRecipeID) {
  await jetAdmin.delete(JetAdminCollection.WashRecipes, id);
}
