import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

const IconCheckInactive = (props: SvgProps) => (
  <Svg {...props} width={24} height={24} fill="none">
    <Circle cx={12} cy={12} r={12} fill="#F1F1F1" />
    <Path
      d="m6.75 11.5 3.592 3.5L16.5 9"
      stroke="#AAABAB"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default IconCheckInactive;
