import { setupURLPolyfill } from 'react-native-url-polyfill';
import { Platform } from 'react-native';
import { createClient } from '@supabase/supabase-js'

if (Platform.OS !== 'web'){
  setupURLPolyfill();
}

const supabaseUrl = 'https://gipkdguowmxvravqbvcj.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdpcGtkZ3Vvd214dnJhdnFidmNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg2NjcxOTQsImV4cCI6MjAwNDI0MzE5NH0.0lx8vafiQnFnIBUINQzt0SFU7MlovqgJyB9qYKTqzOc'
const supabaseAdmKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdpcGtkZ3Vvd214dnJhdnFidmNqIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4ODY2NzE5NCwiZXhwIjoyMDA0MjQzMTk0fQ.QllOsFwt_R0xzrK6SmACXiSAcPkuZlbyZPln4TajBAg'
const supabase = createClient(supabaseUrl, supabaseKey)
const supabaseAdmin = createClient(supabaseUrl, supabaseAdmKey)

export { supabase, supabaseAdmin }