import {
  Avatar,
  Box,
  Card,
  Center,
  HStack,
  Icon,
  Input,
  Pressable,
  ScrollView,
  Skeleton,
  Text,
  useContrastText,
  View,
  VStack,
} from 'native-base';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
// @ts-ignore
import BottomSheet from 'react-native-easy-bottomsheet';
import { MaterialIcons } from '@expo/vector-icons';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import EmptyPlaceholder from '../../components/EmptyPlaceholder';
import Loading from '../../components/Loading';
import { t } from '../languages';
import FormInput from './Input/FormInput';

export interface ISelectValues {
  key: string;
  label: string;
}

interface ISelectWithModal {
  values: ISelectValues[];
  inputName: string;
  methods: UseFormReturn<any, any>;
  label: string;
  placeholder: string;
  isLoading: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
  refetch: () => void;
}

export const SelectWithModal: React.FC<ISelectWithModal> = ({
  values,
  inputName,
  methods,
  label,
  placeholder,
  isLoading,
  refetch,
  minHeight = '40%',
  maxHeight = '91%',
}) => {
  const { trigger, watch, setValue } = methods;
  const [isVisible, setVisible] = useState(false);
  const [filteredData, setFilteredData] = useState<ISelectValues[]>([]);
  const selectedValue = watch(inputName);

  function handlePress(value: ISelectValues) {
    if (isLoading) return;

    setValue(inputName, value.key);
    trigger(inputName);
    setVisible(false);
  }

  function onPressFormInput() {
    if (!isLoading) setVisible(true);
  }

  function searchFilter(text: string) {
    if (text) {
      const newData = values.filter((item) =>
        item.label.toUpperCase().includes(text.toUpperCase())
      );
      setFilteredData(newData);
    } else {
      setFilteredData([]);
    }
  }

  return (
    <>
      <View style={styles.centeredView}>
        <BottomSheet
          bottomSheetTitle={label}
          bottomSheetStyle={{
            backgroundColor: 'white',
            maxHeight: maxHeight,
            minHeight: minHeight,
          }}
          // align center
          // bottomSheetTitleStyle={{
          //   color: '#0A2463',
          //   textAlign: 'center',
          //   fontSize: 16,
          //   alignContent: 'center',
          // }}
          onRequestClose={() => setVisible(false)}
          bottomSheetVisible={isVisible}
          onBackdropPress
        >
          {values.length === 0 && !isLoading && (
            <EmptyPlaceholder
              emptyText={t('domain.generic.empty')}
              marginTop={5}
              action={{
                text: t('domain.generic.refresh'),
                onPress: refetch,
              }}
            />
          )}

          {isLoading ? (
            <VStack space="8px">
              <Skeleton h="10" />
              <Skeleton h="10" />
              <Skeleton h="10" />
              <Skeleton h="10" />
              <Skeleton h="10" />
            </VStack>
          ) : (
            <VStack>
              <Input
                placeholder={t('domain.analysis.step5.input.chemical.searchPlaceholder')}
                width="100%"
                borderRadius="4"
                py="3"
                px="1"
                fontSize="14"
                onChangeText={(text) => searchFilter(text)}
                InputLeftElement={
                  <Icon
                    m="2"
                    ml="3"
                    size="6"
                    color="gray.400"
                    as={<MaterialIcons name="search" />}
                  />
                }
              />
              <View>
                {isLoading ? <Loading /> : (
                  <ScrollView h={750}>
                  {filteredData.length > 0
                    ? filteredData
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((value, index) => {
                          return (
                            <Pressable
                              key={`modal-${index}-${inputName}-${value.key}`}
                              onPress={() => handlePress(value)}
                            >
                              <Box my={5}>
                                <Text color="black" fontSize="lg" fontWeight={900}>
                                  {value.label}
                                </Text>
                              </Box>
                            </Pressable>
                          );
                        })
                    : values
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((value, index) => {
                          return (
                            <Pressable
                              key={`modal-${index}-${inputName}-${value.key}`}
                              onPress={() => handlePress(value)}
                            >
                              <Box my={5}>
                                <Text color="black" fontSize="lg" fontWeight={900}>
                                  {value.label}
                                </Text>
                              </Box>
                            </Pressable>
                          );
                        })}
                  <Box mb={10} />
                </ScrollView>
                )}
              </View>
            </VStack>
          )}
        </BottomSheet>
      </View>
      <Box>
        <Pressable onPress={onPressFormInput}>
          <View pointerEvents="none">
            <FormInput
              isLoading={isLoading}
              methods={methods}
              name={inputName}
              inputProps={{
                value: values.find((value) => value.key === selectedValue)?.label || ``,
              }}
              placeholder={placeholder}
              label={label}
            />
          </View>
        </Pressable>
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#3E92CC',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  flag: {
    fontSize: 20,
    fontWeight: 'bold',
    marginRight: 10,
  },
  country: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export default SelectWithModal;
