import React, { useEffect } from 'react';
import { Heading, ScrollView, Stack, Text, VStack } from 'native-base';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { t } from 'i18n-js';
import { Page } from '../../../components/Page';
import { SignInForm } from '../../auth/components/SignInForm';
import { ResetPassword } from '../../auth/screens/ResetPassword';
import { GuestRootStackParamList } from '../../../routes/guest.routes';
import BackHeader from '../../../components/BackHeader';

interface MyRecipesScreenInterface {
  navigation: NativeStackNavigationProp<HomeStackParamList>;
}

export function MyRecipesScreen({ navigation }: MyRecipesScreenInterface) {
  return (
    <Page>
      <BackHeader onPress={() => navigation.navigate('Home')} backTitle />
      <Heading>{t('domain.myRecipesScreen.pageTitle')}</Heading>
      <Text mt="6px">{t('domain.myRecipesScreen.subtitle')}</Text>
      <VStack justifyContent={'space-between'} flex={1} pt="24px"></VStack>
    </Page>
  );
}
