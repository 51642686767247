import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

const Centrifuge = (props: SvgProps) => (
  <Svg {...props} width={26} height={26} fill="none">
    <Circle cx={13} cy={13} r={13} fill="#CFE8E5" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 25.019c6.638 0 12.019-5.381 12.019-12.019S19.638.981 13 .981.981 6.362.981 13 6.362 25.019 13 25.019ZM13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.387 6.53a1.594 1.594 0 1 0 0-3.187 1.594 1.594 0 0 0 0 3.188Zm0 1.115a2.708 2.708 0 1 0 0-5.417 2.708 2.708 0 0 0 0 5.417ZM13.387 22.008a1.594 1.594 0 1 0 0-3.189 1.594 1.594 0 0 0 0 3.189Zm0 1.114a2.708 2.708 0 1 0 0-5.417 2.708 2.708 0 0 0 0 5.417ZM19.578 18.138a1.594 1.594 0 1 0 0-3.188 1.594 1.594 0 0 0 0 3.188Zm0 1.114a2.708 2.708 0 1 0 0-5.417 2.708 2.708 0 0 0 0 5.417ZM6.423 18.138a1.594 1.594 0 1 0 0-3.188 1.594 1.594 0 0 0 0 3.188Zm0 1.114a2.708 2.708 0 1 0 0-5.416 2.708 2.708 0 0 0 0 5.416ZM19.578 11.174a1.594 1.594 0 1 0 0-3.189 1.594 1.594 0 0 0 0 3.189Zm0 1.114a2.708 2.708 0 1 0 0-5.417 2.708 2.708 0 0 0 0 5.417ZM6.423 11.174a1.594 1.594 0 1 0 0-3.189 1.594 1.594 0 0 0 0 3.189Zm0 1.114a2.708 2.708 0 1 0 0-5.417 2.708 2.708 0 0 0 0 5.417Z"
      fill="#368D7D"
    />
  </Svg>
);

export default Centrifuge;
