import { ISelectRecipe } from '../../domains/washRecipeAggregator/services/cycle.service';
import { IJetBoiler, IJetBoilerComplete } from '../../types/boiler.type';
import { IJetCountry } from '../../types/country.type';
import { IJetDryer } from '../../types/dryer.type';
import { IJetHocs } from '../../types/hocs';
import { IJetTUnit } from '../../types/unit.type';
import { IJetWashingPlant } from '../../types/washing-plants.type';
import { IJetWashCycle } from '../../types/wash_cycle.type';
import { CO2_MOLAR_MASS_GMOL, WOOD_CO2_G } from '../constants/contants';
import { calcTotalWater } from '../cycle/cycle.calc';
import { calcFuelUsed } from '../cycle/fuel.calc';
import { calcPercent, DEFAULT_FALLBACK } from './calc-percent.calc';
import { ICalcResponse } from './calc.type';

interface ICalcTotalFuel {
  washingPlant: IJetWashingPlant;
  dryer: IJetDryer | undefined;
  boyler: IJetBoilerComplete | undefined;
  country: IJetCountry | undefined;
  currentCycle: Partial<IJetWashCycle> | undefined;
  greenCycle: Partial<IJetWashCycle> | undefined;
}

interface ICalcPerCycle extends ICalcTotalFuel {
  type: ISelectRecipe;
}

export function calcTotalCostFuelCO2(props: ICalcTotalFuel): ICalcResponse {
  if (props.currentCycle === undefined || props.greenCycle === undefined) return DEFAULT_FALLBACK;

  try {
    const current = calcCostFuelCO2PerCycle({
      ...props,
      type: 'current_recipe',
    });

    const green = calcCostFuelCO2PerCycle({
      ...props,
      type: 'new_recipe',
    });

    const _percent = calcPercent({ current, green });

    return {
      current: current,
      green: green,
      percent: _percent === -1 ? '---%' : `${_percent?.toFixed(0)}%`,
      total: current - green,
      percentNumber: _percent,
    };
  } catch (error) {
    return DEFAULT_FALLBACK;
  }
}

function calcCostFuelCO2PerCycle(props: ICalcPerCycle): number {
  try {
    if (props.boyler?.combustible_detail === undefined) return -1;

    const cycle = props.type === 'current_recipe' ? props.currentCycle : props.greenCycle;
    const selectedFuel = props.boyler.combustible_detail;

    if (
      cycle === undefined ||
      props.country === undefined ||
      props.dryer === undefined ||
      props.boyler === undefined ||
      selectedFuel === undefined
    )
      return -1;

    const _co2 = parseFloat(`${selectedFuel['Molec. Of CO2 generated'] || 0}`);
    const _fuelUsed = calcFuelUsed({
      hoc: selectedFuel,
      country: props.country,
      dryer: props.dryer,
      washingPlant: props.washingPlant,
      cycle,
      boyler: props.boyler,
    });

    // If it's MOL:
    if (selectedFuel.Formula !== undefined) {
      return (_fuelUsed * CO2_MOLAR_MASS_GMOL * _co2) / 1000;
    }

    // If it's Kilogram:
    return _fuelUsed * WOOD_CO2_G;
  } catch (error) {
    return -1;
  }
}
