import { MaterialIcons } from '@expo/vector-icons'
import { Box, Button, Center, Image, Text, VStack } from 'native-base'
import React from 'react'
import { t } from '../src/languages'
import Empty from '../src/assets/svgs/empty-default'

interface EmptyPlaceholderProps {
  emptyText?: string
  marginTop?: string | number
  imgComponent?: React.ReactNode
  action?: {
    text: string
    onPress: () => void
  }
}

export const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = ({
  emptyText,
  marginTop,
  imgComponent,
  action,
}) => {
  return (
    <Center mt={marginTop}>
      <VStack alignContent='center' alignItems='center'>
        <Box mb={4}>{imgComponent ? imgComponent : <Empty />}</Box>
        <Text textAlign={'center'} color='gray.700' fontWeight={500} fontSize={13}>
          {emptyText || t('domain.generic.empty')}
        </Text>
        {action && (
          <Box mt={4}>
            <Button
              onPress={action.onPress}
              color='primary.500'
              fontWeight={500}
              fontSize={13}
            >
              {action.text}
            </Button>
          </Box>
        )}
      </VStack>
    </Center>
  )
}

export default EmptyPlaceholder
