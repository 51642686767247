import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const Dryer = (props: SvgProps) => (
  <Svg {...props} width={26} height={31} fill="none">
    <Rect width={26} height={31} rx={2} fill="#CFE8E5" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.932.35a.35.35 0 0 1 .35.35v6.656a.35.35 0 1 1-.7 0V.701a.35.35 0 0 1 .35-.35Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.22 8.057H.7v21.542a.7.7 0 0 0 .701.7H24.52a.7.7 0 0 0 .7-.7V8.057Zm.7-.701v22.243c0 .774-.627 1.4-1.4 1.4H1.4A1.401 1.401 0 0 1 0 29.6V7.356h25.92Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m25.22 1.356-.005-.043A.7.7 0 0 0 24.52.7H1.4a.7.7 0 0 0-.695.612l-.005.043v5.825h24.52V1.356ZM.011 1.226h-.01v6.655h25.92V1.226h-.01A1.401 1.401 0 0 0 24.52 0H1.4C.687 0 .097.535.011 1.226Z"
      fill="#368D7D"
    />
    <Path
      d="M2.977 3.678c0-.484.392-.876.876-.876h1.926a.876.876 0 1 1 0 1.752H3.853a.876.876 0 0 1-.876-.876ZM19.791 3.678c0-.484.392-.876.876-.876h1.926a.876.876 0 0 1 0 1.752h-1.926a.876.876 0 0 1-.876-.876Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.96 27.847a8.582 8.582 0 1 0 0-17.163 8.582 8.582 0 0 0 0 17.163Zm0 .7a9.282 9.282 0 1 0 0-18.564 9.282 9.282 0 0 0 0 18.565Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 25.561c3.555 0 6.438-2.775 6.438-6.2 0-3.423-2.883-6.199-6.438-6.199-3.556 0-6.438 2.776-6.438 6.2 0 3.424 2.882 6.2 6.438 6.2Zm0 .992c4.124 0 7.468-3.22 7.468-7.191 0-3.972-3.344-7.192-7.468-7.192-4.125 0-7.468 3.22-7.468 7.192S8.875 26.553 13 26.553Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.989 16.679c.183.137.22.397.083.58-.22.294-.507 1.04 0 1.715.82 1.095.333 2.267 0 2.711a.415.415 0 1 1-.664-.498c.22-.294.507-1.039 0-1.715-.82-1.094-.333-2.267 0-2.71a.415.415 0 0 1 .58-.083ZM13.202 16.679c.183.137.22.397.083.58-.22.294-.507 1.04 0 1.715.82 1.095.333 2.267 0 2.711a.415.415 0 1 1-.664-.498c.22-.294.507-1.039 0-1.715-.82-1.094-.333-2.267 0-2.71a.415.415 0 0 1 .58-.083ZM15.415 16.679c.183.137.22.397.083.58-.22.294-.507 1.04 0 1.715.82 1.095.333 2.267 0 2.711a.415.415 0 0 1-.664-.498c.22-.294.507-1.039 0-1.715-.82-1.094-.333-2.267 0-2.71a.415.415 0 0 1 .58-.083Z"
      fill="#368D7D"
    />
  </Svg>
);

export default Dryer;
