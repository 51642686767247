import React, { createContext, useState } from 'react';
import { IJetWashRecipe } from '../../../types/wash-recipe.type';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { ISelectRecipe } from '../hooks/useStandardRecipeForm';

interface StandardRecipeProviderInterface {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;

  selectedRecipe: ISelectRecipe | null;
  setSelectedRecipe: React.Dispatch<React.SetStateAction<ISelectRecipe | null>>;
  selectedWashingPlant: IJetWashingPlant | null;
  setSelectedWashingPlant: React.Dispatch<React.SetStateAction<IJetWashingPlant | null>>;
  selectedWashRecipe: IJetWashRecipe | null;
  setSelectedWashRecipe: React.Dispatch<React.SetStateAction<IJetWashRecipe | null>>;

  cycleIndex: number;
  setCycleIndex: React.Dispatch<React.SetStateAction<number>>;

  chemicalIndex: number;
  setChemicalIndex: React.Dispatch<React.SetStateAction<number>>;
  auxiliaryEquipmentIndex: number;
  setAuxiliaryEquipmentIndex: React.Dispatch<React.SetStateAction<number>>;
  laserScrapingIndex: number;
  setLaserScrapingIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const StandardRecipeContext = createContext<StandardRecipeProviderInterface>({
  step: 1,
  setStep: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},

  selectedRecipe: null,
  setSelectedRecipe: () => {},
  selectedWashingPlant: null,
  setSelectedWashingPlant: () => {},
  selectedWashRecipe: null,
  setSelectedWashRecipe: () => {},

  cycleIndex: 0,
  setCycleIndex: () => {},
  chemicalIndex: 0,
  setChemicalIndex: () => {},
  auxiliaryEquipmentIndex: 0,
  setAuxiliaryEquipmentIndex: () => {},
  laserScrapingIndex: 0,
  setLaserScrapingIndex: () => {},
});

const StandardRecipeProvider: React.FC = ({ children }) => {
  const [cycleIndex, setCycleIndex] = useState<number>(0);
  const [chemicalIndex, setChemicalIndex] = useState<number>(0);
  const [auxiliaryEquipmentIndex, setAuxiliaryEquipmentIndex] = useState<number>(0);
  const [laserScrapingIndex, setLaserScrapingIndex] = useState<number>(0);

  const [selectedWashingPlant, setSelectedWashingPlant] = useState<IJetWashingPlant | null>(null);
  const [selectedRecipe, setSelectedRecipe] = useState<ISelectRecipe | null>(null);
  const [selectedWashRecipe, setSelectedWashRecipe] = useState<IJetWashRecipe | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const values: StandardRecipeProviderInterface = {
    step,
    setStep,
    isSubmitting,
    setIsSubmitting,
    selectedRecipe,
    setSelectedRecipe,
    selectedWashingPlant,
    setSelectedWashingPlant,
    selectedWashRecipe,
    setSelectedWashRecipe,
    cycleIndex,
    setCycleIndex,
    chemicalIndex,
    setChemicalIndex,
    auxiliaryEquipmentIndex,
    setAuxiliaryEquipmentIndex,
    laserScrapingIndex,
    setLaserScrapingIndex,
  };

  return <StandardRecipeContext.Provider value={values}>{children}</StandardRecipeContext.Provider>;
};

export default StandardRecipeProvider;
