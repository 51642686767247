import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const BoilerBig = (props: SvgProps) => (
  <Svg width={45} height={54} fill="none" {...props}>
    <Path
      d="M22.087 53c16.4 0 20.833-16 21-24-3.2 3.2-8 3.333-10 3 0-3-1.404-15.996-6-18-4.587-2-3.667-8.667-4-12.5-9.6 5.2-11.667 22.5-11.5 30.5-2.8.4-8.167-4.5-10.5-7-.5 8 .5 28 21 28Z"
      fill="#CFE8E5"
      stroke="#368D7D"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Svg>
);

export default BoilerBig;
