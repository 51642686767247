import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetCountry } from '../../../types/country.type';
import {
  IJetDryer,
  IJetDryerBrand,
  IJetDryerID,
  IJetDryerWithBrand,
} from '../../../types/dryer.type';

export interface IJetDryerList extends IJetAdminResponseProtocol<IJetDryerWithBrand[]> {}

export async function getDryer(id: IJetDryerID | undefined): Promise<IJetDryer | undefined> {
  if (!id) return undefined;
  const response = await jetAdmin.retrieve<IJetDryer>(JetAdminCollection.Dryers, id);

  return response;
}

export async function getDryers(): Promise<IJetDryerList> {
  const resBrands = await jetAdmin.list<IJetDryerBrand[]>(JetAdminCollection.DryerBrands);

  const res = await jetAdmin.list<IJetDryer[]>(JetAdminCollection.Dryers);

  return {
    ...res,
    results: res.results.map((dryer: IJetDryer) => {
      const brand = resBrands.results.find((brand: IJetDryerBrand) => brand.id === dryer.brand);

      return {
        ...dryer,
        brand_detail: brand,
      };
    }),
  };
}
