import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import {
  IJetCentrifugeWithBrand,
  IJetCentrifugeBrand,
  IJetCentrifuge,
  IJetCentrifugeID,
} from '../../../types/centrifuge.type';

export interface IJetCentrifugeList extends IJetAdminResponseProtocol<IJetCentrifugeWithBrand[]> {}

export async function getCentrifuge(
  id: IJetCentrifugeID | undefined
): Promise<IJetCentrifuge | undefined> {
  if (!id) return undefined;
  const response = await jetAdmin.retrieve<IJetCentrifuge>(JetAdminCollection.Centrifuges, id);

  return response;
}

export async function getCentrifuges(): Promise<IJetCentrifugeList> {
  const resBrands = await jetAdmin.list<IJetCentrifugeBrand[]>(
    JetAdminCollection.CentrifugesBrands
  );

  const res = await jetAdmin.list<IJetCentrifuge[]>(JetAdminCollection.Centrifuges);

  return {
    ...res,
    results: res.results.map((machine: IJetCentrifuge) => {
      const brand = resBrands.results.find(
        (brand: IJetCentrifugeBrand) => brand.id === machine.brand
      );

      return {
        ...machine,
        brand_detail: brand,
      };
    }),
  };
}
