import { IJetCountry } from '../../types/country.type';
import { IJetDryer } from '../../types/dryer.type';
import { IJetWashingPlant } from '../../types/washing-plants.type';
import { IJetWashCycle } from '../../types/wash_cycle.type';
import { AIR_SPEC_HEAT_JKG, WATER_SPEC_HEAT_JKG } from '../constants/contants';
import { calcAuxiliaryLitLoaded } from './auxiliary.calc';
import { IJetAuxiliaryEquipmentsQueryResult } from '../../types/wash-recipe.type';

/**
 * @param cycle
 * @param washingPlant
 * @returns
 */
export function calcTotalWater(
  cycle: Partial<IJetWashCycle>,
  washingPlant: Partial<IJetWashingPlant>
): number {
  const {
    rinses_amount_bath_ratio,
    amount_of_rinses,
    general_bath_ratio,
    cycle_auxiliary_equipment
  } = cycle;
  const { overall_load } = washingPlant;

  const _auxiliaryLitLoaded = calcLitLoaded(cycle_auxiliary_equipment || []);

  const _overallLoad = parseFloat(`${overall_load || 0}`);
  const _bathRatio = parseFloat(`${general_bath_ratio || 0}`);
  const _rinseBathRatio = parseFloat(`${rinses_amount_bath_ratio || 0}`);
  const _rinseAmount = parseFloat(`${amount_of_rinses || 0}`);

  return (_overallLoad * _bathRatio)
    + (_overallLoad * _rinseBathRatio * _rinseAmount)
    + _auxiliaryLitLoaded;
}

function calcLitLoaded(cycle_auxiliary_equipment: IJetAuxiliaryEquipmentsQueryResult[]){
  return cycle_auxiliary_equipment?.reduce(
    (acc: number, val: IJetAuxiliaryEquipmentsQueryResult) => {
      if(!val) return 0;
      const auxiliaryLitLoadedCalc = calcAuxiliaryLitLoaded({
        equipment_auxiliary_time: val?.auxiliary_time,
        auxiliary_equipment_energy: val?.equipment?.energy,
      });
      return acc += auxiliaryLitLoadedCalc;
  }, 0);
}

export function calcAirHeat(
  cycle: Partial<IJetWashCycle>,
  dryer: Partial<IJetDryer>,
  washingPlant: Partial<IJetWashingPlant>
): number {
  const _AirSpecHeat = AIR_SPEC_HEAT_JKG;
  const _airFlow = parseFloat(`${dryer['Air flow (m3/h)'] || 0}`);
  const _dTime = parseFloat(`${cycle.extras_drying_time || 0}`);
  const _dTemp = parseFloat(`${cycle.extras_drying_temperature || 0}`);
  const _airTemp = parseFloat(`${washingPlant.air_temperature || 0}`);

  return (_AirSpecHeat * _airFlow * _dTime * (_dTemp - _airTemp)) / 1000;
}

export function calcWaterHeat(
  cycle: Partial<IJetWashCycle>,
  washingPlant: Partial<IJetWashingPlant>
): number {
  const { general_bath_ratio, rinses_amount_bath_ratio, rinses_amount_time, amount_of_rinses } =
    cycle;
  const { overall_load, water_temperature } = washingPlant;

  const _waterSpecHeat = WATER_SPEC_HEAT_JKG;
  const _washingLoad = parseFloat(`${overall_load || 0}`);
  const _cycleBathRatio = parseFloat(`${general_bath_ratio || 0}`);
  const _waterTemp = parseFloat(`${cycle.general_temperature || 0}`);
  const _waterFeedTemp = parseFloat(`${washingPlant.water_temperature || 0}`);
  const _rinsesAmount = parseFloat(`${amount_of_rinses || 0}`);
  const _rinseBathRatio = parseFloat(`${rinses_amount_bath_ratio || 0}`);
  const _RTemp = parseFloat(`${washingPlant.water_temperature || 0}`);

  const pt1 =
    (_waterSpecHeat * _washingLoad * _cycleBathRatio * (_waterTemp - _waterFeedTemp)) / 1000;
  const pt2 =
    (_rinsesAmount *
      (_waterSpecHeat * _washingLoad * _rinseBathRatio) *
      (_RTemp - _waterFeedTemp)) /
    1000;

  return pt1 + pt2;
}
