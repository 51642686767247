import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Box, VStack } from 'native-base';
import React, { useState } from 'react';
import * as yup from 'yup';
import BackHeader from '../../../../components/BackHeader';
import { CustomButton } from '../../../../components/CustomButton';
import FormInput from '../../../../components/Input/FormInput';
import { t } from '../../../../languages';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { useAuth } from '../../../auth/hooks/useAuth';
import { IJetCreateUser, IJetCreateUserStepOne, IJetCreateUserStepTwo } from '../../../auth/services/createUserOnJetAdmin';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export interface SignUpForm2Interface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
  fieldsStepOne: IJetCreateUserStepOne | null;
}

export function SignUpForm2({ navigation, fieldsStepOne }: SignUpForm2Interface) {
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const { register } = useAuth();

  const schema: yup.SchemaOf<IJetCreateUserStepTwo> = yup.object({
    password: yup.string()
      .required()
      .label(t('domain.signUp.screen3.passwordInputLabel')),
    password_confirm: yup.string()
      .required()
      .oneOf([yup.ref('password'), null], t('domain.form.validation.error.passwordMatch'))
      .label(t('domain.signUp.screen3.confirmPasswordInputLabel')),
  });

  const methods = useForm<IJetCreateUserStepTwo>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  async function onSubmit(data: IJetCreateUserStepTwo) {
    setIsSubmiting(true);

    try {
      await register({...data, ...fieldsStepOne} as IJetCreateUser);

      navigation.push('SignUpSuccess');
    } catch (error) {
      return error
    } finally {
      setIsSubmiting(false);
    }
  }

  return (
    <>
      <VStack mt="16px" justifyContent="space-between" flex="1">
        <VStack space="8px">
          <BackHeader buttonText={'buttonText'} />

          <FormInput
            methods={methods}
            name={'password'}
            placeholder={t('domain.signUp.screen3.passwordInputPlaceholder')}
            inputProps={{
              isDisabled: isSubmiting,
              autoCapitalize: 'none',
              type: 'password',
            }}
          />

          <FormInput
            methods={methods}
            name={'password_confirm'}
            placeholder={t('domain.signUp.screen3.confirmPasswordInputPlaceholder')}
            inputProps={{
              isDisabled: isSubmiting,
              autoCapitalize: 'none',
              type: 'password',
            }}
          />
        </VStack>

        <Box display="flex">
          <CustomButton
            variant="solid"
            bgColor="#409D27"
            isLoading={isSubmiting}
            isLoadingText={t('domain.generic.loading')}
            isDisabled={!methods.formState.isValid || isSubmiting}
            mt={10}
            onPress={methods.handleSubmit(onSubmit)}
          >
            {t('domain.signUp.screen3.buttonSubmit')}
          </CustomButton>
        </Box>
      </VStack>
    </>
  );
}
