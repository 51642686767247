import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from '../../../../../components/Input/FormInput';
import { t } from '../../../../../languages';
import { useStandardRecipeForm } from '../../../hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../../../types/standardRecipeForm.type';

interface Step4BathRatioInputProps {}

export const Step4BathRatioInput: React.FC<Step4BathRatioInputProps> = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { getInputName } = useStandardRecipeForm(methods.control);

  const inputName = getInputName('general_bath_ratio');

  return (
    <FormInput
      methods={methods}
      name={inputName}
      label={t('domain.analysis.step4.section.general_information.input.bath_ratio.label')}
      placeholder={t(
        'domain.analysis.step4.section.general_information.input.bath_ratio.placeholder'
      )}
      inputProps={{
        keyboardType: 'numeric',
        maxLength: 6,
      }}
    />
  );
};

export default Step4BathRatioInput;
