import { IJetTAuxiliaryEquipment } from '../../types/auxiliary_equipment.type';
import { IJetCentrifuge } from '../../types/centrifuge.type';
import { IJetDryer } from '../../types/dryer.type';
import { IJetTLaserScraping } from '../../types/laser_scraping.type';
import { IJetWashingMachine } from '../../types/washing-machine.type';
import { IJetWashingPlant } from '../../types/washing-plants.type';
import { IJetWashCycle, IJetWashCycleComplete } from '../../types/wash_cycle.type';
import { IJetWashCycleLaser } from '../../types/wash_cycle_laser.type';
import { calcLaserTime } from '../cycle/laser.calc';
import { calcPercent, DEFAULT_FALLBACK } from './calc-percent.calc';
import { ICalcResponse } from './calc.type';

interface ICalcTotalCostCycleTime {
  washMachine: IJetWashingMachine | undefined;
  currentCycle: Partial<IJetWashCycleComplete> | undefined;
  greenCycle: Partial<IJetWashCycleComplete> | undefined;
  washingPlant: IJetWashingPlant | undefined;
  centrifuge: IJetCentrifuge | undefined;
  dryer: IJetDryer | undefined;
}

interface ICalcPerCycle extends ICalcTotalCostCycleTime {
  type: 'current' | 'green';
}

export function calcTotalCostCycleTime(props: ICalcTotalCostCycleTime): ICalcResponse {
  if (props.currentCycle === undefined || props.greenCycle === undefined) return DEFAULT_FALLBACK;

  try {
    const current = calcCostCycleTimePerCycle({
      ...props,
      type: 'current',
    });

    const green = calcCostCycleTimePerCycle({
      ...props,
      type: 'green',
    });

    const _percent = calcPercent({ current, green });

    return {
      current: current,
      green: green,
      percent: _percent === -1 ? '---%' : `${_percent?.toFixed(0)}%`,
      total: current - green,
      percentNumber: _percent,
    };
  } catch (error) {
    return DEFAULT_FALLBACK;
  }
}

export function calcCostCycleTimePerCycle(props: ICalcPerCycle) {
  if (
    props.washMachine === undefined ||
    props.centrifuge === undefined ||
    props.dryer === undefined ||
    props.washingPlant === undefined
  )
    return -2;

  const cycle = props.type === 'current' ? props.currentCycle : props.greenCycle;

  if (
    cycle?.cycle_auxiliary_equipments_details === undefined ||
    cycle?.cycle_laser_scrapings_details === undefined
  )
    return -3;

  const _auxiliaryTime = cycle.cycle_auxiliary_equipments_details.reduce((acc, val) => {
    const _converted = parseFloat(`${val.auxiliary_time || 0}`);

    // true = not in parallel
    // false = in parallel
    const _parallelTime = val.auxiliary_inparallel ? _converted : 0;

    return (acc += _parallelTime);
  }, 0);

  const _lTime = cycle.cycle_laser_scrapings_details.reduce(
    (acc, laser) => (acc += calcLaserTime({ laser, washingPlant: props.washingPlant })),
    0
  );

  // const _wCycleTime = parseFloat(`${props.washMachine.cycleTime_kw || 0}`);
  const _wTime = parseFloat(`${cycle?.general_time || 0}`);
  const _rinses = parseFloat(`${cycle?.amount_of_rinses || 0}`);
  const _Rtime = parseFloat(`${cycle?.rinses_amount_time || 0}`);
  const _aTime = parseFloat(`${_auxiliaryTime || 0}`);
  const _cTime = parseFloat(`${cycle?.extras_centrifuge_run_time || 0}`);
  const _dTime = parseFloat(`${cycle?.extras_drying_time || 0}`);
  // const _lTime = calcLaserTime({
  //   laser,
  //   washingPlant: props.washingPlant
  // });

  return _wTime + _rinses * _Rtime + (_aTime + _cTime + _dTime + _lTime);
}
