import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import WashLaserForm from './WashLaserForm';

type WashLaserRootProps = NativeStackScreenProps<HomeStackParamList, 'washRecipeCycleLasers'>;

export const WashLaserRoot: React.FC<WashLaserRootProps> = ({
  route,
  navigation
}) => {
  const { cycle, laser, currentAggregator, recipeName, } = route.params;

  return <WashLaserForm
    washCycle={cycle}
    laser={laser}
    navigation={navigation}
    currentAggregator={currentAggregator}
    recipeName={recipeName}
  />;
};

export default WashLaserRoot;
