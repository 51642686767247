import { Box, Text, VStack } from 'native-base';
import React from 'react';
import { t } from '../../../../languages';
import AddRecipeButton from '../../components/AddRecipeButton';
import AuxiliaryEquipmentsList from './AuxiliaryEquipmentList';
import ChemicalsList from '../../../washCycle/sections/chemicals/ChemicalsList';
import LaserScrapingList from './LaserScrapingList';

interface RecipeFormStep4AddProps {}

export const RecipeFormStep4Add: React.FC<RecipeFormStep4AddProps> = () => {
  return (
    <VStack space={'32px'}>
      <Box>
        <Text color="primary.500" mb="8px">
          {t('domain.analysis.step4.section.add.subsection.chemicals.title')}
        </Text>
        <ChemicalsList />
      </Box>

      <Box>
        <Text color="primary.500" mb="8px">
          {t('domain.analysis.step4.section.add.subsection.auxiliary_equipment.title')}
        </Text>
        <AuxiliaryEquipmentsList />
      </Box>

      <Box>
        <Text color="primary.500" mb="8px">
          {t('domain.analysis.step4.section.add.subsection.laser_scraping.title')}
        </Text>
        <LaserScrapingList />
      </Box>
    </VStack>
  );
};

export default RecipeFormStep4Add;
