import { Center, Text, Button, Modal, Box, HStack, Icon } from 'native-base';
import React, { useEffect, useState } from 'react';
import { CustomButton } from '../../../components/CustomButton';
import { t } from '../../../languages';

interface Step4ModalConfirmProps {
  name?: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  onConfirmStandard: () => void;
}

const Step4ModalConfirm: React.FC<Step4ModalConfirmProps> = ({
  name,
  showModal,
  setShowModal,
  onConfirm,
  onConfirmStandard,
}) => {
  function _onConfirm() {
    onConfirm();
    setShowModal(false);
  }

  function _onConfirmStandardRecipe() {
    onConfirmStandard();
    setShowModal(false);
  }

  return (
    <Center>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        _backdrop={{
          _dark: {
            bg: '#153731',
          },
          bg: '#153731',
        }}
      >
        <Modal.Content>
          <Modal.Body style={{ backgroundColor: 'white' }}>
            <Modal.CloseButton />
            <Box p={2}>
              <Text fontWeight="bold" color="black">
                {t('domain.analysis.step3.modal.title')}
              </Text>

              <Box mt="16px">{`${t('domain.analysis.step3.modal.subtitle')} ${name}?`}</Box>

              <Box mt="23px" mb="16px">
                <CustomButton
                  variant={'solid'}
                  onPress={_onConfirmStandardRecipe}
                >
                  {t('domain.analysis.step3.modal.button.use_standard')}
                </CustomButton>
              </Box>

              <CustomButton
                variant={'solid'}
                bgColor="#F1F8F7"
                color="primary.500"
                onPress={_onConfirm}
              >
                {t('domain.analysis.step3.modal.button.use_own_recipe')}
              </CustomButton>
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Center>
  );
};

export default Step4ModalConfirm;
