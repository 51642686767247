import { MaterialIcons } from '@expo/vector-icons';
import { View, Text, HStack, Stack, VStack, Box, FormControl } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import BackHeader from '../../../components/BackHeader';
import { CustomButton } from '../../../components/CustomButton';
import { Input } from '../../../components/Input';
import FormInput from '../../../components/Input/FormInput';
import InputAddon from '../../../components/Input/InputAddon';
import { t } from '../../../languages';
import { IWashingPlantForm } from '../types/washingPlantForm.type';

interface WashingFormStep3Props {}

export const WashingFormStep3: React.FC<WashingFormStep3Props> = () => {
  const [firstRenderOverall, setFirstRenderOverall] = useState<boolean>(true);
  const [firstRenderGarments, setFirstRenderGarments] = useState<boolean>(true);
  const methods = useFormContext<IWashingPlantForm>(); // retrieve all hook methods

  const [overall, numberOfGarments] = methods.watch(['overallLoad', 'numberOfGarments']);

  useEffect(() => {
    if (firstRenderOverall) {
      setFirstRenderOverall(false);
    } else methods.trigger('overallLoad');
  }, [overall]);

  useEffect(() => {
    if (firstRenderGarments) {
      setFirstRenderGarments(false);
    } else methods.trigger('numberOfGarments');
  }, [numberOfGarments]);

  return (
    <>
      <Box>
        <Text fontSize={22} fontWeight="bold" color="primary.500">
          {t('domain.washingPlants.step.three.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={22} fontWeight={500}>
              {t('domain.washingPlants.step.three.title_part_two')}
              <Text fontSize={22} fontWeight="bold" color="primary.500">
                {' ' + t('domain.washingPlants.step.three.title_part_three')}
              </Text>
            </Text>
          </Text>
        </Text>

        <Box mt={8}>
          <FormInput
            methods={methods}
            name={'overallLoad'}
            label={t('domain.washingPlants.step.three.input.overall.label')}
            placeholder={t('domain.washingPlants.step.three.input.overall.placeholder')}
            inputProps={{
              type: 'number',
              keyboardType: 'numeric',
              InputRightElement: <InputAddon str={t('domain.units.other.kg')} />,
            }}
          />
        </Box>

        <Box mt={8}>
          <FormInput
            methods={methods}
            name={'numberOfGarments'}
            placeholder={t('domain.washingPlants.step.three.input.garments.placeholder')}
            label={t('domain.washingPlants.step.three.input.garments.label')}
            inputProps={{
              type: 'number',
              keyboardType: 'numeric',
              InputRightElement: <InputAddon str={t('domain.units.other.units')} />,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default WashingFormStep3;
