import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Box, Center, Heading, HStack, Image, Stack, Text } from 'native-base';
import React from 'react';
import Img from '../../../../assets/images/reset-sucess.png';
import BackHeader from '../../../../components/BackHeader';
import { CustomButton } from '../../../../components/CustomButton';
import { Page } from '../../../../components/Page';
import { t } from '../../../../languages';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';

interface ResetSuccessRouteProps {
  params: {
    email: string;
  };
}

interface ResetSuccessInterface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
  route: ResetSuccessRouteProps;
}

export function ResetSuccess({ route, navigation }: ResetSuccessInterface) {
  return (
    <Page
      backTitle={t('domain.resetPassword.backTitle')}
      buttonText={t('domain.resetPassword.backTitle')}
    >
      <BackHeader
        onPress={() => navigation.navigate('SignIn')}
        backTitle
        buttonText={t('domain.resetPassword.backToSignIn')}
      />
      <Box pb={'200px'}>
        <Center h="70%">
          <Heading fontWeight="bold">{t('domain.resetPassword.successHeading')}</Heading>
          <Box my={'32px'}>
            <Image source={Img} alt="reset sucess img" />
          </Box>
          <Center>
            <Text fontSize="sm">{t('domain.resetPassword.successText')}</Text>
            <Text color="#409D27">{route.params?.email}</Text>
          </Center>
        </Center>
      </Box>
    </Page>
  );
}
