import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { IJetHelp } from '../../../types/help.type';
import { IJetWashRecipeAggregatorID } from '../../../types/wash_recipe_aggregator.type';

interface ICreateJet {
  help: IJetHelp;
}

export async function createHelpLaser(props: ICreateJet): Promise<IJetHelp | undefined> {
  const { help } = props;

  const res = await jetAdmin.create<IJetHelp>(JetAdminCollection.AskForHelp, help);

  return res;
}

export async function hasHelpSolicited(
  washAggregatorID: IJetWashRecipeAggregatorID | undefined
): Promise<boolean> {
  if (!washAggregatorID) return false;

  const res = await jetAdmin.list(JetAdminCollection.AskForHelp, {
    wash_aggregator: washAggregatorID,
    resolved: false,
  });

  return res.count >= 1;
}
