import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Box, Divider, HStack, ScrollView, Text, VStack } from 'native-base';
import React, { useContext, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import BackHeader from '../../../components/BackHeader';
import Confirm from '../../../components/Confirm';
import FormInput from '../../../components/Input/FormInput';
import InputAddon from '../../../components/Input/InputAddon';
import SelectWithModal, { ISelectValues } from '../../../components/SelectWithModal';
import { QueryKeyCache } from '../../../config/query-cache';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../types/wash_cycle.type';
import { IJetWashCycleLaser } from '../../../types/wash_cycle_laser.type';
import { toast } from '../../../utils/toast';
import { deleteWashCycleLaser } from '../../washCycle/services/lasers.service';
import { getLaserScrapings } from '../services/getLaserScrapings';
import { WashRecipeAggregatorContext } from '../../washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import { Platform } from 'react-native';

interface RecipeFormStep7Props {
  washCycle: IJetWashCycle;
  laser: IJetWashCycleLaser | undefined;
  isSubmitting: boolean;
  currentAggregator: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe";
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleLasers', undefined>;
}

const SPACE = '32px';

export const RecipeFormStep7: React.FC<RecipeFormStep7Props> = ({
  laser,
  washCycle,
  isSubmitting,
  currentAggregator,
  recipeName,
  navigation,
}) => {
  const { goBack } = navigation;
  const { setAllLasersScrapings, removeLaserFromCompleteInfo } = useContext(WashRecipeAggregatorContext);
  const methods = useFormContext<IJetWashCycleLaser>();
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [showModalRemoveLasers, setShowModalRemoveLasers] = useState<boolean>(false);

  const inputLaserScrapingID: keyof IJetWashCycleLaser = 'laser_id';
  const inputLaserScrapingMachine: keyof IJetWashCycleLaser = 'laser_machine_operation_time';
  const inputLaserScrapingLabor: keyof IJetWashCycleLaser = 'laser_labor_operation_time';

  // otimizar essa query via jetadmin
  const LaserScrapingQuery = useQuery(QueryKeyCache.getLaserScrapings, getLaserScrapings, {
    cacheTime: (60 * 60 * 24) * 3, // 3 days
    onSuccess: (data) => setAllLasersScrapings(data.results),
  });

  const isLoading = LaserScrapingQuery.isLoading || isSubmitting;

  const laserScrapings: ISelectValues[] = useMemo(
    () =>
      (LaserScrapingQuery.data?.results || []).map(({ id, model, brand_detail }) => ({
        key: id,
        label: `${brand_detail?.name} - ${model}`,
      })),
    [LaserScrapingQuery.data]
  );

  const removeMutation = useMutation(deleteWashCycleLaser, {
    onSuccess: () => {
      methods.reset();
      Platform.OS !== 'web' && toast(t('domain.generic.success'));
      setShowModalRemoveLasers(false);
      setShowModalConfirm(false);
      goBack();
    },
    onError: () => toast(t('domain.generic.error')),
  });

  function removeLaserEquipment() {
    if (laser) {
      removeMutation.mutate({ laser, cycle: washCycle });
      removeLaserFromCompleteInfo(
        currentAggregator.aggregator_id,
        recipeName,
        laser || {}
      );
    };
  }

  function onPressBackHeader() {
    methods.reset(laser);
    goBack();
  }

  return (
    <>
      <ScrollView>
        <VStack justifyContent="space-between" flex={1} space={SPACE}>
          <HStack alignItems="center" alignContent="center" justifyContent="space-between">
            <BackHeader mb="8px" onPress={onPressBackHeader} backTitle />

            {laser && (
              <Confirm
                onConfirm={removeLaserEquipment}
                buttonText={t('domain.analysis.step2.button.remove')}
                button={{ mb: 3 }}
                message={`${t('domain.generic.delete_message')}`}
                onConfirmToastMessage={t('domain.generic.removeSuccessfully')}
                openModal={showModalRemoveLasers}
                setShowModal={setShowModalRemoveLasers}
                isLoading={removeMutation.isLoading}
              />
            )}
          </HStack>

          <Text fontSize={22} fontWeight={500}>
            {t('domain.analysis.step7.title_part_one')}
            <Text>
              {' '}
              <Text fontSize={22} fontWeight="bold" color="primary.500">
                {t('domain.analysis.step7.title_part_two')}{' '}
                <Text fontSize={22} fontWeight={500}>
                  {t('domain.analysis.step7.title_part_three')}
                </Text>
              </Text>
            </Text>
          </Text>

          <Box>
            <SelectWithModal
              values={laserScrapings}
              refetch={LaserScrapingQuery.refetch}
              isLoading={isLoading}
              inputName={inputLaserScrapingID}
              methods={methods}
              label={t('domain.analysis.step7.input.laser_scraping.label')}
              placeholder={t('domain.analysis.step7.input.laser_scraping.placeholder')}
            />
          </Box>

          <Divider />

          <FormInput
            isLoading={isLoading}
            methods={methods}
            name={inputLaserScrapingMachine}
            label={t('domain.analysis.step7.input.machine_operation_time.label')}
            placeholder={t('domain.analysis.step7.input.machine_operation_time.placeholder')}
            inputProps={{
              keyboardType: 'numeric',
              maxLength: 6,
              InputRightElement: <InputAddon str={t('domain.units.time.seconds')} />,
            }}
          />

          <FormInput
            isLoading={isLoading}
            methods={methods}
            name={inputLaserScrapingLabor}
            label={t('domain.analysis.step7.input.labor_operation_time.label')}
            placeholder={t('domain.analysis.step7.input.labor_operation_time.placeholder')}
            inputProps={{
              keyboardType: 'numeric',
              maxLength: 6,
              InputRightElement: <InputAddon str={t('domain.units.time.seconds')} />,
            }}
          />

          <Confirm
            showButton={false}
            onConfirm={removeLaserEquipment}
            buttonText={t('domain.analysis.step2.button.remove')}
            button={{ mb: 3 }}
            message={`${t('domain.analysis.step7.back.modal.message')}`}
            onConfirmToastMessage={t('domain.generic.removeSuccessfully')}
            openModal={showModalConfirm}
            setShowModal={setShowModalConfirm}
            isLoading={removeMutation.isLoading}
          />
        </VStack>
      </ScrollView>
    </>
  );
};

export default RecipeFormStep7;
