import { string } from 'yup';
import { ICalcResponse } from './calc.type';

interface ICalcPercent {
  current: number | string;
  green: number | string;
}

export function calcPercent({ current, green }: ICalcPercent): number {
  const _current = parseFloat(`${current || 0}`);
  const _green = parseFloat(`${green || 0}`);

  const result = (100 * (_current - _green)) / _current;

  // if result is NaN, return -1

  return result < 0 ? -1 : (
    Number.isNaN(result) ? -1 : result
  );
}

export const DEFAULT_FALLBACK: ICalcResponse = {
  current: -1,
  green: -1,
  percent: `---%`,
  total: -1,
  percentNumber: -1,
};

export const DEFAULT_EMPTY: ICalcResponse = {
  current: 0,
  green: 0,
  percent: '',
  total: 0,
  percentNumber: 0,
};
