export const fontConfig = {
  Montserrat: {
    100: { normal: 'Montserrat_300Light' },
    200: { normal: 'Montserrat_300Light' },
    300: { normal: 'Montserrat_300Light' },
    400: { normal: 'Montserrat_400Regular' },
    500: { normal: 'Montserrat_500Medium' },
    600: { normal: 'Montserrat_500Medium' },
    700: { normal: 'Montserrat_700Bold' },
  },
};

export const fonts = {
  heading: 'Montserrat',
  body: 'Montserrat',
  mono: 'Montserrat',
};
