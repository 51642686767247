import { Flex, HStack, Image, ScrollView, Text, VStack } from 'native-base';
import React from 'react';
import { Linking, Platform } from 'react-native';
import BackHeader from '../../components/BackHeader';
import { Page } from '../../components/Page';
import SectionTitle from '../../components/SectionTitle';
import Img from '../../assets/images/logo-2.png';
import { t } from '../../languages';
import Constants from 'expo-constants';

interface AboutUsScreenProps {}

export const AboutUsScreen: React.FC<AboutUsScreenProps> = () => {
  return (
    <Page>
      <BackHeader backTitle mb={0} />
      <ScrollView>
        <VStack justifyContent="space-between">
          <Flex>
            <SectionTitle title={t('domain.aboutUs.section.aboutUs.title')} />

            <HStack alignContent={'center'} justifyContent="center" mb={10}>
              <Image source={Img} alt="logo-image" width="213px" />
            </HStack>

            <Text textAlign="center">{t('domain.aboutUs.section.aboutUs.description')}</Text>

            <Text
              textAlign="center"
              mt={10}
              style={{ color: 'green', textDecorationLine: 'underline' }}
              onPress={() => {
                Linking.openURL(t('domain.aboutUs.section.aboutUs.linkTitle'));
              }}
            >
              {t('domain.aboutUs.section.aboutUs.link')}
            </Text>

            {Platform.OS !== 'web' && (
              <SectionTitle title={t('domain.aboutUs.section.version')}>
                <Text textAlign="justify">{Constants.manifest?.version}</Text>
              </SectionTitle>
            )}

            <SectionTitle title={t('domain.aboutUs.section.copyright.title')} />

            <Text textAlign="justify" fontSize={'xs'}>
              {t('domain.aboutUs.section.copyright.description')}
            </Text>
          </Flex>
        </VStack>
      </ScrollView>
    </Page>
  );
};

export default AboutUsScreen;
