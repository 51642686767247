import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import {
  IJetTAuxiliaryEquipment,
  IJetTAuxiliaryEquipmentBrand,
  IJetTAuxiliaryEquipmentID,
  IJetTAuxiliaryEquipmentType,
} from '../../../types/auxiliary_equipment.type';

export interface IJetAuxiliaryEquipmentsList
  extends IJetAdminResponseProtocol<IJetAuxiliaryEquipmentsComplete[]> {}

export interface IJetAuxiliaryEquipmentsComplete extends IJetTAuxiliaryEquipment {
  brand_detail?: IJetTAuxiliaryEquipmentBrand;
  type_detail?: IJetTAuxiliaryEquipmentType;
}

export async function getAuxiliaryEquipment(
  id: IJetTAuxiliaryEquipmentID | undefined
): Promise<IJetTAuxiliaryEquipment | undefined> {
  if (!id) return undefined;
  const response = await jetAdmin.retrieve<IJetTAuxiliaryEquipment>(
    JetAdminCollection.AuxiliaryEquipments,
    id
  );

  return response;
}

export async function getAuxiliaryEquipments(): Promise<IJetAuxiliaryEquipmentsList> {
  const resAuxiliaryEquipments = await jetAdmin.list<IJetTAuxiliaryEquipment[]>(
    JetAdminCollection.getAllInfosFromAuxilliaryMachines
  );

  const data = resAuxiliaryEquipments.results.map((item) => ({
    ...item.machine,
    brand_detail: item.brand,
    type_detail: item.machine_type
  }));

  return {
    ...resAuxiliaryEquipments,
    results: data,
  };
}
