import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { HStack, ScrollView, Text, VStack } from 'native-base';
import React from 'react';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../../types/wash_cycle.type';
import AddRecipeButton from '../../../standardRecipe/components/AddRecipeButton';

interface ChemicalsListProps {
  cycle: IJetWashCycle;
  currentAggregator?: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe" | undefined;
}

const ChemicalsList: React.FC<ChemicalsListProps> = ({ cycle, currentAggregator, recipeName }) => {
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();

  const washChemicals = cycle?.cycle_chemicals_details || [];

  async function _createWashCycle() {
    navigation.push(
      'washRecipeCycleChemicals',
      {
        cycle,
        chemical: undefined,
        currentAggregator,
        recipeName,
      }
    );
  }

  return (
    <ScrollView>
      <VStack space="8px">
        {washChemicals.map((chemical) => {
          return (
            <AddRecipeButton
              key={`list-${chemical.id}-${chemical.chemical_id}-${chemical.chemical_unit_id}`}
              isAdded={false}
              text={`${chemical.id}`}
              onPress={() => navigation.push('washRecipeCycleChemicals', { chemical, cycle, currentAggregator, recipeName })}
            >
              <VStack>
                <HStack justifyContent="space-between">
                  <Text mr={2} fontSize={14} color="#368D7D" fontWeight={'normal'}>
                    {chemical?.chemical_complete?.name ?? t('domain.analysis.step5.fallback.chemical_without_name')}
                  </Text>
                  <Text textAlign="right" fontSize={14} color="#409D27">
                    {t('domain.generic.edit')}
                  </Text>
                </HStack>
                <Text fontSize={14} color="#368D7D" fontWeight={'normal'}>
                  {chemical.chemical_dose}{chemical.units_complete?.name || t('domain.generic.empty')}
                </Text>
              </VStack>
            </AddRecipeButton>
          );
        })}

        <AddRecipeButton
          text={t('domain.analysis.step4.section.add.subsection.chemicals.button.add')}
          onPress={_createWashCycle}
          isAdded={true}
        />
      </VStack>
    </ScrollView>
  );
};

export default ChemicalsList;
