import { Box, HStack, VStack } from 'native-base';
import React from 'react';
import Step4BathRatioInput from './inputs/Step4BathRatioInput';
import Step4TemperatureInput from './inputs/Step4TemperatureInput';
import Step4TimeInput from './inputs/Step4TimeInput';

interface RecipeFormStep4GeneralInformationProps {}

export const RecipeFormStep4GeneralInformation: React.FC<
  RecipeFormStep4GeneralInformationProps
> = () => {
  return (
    <>
      <VStack space={'24px'}>
        <HStack space={'16px'} width="100%">
          <Box display="flex" flexGrow={1}>
            <Step4TimeInput />
          </Box>
          <Box display="flex" flexGrow={1}>
            <Step4BathRatioInput />
          </Box>
        </HStack>

        <Step4TemperatureInput />
      </VStack>
    </>
  );
};

export default RecipeFormStep4GeneralInformation;
