import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { IJetWashCycle } from '../../../types/wash_cycle.type';
import {
  IJetWashCycleChemical,
  IJetWashCycleChemicalID,
  IJetWashCycleChemicalComplete,
  IJetWashCycleChemicalCreate,
} from '../../../types/wash_cycle_chemical.type';
import { getChemical } from '../../standardRecipe/services/getChemicals';
import { getUnit } from '../../standardRecipe/services/getUnits';
import { editWashCycle, getWashCycle } from '../../washRecipeAggregator/services/cycle.service';

interface ICreateWashCycleChemicalParams {
  data: IJetWashCycleChemical | IJetWashCycleChemicalCreate;
  washCycle: IJetWashCycle;
}

export async function getWashCycleChemical(
  id: IJetWashCycleChemicalID
): Promise<IJetWashCycleChemicalComplete | undefined> {
  const response = await jetAdmin.retrieve<IJetWashCycleChemicalComplete>(
    JetAdminCollection.WashRecipeChemicals,
    id
  );

  const chemical = response?.chemical_id ? await getChemical(response?.chemical_id) : undefined;
  const units = response?.chemical_unit_id ? await getUnit(response?.chemical_unit_id) : undefined;

  return {
    ...response,
    chemical_complete: chemical,
    units_complete: units,
  };
}

export async function listWashCycleChemicals(washCycle: IJetWashCycle) {
  try {
    const cyclesIDS = washCycle.chemicals;
    const cycleIDPromiseArr = cyclesIDS?.map((cycleID) => getWashCycleChemical(cycleID));
    if (!cycleIDPromiseArr || cycleIDPromiseArr.length === 0) return [];

    const cyclesResponse = await Promise.all(cycleIDPromiseArr);
    // remove all undefined values from the array
    const cycles = cyclesResponse.filter((cycle) => {
      return !!cycle && cycle !== undefined && cycle !== null && cycle.id !== undefined;
    }) as IJetWashCycleChemicalComplete[];

    return cycles;
  } catch (error) {
    return [];
  }
}

export async function createOrEditWashCycleChemical(
  params: ICreateWashCycleChemicalParams
): Promise<IJetWashCycleChemical | undefined> {
  const { data, washCycle } = params;

  // @ts-ignore
  if (data?.id) {
    const res = await editWashCycleChemical(data);
    return res;
  } else {
    return await createWashCycleChemical({ data, washCycle });
  }
}

export async function createWashCycleChemical(
  props: ICreateWashCycleChemicalParams
): Promise<IJetWashCycleChemical | undefined> {
  const { data, washCycle } = props;
  const washCycleChemicalCreate = convertWashCycleChemicalFormToWashCycleChemicalCreate(data);

  const res = await jetAdmin.create<IJetWashCycleChemical>(
    JetAdminCollection.WashRecipeChemicals,
    washCycleChemicalCreate
  );

  if (!res?.id || !washCycle.chemicals) return res;

  await editWashCycle({ ...washCycle, chemicals: [...washCycle.chemicals, res.id] });

  return res;
}

export async function editWashCycleChemical(
  props: Partial<IJetWashCycleChemical>
): Promise<IJetWashCycleChemical | undefined> {
  if (!props.id) throw new Error('Wash recipe id is required');

  const res = await jetAdmin.update<IJetWashCycleChemical>(
    JetAdminCollection.WashRecipeChemicals,
    props.id,
    props
  );

  return res;
}

function convertWashCycleChemicalFormToWashCycleChemicalCreate(
  data: IJetWashCycleChemicalCreate
): IJetWashCycleChemicalCreate {
  return {
    ...data,
    chemical_bath_ratio: data.chemical_bath_ratio || undefined,
    chemical_dose: data.chemical_dose || undefined,
    chemical_id: data.chemical_id || undefined,
    chemical_temperature: data.chemical_temperature || undefined,
    chemical_time: data.chemical_time || undefined,
    chemical_unit_id: data.chemical_unit_id || undefined,
  };
}

interface IDeleteWashCycleChemical {
  chemical: IJetWashCycleChemical;
  cycle: IJetWashCycle;
}

export async function deleteWashCycleChemical(props: IDeleteWashCycleChemical): Promise<void> {
  const { chemical, cycle } = props;
  if (!chemical.id || !cycle.id) throw new Error('Wash recipe id is required');

  const _cycle = await getWashCycle(cycle.id);
  if (!_cycle) throw new Error('Wash cycle not found');

  // Remove only the id from washCycleChemicalIDs array
  const _newArr = _cycle?.chemicals?.filter(
    (washCycleChemicalID) => washCycleChemicalID !== props.chemical.id
  );

  await editWashCycle({
    ..._cycle,
    chemicals: _newArr,
  });

  if (props?.chemical?.id) {
    await jetAdmin.delete(JetAdminCollection.WashRecipeChemicals, props?.chemical?.id);
  } else {
    throw new Error('Wash recipe chemical id is required');
  }
}
