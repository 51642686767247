import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
  Box,
  FormControl,
  Image,
  Link,
  Pressable,
  ScrollView,
  Stack,
  Text,
  VStack,
  View,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CountryPicker from 'react-native-country-picker-modal';
import Img from '../../../../assets/images/arrow-right.png';
import { CustomButton } from '../../../../components/CustomButton';
import FormInput from '../../../../components/Input/FormInput';
import { Country, CountryCode } from '../../../../config/countries';
import { t } from '../../../../languages';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { IJetCreateUserStepOne } from '../../../auth/services/createUserOnJetAdmin';
import ControlledCheckbox from '../../../../components/Input/ControlledCheckbox';
import { yupResolver } from '@hookform/resolvers/yup';

export interface SignUpFormInterface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
  handleGoToStepTwo: (data: IJetCreateUserStepOne) => void;
  fieldsStepOne: IJetCreateUserStepOne | null;
}

export function SignUpForm({ navigation, handleGoToStepTwo, fieldsStepOne }: SignUpFormInterface) {
  const [modalCountries, setModalCountries] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<CountryCode>('AD');
  const [country, setCountry] = useState<Country>({} as Country);
  const [callingCode, setCallingCode] = useState<string>('');

  const schema: yup.SchemaOf<IJetCreateUserStepOne> = yup.object({
    name: yup.string().required(),
    company: yup.string().required(),
    country: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    agree_with_terms: yup.boolean().required().isTrue().label(t('domain.signUp.screen1.acceptTermsRequiredMessage')),
    calling_code: yup.string().required(),
    cca2: yup.string().notRequired(),
    active: yup.boolean().default(false),
  });

  const methods = useForm<IJetCreateUserStepOne>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {...fieldsStepOne},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fieldsStepOne?.cca2 && setCountryCode(fieldsStepOne.cca2)
    fieldsStepOne?.calling_code && setCallingCode(fieldsStepOne.calling_code)
    fieldsStepOne?.country && setCountry({ name: fieldsStepOne.country } as Country)
    fieldsStepOne?.agree_with_terms && methods.setValue('agree_with_terms', fieldsStepOne.agree_with_terms)
  }, [fieldsStepOne])

  const onSelectCountries = (country: Country) => {
    setModalCountries(false);
    setCountryCode(country?.cca2);
    setCallingCode(country?.callingCode[0]);
    setCountry(country);
    methods.setValue('calling_code', country?.callingCode[0]);
    methods.setValue('cca2', country?.cca2);
  };

  useEffect(() => {
    if(country?.name){
      methods.setValue('country', String(country?.name));
      methods.trigger('country')
    }
  }, [country]);

  return (
    <ScrollView>
      <Box
        flex="1"
        w={{
          base: '100%',
          md: '100%',
        }}
      >
        <FormControl>
          <Stack h="100%" justifyContent="space-between" mt="16px">
            <Box>
              <Box>
                <VStack space={'8px'}>
                  <FormInput
                    methods={methods}
                    name={'name'}
                    placeholder={t('domain.signUp.screen1.inputPlaceholder')}
                  />

                  <FormInput
                    methods={methods}
                    name={'company'}
                    placeholder={t('domain.signUp.screen1.inputPlaceholder2')}
                  />
                  <Pressable onPress={() => setModalCountries(true)}>
                    <View pointerEvents="none">
                      <FormInput
                        inputProps={{
                          value: `${country?.name && callingCode ? `${country?.name} +(${callingCode})` : ''}`,
                        }}
                        methods={methods}
                        name={'country'}
                        placeholder={t('domain.washingPlants.step.two.input.placeholder')}
                      />
                    </View>
                  </Pressable>

                  <FormInput
                    methods={methods}
                    name={'phone'}
                    placeholder={t('domain.signUp.screen1.inputPlaceholder3')}
                  />

                  <FormInput
                    methods={methods}
                    name={'email'}
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    placeholder={t('domain.signUp.screen1.inputPlaceholder4')}
                  />
                </VStack>

                <Box
                  flexDirection="row"
                  mt="5"
                  justifyContent="flex-start"
                  alignContent="center"
                  alignItems="center"
                >
                  <ControlledCheckbox
                    methods={methods}
                    name="agree_with_terms"
                  />
                  <Box ml="10px">
                    <Box flexDirection="row">
                      <Text mr="3px" fontSize="16px" lineBreakMode="middle">
                        {t('domain.signUp.screen1.agreement1')}
                      </Text>
                      <Link href="https://www.kemin.com/eu/en/company/terms/terms-and-conditions" isExternal>
                      <Text fontSize="16px" lineBreakMode="middle" color="#409D27">
                        {t('domain.signUp.screen1.agreement2')}
                      </Text>{' '}
                      </Link>
                    </Box>
                    <Box flexDirection="row">
                      <Text mr="3px" fontSize="16px" lineBreakMode="middle">
                        {t('domain.signUp.screen1.agreement3')}
                      </Text>
                      <Link  href="https://www.kemin.com/eu/en/company/terms/privacy-policy"
                  isExternal>
                      <Text fontSize="16px" lineBreakMode="middle" color="#409D27">
                        {t('domain.signUp.screen1.agreement4')}
                      </Text>
                      </Link>
                    </Box>
                  </Box>
                </Box>
                {methods.formState.errors['agree_with_terms']?.message && (
                  <Text mt="5px" color="red.500" fontSize="12px">
                    {methods.formState.errors['agree_with_terms'].message}
                  </Text>
                )}
              </Box>
            </Box>
            <Box display="flex" mb={'50px'}>
              <Box
                mb={'12px'}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Text fontSize="md">{t('domain.signUp.screen1.haveAccount')}</Text>
                <Link
                  _text={{
                    fontSize: 'md',
                    color: '#409D27',
                    ml: '5px',
                  }}
                  onPress={() => navigation.navigate('SignIn')}
                >
                  {t('domain.signUp.screen1.buttonLogin')}
                </Link>
              </Box>
              <Box display="flex">
                <CustomButton
                  variant="solid"
                  bgColor="#409D27"
                  isLoadingText={t('domain.generic.loading')}
                  mt={10}
                  isDisabled={!methods.formState.isValid}
                  onPress={methods.handleSubmit(handleGoToStepTwo)}
                  rightIcon={
                    <Box>
                      <Image source={Img} alt="next icon" />
                    </Box>
                  }
                >
                  {t('domain.signUp.screen1.buttonNext')}
                </CustomButton>
              </Box>
            </Box>
          </Stack>
        </FormControl>
      </Box>
      <CountryPicker
        {...{
          countryCode,
          onSelect: onSelectCountries,
        }}
        withFilter
        containerButtonStyle={{ display: 'none' }}
        visible={modalCountries}
        onClose={() => setModalCountries(false)}
        withCallingCode
      />
    </ScrollView>
  );
}
