import { getAllWashRecipeInfo } from '../domains/washRecipe/services/washRecipe.service';
import { IJetBoiler, IJetBoilerBrand, IJetBoilerComplete } from '../types/boiler.type';
import { IJetCentrifugeWithBrand } from '../types/centrifuge.type';
import { IJetCountry } from '../types/country.type';
import { IJetDryerWithBrand } from '../types/dryer.type';
import { IJetHocs } from '../types/hocs';
import { IJetCycleQueryResult, IJetWashRecipe, IJetWashRecipeCompleteInfo, IJetWashRecipeID } from '../types/wash-recipe.type';
import { IJetWashingMachineWithBrand } from '../types/washing-machine.type';
import { IJetWashingPlant, IJetWashingPlantID } from '../types/washing-plants.type';
import { IJetWashCycleComplete } from '../types/wash_cycle.type';
import { IJetWashRecipeAggregatorID } from '../types/wash_recipe_aggregator.type';
import { jetAdmin, JetAdminCollection } from './jetService';
import { IJetTypeOfCycle } from '../types/type-of-cycle.type';

export interface IGetAllWashInfo {
  centrifuge: IJetCentrifugeWithBrand;
  boiler: IJetBoiler;
  boiler_brand_detail: IJetBoilerBrand;
  combustible_detail: IJetHocs;
  country: IJetCountry;
  id: string;
  name: string;
  description?: string;
  active: boolean;
  overall_load: string;
  number_of_garments: string;
  wash_machine: IJetWashingMachineWithBrand;
  water_temperature: string;
  air_temperature: string | undefined;
  dryer: IJetDryerWithBrand;
  user: any;
  created_at: string;
  updated_at: string;
}

interface IGetAllInfoFromAggregators {
  washingPlantID?: IJetWashingPlantID;
  washRecipeId: IJetWashRecipeID;
}

export interface IBrandDetail {
  name: string;
  id: string;
  active: boolean;
}

export interface IGetEverythingResponse {
  washingPlant: IJetWashingPlant;
  country: IJetCountry | undefined;
  boiler: IJetBoilerComplete | undefined;
  centrifuge: IJetCentrifugeWithBrand | undefined;
  washingMachine: IJetWashingMachineWithBrand | undefined;
  cycle_type: IJetTypeOfCycle | undefined;
  dryer: IJetDryerWithBrand | undefined;
  currentRecipe: IJetWashCycleComplete | undefined;
  greenRecipe: IJetWashCycleComplete | undefined;
  aggregatorID: IJetWashRecipeAggregatorID;
  wash_recipe_info: IJetWashRecipe | undefined;
}

export async function getAllInfo(
  { washingPlantID, washRecipeId }: IGetAllInfoFromAggregators
): Promise<(IGetEverythingResponse[])>{
  console.log('bla', washingPlantID)
  const washingPlantResponse = await jetAdmin.list<IGetAllWashInfo>(
    JetAdminCollection.GetAllWashingPlantInfo,
    { 
      ...(washingPlantID && {
        id: washingPlantID
      })
    }
  );

  const washingPlantInfo: IGetAllWashInfo = washingPlantResponse.results[0];
  const allWashRecipeInfos = await getAllWashRecipeInfo(washRecipeId || '');

  const mappedInfo = allWashRecipeInfos?.map((item: IJetWashRecipeCompleteInfo) => ({
    ...formatAllWashingPlantInfos(washingPlantInfo, item)
  }));

  return mappedInfo;
}

function formatAllWashingPlantInfos(
  washingPlantInfo: IGetAllWashInfo,
  aggregatorInfo: IJetWashRecipeCompleteInfo,
){
  const {
    boiler,
    centrifuge,
    dryer, 
    country,
    boiler_brand_detail,
    combustible_detail,
    wash_machine,
  } = washingPlantInfo;

  const {
    current_recipe,
    green_recipe,
    id,
    cycle_type,
  } = aggregatorInfo.wash_recipe_aggregator;

  return {
    washingPlant: {
      active: washingPlantInfo.active,
      air_temperature: washingPlantInfo.air_temperature,
      boiler: washingPlantInfo.boiler.id,
      centrifuge: washingPlantInfo.centrifuge.id,
      country: washingPlantInfo.country.code_numeric,
      created_at: washingPlantInfo.created_at,
      description: washingPlantInfo.description,
      dryer: washingPlantInfo.dryer.id,
      id: washingPlantInfo.id,
      name: washingPlantInfo.name,
      number_of_garments: washingPlantInfo.number_of_garments,
      overall_load: washingPlantInfo.overall_load,
      updated_at: washingPlantInfo.updated_at,
      user: washingPlantInfo.user.id,
      washing_machine: washingPlantInfo.wash_machine.id,
      water_temperature: washingPlantInfo.water_temperature,
    },
    cycle_type,
    country: country,
    boiler: {
      ...boiler,
      brand_detail: boiler_brand_detail,
      combustible_detail: combustible_detail,
    },
    centrifuge: centrifuge,
    dryer: dryer,
    washingMachine: wash_machine,
    aggregatorID: id,
    greenRecipe: green_recipe?.id !== null ? formatRecipeAllInfo(green_recipe) : undefined,
    currentRecipe: current_recipe?.id !== null ? formatRecipeAllInfo(current_recipe) : undefined,
    wash_recipe_info: {
      id: aggregatorInfo.id,
      name: aggregatorInfo.name,
      active: aggregatorInfo.active,
      washing_plant: aggregatorInfo.washing_plant,
      created_at: aggregatorInfo.created_at,
      updated_at: aggregatorInfo.updated_at,
      wash_recipe_aggregators: aggregatorInfo.wash_recipe_aggregators,
      wash_recipe_aggregators_description: [],
    },
  }
}

function formatRecipeAllInfo(recipe_info: IJetCycleQueryResult){
  return {
    id: recipe_info.id,
    type_of_cycle: recipe_info.type_of_cycle,
    general_time: recipe_info.general_time,
    general_temperature: recipe_info.general_temperature,
    general_bath_ratio: recipe_info.general_bath_ratio,
    chemicals: recipe_info.chemicals_id,
    add_auxiliary_equipment: recipe_info.add_auxiliary_equipments_id,
    cycle_auxiliary_equipment: recipe_info.add_auxiliary_equipment,
    add_laser_scrapings: recipe_info.add_laser_scrapings_id,
    extras_rinses_add: recipe_info.extras_rinses_add,
    amount_of_rinses: recipe_info.amount_of_rinses,
    rinses_amount_time: recipe_info.rinses_amount_time,
    rinses_amount_bath_ratio: recipe_info.rinses_amount_bath_ratio,
    extras_centrifuge_add: recipe_info.extras_centrifuge_add,
    extras_centrifuge_run_time: recipe_info.extras_centrifuge_run_time,
    extras_centrifuge_pickup: recipe_info.extras_centrifuge_pickup,
    should_add_drying: recipe_info.should_add_drying,
    extras_drying_time: recipe_info.extras_drying_time,
    extras_drying_temperature: recipe_info.extras_drying_temperature,
    default_cycle: recipe_info.default_cycle,
    cycle_chemicals_details: recipe_info.chemicals.map((item) => ({
      ...item,
      id: item.id,
      chemical_id: item.chemical_id,
      chemical_unit_id: item.chemical_unit_id,
      chemical_complete: item.chemical,
      units_complete: item.chemical_unit
    })),
    cycle_auxiliary_equipments_details: recipe_info.add_auxiliary_equipment.map((item) => ({
      ...item,
      id: item.id,
      auxiliary_equipment_id: item.auxiliary_equipment_id,
      auxiliary_equipment: {
        id: item.equipment.id,
        model: item.equipment.model,
        active: item.equipment.active,
        energy: item.equipment.energy,
        lit_per_min: item.equipment.lit_per_min,
        auxiliary_machine_brand: item.equipment.brand.id,
        auxiliary_machine_type: item.equipment.type.id,
      }
    })),
    cycle_laser_scrapings_details: recipe_info.add_laser_scrapings.map((item) => ({
      ...item,
      id: item.id,
      laser_id: item.laser_id,
      laser_detail: {
        id: item.laser.id,
        model: item.laser.name,
        name: item.laser.name,
        active: item.laser.active,
        laser_energy_kw: item.laser.laser_energy_kw,
        laser_pcs_per_time: item.laser.laser_pcs_per_time,
        laser_swap_time: item.laser.laser_swap_time,
        laser_scraping_brand: item.laser.brand.id,
        laser_scraping_type: item.laser.type.id,
      }
    })) ,
    cycle_type_of_cycle_details: recipe_info.type_of_cycle_info,
  }
}
