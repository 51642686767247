import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from '../../../../../components/Input/FormInput';
import InputAddon from '../../../../../components/Input/InputAddon';
import { t } from '../../../../../languages';
import { useStandardRecipeForm } from '../../../hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../../../types/standardRecipeForm.type';

interface Step4TemperatureInputProps {}

export const Step4TemperatureInput: React.FC<Step4TemperatureInputProps> = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { getInputName } = useStandardRecipeForm(methods.control);

  const inputName = getInputName('general_temperature');

  return (
    <FormInput
      methods={methods}
      name={inputName}
      label={t('domain.analysis.step4.section.general_information.input.temperature.label')}
      placeholder={t(
        'domain.analysis.step4.section.general_information.input.temperature.placeholder'
      )}
      inputProps={{
        keyboardType: 'numeric',
        maxLength: 5,
        InputRightElement: <InputAddon str={t('domain.units.temperature.celsius')} />,
      }}
    />
  );
};

export default Step4TemperatureInput;
