import { VStack } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Grid, Row } from 'react-native-easy-grid';
import FormInput from '../../../../../components/Input/FormInput';
import InputAddon from '../../../../../components/Input/InputAddon';
import { t } from '../../../../../languages';
import { useStandardRecipeForm } from '../../../hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../../../types/standardRecipeForm.type';

interface Step4ExtrasCentrifugeProps {}

const Step4ExtrasCentrifuge: React.FC<Step4ExtrasCentrifugeProps> = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { getInputName } = useStandardRecipeForm(methods.control);

  const inputCentrifugePickup = getInputName('extras_centrifuge_pickup');
  const inputRuntime = getInputName('extras_centrifuge_run_time');

  return (
    <VStack space="24px" mb="24px">
      <FormInput
        methods={methods}
        name={inputRuntime}
        label={t('domain.analysis.step4.section.extras.subsection.centrifuge.input.run_time.label')}
        placeholder={t(
          'domain.analysis.step4.section.extras.subsection.centrifuge.input.run_time.placeholder'
        )}
        inputProps={{
          keyboardType: 'numeric',
          maxLength: 6,
          InputRightElement: <InputAddon str={t('domain.units.time.minutes')} />,
        }}
      />

      <FormInput
        methods={methods}
        name={inputCentrifugePickup}
        label={t(
          'domain.analysis.step4.section.extras.subsection.centrifuge.input.pickup.placeholder'
        )}
        placeholder={t(
          'domain.analysis.step4.section.extras.subsection.centrifuge.input.pickup.placeholder'
        )}
        inputProps={{
          keyboardType: 'numeric',
          maxLength: 5,
          InputRightElement: <InputAddon str={t('domain.units.other.percent')} />,
        }}
      />
    </VStack>
  );
};

export default Step4ExtrasCentrifuge;
