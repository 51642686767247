import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const IconDown = (props: SvgProps) => (
  <Svg {...props} width={19} height={11} fill="none">
    <Path d="m1 1 8.5 8.5L18 1" stroke="#368D7D" strokeWidth={2} strokeLinecap="round" />
  </Svg>
);

export default IconDown;
