import { IJetTLaserScraping } from '../../types/laser_scraping.type';
import { IJetWashingPlant } from '../../types/washing-plants.type';
import { IJetWashCycle } from '../../types/wash_cycle.type';
import { IJetWashCycleLaser, IJetWashCycleLaserComplete } from '../../types/wash_cycle_laser.type';

interface ICalcLaserEnergy {
  washingPlant: IJetWashingPlant | undefined;
  laser: IJetWashCycleLaserComplete;
}

interface ICalcLaserTime {
  washingPlant: IJetWashingPlant | undefined;
  laser: IJetWashCycleLaserComplete;
}

export function calcLaserEnergy(props: ICalcLaserEnergy): number {
  const _energy = parseFloat(`${props?.laser?.laser_detail?.laser_energy_kw || 0}`);
  const _opTime = parseFloat(`${props?.laser?.laser_labor_operation_time || 0}`);
  const _numberOfGarments = parseFloat(`${props?.washingPlant?.number_of_garments || 0}`);
  const _pcsTime = parseFloat(`${props?.laser?.laser_detail?.laser_pcs_per_time || 0}`);

  return ((_energy / 60) * ((_opTime * _numberOfGarments) / _pcsTime)) / 60;
}

export function calcLaserTime(props: ICalcLaserTime): number {
  const _opTime = parseFloat(`${props?.laser?.laser_machine_operation_time || 0}`);
  const _laborTime = parseFloat(`${props?.laser?.laser_labor_operation_time || 0}`);
  const _numberOfGarments = parseFloat(`${props?.washingPlant?.number_of_garments || 0}`);
  const _pcsTime = parseFloat(`${props?.laser?.laser_detail?.laser_pcs_per_time || 0}`);
  // const _swapTime = parseFloat(`${props?.laser?.laser_detail?.laser_swap_time || 0}`);

  return ((_laborTime + _opTime) * _numberOfGarments) / _pcsTime / 60;
}
