import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetWashingPlant, IJetWashingPlantID } from '../../../types/washing-plants.type';
import { IJetUserID } from '../../auth/types/IJetUser';

export interface IJetWashingPlantsList extends IJetAdminResponseProtocol<IJetWashingPlant[]> {}

export async function getWashingPlants(userID: IJetUserID): Promise<IJetWashingPlantsList> {
  const res = await jetAdmin.list<IJetWashingPlant[]>(JetAdminCollection.WashingPlants, {
    user: userID,
  });

  return res;
}

export async function getWashingPlant(
  id: IJetWashingPlantID
): Promise<IJetWashingPlant | undefined> {
  const res = await jetAdmin.retrieve<IJetWashingPlant>(JetAdminCollection.WashingPlants, id);

  return res;
}
