import { HStack, ScrollView, Skeleton, Text, VStack } from 'native-base';
import React, { useMemo } from 'react';
import {
  DeepPartial,
  FieldPathValues,
  useFieldArray,
  UseFieldArrayProps,
  useFormContext,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { ISelectValues } from '../../../../components/SelectWithModal';
import { QueryKeyCache } from '../../../../config/query-cache';
import { t } from '../../../../languages';
import AddRecipeButton from '../../components/AddRecipeButton';
import { StandardRecipeFormStep, useStandardRecipeForm } from '../../hooks/useStandardRecipeForm';
import { getLaserScrapings } from '../../services/getLaserScrapings';
import { IStandardRecipeForm } from '../../types/standardRecipeForm.type';
import { getInputName } from '../utils/getFormInputName';

interface LaserScrapingListProps {}

export type ILaserScrapingArrInputName =
  | `cycles.${number}.currentRecipe.add_laser_scraping`
  | `cycles.${number}.newRecipe.add_laser_scraping`;

const LaserScrapingList: React.FC<LaserScrapingListProps> = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { getInputName, goToDeepStep, goToStep, createNewAddCycle } = useStandardRecipeForm(
    methods.control
  );

  const laserScrapingQuery = useQuery(QueryKeyCache.getLaserScrapings, getLaserScrapings);

  const laserScrapingInputName = getInputName('add_laser_scraping') as ILaserScrapingArrInputName;
  const { fields, append } = useFieldArray({
    control: methods.control,
    name: laserScrapingInputName,
  });

  if (laserScrapingQuery.isLoading) {
    return (
      <VStack space={'8px'}>
        <Skeleton h={12} />
        <Skeleton h={12} />
      </VStack>
    );
  }

  return (
    <ScrollView>
      <VStack space="8px">
        {fields.map((laser, index) => {
          const _laser = laserScrapingQuery.data?.results.find(({ id }) => id === laser.laser_id);
          const laserName = `${_laser?.brand_detail?.name} ${_laser?.model}`;

          return (
            <AddRecipeButton
              isAdded={false}
              text={`${laser.laser_id}`}
              onPress={() => goToDeepStep(StandardRecipeFormStep.Step7LaserScraping, index)}
            >
              <HStack justifyContent="space-between">
                <Text mr={2} fontSize={14} color="#368D7D" fontWeight={'normal'}>
                  {laserName ?? t('domain.analysis.step7.fallback.laser_scraping_without_name')}
                </Text>
                <Text textAlign="right" fontSize={14} color="#409D27">
                  {t('domain.generic.edit')}
                </Text>
              </HStack>
            </AddRecipeButton>
          );
        })}

        <AddRecipeButton
          text={t('domain.analysis.step4.section.add.subsection.laser_scraping.button.add')}
          onPress={() => {
            createNewAddCycle('add_laser_scraping');
            goToStep(StandardRecipeFormStep.Step7LaserScraping);
          }}
          isAdded={true}
        />
      </VStack>
    </ScrollView>
  );
};

export default LaserScrapingList;
