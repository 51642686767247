import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Box, Divider, HStack, ScrollView, Text, VStack } from 'native-base';
import React, { useContext, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Grid } from 'react-native-easy-grid';
import { useMutation, useQuery } from 'react-query';
import BackHeader from '../../../components/BackHeader';
import Confirm from '../../../components/Confirm';
import FormInput from '../../../components/Input/FormInput';
import SelectWithModal, { ISelectValues } from '../../../components/SelectWithModal';
import { QueryKeyCache } from '../../../config/query-cache';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../types/wash_cycle.type';
import { IJetWashCycleChemical } from '../../../types/wash_cycle_chemical.type';
import { toast } from '../../../utils/toast';
import { deleteWashCycleChemical } from '../../washCycle/services/chemicals.service';
import { getChemicals } from '../services/getChemicals';
import { getUnits } from '../services/getUnits';
import { WashRecipeAggregatorContext } from '../../washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import { Platform } from 'react-native';

interface RecipeFormStep5Props {
  washCycle: IJetWashCycle;
  chemical: IJetWashCycleChemical | undefined;
  isSubmitting: boolean;
  currentAggregator: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe";
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleChemicals', undefined>;
}

const SPACE = '32px';

export const RecipeFormStep5: React.FC<RecipeFormStep5Props> = ({
  chemical,
  washCycle,
  isSubmitting,
  currentAggregator,
  recipeName,
  navigation,
}) => {
  const { setAllChemicals, setAllUnits, removeChemicalFromCompleteInfo } = useContext(WashRecipeAggregatorContext);
  const { goBack } = navigation;
  const methods = useFormContext<IJetWashCycleChemical>();
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [showModalRemoveChemical, setShowModalRemoveChemical] = useState<boolean>(false);

  const inputChemicalID: keyof IJetWashCycleChemical = 'chemical_id';
  const inputChemicalDose: keyof IJetWashCycleChemical = 'chemical_dose';
  const inputChemicalUnit: keyof IJetWashCycleChemical = 'chemical_unit_id';

  const chemicalsQuery = useQuery(QueryKeyCache.GetChemicals, getChemicals, {
    cacheTime: (60 * 60 * 24) * 3, // 3 days
    onSuccess: (data) => {
      setAllChemicals(data.results);
    }
  });
  const unitsQuery = useQuery(QueryKeyCache.GetUnits, getUnits, {
    cacheTime: (60 * 60 * 24) * 3, // 3 days
    onSuccess: (data) => {
      setAllUnits(data.results);
    }
  });

  const isLoading =
    chemicalsQuery.isLoading || isSubmitting;

  const chemicals: ISelectValues[] = useMemo(
    () =>
      (chemicalsQuery.data?.results || []).map(({ id, name }) => ({
        key: id,
        label: name,
      })),
    [chemicalsQuery.data]
  );

  const units: ISelectValues[] = useMemo(
    () =>
      (unitsQuery.data?.results || []).map(({ id, name }) => ({
        key: id,
        label: name,
      })),
    [unitsQuery.data]
  );

  const removeMutation = useMutation(deleteWashCycleChemical, {
    onSuccess: () => {
      methods.reset();
      Platform.OS !== 'web' && toast(t('domain.generic.success'));
      setShowModalConfirm(false);
      setShowModalRemoveChemical(false);
      goBack();
    },
    onError: () => toast(t('domain.generic.error')),
  });

  function removeChemical() {
    if (chemical) {
      removeMutation.mutate({ chemical, cycle: washCycle });
      removeChemicalFromCompleteInfo(
        currentAggregator.aggregator_id,
        recipeName,
        chemical || {}
      );
    };
  }

  function onPressBackHeader() {
    methods.reset(chemical);
    goBack();
  }

  return (
    <>
      <ScrollView>
        <VStack justifyContent="space-between" flex={1} space={SPACE}>
          <HStack alignItems="center" alignContent="center" justifyContent="space-between">
            <BackHeader mb="8px" onPress={onPressBackHeader} backTitle />

            {chemical && (
              <Confirm
                onConfirm={removeChemical}
                buttonText={t('domain.analysis.step2.button.remove')}
                button={{ mb: 3 }}
                message={`${t('domain.generic.delete_message')}`}
                onConfirmToastMessage={t('domain.generic.removeSuccessfully')}
                isLoading={removeMutation.isLoading}
                setShowModal={setShowModalRemoveChemical}
                openModal={showModalRemoveChemical}
              />
            )}
          </HStack>

          <Text fontSize={22} fontWeight={500}>
            {t('domain.analysis.step5.title_part_one')}
            <Text>
              {' '}
              <Text fontSize={22} fontWeight="bold" color="primary.500">
                {t('domain.analysis.step5.title_part_two')}{' '}
                <Text fontSize={22} fontWeight={500}>
                  {t('domain.analysis.step5.title_part_three')}
                </Text>
              </Text>
            </Text>
          </Text>

          <Box>
            <SelectWithModal
              values={chemicals}
              refetch={chemicalsQuery.refetch}
              isLoading={isLoading}
              inputName={inputChemicalID}
              methods={methods}
              label={t('domain.analysis.step5.input.chemical.label')}
              placeholder={t('domain.analysis.step5.input.chemical.placeholder')}
            />
          </Box>

          <Divider />

          <Grid>
            <Col style={{ marginRight: 16 }}>
              <FormInput
                isLoading={isLoading}
                methods={methods}
                name={inputChemicalDose}
                label={t('domain.analysis.step5.input.dose.label')}
                placeholder={t('domain.analysis.step5.input.dose.placeholder')}
                inputProps={{
                  keyboardType: 'numeric',
                  maxLength: 6,
                }}
              />
            </Col>
            <Col>
              <SelectWithModal
                values={units}
                refetch={unitsQuery.refetch}
                isLoading={isLoading}
                inputName={inputChemicalUnit}
                methods={methods}
                label={t('domain.analysis.step5.input.unit.label')}
                placeholder={t('domain.analysis.step5.input.unit.placeholder')}
              />
            </Col>
          </Grid>

          <Confirm
            showButton={false}
            openModal={showModalConfirm}
            setOpenModal={setShowModalConfirm}
            onConfirm={removeChemical}
            buttonText={t('domain.analysis.step2.button.remove')}
            button={{ mb: 3 }}
            message={`${t('domain.analysis.step5.back.modal.message')}`}
            onConfirmToastMessage={t('domain.generic.removeSuccessfully')}
            setShowModal={setShowModalConfirm}
            isLoading={removeMutation.isLoading}
          />
        </VStack>
      </ScrollView>
    </>
  );
};

export default RecipeFormStep5;
