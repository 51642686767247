import { yupResolver } from '@hookform/resolvers/yup';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { SchemaOf, object, string, number, boolean } from 'yup';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../../types/wash_cycle.type';
import { IJetWashCycleAuxiliary } from '../../../../types/wash_cycle_auxiliary.type';
import WashAuxiliary from './WashAuxiliary';

interface WashAuxiliaryFormProps {
  washCycle: IJetWashCycle;
  auxiliary: IJetWashCycleAuxiliary | undefined;
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleAuxiliarys', undefined>;
  currentAggregator: ICompleteWashInfoAggregators | undefined;
  recipeName: "currentRecipe" | "greenRecipe" | undefined;
}

export const WashAuxiliaryForm: React.FC<WashAuxiliaryFormProps> = ({
  washCycle,
  auxiliary,
  navigation,
  currentAggregator,
  recipeName,
}) => {
  const schema: SchemaOf<IJetWashCycleAuxiliary> = object({
    id: string().optional().notRequired(),
    auxiliary_equipment_id: string().required(
      t('domain.analysis.step6.input.auxiliary_equipment.label')
    ),
    auxiliary_time: number().required().label(t('domain.analysis.step6.input.time.label')),
    auxiliary_inparallel: boolean().notRequired().optional(),
  });

  const methods = useForm<IJetWashCycleAuxiliary>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      ...auxiliary,
      auxiliary_equipment_id: auxiliary?.auxiliary_equipment_id || undefined,
      auxiliary_time: auxiliary?.auxiliary_time || undefined,
      auxiliary_inparallel: auxiliary?.auxiliary_inparallel || false,
      id: auxiliary?.id || undefined,
    },
    resolver: yupResolver(schema),
  });

  return (
    <>
      <FormProvider {...methods}>
        <WashAuxiliary
          washCycle={washCycle}
          auxiliary={auxiliary}
          navigation={navigation}
          currentAggregator={currentAggregator || {} as ICompleteWashInfoAggregators}
          recipeName={recipeName || "currentRecipe"}
        />
      </FormProvider>
    </>
  );
};

export default WashAuxiliaryForm;
