import { Entypo } from '@expo/vector-icons';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
  ZStack
} from 'native-base';
import React, { useCallback } from 'react';
import { useWindowDimensions } from 'react-native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { object, SchemaOf } from 'yup';
import BackHeader from '../../../components/BackHeader';
import { Page } from '../../../components/Page';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { Report } from '../../reports/Report';
import { CycleSummaryTotal } from '../components/CycleSummaryTotal';
import CycleTab from './CycleTab';
import { RouteProp } from '@react-navigation/native';

interface WashRecipeAggregatorProps {
  navigation: NativeStackNavigationProp<HomeStackParamList, "WashRecipeAggregator", undefined>;
  route: RouteProp<HomeStackParamList, "WashRecipeAggregator">;
}

export const schema: SchemaOf<any> = object({});

export const WashRecipeAggregator: React.FC<WashRecipeAggregatorProps> = ({
  navigation,
  route,
}) => {
  const layout = useWindowDimensions();
  const [index, setIndex] = React.useState(0);

  const title1 = t('domain.home.tabs.my_cycles');
  const title2 = t('domain.home.tabs.summary');
  const title3 = t('domain.home.tabs.report');
  const [routes] = React.useState([
    { key: 'first', title: title1 },
    { key: 'second', title: title2 },
    { key: 'third', title: title3 },
  ]);

  const { washingPlant, washRecipe } = route.params;

  const CyclesTab = () => <CycleTab
      navigation={navigation}
      washingPlant={washingPlant}
      washRecipe={washRecipe} />

  const OverviewTab = () => <CycleSummaryTotal washingPlant={washingPlant} washRecipe={washRecipe} />

  const ReportTab = () => <Report washRecipe={washRecipe} washingPlant={washingPlant} />

  const renderScene = SceneMap({
    first: CyclesTab,
    second: OverviewTab,
    third: ReportTab,
  });

  return (
    <Page>
      <BackHeader
        mb={5}
        backTitle
        onPress={() => navigation.navigate('MyWashes', { washingPlant })}
      />

      <VStack justifyContent="space-between" flex={1}>
        <Box mb={'10px'}>
          <Text fontSize={'20px'}>
            {t('domain.analysis.step2.title_part_one')}
          </Text>

          <HStack
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center"
            space="16px"
          >
            <Text maxWidth='90%' lineHeight={32} fontSize={28} mt="12px" color="primary.500" fontWeight={'bold'}>
              {washRecipe.name}
            </Text>
            <IconButton
              mt={2}
              onPress={() =>
                navigation.push('WashRecipeCreateOrEdit', {
                  washingPlant,
                  washRecipe,
                })
              }
              color="primary.500"
              icon={<Entypo name="edit" size={18} color="#368D7D" />}
            />
          </HStack>
        </Box>

        <Flex grow={1}>
          <ZStack>
            <Divider mt={'50px'} />
          </ZStack>
          <TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            onIndexChange={setIndex}
            initialLayout={{ width: layout.width }}
            lazy={false}
            renderTabBar={(props) => (
              <TabBar
                {...props}
                style={{
                  backgroundColor: '#F2F8F7',
                  // width: '60%',
                  elevation: 0,
                  height: 50,
                  marginBottom: 20,
                }}
                indicatorStyle={{
                  backgroundColor: '#409D27',
                  borderBottomColor: '#409D27',
                  borderBottomWidth: 3,
                }}
                labelStyle={{
                  textTransform: 'capitalize',
                }}
                activeColor={'#409D27'}
                inactiveColor={'#BDBDBD'}
              />
            )}
          />
        </Flex>
      </VStack>
    </Page>
  );
};

export default WashRecipeAggregator;
