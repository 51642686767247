import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { CustomButton } from '../../../../components/CustomButton';
import { Page } from '../../../../components/Page';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../../types/wash_cycle.type';
import { IJetWashCycleChemical } from '../../../../types/wash_cycle_chemical.type';
import { toast } from '../../../../utils/toast';
import RecipeFormStep5 from '../../../standardRecipe/form/RecipeFormStep5';
import { createOrEditWashCycleChemical } from '../../services/chemicals.service';
import { WashRecipeAggregatorContext } from '../../../washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import { Platform } from 'react-native';

interface WashChemicalProps {
  washCycle: IJetWashCycle;
  chemical: IJetWashCycleChemical | undefined;
  currentAggregator: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe";
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleChemicals', undefined>;
}

export const WashChemical: React.FC<WashChemicalProps> = ({
  washCycle,
  chemical,
  currentAggregator,
  recipeName,
  navigation
}) => {
  // preciso do aggregator e do tipo de ciclo que está sendo editado
  const { allChemicals, allUnits, updateAggregatorInfo } = useContext(WashRecipeAggregatorContext);
  const { goBack } = navigation;
  const { handleSubmit, formState, reset } = useFormContext<IJetWashCycleChemical>();

  const chemicalMutation = useMutation(createOrEditWashCycleChemical);

  const onSubmit = async (data: IJetWashCycleChemical) => {
    chemicalMutation.mutate(
      { data, washCycle },
      {
        onSuccess: (value) => {
          reset();
          chemical === undefined
            ? insertNewChemicalInAggregator(value)
            : editChemicalInAggregator(data);
            Platform.OS !== 'web' && toast(t('domain.generic.success'));
          goBack();
        },
        onError: (error) => toast(t('domain.generic.error')),
      }
    );
  };

  function insertNewChemicalInAggregator(
    value: IJetWashCycleChemical | undefined,
  ){
    const newChemical = {
      ...value,
      chemical_complete: allChemicals.find(item => item.id === value?.chemical_id),
      chemical: allChemicals.find(item => item.id === value?.chemical_id),
      units_complete: allUnits.find(item => item.id === value?.chemical_unit_id),
      chemical_unit: allUnits.find(item => item.id === value?.chemical_unit_id),
    };

    const updatedAggregator: ICompleteWashInfoAggregators = {
      ...currentAggregator,
      [recipeName]: {
        ...currentAggregator[recipeName],
        chemicals: [
          ...currentAggregator[recipeName]?.chemicals || [],
          value?.id
        ],
        cycle_chemicals_details: [
          ...currentAggregator[recipeName]?.cycle_chemicals_details || [],
          newChemical,
        ],
      },
    };
    updateAggregatorInfo(updatedAggregator);
  };

  function editChemicalInAggregator(value: IJetWashCycleChemical){
    const newChemical = allChemicals.find(item => item.id == value?.chemical_id);
    const newUnit = allUnits.find(item => item.id == value?.chemical_unit_id);
    const updatedAggregator: ICompleteWashInfoAggregators = {
      ...currentAggregator,
      [recipeName]: {
        ...currentAggregator[recipeName],
        cycle_chemicals_details: currentAggregator[recipeName]?.cycle_chemicals_details?.map(item => {
          if(item.id == value.id){
            return {
              ...item,
              chemical_dose: value.chemical_dose,
              chemical: newChemical,
              chemical_complete: newChemical,
              chemical_unit_id: newUnit,
              units_complete: newUnit,
            }
          }else{
            return item
          }
        })
      },
    };

    updateAggregatorInfo(updatedAggregator);
  }

  return (
    <Page>
      <RecipeFormStep5
        chemical={chemical}
        washCycle={washCycle}
        currentAggregator={currentAggregator}
        recipeName={recipeName}
        isSubmitting={chemicalMutation.isLoading}
        navigation={navigation}
      />

      <CustomButton
        variant={'solid'}
        onPress={handleSubmit(onSubmit)}
        isDisabled={chemicalMutation.isLoading || formState.isSubmitting}
      >
        {t('domain.generic.save')}
      </CustomButton>
    </Page>
  );
};

export default WashChemical;
