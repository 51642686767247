import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { CustomButton } from '../../../../components/CustomButton';
import { Page } from '../../../../components/Page';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../../types/wash_cycle.type';
import { IJetWashCycleLaser } from '../../../../types/wash_cycle_laser.type';
import { toast } from '../../../../utils/toast';
import RecipeFormStep7 from '../../../standardRecipe/form/RecipeFormStep7';
import { createOrEditWashCycleLaser } from '../../services/lasers.service';
import { WashRecipeAggregatorContext } from '../../../washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import { Platform } from 'react-native';

interface WashLaserProps {
  washCycle: IJetWashCycle;
  laser: IJetWashCycleLaser | undefined;
  currentAggregator: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe";
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleLasers', undefined>;
}

export const WashLaser: React.FC<WashLaserProps> = ({
  washCycle,
  laser,
  currentAggregator,
  recipeName,
  navigation,
}) => {
  const { goBack } = navigation;
  const { allLasersScrapings, updateAggregatorInfo } = useContext(WashRecipeAggregatorContext);
  const { handleSubmit, formState, reset } = useFormContext<IJetWashCycleLaser>();

  const laserMutation = useMutation(createOrEditWashCycleLaser);

  const onSubmit = async (data: IJetWashCycleLaser) => {
    laserMutation.mutate(
      { data, washCycle },
      {
        onSuccess: (value) => {
          laser === undefined
            ? updateLaserScrapingInAggregator(value)
            : editLaserInAggregator(data);
          Platform.OS !== 'web' && toast(t('domain.generic.success'));
          reset();
          goBack();
        },
        onError: () => toast(t('domain.generic.error')),
      }
    );
  };

  function updateLaserScrapingInAggregator(
    data: IJetWashCycleLaser | undefined
  ){
    const laser = allLasersScrapings.find(item => item.id == data?.laser_id);
    const newLaser = {
      ...data,
      laser_detail: laser,
      laser: {
        ...laser,
        brand: laser?.brand_detail,
      }
    };

    const updatedAggregator: ICompleteWashInfoAggregators = {
      ...currentAggregator,
      [recipeName]: {
        ...currentAggregator[recipeName],
        add_laser_scrapings: [
          ...currentAggregator[recipeName]?.add_laser_scrapings || [],
          data?.id
        ],
        cycle_laser_scrapings_details: [
          ...currentAggregator[recipeName]?.cycle_laser_scrapings_details || [],
          newLaser,
        ],
      },
    };
    updateAggregatorInfo(updatedAggregator);
  };

  function editLaserInAggregator(value: IJetWashCycleLaser){
    const laser = allLasersScrapings.find(item => item.id == value?.laser_id);
    const updatedAggregator: ICompleteWashInfoAggregators = {
      ...currentAggregator,
      [recipeName]: {
        ...currentAggregator[recipeName],
        cycle_laser_scrapings_details: currentAggregator[recipeName]?.cycle_laser_scrapings_details?.map(item => {
          if(item.id == value.id){
            return {
              ...item,
              laser: {
                ...laser,
                brand: laser?.brand_detail,
              },
              laser_detail: laser,
              laser_labor_operation_time: value.laser_labor_operation_time,
              laser_machine_operation_time: value.laser_machine_operation_time,
            }
          }else{
            return item;
          }
        }),
      },
    };
    updateAggregatorInfo(updatedAggregator);
  }

  return (
    <Page>
      <RecipeFormStep7
        laser={laser}
        washCycle={washCycle}
        isSubmitting={laserMutation.isLoading}
        currentAggregator={currentAggregator}
        recipeName={recipeName}
        navigation={navigation}
      />

      <CustomButton
        variant={'solid'}
        onPress={handleSubmit(onSubmit)}
        isDisabled={laserMutation.isLoading || formState.isSubmitting}
      >
        {t('domain.generic.save')}
      </CustomButton>
    </Page>
  );
};

export default WashLaser;
