import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Box, Button, Input, ScrollView, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { Platform } from 'react-native';
import BackHeader from '../../../components/BackHeader';
import { Page } from '../../../components/Page';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import LottieGreenEco from '../lottieAnimations/LottieGreenEco';

type SavingsScreenProps = NativeStackScreenProps<HomeStackParamList, 'Savings'>;

export const SavingsScreen: React.FC<SavingsScreenProps> = ({ navigation, route }) => {
  const calcs = route.params;
  const [batches, setBatches] = useState<number | undefined>(0);

  return (
    <Page>
      <BackHeader backTitle buttonText={t('domain.knowMore.backTitle')} mb={5} />
      <ScrollView>
        <VStack justifyContent="space-between" flex={1}>
          <Box flex={1} mb={10}>
            <Text fontSize={26} lineHeight={30} fontWeight={500}>
              {t('domain.knowMore.title_part_one')}
              <Text>
                {'   '}
                <Text fontSize={26} lineHeight={30} fontWeight="bold" color="primary.500">
                  {t('domain.knowMore.title_part_two')}
                </Text>
              </Text>
            </Text>
          </Box>

          <Box flex={1} mb={16}>
            <Text fontSize={17} fontWeight={500}>
              {t('domain.knowMore.inputLabel_part_one')}
              <Text>
                {' '}
                <Text fontSize={17} fontWeight="bold" color="primary.500">
                  {t('domain.knowMore.inputLabel_part_two')}
                </Text>{' '}
                <Text fontSize={17} fontWeight={500}>
                  {t('domain.knowMore.inputLabel_part_three')}
                </Text>
              </Text>
            </Text>
            <Input
              value={batches ? (batches >= 0 ? batches.toString() : '') : ''}
              onChangeText={(text) => {
                // only accept number
                if (text.match(/^[0-9]*$/)) {
                  setBatches(parseInt(text));
                }
              }}
              placeholder={t('domain.knowMore.inputSearchPlaceholder')}
              width="100%"
              keyboardType="numeric"
              borderRadius="4"
              mt="2"
              py="3"
              px="1"
              fontSize="14"
              pl="5"
              backgroundColor="#E5EEED"
            />
          </Box>

          <Box flex={1} mb="30%">
            <Text fontSize={22} fontWeight={500}>
              {t('domain.knowMore.info_part_one')}
              <Text>
                {' '}
                <Text fontSize={22} fontWeight="bold" color="primary.500">
                  {t('domain.knowMore.info_part_two')}
                </Text>
              </Text>
            </Text>
            {Platform.OS !== 'web' && <LottieGreenEco />}
          </Box>
          <Button
            isDisabled={!batches || batches <= 0}
            onPress={() =>
              navigation.push('Environments', {
                input: Number(batches),
                ...calcs,
              })
            }
          >
            {t('domain.knowMore.button')}
          </Button>
        </VStack>
      </ScrollView>
    </Page>
  );
};

export default SavingsScreen;
