import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetTUnit, IJetTUnitID } from '../../../types/unit.type';

export interface IJetUnitsList extends IJetAdminResponseProtocol<IJetTUnit[]> {}

export async function getUnits(): Promise<IJetUnitsList> {
  const res = await jetAdmin.list<IJetTUnit[]>(JetAdminCollection.Units);

  return res;
}

export async function getUnit(id: IJetTUnitID): Promise<IJetTUnit | undefined> {
  const res = await jetAdmin.retrieve<IJetTUnit>(JetAdminCollection.Units, id);

  return res;
}
