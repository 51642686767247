import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

const CentrifugeBig = (props: SvgProps) => (
  <Svg {...props} width={56} height={56} fill="none">
    <Circle cx={28} cy={28} r={28} fill="#CFE8E5" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 53.887c14.297 0 25.887-11.59 25.887-25.887S42.297 2.113 28 2.113 2.113 13.703 2.113 28 13.703 53.887 28 53.887ZM28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.833 14.066a3.433 3.433 0 1 0 0-6.866 3.433 3.433 0 0 0 0 6.866Zm0 2.4a5.833 5.833 0 1 0 0-11.666 5.833 5.833 0 0 0 0 11.666ZM28.833 47.4a3.433 3.433 0 1 0 0-6.866 3.433 3.433 0 0 0 0 6.867Zm0 2.4a5.833 5.833 0 1 0 0-11.666 5.833 5.833 0 0 0 0 11.666ZM42.167 39.066a3.433 3.433 0 1 0 0-6.866 3.433 3.433 0 0 0 0 6.866Zm0 2.4a5.833 5.833 0 1 0 0-11.666 5.833 5.833 0 0 0 0 11.667ZM13.833 39.066a3.433 3.433 0 1 0 0-6.866 3.433 3.433 0 0 0 0 6.866Zm0 2.4a5.833 5.833 0 1 0 0-11.666 5.833 5.833 0 0 0 0 11.667ZM42.167 24.067a3.433 3.433 0 1 0 0-6.867 3.433 3.433 0 0 0 0 6.867Zm0 2.4a5.833 5.833 0 1 0 0-11.667 5.833 5.833 0 0 0 0 11.666ZM13.833 24.067a3.433 3.433 0 1 0 0-6.867 3.433 3.433 0 0 0 0 6.867Zm0 2.4a5.833 5.833 0 1 0 0-11.667 5.833 5.833 0 0 0 0 11.666Z"
      fill="#368D7D"
    />
  </Svg>
);

export default CentrifugeBig;
