import { Progress } from 'native-base';
import React from 'react';
import { useWashingPlantForm } from '../hooks/useWashingPlantForm';

interface FormProgressProps {}

export const FormProgress: React.FC<FormProgressProps> = () => {
  const { firstStep, lastStep, step } = useWashingPlantForm();

  return (
    <Progress background="#E5EEED" value={((step - firstStep) / (lastStep - firstStep)) * 100} />
  );
};

export default FormProgress;
