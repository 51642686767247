import { Box, Card, HStack, Pressable, Text } from 'native-base';
import React from 'react';
import { SvgProps } from 'react-native-svg';

interface MachineCardProps {
  name: string;
  onPress: () => void;
  icon?: JSX.Element;
  isSelected: boolean;
}

export const MachineCard: React.FC<MachineCardProps> = ({ name, onPress, icon, isSelected }) => {
  return (
    <Pressable onPress={onPress}>
      <Card
        width="100%"
        bgColor="#E5EEED"
        px={'24px'}
        py={'12px'}
        borderWidth={isSelected ? '2px' : '0px'}
        borderColor="primary.500"
        shadow={1}
      >
        <HStack alignItems="center" justifyContent="flex-start">
          {!!icon && <Box mr={'20px'}>{icon}</Box>}
          <Text>{name}</Text>
        </HStack>
      </Card>
    </Pressable>
  );
};

export default MachineCard;
