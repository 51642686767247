import { Box, HStack, Text } from 'native-base';
import React, { useContext, useState } from 'react';
import { UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import Confirm from '../../../components/Confirm';
import { t } from '../../../languages';
import { IJetWashRecipe } from '../../../types/wash-recipe.type';
import {
  IJetWashRecipeAggregatorComplete,
  IJetWashRecipeAggregatorID,
} from '../../../types/wash_recipe_aggregator.type';
import AddRecipeButton from '../../standardRecipe/components/AddRecipeButton';
import GreenRecipeModal from './GreenRecipeModal';
import {
  IDeleteWashRecipeAggregator,
} from '../services/washRecipeAggregator.service';
import CurrentRecipeModal from './CurrentRecipeModal';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { IJetTypeOfCycle } from '../../../types/type-of-cycle.type';
import { createOrEditWashCycle, getWashCycle, ISelectRecipe } from '../services/cycle.service';
import { IJetWashCycle } from '../../../types/wash_cycle.type';
import Accordion from '../../../../components/Accordion';
import ActiveCheck from '../../../assets/svgs/icon-check-active';
import InactiveCheck from '../../../assets/svgs/icon-check-inactive';
import SectionTitle from '../../../components/SectionTitle';
import CycleSummary from './CycleSummary';
import { QueryKeyCache } from '../../../config/query-cache';
import { hasHelpSolicited } from '../../washCycle/services/help.service';
import { Platform } from 'react-native';
import { WashRecipeAggregatorContext } from '../contexts/WashRecipeAggregatorContext';
import { IGetEverythingResponse } from '../../../services/getEverything';

interface AddRecipeButtonProps {
  index: number;
  typeOfCycles: IJetTypeOfCycle[];
  isLoading: boolean;
  deleteMutation: UseMutationResult<void, unknown, IDeleteWashRecipeAggregator, unknown>;
  washAggregator: IJetWashRecipeAggregatorComplete;
  washingCycleCompleteInfoQuery: UseQueryResult<IGetEverythingResponse[], unknown>;
  washRecipe: IJetWashRecipe;
  washingPlant: IJetWashingPlant;
  washCycle?: IJetWashCycle | null;
  shouldBeOpen?: boolean;
}

export const AggregatorAddCycleButton: React.FC<AddRecipeButtonProps> = ({
  washAggregator,
  index,
  typeOfCycles,
  isLoading,
  deleteMutation,
  washRecipe,
  washingPlant,
  washCycle,
}) => {
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [useStandardModal, setUseStandardModal] = useState<boolean>(false);
  const [hasRecipeError, setHasRecipeError] = useState(false);
  const { setLastTimeChanged,  completeWashInformation, updateAggregatorInfo } = useContext(WashRecipeAggregatorContext);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

  const currentAggregator = completeWashInformation?.aggregators.find(
    item => item.aggregator_id === washAggregator.id
  );

  const hasHelp = useQuery(`${QueryKeyCache.GetHelp}-${washAggregator.id}`, () =>
    hasHelpSolicited(washAggregator.id),
    {
      cacheTime: (60 * 60 * 1), // 1 hour
    }
  );
  const isCurrentRecipeSelected = !!washAggregator.current_recipe;
  const isNewRecipeSelected = !!washAggregator.new_recipe;
  const cycleType = typeOfCycles.find(({ id }) => id === washAggregator.cycle_type);

  const onPressAddNewCurrentRecipe = () => {
    !isCurrentRecipeSelected
      ? setUseStandardModal(true)
      : navigation.push('washRecipeCycle', {
          washingPlant: washingPlant,
          washRecipe: washRecipe,
          washRecipeAggregator: washAggregator,
          iSelectRecipe: 'current_recipe',
        });
  };

  const onPressAddNewGreenOfChangeRecipe = () => {
    !isNewRecipeSelected
      ? setShowModal(true)
      : navigation.push('washRecipeCycle', {
          washRecipeAggregator: washAggregator,
          washingPlant,
          washRecipe,
          iSelectRecipe: 'new_recipe',
        });
  };

  const onPressRemoveCycle = async (id?: IJetWashRecipeAggregatorID) => {
    if (!id) return;
    deleteMutation.mutate({ id, washRecipeID: washRecipe.id }, {
      onSuccess: () => setShowModalConfirm(false),
    });
  }

  const clearErrorRecipe = () => setHasRecipeError(false);

  const onPressCreateOrEdit = async (type: ISelectRecipe, useStandard?: boolean, copyRecipe?: boolean) => {
    // Quando tem um ciclo selecionado
    if (!cycleType?.id) {
      navigation.push('washRecipeCycleSelect', {
        washRecipeAggregator: washAggregator,
        washingPlant,
        washRecipe,
      });
      return;
    }

    let standardCycle = null;

    if(useStandard){
      standardCycle = await getWashCycle(cycleType?.standard_cycle);
    };

    if(copyRecipe && !washAggregator.current_recipe){
      return setHasRecipeError(true);
    }

    if(copyRecipe && washAggregator.current_recipe){
      standardCycle = await getWashCycle(washAggregator.current_recipe)
    }

    setShowModal(false);

    const newCycle = await createOrEditWashCycle({
      washAggregator: {
        current_recipe: washAggregator.current_recipe || undefined,
        cycle_type: washAggregator.cycle_type || undefined,
        id: washAggregator?.id || undefined,
        new_recipe: washAggregator.new_recipe || undefined,
      },
      type,
      data: {
        add_auxiliary_equipment: [],
        add_laser_scrapings: [],
        cycle_auxiliary_equipment: [],
        chemicals: [],
        ...(standardCycle || {}),
        id: washCycle?.id,
        type_of_cycle: cycleType?.id,
        default_cycle: false,
      },
    });

    const cycleCreated = {
      ...newCycle,
      cycle_type_of_cycle_details: cycleType,
      cycle_chemicals_details: standardCycle ? standardCycle?.cycle_chemicals_details : [],
      cycle_laser_scrapings_details: standardCycle ? standardCycle?.cycle_laser_scrapings_details : [],
      cycle_auxiliary_equipments_details: standardCycle ? standardCycle?.cycle_auxiliary_equipments_details : [],
    }

    const updatedAggregator = {
      aggregator_id: washAggregator.id || '',
      cycle_type: cycleType,
      currentRecipe: type === 'current_recipe' ? cycleCreated : currentAggregator?.currentRecipe,
      greenRecipe: type === 'new_recipe' ? cycleCreated : currentAggregator?.greenRecipe,
    };

    updateAggregatorInfo(updatedAggregator);

    const _aggregator = {
      id: washAggregator.id || '',
      cycle_type: cycleType.id,
      current_recipe: type === 'current_recipe'
        ? newCycle?.id || ''
        : currentAggregator?.currentRecipe?.id || '',
      new_recipe: type === 'new_recipe'
        ? newCycle?.id || ''
        : currentAggregator?.greenRecipe?.id,
    }

    if (!washAggregator?.id || !_aggregator) return;

    // remover esse last time changed e testar
    setLastTimeChanged(new Date());
    navigation.push('washRecipeCycle', {
      washingPlant: washingPlant,
      washRecipe: washRecipe,
      washRecipeAggregator: _aggregator,
      iSelectRecipe: type,
    });
  };

  return (
    <>
      <Accordion
        id={`accordion-${washAggregator.id}`}
        isValid={true}
        left={isCurrentRecipeSelected && isNewRecipeSelected ? <ActiveCheck /> : <InactiveCheck />}
        title={cycleType?.name || t('domain.analysis.step2.cycle')}
        titleColor="#368D7D"
        titleWeight="bold"
        py="25px"
      >
        <AddRecipeButton
          isAdded={!isCurrentRecipeSelected}
          text={t('domain.analysis.step2.button.add_current_recipe')}
          onPress={onPressAddNewCurrentRecipe}
        >
          <HStack justifyContent="space-between">
            <Text marginRight={2} fontSize={14} color="#368D7D" fontWeight={'normal'}>
              {t('domain.analysis.step2.your_recipe')}
            </Text>
            <Text textAlign="right" fontSize={14} color="#409D27">
              {t('domain.generic.edit')}
            </Text>
          </HStack>
        </AddRecipeButton>

        <Box my="8px">
          <AddRecipeButton
            isAdded={!isNewRecipeSelected}
            text={t('domain.analysis.step2.button.add_new_recipe')}
            onPress={onPressAddNewGreenOfChangeRecipe}
            isDisabled={isLoading}
          >
            <HStack justifyContent="space-between">
              <Text marginRight={2} fontSize={14} color="#368D7D" fontWeight={'normal'}>
                {t('domain.analysis.step2.green_recipe')}
              </Text>
              <Text textAlign="right" fontSize={14} color="#409D27">
                {t('domain.generic.edit')}
              </Text>
            </HStack>
          </AddRecipeButton>
          {hasHelp.data && (
            <Text fontSize="12px">{t('domain.analysis.step2.green_recipe_ask_helped')}</Text>
          )}
        </Box>

        <SectionTitle title={t('domain.analysis.step2.you_saved')}>
          <Box my={Platform.OS === 'web' ? 5 : 0}>
            <CycleSummary washAggregator={washAggregator} washingPlant={washingPlant} />
          </Box>
        </SectionTitle>

        <HStack flexGrow={1} justifyContent="flex-end" mt={4} mb={-2} mr={1}>
          <Confirm
            onConfirm={() => onPressRemoveCycle(washAggregator.id)}
            buttonText={t('domain.analysis.step2.button.remove')}
            button={{ mb: 1 }}
            message={`${t('domain.analysis.step2.toast.message')} ${index + 1}?`}
            onConfirmToastMessage={t('domain.analysis.step2.toast.removeSuccessfully')}
            isLoading={deleteMutation.isLoading}
            setShowModal={setShowModalConfirm}
            openModal={showModalConfirm}
          />
        </HStack>
      </Accordion>

      <CurrentRecipeModal
        name={cycleType?.name}
        showModal={useStandardModal}
        setShowModal={setUseStandardModal}
        onConfirm={() => onPressCreateOrEdit('current_recipe', false)}
        onConfirmStandard={() => onPressCreateOrEdit('current_recipe', true)}
      />

      <GreenRecipeModal
        showModal={showModal}
        setShowModal={setShowModal}
        onConfirm={(useStandard: boolean = false, copyRecipe: boolean = false) =>
          onPressCreateOrEdit('new_recipe', useStandard, copyRecipe)
        }
        washAggregator={washAggregator.id}
        washing_plant={washingPlant}
        hasAskedForHelp={hasHelp.data === true}
        hasRecipeError={hasRecipeError}
        setHasRecipeError={setHasRecipeError}
        clearErrorRecipe={clearErrorRecipe}
      />
    </>
  );
};

export default AddRecipeButton;
