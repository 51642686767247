import React from 'react';
import { useAuth } from '../hooks/useAuth';

interface AuthGuardInterface {}

const AuthGuard: React.FC<AuthGuardInterface> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <>{children}</> : null;
};

export default AuthGuard;
