import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Text, Box, VStack, Flex } from 'native-base';
import React, { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import Loading from '../../../../components/Loading';
import BackHeader from '../../../components/BackHeader';
import FormInput from '../../../components/Input/FormInput';
import { Page } from '../../../components/Page';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { IJetWashRecipeForm } from '../../../types/wash-recipe.type';
import { toast } from '../../../utils/toast';
import StandardFormNextButton from '../../standardRecipe/components/StandardFormNextButton';
import { createOrEditWashRecipe } from '../services/washRecipe.service';

type WashRecipeCreateOrEditProps = NativeStackScreenProps<
  HomeStackParamList,
  'WashRecipeCreateOrEdit'
>;

export const WashRecipeCreateOrEdit: React.FC<WashRecipeCreateOrEditProps> = ({
  navigation,
  route,
}) => {
  const { washingPlant, washRecipe } = route.params;
  const methods = useForm<IJetWashRecipeForm>({
    // resolver: yupResolver(schema),
    defaultValues: {
      name: washRecipe?.name,
      id: washRecipe?.id,
    },
  });
  const { handleSubmit, watch } = methods;

  const { isLoading, mutate } = useMutation(createOrEditWashRecipe, {
    onSuccess: (response) => {
      if (!response) return;
      navigation.push('WashRecipeAggregator', { washRecipe: response, washingPlant });
    },
    onError: () => toast('Error creating washing recipe'),
  });

  const onSubmit = (data: IJetWashRecipeForm) => {
    mutate({
      data,
      washingPlant: washingPlant,
    });
  };

  return (
    <Page>
      <BackHeader backTitle />
      <VStack justifyContent="space-between" flex={1}>
        <VStack flexGrow={1}>
          <Text fontSize={22} fontWeight="bold" color="primary.500">
            {t('domain.analysis.step1.title_part_one')}
            <Text>
              {' '}
              <Text fontSize={22} fontWeight={500}>
                {t('domain.analysis.step1.title_part_two')}
              </Text>
            </Text>
          </Text>

          <Box mt={8}>
            <FormInput
              isLoading={isLoading}
              methods={methods}
              name={'name'}
              placeholder={t('domain.analysis.step1.input.placeholder')}
            />
          </Box>

          {isLoading && (
            <Flex justifyContent="center" grow={1}>
              <Loading />
            </Flex>
          )}
        </VStack>

        <StandardFormNextButton onPress={handleSubmit(onSubmit)} isDisabled={isLoading} />
      </VStack>
    </Page>
  );
};

export default WashRecipeCreateOrEdit;
