import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import {
  IJetWashRecipe,
  IJetWashRecipeComplete,
  IJetWashRecipeCompleteInfo,
  IJetWashRecipeCreate,
  IJetWashRecipeForm,
  IJetWashRecipeID,
} from '../../../types/wash-recipe.type';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { getWashingPlant } from '../../washingPlant/services/getWashingPlants';
import {
  listWashRecipeAggregators,
} from '../../washRecipeAggregator/services/washRecipeAggregator.service';

interface ICreateWashRecipeParams {
  data: IJetWashRecipeForm;
  washingPlant: IJetWashingPlant;
}

export async function getWashRecipe(id: IJetWashRecipeID) {
  // analisar como refatorar essa função depois
  const response = await jetAdmin.retrieve<IJetWashRecipe>(JetAdminCollection.WashRecipes, id);
  if (!response?.washing_plant) throw new Error('Washing plant not found');

  const washingPlant = await getWashingPlant(response.washing_plant);
  if (!washingPlant) throw new Error('Washing plant not found');

  // const aggregatorsIDS = response?.wash_recipe_aggregators || [];
  // const _aggregators = await listWashRecipeAggregators({ aggregatorsIDS });

  const completeResponse: IJetWashRecipeComplete = {
    ...response,
    washing_plant_detail: washingPlant,
    wash_recipe_aggregators_detail: [],
    cycles: [],
  };

  return completeResponse;
}

export async function getAllWashRecipeInfo(
  id: IJetWashRecipeID
): Promise<IJetWashRecipeCompleteInfo[]> {
  const response = await jetAdmin.list<IJetWashRecipeCompleteInfo[]>(JetAdminCollection.GetAllInfosFromAggregators, { id });
  return response.results;
};

export async function createOrEditWashRecipe(
  params: ICreateWashRecipeParams
): Promise<IJetWashRecipe | undefined> {
  const { data, washingPlant } = params;

  if (data.id) {
    return await editWashRecipe(data);
  } else {
    const response = await createWashRecipe({ data, washingPlant });
    if (!response) throw new Error('Wash recipe not created');

    // const _emptyAggregator: IJetWashRecipeAggregatorForm = {}
    // const _aggregator = await createWashRecipeAggregator({ data: _emptyAggregator, washingRecipe: response });
    // if (!_aggregator?.id) throw new Error("Could not create wash recipe aggregator");
    // await editWashRecipe({ ...response, wash_recipe_aggregators: [_aggregator.id] });

    await editWashRecipe({ ...response, wash_recipe_aggregators: [] });

    return response;
  }
}

export async function createWashRecipe(
  props: ICreateWashRecipeParams
): Promise<IJetWashRecipe | undefined> {
  const { data, washingPlant } = props;
  const washRecipeCreate = convertWashRecipeFormToWashRecipeCreate(data, washingPlant);

  const res = await jetAdmin.create<IJetWashRecipe>(
    JetAdminCollection.WashRecipes,
    washRecipeCreate
  );

  return res;
}

export async function editWashRecipe(
  props: Partial<IJetWashRecipe>
): Promise<IJetWashRecipe | undefined> {
  if (!props.id) throw new Error('Wash recipe id is required');

  const res = await jetAdmin.update<IJetWashRecipe>(
    JetAdminCollection.WashRecipes,
    props.id,
    props
  );

  return res;
}

function convertWashRecipeFormToWashRecipeCreate(
  data: IJetWashRecipeForm,
  washingPlant: IJetWashingPlant
): IJetWashRecipeCreate {
  return {
    name: data.name,
    washing_plant: washingPlant.id,
    active: true,
    created_at: new Date(),
    updated_at: new Date(),
  };
}
