import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const DryerBig = (props: SvgProps) => (
  <Svg {...props} width={47} height={57} fill="none">
    <Rect width={47} height={56.038} rx={2} fill="#CFE8E5" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.147.633c.35 0 .633.284.633.633v12.031a.633.633 0 0 1-1.266 0V1.267c0-.35.283-.634.633-.634Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.59 14.563H1.267v38.942c0 .7.567 1.267 1.267 1.267h41.791c.7 0 1.267-.567 1.267-1.267V14.563Zm1.267-1.266v40.208a2.533 2.533 0 0 1-2.533 2.533H2.533A2.533 2.533 0 0 1 0 53.505V13.297h46.857Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m45.59 2.452-.01-.08a1.267 1.267 0 0 0-1.256-1.106H2.533c-.645 0-1.18.484-1.257 1.107l-.01.079v10.529h44.325V2.452ZM.02 2.216H0v12.031h46.857V2.217h-.02A2.533 2.533 0 0 0 44.324 0H2.533A2.533 2.533 0 0 0 .019 2.216Z"
      fill="#368D7D"
    />
    <Path
      d="M5.382 6.648c0-.874.709-1.583 1.583-1.583h3.482a1.583 1.583 0 1 1 0 3.166H6.965a1.583 1.583 0 0 1-1.583-1.583ZM35.776 6.648c0-.874.709-1.583 1.583-1.583h3.483a1.583 1.583 0 1 1 0 3.166h-3.483a1.583 1.583 0 0 1-1.583-1.583Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.429 50.34c8.568 0 15.513-6.946 15.513-15.514S31.997 19.313 23.43 19.313 7.915 26.258 7.915 34.826 14.861 50.34 23.43 50.34Zm0 1.266c9.267 0 16.78-7.512 16.78-16.78 0-9.267-7.513-16.78-16.78-16.78-9.267 0-16.78 7.513-16.78 16.78 0 9.268 7.513 16.78 16.78 16.78Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 46.207c6.427 0 11.638-5.018 11.638-11.207 0-6.19-5.21-11.207-11.638-11.207-6.427 0-11.638 5.018-11.638 11.207 0 6.19 5.21 11.207 11.638 11.207Zm0 1.793C30.956 48 37 42.18 37 35s-6.044-13-13.5-13S10 27.82 10 35s6.044 13 13.5 13Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.864 30.15a.75.75 0 0 1 .15 1.05c-.398.531-.916 1.878 0 3.1 1.484 1.978.602 4.098 0 4.9a.75.75 0 1 1-1.2-.9c.399-.531.917-1.878 0-3.1-1.483-1.978-.601-4.098 0-4.9a.75.75 0 0 1 1.05-.15ZM23.864 30.15a.75.75 0 0 1 .15 1.05c-.398.531-.916 1.878 0 3.1 1.484 1.978.602 4.098 0 4.9a.75.75 0 1 1-1.2-.9c.399-.531.917-1.878 0-3.1-1.483-1.978-.601-4.098 0-4.9a.75.75 0 0 1 1.05-.15ZM27.864 30.15a.75.75 0 0 1 .15 1.05c-.398.531-.916 1.878 0 3.1 1.484 1.978.602 4.098 0 4.9a.75.75 0 1 1-1.2-.9c.399-.531.917-1.878 0-3.1-1.483-1.978-.601-4.098 0-4.9a.75.75 0 0 1 1.05-.15Z"
      fill="#368D7D"
    />
  </Svg>
);

export default DryerBig;
