import { Box, HStack, Pressable, Text, View } from 'native-base';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FlatList, Platform, StyleSheet } from 'react-native';
// @ts-ignore
import BottomSheet from 'react-native-easy-bottomsheet';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import Loading from '../../../../components/Loading';
import FormInput from '../../../components/Input/FormInput';
import { t } from '../../../languages';
import { IJetCountry } from '../../../types/country.type';
import { IWashingPlantForm } from '../types/washingPlantForm.type';

interface ICountryModal {
  countries: IJetCountry[];
  isLoading: boolean;
}

export const CountryModal: React.FC<ICountryModal> = ({ countries, isLoading }) => {
  const methods = useFormContext<IWashingPlantForm>();
  const { trigger, watch, setValue } = methods;
  const [isVisible, setVisible] = useState(false);

  function handleCountryPress(country: IJetCountry) {
    setValue('selectedCountryID', country.code_numeric);
    trigger('selectedCountryID');
    setVisible(false);
  }

  return (
    <>
      <View style={styles.centeredView}>
        <BottomSheet
          bottomSheetTitle={t('domain.washingPlants.step.two.input.placeholder')}
          bottomSheetStyle={{
            backgroundColor: 'white',
            maxHeight: '91%',
            minHeight: '40%',
          }}
          onRequestClose={() => setVisible(false)}
          bottomSheetVisible={isVisible}
          onBackdropPress
        >
          <FlatList
            data={countries || []}
            style={{
              backgroundColor: 'white',
              height: Platform.OS === 'web' ? '70vh' : '100%',
            }}
            ItemSeparatorComponent={() => <Box mb="8px" />}
            ListEmptyComponent={() => (!isLoading ? <EmptyPlaceholder /> : <Loading />)}
            renderItem={({ item, index }) => {
              const isLast = countries.length ? index === countries.length - 1 : false;
              return (
                <>
                  <Pressable key={`country-${item.id}`} onPress={() => handleCountryPress(item)}>
                    <HStack mb={5} alignContent="center" alignItems="center">
                      <Text color="black" fontSize="lg" fontWeight={900}>
                        {item.country}
                      </Text>
                    </HStack>
                  </Pressable>
                  {isLast && <Box height={10} />}
                </>
              );
            }}
          />
          <Box mb={10} />
        </BottomSheet>
      </View>
      <Box mt={8}>
        <Pressable onPress={() => setVisible(true)}>
          <View pointerEvents="none">
            <FormInput
              methods={methods}
              name={'selectedCountryID'}
              inputProps={{
                value:
                  countries.find((country) => country.code_numeric === watch('selectedCountryID'))
                    ?.country || ``,
              }}
              placeholder={t('domain.washingPlants.step.two.input.placeholder')}
            />
          </View>
        </Pressable>
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#3E92CC',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  flag: {
    fontSize: 20,
    fontWeight: 'bold',
    marginRight: 10,
  },
  country: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export default CountryModal;
