import React from 'react';
import { Heading, ScrollView, Stack, Text } from 'native-base';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { GuestRootStackParamList } from '../../routes/guest.routes';
import { useAuth } from '../auth/hooks/useAuth';
import { Page } from '../../components/Page';
import { t } from '../../languages';
import { Home } from './screens/Home';
import { HomeStackParamList } from '../../routes/auth.routes';

interface HomeRootInterface {
  navigation: NativeStackNavigationProp<HomeStackParamList>;
}

export function HomeRoot({ navigation }: HomeRootInterface) {
  const auth = useAuth();

  return (
    <Page>
      <Home navigation={navigation} />
    </Page>
  );
}
