import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SignIn } from '../domains/auth/screens/SignIn';
import { ResetSuccess } from '../domains/auth/screens/ResetSuccess';
import '../globals/styles/theme';
import { ResetPasswordRoot } from '../domains/auth/screens/ResetPassword/ResetPasswordRoot';
import { SignUp } from '../domains/auth/screens/SignUp';
import { WaitingApproval } from '../domains/auth/screens/WaitingApproval';
import { SignupSuccess } from '../domains/customer/components/Card';
import GuestGuard from '../domains/auth/guards/GuestGuard';

export type GuestRootStackParamList = {
  Onboarding1: undefined;
  Onboarding2: undefined;
  SignIn: undefined;
  SignUp: undefined;
  SignUp2: undefined;
  SignUpSuccess: undefined;
  WaitingApproval: undefined;
  SignUp3: {
    name: string;
    email: string;
    phone: string;
    vatNumber: string;
    board: string;
  };
  SignUp4: {
    name: string;
    email: string;
    phone: string;
    vatNumber: string;
    board: string;
    address_id: string;
  };
  CustomerSignUp: undefined;
  CustomerSignUp2: {
    name: string;
  };
  CustomerSignUp3: {
    name: string;
    email: string;
    phone: string;
  };
  ResetPassword: {
    origin: string;
    token: string;
    type: string;
    email: string;
  };
  ResetSuccess: { email: string };
  Home: undefined;
};

const Stack = createNativeStackNavigator<GuestRootStackParamList>();

const UnauthenticatedRoutes = () => {
  return (
    <GuestGuard>
      <Stack.Navigator>
        <Stack.Screen name="SignIn" component={SignIn} options={{ headerShown: false }} />
        <Stack.Screen name="SignUp" component={SignUp} options={{ headerShown: false }} />
        <Stack.Screen
          name="SignUpSuccess"
          component={SignupSuccess}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="WaitingApproval"
          component={WaitingApproval}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ResetPassword"
          component={ResetPasswordRoot}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ResetSuccess"
          component={ResetSuccess}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </GuestGuard>
  );
};

export default UnauthenticatedRoutes;
