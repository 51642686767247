import { FormControl, IInputProps, Input, Skeleton } from 'native-base';
import React, { useMemo } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';

interface FormInputProps<T> {
  methods: UseFormReturn<any, any>;
  name: string;
  placeholder: string;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  inputProps?: IInputProps;
  border?: number;
  label?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

const FormInput: React.FC<FormInputProps<unknown>> = ({
  methods,
  name,
  placeholder,
  rules,
  inputProps,
  border = 1,
  label,
  isLoading = false,
  isDisabled = false,
  isReadOnly = false,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
  } = methods;

  const error = errors[name];
  const hasError = !!error;

  const _color = useMemo(() => {
    if (hasError) return 'red.500';
    if (isDisabled || isReadOnly) return 'gray.400';

    return '#368D7D';
  }, [hasError, isReadOnly, isDisabled]);

  // create a function that replace ',' to '.' if keyboard type is numeric
  const _onChange = (value: string) => {
    if (inputProps?.keyboardType === 'numeric') {
      if (value) {
        return value.replace(/,/g, '.');
      }
    }
    return value;
  };

  return (
    <FormControl isRequired={!!rules?.required} isInvalid={!!errors[name]}>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            {label && <FormControl.Label>{label}</FormControl.Label>}
            <Skeleton isLoaded={!isLoading}>
              <Input
                onBlur={onBlur}
                isInvalid={!!errors[name]}
                onChangeText={(value) => {
                  return onChange(_onChange(value));
                  // onChange(value);
                  // _onChange();
                }}
                value={value ? String(value) : undefined}
                placeholder={placeholder}
                background="#E4EEED"
                isReadOnly={isReadOnly}
                isDisabled={isDisabled}
                padding={'20px'}
                borderRadius={8}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'16px'}
                borderWidth={border ? 1 : 0}
                borderTopColor={hasError ? 'red.500' : 'transparent'}
                borderLeftColor={hasError ? 'red.500' : 'transparent'}
                borderRightColor={hasError ? 'red.500' : 'transparent'}
                borderBottomColor={hasError ? 'red.500' : 'transparent'}
                placeholderTextColor={'rgba(165, 178, 178, 1)'}
                color={_color}
                {...inputProps}
              />
            </Skeleton>
            <FormControl.ErrorMessage>{errors[name]?.message}</FormControl.ErrorMessage>
          </>
        )}
      />
    </FormControl>
  );
};

export default FormInput;
