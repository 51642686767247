import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Box, Button, ScrollView, VStack } from 'native-base';
import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Grid, Row } from 'react-native-easy-grid';
import Loading from '../../../../components/Loading';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { IJetWashRecipeAggregatorComplete } from '../../../types/wash_recipe_aggregator.type';
import { WashRecipeAggregatorContext } from '../contexts/WashRecipeAggregatorContext';
import { SummaryIndicator } from './SummaryIndicator';

interface CycleSummaryTotalProps {
  washingPlant: IJetWashingPlant;
  washRecipe: IJetWashRecipeAggregatorComplete;
}

export const CycleSummaryTotal: React.FC<CycleSummaryTotalProps> = () => {
  const { aggregators, cycleSummary, isLoading } = useContext(WashRecipeAggregatorContext);
  const {
    totalFuelPercent,
    totalFuel,
    totalEnergyPercent,
    totalEnergy,
    totalWaterPercent,
    totalWater,
    totalTimePercent,
    totalTime,
    totalGreenTime,
  } = cycleSummary
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();
  
  if (aggregators?.length === 0) return <></>;

  if (isLoading)
    return (
      <VStack>
        <Box my={5} borderRadius={'md'} p={5} bgColor="white">
          <Loading />
        </Box>
        <Button isDisabled onPress={() => {}}>
          {t('domain.home.button.know_more')}
        </Button>
      </VStack>
    );

  return (
    <>
    <ScrollView flexGrow={1}>
      <Box borderRadius={'md'} p={5} bgColor="white">
        <Grid>
          <Row>
            <Grid
              style={{
                ...stylesSummary.empty,
                ...stylesSummary.right,
                ...stylesSummary.bottom,
              }}
            >
              <SummaryIndicator
                isLoading={isLoading}
                title="CO₂"
                unit="Kg"
                chart={{
                  rgb: '71, 143, 84',
                  data: [Number(totalFuelPercent / 100)],
                  // bgColor: '#F2F8F7',
                }}
                subtitle={`${totalFuelPercent.toFixed(0)}%`}
                value={totalFuel === -1 ? '---' : `${totalFuel.toFixed(2)}`}
              />
            </Grid>
            <Grid style={{ ...stylesSummary.empty, ...stylesSummary.bottom }}>
              <SummaryIndicator
                isLoading={isLoading}
                title="Energy"
                unit="kWh"
                chart={{
                  rgb: '253, 152, 45',
                  data: [Number(totalEnergyPercent / 100)],
                  // bgColor: '#F2F8F7',
                }}
                subtitle={`${totalEnergyPercent.toFixed(0)}%`}
                value={totalEnergy === -1 ? '---' : `${totalEnergy.toFixed(2)}`}
              />
            </Grid>
          </Row>
          <Row>
            <Grid style={{ ...stylesSummary.empty, ...stylesSummary.right }}>
              <SummaryIndicator
                isLoading={isLoading}
                title="Water"
                unit="Liters"
                chart={{
                  rgb: '40, 162, 229',
                  data: [Number(totalWaterPercent / 100)],
                  // bgColor: '#F2F8F7',
                }}
                subtitle={`${totalWaterPercent.toFixed(0)}%`}
                value={totalWater === -1 ? '---' : `${totalWater.toFixed(2)}`}
              />
            </Grid>
            <Grid style={{ ...stylesSummary.empty }}>
              <SummaryIndicator
                isLoading={isLoading}
                title="Time"
                unit="Min."
                chart={{
                  rgb: '155, 59, 196',
                  data: [Number(totalTimePercent / 100)],
                  // bgColor: '#F2F8F7',
                }}
                subtitle={`${totalTimePercent.toFixed(0)}%`}
                value={totalTime === -1 ? '---' : `${totalTime.toFixed(2)}`}
              />
            </Grid>
          </Row>
        </Grid>
      </Box>

    </ScrollView>
      <Button
        mt={5}
        onPress={() =>
          navigation.push('Savings', {
            totalEnergy,
            totalFuel,
            totalGreenTime,
            totalTime,
            totalWater,
          })
        }
      >
        {t('domain.home.button.know_more')}
      </Button>
      </>
  );
};

export const stylesSummary = StyleSheet.create({
  left: {
    borderColor: 'transparent',
    borderLeftColor: '#D1DDDC',
    borderWidth: 1,
  },
  top: {
    borderColor: 'transparent',
    borderTopColor: '#D1DDDC',
    borderWidth: 1,
  },
  right: {
    borderColor: 'transparent',
    borderRightColor: '#D1DDDC',
    borderWidth: 1,
  },
  bottom: {
    borderColor: 'transparent',
    borderBottomColor: '#D1DDDC',
    borderWidth: 1,
  },
  empty: {
    borderColor: 'transparent',
    borderWidth: 1,
  },
  mt: {
    marginTop: -10,
  },
});

export default React.memo(CycleSummaryTotal);
