import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const IconUp = (props: SvgProps) => (
  <Svg {...props} width={19} height={12} fill="none">
    <Path d="M18 10.5 9.5 2 1 10.5" stroke="#368D7D" strokeWidth={2} strokeLinecap="round" />
  </Svg>
);

export default IconUp;
