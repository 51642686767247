import axios, { AxiosResponse, AxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, {
  retries: 3,
  retryCondition: (error) => {
    if (!error?.response?.status) return true;

    return error?.response?.status >= 500;
  },
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
});

export type Endpoint = string;
export type Body = any;
export type Token = string | undefined;
export type Otherheaders = AxiosRequestHeaders | undefined;

export const AUTH_TOKEN = '@auth-token';

export enum VariantEnum {
  Default = 'info',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

export interface CustomAxiosResponse<T> extends AxiosResponse<T> {
  message: string;
  variant: VariantEnum;
}

/**
 * A facade axios PUT implementation
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body the body of the request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosPUT = async <T>(
  endpoint: Endpoint,
  body: Body,
  token: Token,
  otherHeaders: Otherheaders = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.put(endpoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const succeed: boolean = response.status >= 200 && response.status < 300;
    const variant: VariantEnum = succeed ? VariantEnum.Success : VariantEnum.Error;

    return {
      ...response,
      message: 'Sucesso!',
      variant,
    };
  } catch (error: any) {
    return error;
  }
};

/**
 * A facade axios PUT implementation
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body the body of the request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosPATCH = async <T>(
  endpoint: Endpoint,
  body: Body,
  token: Token,
  otherHeaders: Otherheaders = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.patch(endpoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const succeed: boolean = response.status >= 200 && response.status < 300;
    const variant: VariantEnum = succeed ? VariantEnum.Success : VariantEnum.Error;

    return {
      ...response,
      message: 'Sucesso!',
      variant,
    };
  } catch (error: any) {
    return error;
  }
};

/**
 * Use to get
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosGET = async <T>(
  endpoint: Endpoint,
  token?: Token,
  otherHeaders: Otherheaders = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const variant: VariantEnum = checkIfRequestWasSuccessful(response.status)
      ? VariantEnum.Success
      : VariantEnum.Error;

    return {
      ...response,
      message: 'Sucesso!',
      variant,
    };
  } catch (error) {
    throw new Error('Ooops, aconteceu um problema, tente novamente!');
  }
};

export const axiosPOST = async <T>(
  endpoint: Endpoint,
  body: Body,
  token?: Token,
  otherHeaders: Otherheaders = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.post(endpoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const variant: VariantEnum = checkIfRequestWasSuccessful(response.status)
      ? VariantEnum.Success
      : VariantEnum.Error;

    return {
      ...response,
      message: 'Sucesso!',
      variant,
    };
  } catch (error) {
    throw error;
  }
};

/**
 * Use to delete
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 * @param {object} otherData
 */
export const axiosDelete = async <T>(
  endpoint: Endpoint,
  token: Token,
  otherHeaders: Otherheaders = {},
  otherData = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
      data: otherData,
    });

    const variant: VariantEnum = checkIfRequestWasSuccessful(response.status)
      ? VariantEnum.Success
      : VariantEnum.Error;

    return {
      ...response,
      message: 'Sucesso!',
      variant,
    };
  } catch (error: any) {
    return error;
  }
};

export const checkIfRequestWasSuccessful = (status: number): boolean =>
  status >= 200 && status < 300;

