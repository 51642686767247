import * as React from 'react';
import Svg, { SvgProps, G, Path, Circle, Defs, ClipPath } from 'react-native-svg';

const EmptyCycles = (props: SvgProps) => (
  <Svg {...props} width={224} height={77} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M57.641 2.46H1.681A1.68 1.68 0 0 0 0 4.141v70.381c0 .929.753 1.681 1.68 1.681h55.961a1.68 1.68 0 0 0 1.681-1.68V4.14a1.68 1.68 0 0 0-1.68-1.68Z"
        fill="#368D7D"
      />
      <Path
        d="M59.221 1.162H3.261a1.68 1.68 0 0 0-1.68 1.681v70.381c0 .928.752 1.68 1.68 1.68h55.96a1.68 1.68 0 0 0 1.681-1.68V2.843a1.68 1.68 0 0 0-1.68-1.68Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M20.534 4.813H7.97v5.841h12.564V4.813ZM15.823 66.78H5.613v4.445h10.21V66.78ZM49.808 10.654a2.372 2.372 0 1 0 0-4.744 2.372 2.372 0 0 0 0 4.744ZM50.771 33.56h-5.11v11.134h5.11V33.56ZM7.421 14.396h46.91M5.614 62.752H54.33"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M31.782 54.546c8.82 0 15.972-7.151 15.972-15.972s-7.151-15.972-15.972-15.972-15.972 7.15-15.972 15.972c0 8.821 7.15 15.972 15.972 15.972Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M31.782 50.26c6.213 0 11.25-5.038 11.25-11.251 0-6.214-5.037-11.25-11.25-11.25-6.214 0-11.25 5.036-11.25 11.25 0 6.213 5.036 11.25 11.25 11.25Z"
        fill="#368D7D"
      />
      <Path d="M20.534 40.635s7.14-3.057 14.223 1.096" stroke="#E4F4F0" strokeMiterlimit={10} />
      <Path
        d="M42.977 40.135s-8.528-3.836-12.253-.072M32.43 35.342s-.23-2.145.73-1.778c.961.366-.73 1.778-.73 1.778ZM30.925 37.675s.324-2.736-1.142-2.327c-1.467.41 1.142 2.327 1.142 2.327Z"
        stroke="#E4F4F0"
        strokeMiterlimit={10}
      />
    </G>
    <G clipPath="url(#b)">
      <Path
        d="M219.641 2.46h-55.96c-.928 0-1.681.753-1.681 1.681v70.381c0 .929.753 1.681 1.681 1.681h55.96c.928 0 1.681-.752 1.681-1.68V4.14c0-.928-.753-1.68-1.681-1.68Z"
        fill="#368D7D"
      />
      <Path
        d="M221.221 1.162h-55.96c-.928 0-1.681.753-1.681 1.681v70.381c0 .928.753 1.68 1.681 1.68h55.96a1.68 1.68 0 0 0 1.681-1.68V2.843a1.68 1.68 0 0 0-1.681-1.68Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M182.534 4.813H169.97v5.841h12.564V4.813ZM177.823 66.78h-10.209v4.445h10.209V66.78ZM211.808 10.654a2.372 2.372 0 1 0 0-4.744 2.372 2.372 0 0 0 0 4.744ZM212.771 33.56h-5.11v11.134h5.11V33.56ZM169.421 14.396h46.91M167.614 62.752h48.717"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M193.782 54.546c8.821 0 15.972-7.151 15.972-15.972s-7.151-15.972-15.972-15.972-15.972 7.15-15.972 15.972c0 8.821 7.151 15.972 15.972 15.972Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M193.782 50.26c6.213 0 11.25-5.038 11.25-11.251 0-6.214-5.037-11.25-11.25-11.25-6.214 0-11.251 5.036-11.251 11.25 0 6.213 5.037 11.25 11.251 11.25Z"
        fill="#368D7D"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.864 34.15a.749.749 0 0 1 .15 1.05c-.398.531-.916 1.878 0 3.1 1.484 1.978.602 4.098 0 4.9a.75.75 0 1 1-1.2-.9c.399-.531.917-1.878 0-3.1-1.483-1.978-.601-4.098 0-4.9a.75.75 0 0 1 1.05-.15ZM193.864 34.15a.749.749 0 0 1 .15 1.05c-.398.531-.916 1.878 0 3.1 1.484 1.978.602 4.098 0 4.9a.75.75 0 1 1-1.2-.9c.399-.531.917-1.878 0-3.1-1.483-1.978-.601-4.098 0-4.9a.75.75 0 0 1 1.05-.15ZM197.864 34.15a.749.749 0 0 1 .15 1.05c-.398.531-.916 1.878 0 3.1 1.484 1.978.602 4.098 0 4.9a.75.75 0 1 1-1.2-.9c.399-.531.917-1.878 0-3.1-1.483-1.978-.601-4.098 0-4.9a.75.75 0 0 1 1.05-.15Z"
        fill="#E5EEED"
      />
    </G>
    <Path
      d="M138.641 2.46h-55.96A1.68 1.68 0 0 0 81 4.141v70.381c0 .929.753 1.681 1.68 1.681h55.961c.928 0 1.681-.752 1.681-1.68V4.14c0-.928-.753-1.68-1.681-1.68Z"
      fill="#368D7D"
    />
    <Path
      d="M140.221 1.162h-55.96a1.68 1.68 0 0 0-1.68 1.681v70.381c0 .928.752 1.68 1.68 1.68h55.96a1.68 1.68 0 0 0 1.681-1.68V2.843a1.68 1.68 0 0 0-1.681-1.68Z"
      fill="#fff"
      stroke="#368D7D"
      strokeMiterlimit={10}
    />
    <Path
      d="M101.534 4.813H88.97v5.841h12.564V4.813ZM96.823 66.78h-10.21v4.445h10.21V66.78ZM130.808 10.654a2.372 2.372 0 1 0 0-4.744 2.372 2.372 0 0 0 0 4.744ZM131.771 33.56h-5.11v11.134h5.11V33.56ZM88.421 14.396h46.91M86.614 62.752h48.717"
      stroke="#368D7D"
      strokeMiterlimit={10}
    />
    <Path
      d="M112.972 54.944c8.821 0 15.972-7.151 15.972-15.972S121.793 23 112.972 23 97 30.15 97 38.972c0 8.821 7.151 15.972 15.972 15.972Z"
      fill="#fff"
      stroke="#368D7D"
      strokeMiterlimit={10}
    />
    <Circle cx={113} cy={39} r={10} fill="#368D7D" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113 48.245A9.245 9.245 0 0 0 122.245 39a9.245 9.245 0 1 0-18.49 0A9.245 9.245 0 0 0 113 48.245Zm0 .755c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.298 34.024a1.226 1.226 0 1 0 0-2.452 1.226 1.226 0 0 0 0 2.452Zm0 .857a2.083 2.083 0 1 0 0-4.167 2.083 2.083 0 0 0 0 4.167ZM113.298 45.929a1.226 1.226 0 1 0 0-2.453 1.226 1.226 0 0 0 0 2.453Zm0 .857a2.083 2.083 0 1 0 0-4.167 2.083 2.083 0 0 0 0 4.167ZM118.06 42.952a1.226 1.226 0 1 0 0-2.452 1.226 1.226 0 0 0 0 2.452Zm0 .857a2.083 2.083 0 1 0 0-4.167 2.083 2.083 0 0 0 0 4.167ZM107.94 42.952a1.226 1.226 0 1 0 .001-2.453 1.226 1.226 0 0 0-.001 2.453Zm0 .857a2.084 2.084 0 1 0 0-4.167 2.084 2.084 0 0 0 0 4.167ZM118.06 37.595a1.226 1.226 0 1 0 0-2.452 1.226 1.226 0 0 0 0 2.452Zm0 .857a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166ZM107.94 37.595a1.226 1.226 0 1 0 .001-2.453 1.226 1.226 0 0 0-.001 2.453Zm0 .857a2.084 2.084 0 1 0 0-4.167 2.084 2.084 0 0 0 0 4.167Z"
      fill="#E5EEED"
    />
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(0 1)" d="M0 0h61.065v75.203H0z" />
      </ClipPath>
      <ClipPath id="b">
        <Path fill="#fff" transform="translate(162 1)" d="M0 0h61.065v75.203H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default EmptyCycles;
