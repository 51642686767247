import React from 'react';
import WashingFormStep1 from '../form/WashingFormStep1';
import WashingFormStep2 from '../form/WashingFormStep2';
import WashingFormStep3 from '../form/WashingFormStep3';
import WashingFormStep4 from '../form/WashingFormStep4';
import WashingFormStep5 from '../form/WashingFormStep5';
import WashingFormStep6 from '../form/WashingFormStep6';
import WashingFormStep7 from '../form/WashingFormStep7';
import WashingFormStep8 from '../form/WashingFormStep8';
import WashingFormStep9 from '../form/WashingFormStep9';
import { useWashingPlantForm } from '../hooks/useWashingPlantForm';
import WashingFormStep10 from './WashingFormStep10';

export const FormSteps: React.FC = () => {
  const { step } = useWashingPlantForm();

  switch (step) {
    case 1:
      return <WashingFormStep1 />;
    case 2:
      return <WashingFormStep2 />;
    case 3:
      return <WashingFormStep3 />;
    case 4:
      return <WashingFormStep4 />;
    case 5:
      return <WashingFormStep5 />;
    case 6:
      return <WashingFormStep10 />;
    case 7:
      return <WashingFormStep6 />;
    case 8:
      return <WashingFormStep7 />;
    case 9:
      return <WashingFormStep8 />;
    case 10:
      return <WashingFormStep9 />;
    default:
      return <WashingFormStep1 />;
  }
};
