import { Box, Divider, Text } from 'native-base';
import React from 'react';
import { t } from '../languages';

interface SectionTitleProps {
  title: string;
  first?: boolean;
  color?: string;
  showDivider?: boolean;
  children?: React.ReactNode;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  children,
  first = false,
  color,
  showDivider = true,
}) => {
  return (
    <>
      <Text
        fontSize={14}
        mt={first ? 0 : '32px'}
        color={color ? color : 'primary.500'}
        fontWeight={'bold'}
      >
        {title}
      </Text>

      {showDivider && <Divider />}

      <Box mt={'16px'} mb="12px">
        {children || null}
      </Box>
    </>
  );
};

export default SectionTitle;
