import * as React from 'react';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from '@react-navigation/native';
import { ColorSchemeName } from 'react-native';
import AuthenticatedNavigator from './auth.routes';
import UnauthenticatedNavigator, { GuestRootStackParamList } from './guest.routes';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as LinkingExpo from 'expo-linking';

declare global {
  namespace ReactNavigation {
    interface RootParamList extends AuthRootStackParamList, GuestRootStackParamList {}
  }
}

const prefix = LinkingExpo.createURL('/');

const linking = {
  prefixes: [prefix],
};

const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {

  return (
    <NavigationContainer
      onStateChange={(state) => AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
      linking={linking}
    >
      <AuthenticatedNavigator />
      <UnauthenticatedNavigator />
    </NavigationContainer>
  );
}
