export default {
  primary: {
    50: '#409D27',
    100: '#409D27',
    200: '#409D27',
    300: '#409D27',
    400: '#409D27',
    500: '#409D27',
    600: '#409D27',
    700: '#409D27',
    800: '#409D27',
    900: '#409D27',
  },

  blue1: '#368D7D',
  blue2: '#22DBA3',

  red1: '#D13D3D',
  red2: '#E38B8B',
  red3: '#F8E2E2',

  green1: '#4C814C',
  green2: '#A5C6A5',
  green3: '#E8F1E8',

  yellow1: '#F5CE69',
  yellow2: '#F9E2A5',
  yellow3: '#FCF0D2',

  gray1: '#727272',
  gray2: '#D0D0D0',
  gray3: '#DFE2E8',

  purple: '#409D27',
};
