import { CustomAxiosResponse } from '../../../services/axiosService';
import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { IJetWashingPlant, IJetWashingPlantID } from '../../../types/washing-plants.type';
import { UserInterface } from '../../auth/types/IJetUser';
import { IJetWashingPlantCreate, IWashingPlantForm } from '../types/washingPlantForm.type';

interface ICreateWashingPlantParams {
  data: IWashingPlantForm;
  user: UserInterface;
}

export async function createOrEditWashingPlant(
  props: ICreateWashingPlantParams
): Promise<IJetWashingPlant | undefined> {
  const { data, user } = props;
  const washingPlantCreate = convertWashingPlantFormToWashingPlantCreate(data, user);

  if (data.id) {
    return await jetAdmin.update<IJetWashingPlant>(
      JetAdminCollection.WashingPlants,
      data.id,
      washingPlantCreate
    );
  } else {
    return await jetAdmin.create<IJetWashingPlant>(
      JetAdminCollection.WashingPlants,
      washingPlantCreate
    );
  }
}

function convertWashingPlantFormToWashingPlantCreate(
  data: IWashingPlantForm,
  user: UserInterface
): IJetWashingPlantCreate {
  return {
    name: data.washingPlantName,
    description: '',
    active: true,
    overall_load: parseInt(data.overallLoad.toString()),
    number_of_garments: parseInt(data.numberOfGarments.toString()),
    water_temperature: parseInt(data.waterTemperature.toString()),
    air_temperature: parseInt(data.airTemperature.toString()),
    country: data.selectedCountryID,
    washing_machine: data.selectedWashingMachineID,
    centrifuge: data.selectedCentrifugeID,
    boiler: data.selectedBoilerID,
    dryer: data.selectedDryerID,
    user: user.id,
    created_at: new Date(),
    updated_at: new Date(),
  };
}

export async function deleteWashingPlant(id: IJetWashingPlantID) {
  await jetAdmin.delete(JetAdminCollection.WashingPlants, id);
}
