import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { HomeStackParamList } from '../../../routes/auth.routes';
import WashRecipeAggregatorProvider, { WashRecipeAggregatorContext } from '../contexts/WashRecipeAggregatorContext';
import WashRecipeAggregator from './WashRecipeAggregator';

type WashRecipeAggregatorRootProps = NativeStackScreenProps<HomeStackParamList, 'WashRecipeAggregator'>;

const WashRecipeAggregatorRoot: React.FC<WashRecipeAggregatorRootProps> = ({
  navigation,
  route,
}) => {
  const { initializeProvider } = useContext(WashRecipeAggregatorContext);
  
  const { washingPlant, washRecipe } = route.params;

  useLayoutEffect(() => {
    initializeProvider(washingPlant, washRecipe);
  }, [initializeProvider, washingPlant, washRecipe]);

  return (
    <WashRecipeAggregator
      navigation={navigation}
      route={route}
    />
  );
};

export default WashRecipeAggregatorRoot;