import { format } from 'date-fns';
import {
  Box,
  Card,
  HamburgerIcon,
  HStack,
  Icon,
  IconButton,
  Menu,
  Pressable,
  Progress,
  Skeleton,
  Text,
  VStack,
  ZStack,
} from 'native-base';
import React, { useState } from 'react';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { enUS, ptBR } from 'date-fns/locale';
import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { t } from '../../../languages';
import Confirm from '../../../components/Confirm';
import { useMutation } from 'react-query';
import { deleteWashingPlant } from '../../washingPlant/services/createWashingPlant';
import { toast } from '../../../utils/toast';

interface WashingPlantCardProps {
  washingPlant: IJetWashingPlant;
  onDelete: () => {};
}

const WashingPlantCard: React.FC<WashingPlantCardProps> = ({ washingPlant, onDelete }) => {
  const { navigate } = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

  const washMutation = useMutation(deleteWashingPlant, {
    onSuccess: () => {
      onDelete();
      toast(t('domain.generic.success'));
    },
    onError: () => toast(t('domain.generic.error')),
  });

  async function onPressDelete() {
    washMutation.mutate(washingPlant.id, {
      onSuccess: () => setShowModalConfirm(false),
    });
  }

  return (
    <>
      <Skeleton isLoaded={!washMutation.isLoading}>
        <Pressable onPress={() => navigate('MyWashes', { washingPlant })}>
          <Card width="100%" bgColor="white" borderRadius="8px" px={'20px'} py="14px" shadow={1}>
            <VStack space="5px">
              <HStack justifyContent="space-between" alignContent="center" alignItems="center">
                <Text color="primary.500" fontWeight="bold" fontSize="lg">
                  {washingPlant.name}
                </Text>
                <Menu
                  placement="left bottom"
                  trigger={(triggerProps) => {
                    return (
                      <IconButton
                        {...triggerProps}
                        color="primary.500"
                        m={-2}
                        icon={<Entypo name="dots-three-vertical" size={16} color="#368D7D" />}
                      />
                    );
                  }}
                >
                  <Menu.Item onPress={() => setShowModalConfirm(true)}>
                    {t('domain.generic.delete')}
                  </Menu.Item>
                </Menu>
              </HStack>
              <Text fontSize="md">
                {format(new Date(washingPlant.created_at), 'MM/dd/yyyy - hh:mm a')}
              </Text>
            </VStack>
          </Card>
        </Pressable>
      </Skeleton>
      <Confirm
        showButton={false}
        openModal={showModalConfirm}
        setOpenModal={setShowModalConfirm}
        onConfirm={onPressDelete}
        button={{ mb: 3 }}
        buttonText={t('domain.signUp.profile.section.delete.modal.title')}
        message={`${t('domain.generic.delete_message')}`}
        title={`${t('domain.generic.delete')}`}
        onConfirmToastMessage={t('domain.signUp.profile.section.delete.modal.success')}
        setShowModal={setShowModalConfirm}
        isLoading={washMutation.isLoading}
      />
    </>
  );
};

export default WashingPlantCard;
