import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Heading, Text } from 'native-base';
import React from 'react';
import { Page } from '../../../../components/Page';
import { t } from '../../../../languages';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { SignInForm } from '../../components/SignInForm';

interface SignInInterface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
}

export function SignIn({ navigation }: SignInInterface) {
  return (
    <Page backTitle={t('domain.signIn.backTitle')} buttonText={t('domain.signIn.backTitle')}>
      <Heading>{t('domain.signIn.pageTitle')}</Heading>
      <Text mt="6px">{t('domain.signIn.subtitle')}</Text>
      <SignInForm navigation={navigation} />
    </Page>
  );
}
