import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { HStack, ScrollView, Text, VStack } from 'native-base';
import React from 'react';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../../types/wash_cycle.type';
import AddRecipeButton from '../../../standardRecipe/components/AddRecipeButton';

interface LaserScrapingListProps {
  cycle: IJetWashCycle;
  currentAggregator?: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe" | undefined;
}

const LaserScrapingList: React.FC<LaserScrapingListProps> = ({
  cycle,
  recipeName,
  currentAggregator,
}) => {
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();

  const washLaserScraping = cycle.cycle_laser_scrapings_details || [];

  async function _createWashCycle() {
    navigation.push('washRecipeCycleLasers', {
      laser: undefined,
      cycle,
      recipeName,
      currentAggregator,
    });
  }

  return (
    <ScrollView>
      <VStack space="8px">
        {washLaserScraping.map((laser) => {
          const laserName = laser?.laser?.brand?.name && laser?.laser_detail?.model
            ? `${laser?.laser?.brand?.name || ''} (${laser?.laser_detail?.model || ''})`
            : t('domain.analysis.step7.fallback.laser_scraping_without_name');

          return (
            <AddRecipeButton
              key={`list-laser-${laser.id}-${laser.laser_id}`}
              isAdded={false}
              text={`${laser.id}`}
              onPress={() =>
                navigation.push('washRecipeCycleLasers', {
                  laser,
                  cycle,
                  recipeName,
                  currentAggregator,
                })
              }
            >
              <HStack justifyContent="space-between">
                <Text mr={2} fontSize={14} color="#368D7D" fontWeight={'normal'}>
                  {laserName}
                </Text>
                <Text textAlign="right" fontSize={14} color="#409D27">
                  {t('domain.generic.edit')}
                </Text>
              </HStack>
            </AddRecipeButton>
          );
        })}

        <AddRecipeButton
          text={t('domain.analysis.step4.section.add.subsection.laser_scraping.button.add')}
          onPress={_createWashCycle}
          isAdded={true}
        />
      </VStack>
    </ScrollView>
  );
};

export default LaserScrapingList;
