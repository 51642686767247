import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Text, Box, VStack, Divider, Button, ScrollView, HStack, Flex } from 'native-base';
import React, { LegacyRef, MutableRefObject, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import BackHeader from '../../../components/BackHeader';
import Confirm from '../../../components/Confirm';
import SectionTitle from '../../../components/SectionTitle';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import AddRecipeButton from '../components/AddRecipeButton';
import Step2ModalConfirm from '../../washRecipeAggregator/components/GreenRecipeModal';
import { useStandardRecipeForm } from '../hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../types/standardRecipeForm.type';

interface RecipeFormStep2Props {}

export const RecipeFormStep2: React.FC<RecipeFormStep2Props> = () => {
  const { goBack } = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const scrollViewRef = useRef<HTMLInputElement>();
  const methods = useFormContext<IStandardRecipeForm>();
  const { selectRecipe, nextStep, addNewCycle, removeCycle } = useStandardRecipeForm(
    methods.control
  );

  const removeSelectedCycle = (index: number) => {
    removeCycle(index);
    setShowModalConfirm(false);
  }

  const washName = methods.watch('washName');
  const cycles = methods.watch('cycles');

  return (
    <>
      <VStack justifyContent="space-between" flex={1}>
        <Box>
          <BackHeader onPress={goBack} backTitle />

          <Text fontSize={'20px'}>{t('domain.analysis.step2.title_part_one')}</Text>

          <Text lineHeight={32} fontSize={32} mt="12px" color="primary.500" fontWeight={'bold'}>
            {washName}
          </Text>

          <Box>
            <SectionTitle title={t('domain.analysis.step2.my_cycles')} />

            {cycles.length === 0 ? (
              <EmptyPlaceholder
                emptyText={t('domain.analysis.step2.no_cycles_empty')}
                marginTop={10}
                action={{
                  text: t('domain.analysis.step2.add_cycle'),
                  onPress: () => nextStep(),
                }}
              />
            ) : (
              <>
                <ScrollView
                  maxHeight="250px"
                  ref={scrollViewRef}
                  onContentSizeChange={() =>
                    // @ts-ignore
                    scrollViewRef?.current?.scrollToEnd({ animated: true })
                  }
                >
                  {cycles.map((cycle, index) => {
                    const isCurrentRecipeDirty =
                      methods?.formState?.dirtyFields?.cycles?.[index]?.currentRecipe !== undefined;
                    const isNewRecipeDirty =
                      methods?.formState?.dirtyFields?.cycles?.[index]?.newRecipe !== undefined;

                    return (
                      <Box key={`cycle-${index}`} mb={4}>
                        <HStack
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                          alignContent="center"
                        >
                          <Text mb="4px" fontSize={14} color="primary.500" fontWeight={'bold'}>
                            {t('domain.analysis.step2.cycle')} {index + 1}
                          </Text>

                          <Confirm
                            onConfirm={() => removeSelectedCycle(index)}
                            buttonText={t('domain.analysis.step2.button.remove')}
                            button={{ mb: 1 }}
                            message={`${t('domain.analysis.step2.toast.message')} ${index + 1}?`}
                            onConfirmToastMessage={t(
                              'domain.analysis.step2.toast.removeSuccessfully'
                            )}
                            setShowModal={setShowModalConfirm}
                            openModal={showModalConfirm}
                            isLoading={false}
                          />
                        </HStack>

                        <AddRecipeButton
                          isAdded={!isCurrentRecipeDirty}
                          text={t('domain.analysis.step2.button.add_current_recipe')}
                          onPress={() => {
                            selectRecipe('currentRecipe', index);
                            nextStep();
                          }}
                        >
                          <HStack justifyContent="space-between">
                            <Text fontSize={14} color="#368D7D" mr={2} fontWeight={'normal'}>
                              {t('domain.analysis.step2.your_recipe')}
                            </Text>
                            <Text textAlign="right" fontSize={14} color="#409D27">
                              {t('domain.generic.edit')}
                            </Text>
                          </HStack>
                        </AddRecipeButton>

                        <Box my="8px">
                          <AddRecipeButton
                            isAdded={!isNewRecipeDirty}
                            text={t('domain.analysis.step2.button.add_new_recipe')}
                            onPress={() => {
                              selectRecipe('newRecipe', index);
                              setShowModal(true);
                            }}
                          >
                            <HStack justifyContent="space-between">
                              <Text mr={2} fontSize={14} color="#368D7D" fontWeight={'normal'}>
                                {t('domain.analysis.step2.green_recipe')}
                              </Text>
                              <Text textAlign="right" fontSize={14} color="#409D27">
                                {t('domain.generic.edit')}
                              </Text>
                            </HStack>
                          </AddRecipeButton>
                        </Box>
                      </Box>
                    );
                  })}
                </ScrollView>
                <Button onPress={addNewCycle} mb={5}>
                  {t('domain.analysis.step2.add_cycle')}
                </Button>
              </>
            )}
          </Box>

          <Box>
            <SectionTitle title={t('domain.analysis.step2.you_saved')} />
          </Box>
        </Box>
      </VStack>

      <Step2ModalConfirm showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default RecipeFormStep2;
