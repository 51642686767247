import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { IJetUserID, UserInterface } from '../types/IJetUser';

export async function getUserByFirebaseId(authenticator_id: string | number): Promise<UserInterface> {
  const res = await jetAdmin.list<UserInterface[]>(JetAdminCollection.Customers, {
    authenticator_id,
  });

  if (res.count === 0) {
    throw new Error('User not found');
  }

  if (res.results[0].active === false) {
    throw new Error('user_not_active');
  }

  return res.results[0];
}

export async function getUserById(id: IJetUserID | undefined): Promise<UserInterface | undefined> {
  if (!id) {
    throw new Error('User not found');
  }

  const res = await jetAdmin.retrieve<UserInterface>(JetAdminCollection.Customers, id?.toString());

  return res;
}

export async function getUserByEmail(email: string): Promise<UserInterface | undefined> {
  if(!email) {
    throw new Error('Email not provided');
  }

  const res = await jetAdmin.list<UserInterface[]>(JetAdminCollection.Customers, {
    email,
  });

  if (res.count === 0) {
    throw new Error('User not found');
  }

  if (res.results[0].active === false) {
    throw new Error('user_not_active');
  }

  return res.results[0];
}
