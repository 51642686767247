import { isValid } from 'date-fns';
import { Checkbox, Text, VStack } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../../components/Accordion';
import { t } from '../../../languages';
import { IJetWashCycle } from '../../../types/wash_cycle.type';
import Step4ExtrasCentrifuge from '../inputs/Step4ExtrasCentrifuge';
import Step4ExtrasDrying from '../inputs/Step4ExtrasDrying';
import Step4ExtrasRinses from '../inputs/Step4ExtrasRinses';

interface WashCycleStep4ExtrasProps {}

export const WashCycleStep4Extras: React.FC<WashCycleStep4ExtrasProps> = () => {
  const [rinsesAccordion, setRinsesAccordion] = useState<boolean>(false);
  const [centrifugeAccordion, setCentrifugeAccordion] = useState<boolean>(false);
  const [dryingAccordion, setDryingAccordion] = useState<boolean>(false);
  const [isRinsesValid, setIsRinsesValid] = useState<boolean>(true);
  const [isCentrifugeValid, setIsCentrifugeValid] = useState<boolean>(true);
  const [isDryingValid, setIsDryingValid] = useState<boolean>(true);

  const methods = useFormContext<IJetWashCycle>();
  const { setValue, watch, formState, clearErrors } = methods;

  const inputAddRinses = 'extras_rinses_add';
  const inputAddCentrifuge = 'extras_centrifuge_add';
  const inputAddDrying = 'should_add_drying';

  const addRinses = watch(inputAddRinses);
  const addCentrifuge = watch(inputAddCentrifuge);
  const addDrying = watch(inputAddDrying);

  const amountOfRinses = watch('amount_of_rinses');
  const rinsesTime = watch('rinses_amount_time');
  const rinsesBathRatio = watch('rinses_amount_bath_ratio');

  const centrifugeRunTime = watch('extras_centrifuge_run_time');
  const centrifugePickup = watch('extras_centrifuge_pickup');

  const dryingRunTime = watch('extras_drying_time');
  const dryingTemperature = watch('extras_drying_temperature');

  function changeAddRinses(value: boolean) {
    setValue(inputAddRinses, value);
    setRinsesAccordion(value);

    if(!value){
      clearErrors('rinses_amount_time');
      clearErrors('rinses_amount_bath_ratio');
      clearErrors('amount_of_rinses');
    }
  }

  function changeAddCentrifuge(value: boolean) {
    setValue(inputAddCentrifuge, value);
    setCentrifugeAccordion(value);

    if(!value){
      clearErrors('extras_centrifuge_pickup');
      clearErrors('extras_centrifuge_run_time');
    }
  }

  function changeAddDrying(value: boolean) {
    setValue(inputAddDrying, value);
    setDryingAccordion(value);

    if(!value){
      clearErrors('extras_drying_time');
      clearErrors('extras_drying_temperature');
    }
  }

  useEffect(() => {
    const isValidRinses = [
      formState.errors.rinses_amount_time,
      formState.errors.rinses_amount_bath_ratio,
      formState.errors.amount_of_rinses,
    ].every((error) => !error);

    setIsRinsesValid(isValidRinses);
  }, [formState.errors, addRinses, amountOfRinses, rinsesTime, rinsesBathRatio]);

  useEffect(() => {
    const isValidCentrifuge = [
      formState.errors.extras_centrifuge_pickup,
      formState.errors.extras_centrifuge_run_time,
    ].every((error) => !error);

    setIsCentrifugeValid(isValidCentrifuge);
  }, [formState.errors, addCentrifuge, centrifugeRunTime, centrifugePickup]);

  useEffect(() => {
    const isValidDrying = [
      formState.errors.extras_drying_temperature,
      formState.errors.extras_drying_time,
    ].every((error) => !error);

    setIsDryingValid(isValidDrying);
  }, [formState.errors, addDrying, dryingRunTime, dryingTemperature]);

  return (
    <VStack p={0.5} space="16px">
      <Accordion
        isValid={isRinsesValid}
        open={rinsesAccordion}
        id={'whc-rinses'}
        left={<Checkbox
          accessibilityLabel="Checkbox"
          onChange={changeAddRinses}
          isChecked={!!addRinses}
          value={`${addRinses}`} />}
        title={t('domain.analysis.step4.section.extras.subsection.rinses.title')}       >
        <Step4ExtrasRinses />
      </Accordion>

      <Accordion
        open={centrifugeAccordion}
        isValid={isCentrifugeValid}
        id={'whc-centrifuge'}
        left={<Checkbox
          accessibilityLabel="Checkbox"
          onChange={changeAddCentrifuge}
          isChecked={!!addCentrifuge}
          value={`${addCentrifuge}`} />}
        title={t('domain.analysis.step4.section.extras.subsection.centrifuge.title')}       >
        <Step4ExtrasCentrifuge />
      </Accordion>

      <Accordion
        open={dryingAccordion}
        isValid={isDryingValid}
        id={'whc-drying'}
        left={<Checkbox
          accessibilityLabel="Checkbox"
          onChange={changeAddDrying}
          isChecked={!!addDrying}
          value={`${addDrying}`} />}
        title={t('domain.analysis.step4.section.extras.subsection.centrifuge.drying.title')}       >
        <Step4ExtrasDrying />
      </Accordion>
    </VStack>
  );
};

export default WashCycleStep4Extras;
