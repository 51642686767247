import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import {
  IJetBoiler,
  IJetBoilerBrand,
  IJetBoilerComplete,
  IJetBoilerID,
} from '../../../types/boiler.type';
import { IJetHocs } from '../../../types/hocs';
import { getHoc, getHocs } from './getHocs';

export interface IJetBoilerList extends IJetAdminResponseProtocol<IJetBoilerComplete[]> {}

export async function getBoiler(
  id: IJetBoilerID | undefined
): Promise<IJetBoilerComplete | undefined> {
  if (!id) return undefined;
  const response = await jetAdmin.retrieve<IJetBoiler>(JetAdminCollection.Boilers, id);

  if (!response) return undefined;

  const _hoc = response?.combustible ? await getHoc(response.combustible) : undefined;
  const brand_detail = response?.brand ? await getBoilerBrand(response.brand) : undefined;

  return {
    ...response,
    combustible_detail: _hoc,
    brand_detail,
  };
}

export async function getBoilerBrand(
  id: IJetBoilerID | undefined
): Promise<IJetBoilerBrand | undefined> {
  if (!id) return undefined;
  const response = await jetAdmin.retrieve<IJetBoilerBrand>(JetAdminCollection.BoilerBrands, id);

  if (!response) return undefined;

  return response;
}

export async function getBoilers(): Promise<IJetBoilerList> {
  const resBrands = await jetAdmin.list<IJetBoilerBrand[]>(JetAdminCollection.BoilerBrands);

  const resHocs = await getHocs();

  const res = await jetAdmin.list<IJetBoiler[]>(JetAdminCollection.Boilers);

  return {
    ...res,
    results: res.results.map((boiler: IJetBoiler) => {
      const brand = resBrands.results.find((brand: IJetBoilerBrand) => brand.id === boiler.brand);

      const hoc = resHocs.results.find((hoc: IJetHocs) => hoc.id === boiler.combustible);

      return {
        ...boiler,
        brand_detail: brand,
        combustible_detail: hoc,
      };
    }),
  };
}
function getDryersBrand(brand: string) {
  throw new Error('Function not implemented.');
}
