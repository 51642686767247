import React, { useEffect, useState } from 'react';
import { Heading, Skeleton, Text } from 'native-base';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { t } from '../../../../languages';
import { Page } from '../../../../components/Page';
import { ResetPassword } from './index';
import BackHeader from '../../../../components/BackHeader';
import { RouteProp } from '@react-navigation/native';
import { supabase } from '../../../../../supabase';
import { toast } from '../../../../utils/toast';

interface SignInInterface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
  route: RouteProp<GuestRootStackParamList, "ResetPassword">;
}

export function ResetPasswordRoot({ navigation, route }: SignInInterface) {
  const [showNewPasswordInput, setShowNewPasswordInput] = useState(false)

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event) => {
      if (event === "PASSWORD_RECOVERY") {
        setShowNewPasswordInput(true)
      }
    });
  }, [])

  // useEffect(() => {
  //   if(route?.params?.token){
  //     setIsLoadingToken(true);
  //     supabase.auth.verifyOtp({
  //       email: route.params.email, 
  //       token: route.params.token, 
  //       type: 'recovery'
  //     }).then((data) => {
  //       setIsLoadingToken(false);
  //       setShowNewPasswordInput(!data.error);
  //       if(data.error) toast(t('domain.resetPassword.invalid_token_recovery'));
  //     })
  //   }
  // }, []);

  const subtitle = showNewPasswordInput
    ? t('domain.resetPassword.reset_password_subtitle')
    : t('domain.resetPassword.subtitle')

  return (
    <Page>
      <BackHeader backTitle buttonText={t('domain.resetPassword.backTitle')} />
      <Heading>{t('domain.resetPassword.pageTitle')}</Heading>
      <Text mt="6px">{subtitle}</Text>
      <ResetPassword
        navigation={navigation}
        showNewPasswordInput={showNewPasswordInput}
      />
    </Page>
  );
}
