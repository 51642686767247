import { useNavigation } from '@react-navigation/native';
import { useContext } from 'react';
import Toast from 'react-native-root-toast';
import { firebaseErrorsCodeTranslated } from '../../../config/firebase-errors';
import { t } from '../../../languages';
import { toast } from '../../../utils/toast';
import { AuthContext } from '../contexts/AuthContext';
import { createUserOnJetAdmin, IJetCreateUser } from '../services/createUserOnJetAdmin';
import { UserInterface } from '../types/IJetUser';
import { supabase } from '../../../../supabase';
import { getUserByFirebaseId } from '../services/getUserById';
import { User } from '@supabase/supabase-js';

interface UseAuthInterface {
  user: UserInterface | undefined;
  isAuthenticated: boolean;
  isAdmin: boolean;
  token: string | undefined;
  login: (email: string, password: string) => Promise<void>;
  register: (newUser: IRegisterCustomer) => Promise<IJetCreateUser | undefined>;
  logout: () => Promise<void>;
  updateProfile: (user: UserInterface) => Promise<void>;
  resendResetPasswordLink: (email: string) => Promise<any>;
  resetUserPassword: (password: string) => Promise<User>;
}

interface IRegisterCustomer extends Omit<IJetCreateUser, 'authenticator_id'> {}

export function useAuth(): UseAuthInterface {
  const navigation = useNavigation();
  const { setUser, setToken, user, token, logout } = useContext(AuthContext);

  const isAuthenticated = user !== undefined && token !== undefined;
  const isAdmin = false;

  return {
    user,
    isAuthenticated,
    isAdmin,
    token,
    register: async (newUser: IJetCreateUser) => {
      try {
        const { data, error } = await supabase.auth.signUp({
          email: newUser.email,
          password: newUser.password,
        });

        if(error){
          throw new Error(error.message);
        };

        const user = await createUserOnJetAdmin({
          ...newUser,
          authenticator_id: data.user?.id,
        });

        return user;
      } catch (error: any) {
        const _msg = error.message || t('domain.form.validation.error.error_unexpected');

        toast(firebaseErrorsCodeTranslated.en[error.code] ?? _msg, {
          duration: Toast.durations.LONG,
          position: Toast.positions.BOTTOM,
        });
      }
    },
    login: async (email: string, password: string) => {
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });

        if(error){
          throw new Error(error.message);
        };

        const jetUser = await getUserByFirebaseId(data.user.id);
        setToken(data.session.access_token);
        setUser(jetUser);
        navigation.navigate('Home');
      } catch (error: any) {
        const _msg =
          error.message === 'user_not_active'
            ? t('domain.signIn.user_not_active')
            : error.message || t('domain.form.validation.error.error_unexpected');

        toast(firebaseErrorsCodeTranslated.en[error.code] ?? _msg, {
          duration: Toast.durations.LONG,
          position: Toast.positions.BOTTOM,
        });
      }
    },
    resendResetPasswordLink: async (email: string) => {
      try{
        const { data, error } = await supabase.auth.resetPasswordForEmail(email);

        if(error){
          throw new Error(error.message);
        };

        return {
          data,
          error,
        }
      }catch(error: any){
        console.log('error', error)
        const _msg =
          error.message === 'user_not_active'
            ? t('domain.signIn.user_not_active')
            : error.message || t('domain.form.validation.error.error_unexpected');

        toast(firebaseErrorsCodeTranslated.en[error.code] ?? _msg, {
          duration: Toast.durations.LONG,
          position: Toast.positions.BOTTOM,
        });
      }
    },
    resetUserPassword: async (password: string) => {
      const { data, error } = await supabase.auth.updateUser({ password });

      if(error){
        throw new Error(error.message);
      };

      return data.user;
    },
    logout,
    updateProfile: async (user: UserInterface) => {
      setUser(user);
    },
  };
}
