import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Box, Checkbox, Divider, HStack, ScrollView, Text, VStack } from 'native-base';
import React, { useContext, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import BackHeader from '../../../components/BackHeader';
import Confirm from '../../../components/Confirm';
import FormInput from '../../../components/Input/FormInput';
import InputAddon from '../../../components/Input/InputAddon';
import SelectWithModal, { ISelectValues } from '../../../components/SelectWithModal';
import { QueryKeyCache } from '../../../config/query-cache';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../../../types/wash_cycle.type';
import { IJetWashCycleAuxiliary } from '../../../types/wash_cycle_auxiliary.type';
import { toast } from '../../../utils/toast';
import { deleteWashCycleAuxiliary } from '../../washCycle/services/auxiliarys.service';
import { getAuxiliaryEquipments } from '../services/getAuxiliaryEquipments';
import { WashRecipeAggregatorContext } from '../../washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import { Platform } from 'react-native';

interface RecipeFormStep6Props {
  washCycle: IJetWashCycle;
  auxiliary: IJetWashCycleAuxiliary | undefined;
  isSubmitting: boolean;
  currentAggregator: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe";
  navigation: NativeStackNavigationProp<HomeStackParamList, 'washRecipeCycleAuxiliarys', undefined>;
}

const SPACE = '32px';

export const RecipeFormStep6: React.FC<RecipeFormStep6Props> = ({
  auxiliary,
  washCycle,
  isSubmitting,
  currentAggregator,
  recipeName,
  navigation,
}) => {
  const { goBack } = navigation;
  const { setAllAuxilliaryEquipments, removeAuxiliaryFromCompleteInfo } = useContext(WashRecipeAggregatorContext);
  const methods = useFormContext<IJetWashCycleAuxiliary>();
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [showModalRemoveAuxiliary, setShowModalRemoveAuxiliary] = useState<boolean>(false);

  const inputAuxiliaryEquipamentParallel: keyof IJetWashCycleAuxiliary = 'auxiliary_inparallel';
  const inputAuxiliaryEquipamentID: keyof IJetWashCycleAuxiliary = 'auxiliary_equipment_id';
  const inputAuxiliaryEquipamentTime: keyof IJetWashCycleAuxiliary = 'auxiliary_time';

  const auxiliaryEqpQuery = useQuery(QueryKeyCache.getAuxiliaryEquipments, getAuxiliaryEquipments, {
    cacheTime: (60 * 60 * 24) * 3, // 3 days
    onSuccess: (data) => setAllAuxilliaryEquipments(data.results)
  });

  const isLoading = auxiliaryEqpQuery.isLoading || isSubmitting;

  const auxiliaryEquipments: ISelectValues[] = useMemo(
    () =>
      (auxiliaryEqpQuery.data?.results || []).map(({ id, model, brand_detail }) => ({
        key: id,
        label: `${brand_detail?.name} - ${model}`,
      })),
    [auxiliaryEqpQuery.data]
  );

  const removeMutation = useMutation(deleteWashCycleAuxiliary, {
    onSuccess: () => {
      methods.reset();
      Platform.OS !== 'web' && toast(t('domain.generic.success'));
      setShowModalConfirm(false);
      setShowModalRemoveAuxiliary(false);
      goBack();
    },
    onError: () => toast(t('domain.generic.error')),
  });

  function removeAuxiliaryEquipment() {
    if (auxiliary) {
      removeMutation.mutate({ auxiliary, cycle: washCycle });
      removeAuxiliaryFromCompleteInfo(
        currentAggregator.aggregator_id,
        recipeName,
        auxiliary || {},
      );
    };
  }

  function onPressBackHeader() {
    methods.reset(auxiliary);
    goBack();
  }

  return (
    <>
      <ScrollView>
        <VStack justifyContent="space-between" flex={1} space={SPACE}>
          <HStack alignItems="center" alignContent="center" justifyContent="space-between">
            <BackHeader mb="8px" onPress={onPressBackHeader} backTitle />

            {auxiliary && (
              <Confirm
                onConfirm={removeAuxiliaryEquipment}
                buttonText={t('domain.analysis.step2.button.remove')}
                button={{ mb: 3 }}
                message={`${t('domain.generic.delete_message')}`}
                onConfirmToastMessage={t('domain.generic.removeSuccessfully')}
                openModal={showModalRemoveAuxiliary}
                setShowModal={setShowModalRemoveAuxiliary}
                isLoading={removeMutation.isLoading}
              />
            )}
          </HStack>

          <Text fontSize={22} fontWeight={500}>
            {t('domain.analysis.step6.title_part_one')}
            <Text>
              {' '}
              <Text fontSize={22} fontWeight="bold" color="primary.500">
                {t('domain.analysis.step6.title_part_two')}{' '}
                <Text fontSize={22} fontWeight={500}>
                  {t('domain.analysis.step6.title_part_three')}
                </Text>
              </Text>
            </Text>
          </Text>

          <Box>
            <SelectWithModal
              values={auxiliaryEquipments}
              refetch={auxiliaryEqpQuery.refetch}
              isLoading={isLoading}
              inputName={inputAuxiliaryEquipamentID}
              methods={methods}
              label={t('domain.analysis.step6.input.auxiliary_equipment.label')}
              placeholder={t('domain.analysis.step6.input.auxiliary_equipment.placeholder')}
            />
          </Box>

          <Divider />

          <FormInput
            isLoading={isLoading}
            methods={methods}
            name={inputAuxiliaryEquipamentTime}
            label={t('domain.analysis.step6.input.time.label')}
            placeholder={t('domain.analysis.step6.input.time.placeholder')}
            inputProps={{
              keyboardType: 'numeric',
              maxLength: 6,
              InputRightElement: <InputAddon str={t('domain.units.time.minutes')} />,
            }}
          />
          
          {/* Align everything on center */}
          <HStack space={3} mt={-5} width='100%' justifyContent='center' alignItems='center'>
            <Checkbox
              value={`${methods.watch(inputAuxiliaryEquipamentParallel)}`}
              isChecked={methods.watch(inputAuxiliaryEquipamentParallel)}
              onChange={(value) => methods.setValue(inputAuxiliaryEquipamentParallel, value)}
              style={{ borderRadius: 15, padding: 2 }}
            />
            <Text
              mx={.5}
              style={{ flex: 1, flexWrap: 'wrap', flexShrink: 1 }}
            >{t('domain.analysis.step6.input.parallel.label')}</Text>
          </HStack>

          <Confirm
            showButton={false}
            onConfirm={removeAuxiliaryEquipment}
            buttonText={t('domain.analysis.step2.button.remove')}
            button={{ mb: 3 }}
            message={`${t('domain.analysis.step6.back.modal.message')}`}
            onConfirmToastMessage={t('domain.generic.removeSuccessfully')}
            openModal={showModalConfirm}
            setShowModal={setShowModalConfirm}
            isLoading={removeMutation.isLoading}
          />
        </VStack>
      </ScrollView>
    </>
  );
};

export default RecipeFormStep6;
