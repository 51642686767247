import { Box, Text, VStack } from 'native-base';
import React from 'react';
import { t } from '../../../languages';
import {
  ICompleteWashInfoAggregators,
  IJetWashCycle
} from '../../../types/wash_cycle.type';
import AuxiliaryEquipmentsList from '../sections/auxiliary/AuxiliaryList';
import ChemicalsList from '../sections/chemicals/ChemicalsList';
import LaserScrapingList from '../sections/laser/LaserList';

interface WashCycleStep4AddProps {
  cycle: IJetWashCycle;
  currentAggregator?: ICompleteWashInfoAggregators;
  recipeName: "currentRecipe" | "greenRecipe" | undefined;
};

export const WashCycleStep4Add: React.FC<WashCycleStep4AddProps> = ({
  cycle,
  currentAggregator,
  recipeName
}) => {
  return (
    <VStack space={'32px'}>
      <Box>
        <Text color="primary.500" mb="8px">
          {t('domain.analysis.step4.section.add.subsection.chemicals.title')}
        </Text>
        <ChemicalsList
          cycle={cycle}
          currentAggregator={currentAggregator}
          recipeName={recipeName}
        />
      </Box>

      <Box>
        <Text color="primary.500" mb="8px">
          {t('domain.analysis.step4.section.add.subsection.auxiliary_equipment.title')}
        </Text>
        <AuxiliaryEquipmentsList
          cycle={cycle}
          currentAggregator={currentAggregator}
          recipeName={recipeName}
        />
      </Box>

      <Box>
        <Text color="primary.500" mb="8px">
          {t('domain.analysis.step4.section.add.subsection.laser_scraping.title')}
        </Text>
        <LaserScrapingList
          cycle={cycle}
          currentAggregator={currentAggregator}
          recipeName={recipeName}
        />
      </Box>
    </VStack>
  );
};

export default WashCycleStep4Add;
