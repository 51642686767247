import { VStack } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Grid, Row } from 'react-native-easy-grid';
import FormInput from '../../../../../components/Input/FormInput';
import InputAddon from '../../../../../components/Input/InputAddon';
import { t } from '../../../../../languages';
import { useStandardRecipeForm } from '../../../hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../../../types/standardRecipeForm.type';

interface Step4ExtrasDryingProps {}

const Step4ExtrasDrying: React.FC<Step4ExtrasDryingProps> = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { getInputName } = useStandardRecipeForm(methods.control);

  const InputExtrasDryingTemperature = getInputName('extras_drying_temperature');
  const inputExtrasDryingTime = getInputName('extras_drying_time');

  return (
    <VStack space="24px" mb="24px">
      <FormInput
        methods={methods}
        name={inputExtrasDryingTime}
        label={t(
          'domain.analysis.step4.section.extras.subsection.centrifuge.drying.input.run_time.label'
        )}
        placeholder={t(
          'domain.analysis.step4.section.extras.subsection.centrifuge.drying.input.run_time.placeholder'
        )}
        inputProps={{
          keyboardType: 'numeric',
          maxLength: 6,
          InputRightElement: <InputAddon str={t('domain.units.time.minutes')} />,
        }}
      />

      <FormInput
        methods={methods}
        name={InputExtrasDryingTemperature}
        label={t(
          'domain.analysis.step4.section.extras.subsection.centrifuge.drying.input.temperature.label'
        )}
        placeholder={t(
          'domain.analysis.step4.section.extras.subsection.centrifuge.drying.input.temperature.placeholder'
        )}
        inputProps={{
          keyboardType: 'numeric',
          maxLength: 5,
          InputRightElement: <InputAddon str={t('domain.units.temperature.celsius')} />,
        }}
      />
    </VStack>
  );
};

export default Step4ExtrasDrying;
