import { IJetCentrifuge } from '../../types/centrifuge.type';
import { IJetDryer } from '../../types/dryer.type';
import { IJetWashCycle } from '../../types/wash_cycle.type';

interface ICalcDryerEnergy {
  dryer: IJetDryer;
  cycle: Partial<IJetWashCycle> | undefined;
}

export function calcDryerEnergy(props: ICalcDryerEnergy): number {
  const _dTime = parseFloat(`${props?.cycle?.extras_drying_time || 0}`);
  const _energy = parseFloat(`${props.dryer['Energy (KW)'] || 0}`);

  return (_dTime * _energy) / 60;
}
