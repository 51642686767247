import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Box, Stack, Text } from 'native-base';
import React from 'react';
import { Page } from '../../components/Page';
import { HomeStackParamList } from '../../routes/auth.routes';
import WashingPlantProvider from './contexts/WashingPlantContext';
import { WashingPlantForm } from './screens/WashingPlantForm';

type IWashingPlantRoot = NativeStackScreenProps<HomeStackParamList, 'WashingPlant'>;

const WashingPlantRoot: React.FC<IWashingPlantRoot> = ({ route }) => {
  const params = route?.params;

  return (
    <Page>
      <Box flex={1} pt="24px">
        <Stack height="100%">
          <WashingPlantProvider>
            <WashingPlantForm washingPlant={params?.washingPlant} />
          </WashingPlantProvider>
        </Stack>
      </Box>
    </Page>
  );
};

export default WashingPlantRoot;
