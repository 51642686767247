import React, { useState } from 'react';
import {
  Box,
  FormControl,
  Link,
  Skeleton,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import { Input } from '../../../../components/Input';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { CustomButton } from '../../../../components/CustomButton';
import { t } from '../../../../languages/config';
import { useAuth } from '../../hooks/useAuth';
import { toast } from '../../../../utils/toast';

interface ResetPasswordProps {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
  showNewPasswordInput: boolean
}

export function ResetPassword({
  navigation,
  showNewPasswordInput,
}: ResetPasswordProps) {
  const { resendResetPasswordLink, resetUserPassword } = useAuth()
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSendResendPasswordLink = async () => {
    setIsLoading(true);
    try{
      const data = await resendResetPasswordLink(email);
      setIsLoading(false)
      if(!data.error){
        navigation.navigate('ResetSuccess', { email });
      }
    }catch(error){
      setIsLoading(false)
      console.log(error)
    }
  };

  async function handleResetPassword(){
    setIsLoading(true)
    try{
      resetUserPassword(newPassword);
      toast(t('domain.resetPassword.successMessage'));
      setTimeout(() => {
        setIsLoading(false);
        navigation.navigate('SignIn');
      }, 2000)
    }catch(error){
      setIsLoading(false)
      console.log(error)
    }
  }

  const isDisabledContinueButton = (!showNewPasswordInput && !email) || (showNewPasswordInput && newPassword.length < 6)

  return (
    <VStack justifyContent={'space-between'} flex={1} pt="24px">
      <Box mt="12px">
        <FormControl>
          {!showNewPasswordInput ? (
            <>
              <Input
                type="email"
                border
                borderColor="#409D27"
                keyboardType="email-address"
                placeholder={t('domain.resetPassword.inputPlaceholder')}
                autoCapitalize="none"
                value={email}
                onChangeText={(text) => setEmail(text)}
              />
              <FormControl.ErrorMessage
                color="red.300"
                leftIcon={<WarningOutlineIcon size="xs" />}
                textAlign="left"
              >
                {t('domain.resetPassword.emailNotFound')}
                <Link fontSize="sm" onPress={() => navigation.navigate('SignUp')}>
                  {t('domain.resetPassword.emailNotFoundLink')}
                </Link>
              </FormControl.ErrorMessage>
            </>
          ) : (
            <Input
              type="password"
              border
              borderColor="#409D27"
              placeholder={t('domain.resetPassword.newPassword')}
              value={newPassword}
              onChangeText={(text) => setNewPassword(text)}
            />
          )}
        </FormControl>
      </Box>
      <CustomButton
        isDisabled={isDisabledContinueButton || isLoading}
        isLoading={isLoading}
        variant="solid"
        onPress={!showNewPasswordInput ? handleSendResendPasswordLink : handleResetPassword}
        bgColor="#409D27"
      >
        {t('domain.resetPassword.buttons.reset')}
      </CustomButton>
    </VStack>
  );
}
