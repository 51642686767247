import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Center, Text, Modal, Box, Radio } from 'native-base';
import React, { useState } from 'react';
import { CustomButton } from '../../../components/CustomButton';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { IJetWashRecipeAggregatorID } from '../../../types/wash_recipe_aggregator.type';

interface Step2ModalConfirmProps {
  name?: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: (useStandard?: boolean, copyRecipe?: boolean) => void;
  washAggregator?: IJetWashRecipeAggregatorID;
  washing_plant: IJetWashingPlant;
  hasAskedForHelp: boolean;
  hasRecipeError?: boolean;
  clearErrorRecipe: () => void;
  setHasRecipeError: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step2ModalConfirm: React.FC<Step2ModalConfirmProps> = ({
  showModal,
  setShowModal,
  onConfirm,
  washAggregator,
  washing_plant,
  hasAskedForHelp,
  hasRecipeError,
  clearErrorRecipe,
  setHasRecipeError,
}) => {
  const [selectedGreenOfChangeRecipe, setSelectedGreenOfChangeRecipe] = useState('');
  const { navigate } = useNavigation<NativeStackNavigationProp<HomeStackParamList>>();

  function _onConfirm() {
    if(selectedGreenOfChangeRecipe === 'need_help'){
      return _onPressNeedHelp();
    };

    if(selectedGreenOfChangeRecipe === 'copy_standard') {
      onConfirm(false, true);
      return;
    }

    onConfirm(false);
  }

  const _onPressNeedHelp = () => {
    setShowModal(false);
    navigate('RecipeNeedHelp', {
      washAggregator,
      washing_plant,
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setHasRecipeError(false);
  }

  return (
    <Center>
      <Modal
        isOpen={showModal}
        onClose={closeModal}
        _backdrop={{
          _dark: {
            bg: '#153731',
          },
          bg: '#153731',
        }}
      >
        <Modal.Content>
          <Modal.Body style={{ backgroundColor: 'white' }}>
            <Modal.CloseButton />
            <Box p={2}>
              <Text fontWeight="bold" color="black">
                {hasRecipeError ? t('domain.analysis.step2.modal.errorTitle') : t('domain.analysis.step2.modal.title')}
              </Text>

              {!hasAskedForHelp ? (
                <Box mt="16px">
                  {!hasRecipeError ? t('domain.analysis.step2.modal.subtitle') : t('domain.analysis.step2.modal.errorSubtitle')}
                </Box>
              ) : (
                <Box mt="16px">{`${t('domain.analysis.step2.modal.subtitle_asked_help')}`}</Box>
              )}

              {!hasRecipeError && (
                <Box mt={2}>
                  <Radio.Group
                    name="greenOfChange_recipe"
                    accessibilityLabel="greenofchange recipe"
                    value={selectedGreenOfChangeRecipe}
                    onChange={nextValue => setSelectedGreenOfChangeRecipe(nextValue)}
                  >
                    <Radio value="blank_recipe" my="1">
                      {t('domain.analysis.step2.modal.options.blank_recipe')}
                    </Radio>
                    <Radio value="copy_standard" my="1">
                      {t('domain.analysis.step2.modal.options.copy_standard')}
                    </Radio>
                    <Radio value="need_help" my="1">
                      {t('domain.analysis.step2.modal.options.need_help')}
                    </Radio>
                  </Radio.Group>
                </Box>
              )}

              <Box mt="23px" mb="16px">
                <CustomButton
                  variant={'solid'}
                  onPress={!hasRecipeError ? _onConfirm : clearErrorRecipe}
                >
                  {!hasRecipeError ? t('domain.analysis.step2.modal.button.continue') : t('domain.analysis.step2.modal.button.continueError')}
                </CustomButton>
              </Box>
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Center>
  );
};

export default Step2ModalConfirm;
