import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import WashChemicalForm from './WashChemicalForm';

type WashChemicalRootProps = NativeStackScreenProps<HomeStackParamList, 'washRecipeCycleChemicals'>;

export const WashChemicalRoot: React.FC<WashChemicalRootProps> = ({
  route,
  navigation
}) => {
  const { cycle, chemical, currentAggregator, recipeName } = route.params;

  return <WashChemicalForm
    washCycle={cycle}
    chemical={chemical}
    currentAggregator={currentAggregator}
    recipeName={recipeName}
    navigation={navigation}
  />;
};

export default WashChemicalRoot;
