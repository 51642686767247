import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { IJetWashCycle } from '../../../types/wash_cycle.type';
import {
  IJetWashCycleLaser,
  IJetWashCycleLaserID,
  IJetWashCycleLaserComplete,
  IJetWashCycleLaserCreate,
} from '../../../types/wash_cycle_laser.type';
import { getLaserScraping } from '../../standardRecipe/services/getLaserScrapings';
import { editWashCycle, getWashCycle } from '../../washRecipeAggregator/services/cycle.service';

interface ICreateWashCycleLaserParams {
  data: IJetWashCycleLaser | IJetWashCycleLaserCreate;
  washCycle: IJetWashCycle;
}

export async function getWashCycleLaser(
  id: IJetWashCycleLaserID
): Promise<IJetWashCycleLaserComplete | undefined> {
  const response = await jetAdmin.retrieve<IJetWashCycleLaser>(
    JetAdminCollection.WashRecipeLaserScrapings,
    id
  );

  const auxEqp = response?.laser_id ? await getLaserScraping(response?.laser_id) : undefined;

  return {
    ...response,
    laser_detail: auxEqp,
  };
}

export async function listWashCycleLasers(washCycle: IJetWashCycle) {
  try {
    const cyclesIDS = washCycle.add_laser_scrapings;
    const cycleIDPromiseArr = cyclesIDS?.map((cycleID) => getWashCycleLaser(cycleID));
    if (!cycleIDPromiseArr || cycleIDPromiseArr.length === 0) return [];

    const cyclesResponse = await Promise.all(cycleIDPromiseArr);
    // remove all undefined values from the array
    const cycles = cyclesResponse.filter((cycle) => !!cycle) as IJetWashCycleLaserComplete[];

    return cycles;
  } catch (error) {
    return [];
  }
}

export async function createOrEditWashCycleLaser(
  params: ICreateWashCycleLaserParams
): Promise<IJetWashCycleLaser | undefined> {
  const { data, washCycle } = params;

  // @ts-ignore
  if (data?.id) {
    const res = await editWashCycleLaser(data);
    return res;
  } else {
    return await createWashCycleLaser({ data, washCycle });
  }
}

export async function createWashCycleLaser(
  props: ICreateWashCycleLaserParams
): Promise<IJetWashCycleLaser | undefined> {
  const { data, washCycle } = props;
  const washCycleLaserCreate = convertWashCycleLaserFormToWashCycleLaserCreate(data);

  const res = await jetAdmin.create<IJetWashCycleLaser>(
    JetAdminCollection.WashRecipeLaserScrapings,
    washCycleLaserCreate
  );

  if (!res?.id) return res;

  await editWashCycle({
    ...washCycle,
    add_laser_scrapings: [...washCycle.add_laser_scrapings, res.id],
  });

  return res;
}

export async function editWashCycleLaser(
  props: Partial<IJetWashCycleLaser>
): Promise<IJetWashCycleLaser | undefined> {
  if (!props.id) throw new Error('Wash recipe id is required');

  const res = await jetAdmin.update<IJetWashCycleLaser>(
    JetAdminCollection.WashRecipeLaserScrapings,
    props.id,
    props
  );

  return res;
}

function convertWashCycleLaserFormToWashCycleLaserCreate(
  data: IJetWashCycleLaserCreate
): IJetWashCycleLaserCreate {
  return {
    ...data,
    laser_id: data.laser_id || undefined,
    laser_labor_operation_time: data.laser_labor_operation_time || undefined,
    laser_machine_operation_time: data.laser_machine_operation_time || undefined,
  };
}

interface IDeleteWashCycleLaser {
  laser: IJetWashCycleLaser;
  cycle: IJetWashCycle;
}

export async function deleteWashCycleLaser(props: IDeleteWashCycleLaser): Promise<void> {
  const { laser, cycle } = props;
  if (!laser.id || !cycle.id) throw new Error('Wash recipe id is required');

  const _cycle = await getWashCycle(cycle.id);
  if (!_cycle) throw new Error('Wash cycle not found');

  // Remove only the id from washCycleLaserIDs array
  const _newArr = _cycle?.add_laser_scrapings?.filter(
    (washCycleLaserID) => washCycleLaserID !== props.laser.id
  );

  await editWashCycle({
    ..._cycle,
    add_laser_scrapings: _newArr,
  });

  await jetAdmin.delete(JetAdminCollection.WashRecipeLaserScrapings, props.laser.id);
}
