import { useContext } from 'react';
import { WashingPlantContext } from '../contexts/WashingPlantContext';

interface UseWashingPlantForm {
  nextStep: () => void;
  backStep: () => void;
  goToStep: (step: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  isSubmitting: boolean;
  step: number;
  canNext: boolean;
  canBack: boolean;
  firstStep: number;
  lastStep: number;
}

const WASHING_PLANT_FORM_FIRST_STEP = 1;
const WASHING_PLANT_FORM_LAST_STEP = 10;

export function useWashingPlantForm(): UseWashingPlantForm {
  const { step, setStep, isSubmitting, setIsSubmitting } = useContext(WashingPlantContext);

  const nextStep = () => {
    // check if step + 1 is one of type AvailableSteps
    if (step + 1 <= WASHING_PLANT_FORM_LAST_STEP) {
      setStep(step + 1);
    }
  };

  const backStep = () => {
    // check if step - 1 is one of type AvailableSteps
    if (step - 1 >= WASHING_PLANT_FORM_FIRST_STEP) {
      setStep(step - 1);
    }
  };

  const goToStep = (step: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10) => {
    // check if step is one of type AvailableSteps
    if (step >= WASHING_PLANT_FORM_FIRST_STEP && step <= WASHING_PLANT_FORM_LAST_STEP) {
      setStep(step);
    }
  };

  return {
    nextStep,
    backStep,
    step,
    goToStep,
    setIsSubmitting,
    isSubmitting,
    canNext: step < WASHING_PLANT_FORM_LAST_STEP,
    canBack: step > WASHING_PLANT_FORM_FIRST_STEP,
    firstStep: WASHING_PLANT_FORM_FIRST_STEP,
    lastStep: WASHING_PLANT_FORM_LAST_STEP,
  };
}
