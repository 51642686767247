import { Center, Text, Button, Modal, Box, HStack, Icon } from 'native-base';
import {
  IButtonComponentType,
  IButtonProps,
} from 'native-base/lib/typescript/components/primitives/Button/types';
import React, { useEffect, useState } from 'react';
import { t } from '../languages';
import { toast } from '../utils/toast';
import { CustomButton } from './CustomButton';

interface ConfirmProps {
  onConfirm: () => void;
  onCancel?: () => void;
  onConfirmToastMessage?: string;
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  button?: IButtonProps;
  buttonText: string;
  openModal?: boolean;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showButton?: boolean;
  isLoading: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Confirm: React.FC<ConfirmProps> = ({
  onConfirm = () => {},
  onCancel = () => {},
  title = t('domain.default.confirm.title'),
  message = t('domain.default.confirm.message'),
  confirmText = t('domain.default.confirm.confirm_text'),
  cancelText = t('domain.default.confirm.cancel_text'),
  button,
  buttonText,
  onConfirmToastMessage,
  openModal = false,
  showButton = true,
  setOpenModal = () => {},
  isLoading = false,
  setShowModal = () => {},
}) => {

  useEffect(() => {
    setShowModal(openModal);
  }, [openModal]);

  async function _onConfirm() {
    onConfirm();
    if (onConfirmToastMessage) {
      toast(onConfirmToastMessage);
    }
  }

  function _onCancel() {
    setShowModal(false);
    onCancel();
  }

  function _openModal() {
    setShowModal(true);
  }

  useEffect(() => {
    setOpenModal && setOpenModal(openModal);
  }, [openModal]);

  return (
    <>
      {showButton && (
        <Button variant="ghost" padding={0} margin={0} {...button} onPress={_openModal}>
          {buttonText}
        </Button>
      )}
      <Modal
        isOpen={openModal}
        onClose={() => setShowModal(false)}
        _backdrop={{
          _dark: {
            bg: '#153731',
          },
          bg: '#153731',
        }}
      >
        <Modal.Content>
          <Modal.Body style={{ backgroundColor: 'white' }}>
            <Modal.CloseButton />
            <Box p={2}>
              <Text fontWeight="bold" color="black">
                {title}
              </Text>

              <Box mt="16px">{message}</Box>
            </Box>
          </Modal.Body>
          <Modal.Footer background="white">
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={_onCancel}
                isDisabled={isLoading}
              >
                {cancelText}
              </Button>
              <Button
                isDisabled={isLoading}
                isLoading={isLoading}
                onPress={_onConfirm}
              >
                {confirmText}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Confirm;
