import { Box, HStack, Skeleton, Text, VStack, ZStack } from 'native-base';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Grid, Row } from 'react-native-easy-grid';
import { Dimensions, Platform } from 'react-native';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { IJetWashRecipeAggregatorComplete } from '../../../types/wash_recipe_aggregator.type';
import { ProgressChart } from 'react-native-chart-kit';
import { stylesSummary } from './CycleSummaryTotal';
interface CycleSummaryProps {
  washAggregator: Partial<IJetWashRecipeAggregatorComplete>;
  washingPlant: IJetWashingPlant;
}

interface ISummaryIndicator {
  bgColor?: string;
  title: string;
  subtitle: string;
  thirdLine?: {
    title: string;
    unit: string;
  };
  chart?: {
    bgColor?: string;
    rgb: string;
    data: number[];
  };
  unit: string;
  value: string;
  isLoading: boolean;
}

export const SummaryLoading: React.FC = () => (
  <Box>
    <Grid>
      <Row>
        <Grid
          style={{
            ...stylesSummary.empty,
            ...stylesSummary.right,
            ...stylesSummary.bottom,
          }}
        >
          <SummaryIndicator isLoading={true} title="CO₂" unit="Kg" subtitle={''} value={''} />
        </Grid>
        <Grid style={{ ...stylesSummary.empty, ...stylesSummary.bottom }}>
          <SummaryIndicator isLoading={true} title="Energy" unit="kWh" subtitle={''} value={''} />
        </Grid>
      </Row>
      <Row>
        <Grid style={{ ...stylesSummary.empty, ...stylesSummary.right }}>
          <SummaryIndicator isLoading={true} title="Water" unit="Liters" subtitle={''} value={''} />
        </Grid>
        <Grid style={{ ...stylesSummary.empty }}>
          <SummaryIndicator isLoading={true} title="Time" unit="Min." subtitle={''} value={''} />
        </Grid>
      </Row>
    </Grid>
  </Box>
);

export const SummaryIndicator: React.FC<ISummaryIndicator> = ({
  bgColor = 'transparent',
  title,
  thirdLine,
  value,
  isLoading,
  subtitle,
  chart,
  unit,
}) => {
  const [internalSubtitle, setinternalSubtitle] = useState<string | number>(subtitle);
  const [chartData, setChartData] = useState<number[]>([0]);
  const width = Dimensions.get('window').width;
  const chartWidthHeight = useMemo(() => {
    const _width = width * (Platform.OS === 'web' ? 0.2 : 0.25);

    return _width >= 150 ? _width : 150;
  }, [width]);

  useLayoutEffect(() => {
    if (!chart?.data) {
      return;
    } 
   
    if (Number.isNaN(chart?.data[0])) {
      setChartData([0]);
    } else if (chart.data[0] < 0) {
      setChartData([0]);
    } else {
      setChartData(chart.data);
    }
  }, [chart]);

  useLayoutEffect(() => {
    if (subtitle === undefined) {
      return setinternalSubtitle('---%');
    }

    const _subtitle = subtitle?.toString().replace('%', '');

    // check if subtitle is NaN
    if (Number.isNaN(Number(_subtitle))) {
      return setinternalSubtitle('---%');
    }

    // check if subtitle is negative
    if (Number(_subtitle) < 0) {
      return setinternalSubtitle('---%');
    }

    setinternalSubtitle(`${_subtitle}%`);
  }, [subtitle]);

  return (
    <VStack
      flexGrow={1}
      bgColor={bgColor}
      alignContent="center"
      alignItems="center"
      alignSelf="center"
      justifyContent="center"
      mt={4}
      mb={4}
    >
      <Text color="#6F7370" fontSize="20px" lineHeight="24px" mb={Platform.OS === 'web' ? 5 : 0}>
        {title}
      </Text>
      <Skeleton isLoaded={!isLoading} width={chartWidthHeight} height={chartWidthHeight} mt={4}>
        <VStack alignContent="center" alignItems="center" justifyContent="center">
          <ZStack
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            width={chartWidthHeight}
            height={chartWidthHeight}
          >
            <ProgressChart
              data={{ data: chartData }}
              width={chartWidthHeight}
              height={chartWidthHeight}
              strokeWidth={Platform.OS === 'web' ? 14 : 16}
              radius={Platform.OS === 'web' ? 78 : 48}
              hideLegend={true}
              chartConfig={{
                backgroundColor: chart?.bgColor || 'white',
                backgroundGradientFrom: chart?.bgColor || 'white',
                backgroundGradientTo: chart?.bgColor || 'white',
                color: (opacity = 1) => `rgba(${chart?.rgb || '0,0,0'}, ${opacity})`,
              }}
            />

            <Text
              fontSize={Platform.OS === 'web' ? '32px' : '16px'}
              color={`rgba(${chart?.rgb || '255,255,255'}, 0.75)`}
              fontWeight="bold"
            >
              {internalSubtitle}
            </Text>
          </ZStack>

          <HStack
            mb={-2}
            mt={-2}
            space="2px"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Text
              fontSize="20px"
              lineHeight="50px"
              color={chart?.rgb ? `rgb(${chart?.rgb})` : 'primary.500'}
              fontWeight="bold"
            >
              {value}
            </Text>
            <Text
              fontSize="16px"
              lineHeight="50px"
              color={chart?.rgb ? `rgba(${chart?.rgb}, 0.33)` : 'gray.300'}
            >
              {unit}
            </Text>
          </HStack>
          {thirdLine && (
            <HStack mb={-4} space="4px">
              <Text fontSize="20px" lineHeight="50px" color="primary.500" fontWeight="bold">
                {thirdLine.title}
              </Text>
              <Text fontSize="16px" lineHeight="50px" color="gray.300">
                {thirdLine.unit}
              </Text>
            </HStack>
          )}
        </VStack>
      </Skeleton>
    </VStack>
  );
};
