import { Box, Button, Center, Image, Skeleton, Text, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { CustomButton } from '../../../components/CustomButton';
import { Page } from '../../../components/Page';
import { t } from '../../../languages';
import Img from '../../../assets/svgs/wash-machine';
import BackHeader from '../../../components/BackHeader';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { HomeStackParamList } from '../../../routes/auth.routes';
import Loading from '../../../../components/Loading';
import { useMutation } from 'react-query';
import { createHelpLaser } from '../../washCycle/services/help.service';
import { toast } from '../../../utils/toast';
import { useAuth } from '../../auth/hooks/useAuth';

type Props = NativeStackScreenProps<HomeStackParamList, 'RecipeNeedHelp'>;

export const RecipeNeedHelp: React.FC<Props> = ({ navigation, route }) => {
  const { washAggregator, washing_plant } = route.params;
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const _onPress = () => {
    navigation.goBack();
  };

  const helpMutation = useMutation(createHelpLaser, {
    onSuccess: () => {
      setTimeout(() => setIsLoading(false), 5000);
    },
    onError: () => toast(t('domain.analysis.step2.modal_help.error')),
  });

  useEffect(() => {
    helpMutation.mutate({
      help: {
        resolved: false,
        customer: user?.id,
        washing_plant: washing_plant?.id,
        wash_aggregator: washAggregator,
      },
    });
  }, []);

  return (
    <Page>
      <VStack flex={1}>
        <BackHeader backTitle />
        <VStack justifyContent="center" flex={1}>
          <Center p={10} mb={5}>
            {!isLoading && <Img />}
            <Box display="flex" alignContent={'center'}>
              {!isLoading && (
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <Text
                    fontSize="16px"
                    mt="16px"
                    color="primary.500"
                    fontWeight="bold"
                    textAlign={'center'}
                  >
                    {t('domain.analysis.step2.modal_help.title')}
                  </Text>
                </Box>
              )}

              <Box mb={7}>
                {isLoading ? (
                  <Box mt={10}>
                    <Loading loadingText={t('domain.analysis.step2.modal_help.submiting')} />
                  </Box>
                ) : (
                  <Text fontSize="16px" mt="16px" textAlign={'center'}>
                    {t('domain.analysis.step2.modal_help.subtitle')}
                  </Text>
                )}
              </Box>

              {!isLoading && (
                <Button onPress={_onPress} isDisabled={isLoading}>
                  {t('domain.generic.back')}
                </Button>
              )}
            </Box>
          </Center>
        </VStack>
      </VStack>
    </Page>
  );
};

export default RecipeNeedHelp;
