import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { IJetWashRecipeID, IJetWashRecipe } from '../../../types/wash-recipe.type';
import {
  IJetWashRecipeAggregator,
  IJetWashRecipeAggregatorComplete,
  IJetWashRecipeAggregatorCreate,
  IJetWashRecipeAggregatorForm,
  IJetWashRecipeAggregatorID,
} from '../../../types/wash_recipe_aggregator.type';
import { editWashRecipe, getWashRecipe } from '../../washRecipe/services/washRecipe.service';

interface ICreateWashRecipeAggregatorParams {
  data: IJetWashRecipeAggregatorForm;
  washingRecipe: IJetWashRecipe;
}

export async function getWashRecipeAggregator(
  id: IJetWashRecipeAggregatorID
): Promise<IJetWashRecipeAggregatorComplete | undefined> {
  const response = await jetAdmin.retrieve<IJetWashRecipeAggregatorComplete>(
    JetAdminCollection.WashRecipeAggregators,
    id
  );

  return response;
}

export async function listWashRecipeAggregators({
  aggregatorsIDS,
}: {
  aggregatorsIDS: IJetWashRecipeAggregatorID[];
}) {
  try {
    const aggregatorIDPromiseArr = aggregatorsIDS.map((aggregatorID) =>
      getWashRecipeAggregator(aggregatorID)
    );
    const aggregatorsResponse = await Promise.all(aggregatorIDPromiseArr);
    // remove all undefined values from the array
    const aggregators = aggregatorsResponse.filter(
      (aggregator) => !!aggregator
    ) as IJetWashRecipeAggregatorComplete[];

    return aggregators;
  } catch (error) {
    return [];
  }
}

export async function createOrEditWashRecipeAggregator(
  params: ICreateWashRecipeAggregatorParams
): Promise<IJetWashRecipeAggregator | undefined> {
  const { data, washingRecipe } = params;

  if (data.id) {
    return await editWashRecipeAggregator(data);
  } else {
    return await createWashRecipeAggregator({ data, washingRecipe });
  }
}

export async function createWashRecipeAggregator(
  props: ICreateWashRecipeAggregatorParams
): Promise<IJetWashRecipeAggregator | undefined> {
  const { data, washingRecipe } = props;
  const washRecipeAggregatorCreate =
    convertWashRecipeAggregatorFormToWashRecipeAggregatorCreate(data);

  const res = await jetAdmin.create<IJetWashRecipeAggregator>(
    JetAdminCollection.WashRecipeAggregators,
    washRecipeAggregatorCreate
  );

  const _washingRecipe = await getWashRecipe(washingRecipe.id);

  if (res?.id) {
    const _aggregators = _washingRecipe?.wash_recipe_aggregators || [];
    await editWashRecipe({
      ..._washingRecipe,
      wash_recipe_aggregators: [..._aggregators, res.id],
    });
  }

  return res;
}

export async function editWashRecipeAggregator(
  props: Partial<IJetWashRecipeAggregator>
): Promise<IJetWashRecipeAggregator | undefined> {
  if (!props.id) throw new Error('Wash recipe id is required');

  const res = await jetAdmin.update<IJetWashRecipeAggregator>(
    JetAdminCollection.WashRecipeAggregators,
    props.id,
    props
  );

  return res;
}

function convertWashRecipeAggregatorFormToWashRecipeAggregatorCreate(
  data: IJetWashRecipeAggregatorForm
): IJetWashRecipeAggregatorCreate {
  return {
    ...data,
  };
}

export interface IDeleteWashRecipeAggregator {
  id: IJetWashRecipeAggregatorID;
  washRecipeID: IJetWashRecipeID;
}

export async function deleteWashRecipeAggregator(
  props: IDeleteWashRecipeAggregator
): Promise<void> {
  const _wash = await getWashRecipe(props.washRecipeID);

  if (!_wash) throw new Error('Wash recipe not found');

  // Remove only the id from washRecipeAggregatorIDs array
  const _newArr = _wash?.wash_recipe_aggregators?.filter(
    (washRecipeAggregatorID) => washRecipeAggregatorID !== props.id
  );

  await editWashRecipe({
    ..._wash,
    wash_recipe_aggregators: _newArr,
  });

  await jetAdmin.delete(JetAdminCollection.WashRecipeAggregators, props.id);
}
