import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const MachineBig = (props: SvgProps) => (
  <Svg {...props} width={47} height={57} fill="none">
    <Rect width={47} height={56.038} rx={2} fill="#CFE8E5" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.147.633c.35 0 .633.284.633.633v12.031a.633.633 0 0 1-1.266 0V1.267c0-.35.283-.634.633-.634Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.59 14.564H1.267v38.942c0 .7.567 1.266 1.267 1.266h41.791c.7 0 1.267-.567 1.267-1.266V14.564Zm1.267-1.267v40.209a2.533 2.533 0 0 1-2.533 2.533H2.533A2.533 2.533 0 0 1 0 53.506V13.297h46.857Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m45.59 2.452-.01-.08a1.267 1.267 0 0 0-1.256-1.106H2.533c-.645 0-1.18.484-1.257 1.107l-.01.079v10.529h44.325V2.452ZM.02 2.216H0v12.031h46.857V2.217h-.02A2.533 2.533 0 0 0 44.324 0H2.533A2.533 2.533 0 0 0 .019 2.216Z"
      fill="#368D7D"
    />
    <Path
      d="M5.382 6.648c0-.874.709-1.583 1.583-1.583h3.482a1.583 1.583 0 1 1 0 3.166H6.965a1.583 1.583 0 0 1-1.583-1.583ZM35.776 6.648c0-.874.709-1.583 1.583-1.583h3.483a1.583 1.583 0 1 1 0 3.166h-3.483a1.583 1.583 0 0 1-1.583-1.583Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.429 50.34c8.568 0 15.513-6.946 15.513-15.514S31.997 19.313 23.43 19.313 7.915 26.258 7.915 34.826 14.861 50.34 23.43 50.34Zm0 1.266c9.267 0 16.78-7.512 16.78-16.78 0-9.267-7.513-16.78-16.78-16.78-9.267 0-16.78 7.513-16.78 16.78 0 9.268 7.513 16.78 16.78 16.78Z"
      fill="#368D7D"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.428 42.742a7.915 7.915 0 1 0 0-15.83 7.915 7.915 0 0 0 0 15.83Zm0 1.266a9.181 9.181 0 1 0 0-18.363 9.181 9.181 0 0 0 0 18.363Z"
      fill="#368D7D"
    />
  </Svg>
);

export default MachineBig;
