import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const WashMachine = (props: SvgProps) => (
  <Svg {...props} width={165} height={204} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M155.75 3.945H4.542A4.542 4.542 0 0 0 0 8.487v190.174a4.542 4.542 0 0 0 4.542 4.542H155.75a4.542 4.542 0 0 0 4.541-4.542V8.487a4.541 4.541 0 0 0-4.541-4.542Z"
        fill="#368D7D"
      />
      <Path
        d="M160.02.438H8.812A4.542 4.542 0 0 0 4.27 4.98v190.174a4.542 4.542 0 0 0 4.542 4.542H160.02a4.543 4.543 0 0 0 4.542-4.542V4.98A4.542 4.542 0 0 0 160.02.438Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M55.485 10.303h-33.95v15.782h33.95V10.303ZM42.754 177.74H15.169v12.013h27.585V177.74ZM134.583 26.086a6.41 6.41 0 1 0 0-12.82 6.41 6.41 0 0 0 0 12.82ZM137.187 87.98h-13.81v30.084h13.81V87.981ZM20.053 36.195h126.753M15.169 166.858h131.637"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M85.876 144.684c23.835 0 43.157-19.322 43.157-43.157 0-23.835-19.322-43.157-43.157-43.157-23.835 0-43.157 19.322-43.157 43.157 0 23.835 19.322 43.157 43.157 43.157Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M85.876 133.101c16.789 0 30.399-13.61 30.399-30.399s-13.61-30.4-30.4-30.4c-16.788 0-30.399 13.611-30.399 30.4 0 16.789 13.61 30.399 30.4 30.399Z"
        fill="#368D7D"
      />
      <Path d="M55.485 107.095s19.29-8.26 38.431 2.963" stroke="#E4F4F0" strokeMiterlimit={10} />
      <Path
        d="M116.126 105.745s-23.043-10.364-33.109-.193M87.63 92.794s-.623-5.796 1.972-4.805c2.596.99-1.973 4.805-1.973 4.805ZM83.561 99.099s.877-7.392-3.086-6.287c-3.964 1.105 3.086 6.287 3.086 6.287Z"
        stroke="#E4F4F0"
        strokeMiterlimit={10}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h165v203.203H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default WashMachine;
