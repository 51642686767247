import { IJetWashingPlant } from '../../types/washing-plants.type';
import { IJetWashCycle } from '../../types/wash_cycle.type';
import { WATER_COST_M3 } from '../constants/contants';
import { calcTotalWater } from '../cycle/cycle.calc';
import { calcPercent, DEFAULT_FALLBACK } from './calc-percent.calc';
import { ICalcResponse } from './calc.type';

export function calcTotalCostWater(
  currentCycle: Partial<IJetWashCycle> | undefined,
  greenCycle: Partial<IJetWashCycle> | undefined,
  washingPlant: Partial<IJetWashingPlant>
): ICalcResponse {
  try {
    if (!currentCycle || !greenCycle) return DEFAULT_FALLBACK;

    const _waterCostM3 = WATER_COST_M3;

    const current = calcTotalWater(currentCycle, washingPlant);
    const green = calcTotalWater(greenCycle, washingPlant);
    const _percent = calcPercent({ current, green });

    return {
      current: current,
      green: green,
      percent: _percent === -1 ? '---%' : `${_percent?.toFixed(0)}%`,
      total: current - green,
      totalCost: (current - green) * _waterCostM3,
      percentNumber: _percent,
    };
  } catch (error) {
    return DEFAULT_FALLBACK;
  }
}
