import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Box, Button, Container, Heading, HStack, Image, Stack, Text } from 'native-base';
import React from 'react';
import Img from '../../../../assets/images/logo-2.png';
import { CustomButton } from '../../../../components/CustomButton';
import { Page } from '../../../../components/Page';
import { t } from '../../../../languages';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';

export interface SignUpInterface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
}

export function SignupSuccess({ navigation }: SignUpInterface) {
  return (
    <>
      <Page>
        <HStack alignContent={'center'} justifyContent="center" alignItems="center">
          <Image source={Img} alt="logo-image" mt="50px" width="213px" height="276px" />
        </HStack>
        <Box display="flex" alignContent={'center'}>
          <Box
            mt="50px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="24px" color="#409D27" lineHeight={27}>
              {t('domain.signUp.waitingApproval.title')}
            </Text>
          </Box>
          <Box>
            <Text fontSize="16px" mt="16px" textAlign={'center'}>
              {t('domain.signUp.waitingApproval.subtitle')}
            </Text>
          </Box>
        </Box>
        <Box display="flex" mt="20px">
          <CustomButton
            variant="solid"
            bgColor="#409D27"
            mt={10}
            onPress={() => navigation.navigate('SignIn')}
          >
            {t('domain.signUp.waitingApproval.button')}
          </CustomButton>
        </Box>
      </Page>
    </>
  );
}
