// Generated by https://quicktype.io
//
// To change quicktype's target language, run command:
//
//   "Set quicktype target language"

import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { UserInterface } from '../types/IJetUser';

export interface IJetCreateUser {
  company: string;
  email: string;
  calling_code: string;
  phone: string;
  name: string;
  profile_image?: string;
  country?: string;
  status?: string;
  authenticator_id?: string;
  active?: boolean;
  password: string;
  password_confirm: string;
  cca2: any;
  agree_with_terms: boolean;
}

export type IJetCreateUserStepOne = Omit<IJetCreateUser,
  "password" | "password_confirm" | "authenticator_id" | "status" | "profile_image"
>;

export type IJetCreateUserStepTwo = {
  password?: string;
  password_confirm?: string;
}

export interface CreateUserOnJetAdmin extends IJetCreateUser {
  'created at': number;
  'created time': number;
  'updated time': number;
}

export function createUserOnJetAdmin(user: IJetCreateUser) {
  const newUser: CreateUserOnJetAdmin = {
    ...user,
    phone: `+${user.calling_code} ${user.phone}`,
    'created at': Date.now(),
    'created time': Date.now(),
    'updated time': Date.now(),
  };

  return jetAdmin.create<CreateUserOnJetAdmin>(JetAdminCollection.Customers, newUser);
}

export async function editUserOnJetAdmin(user: Partial<UserInterface>) {
  if (!user.id) return;

  const res = await jetAdmin.update<CreateUserOnJetAdmin>(
    JetAdminCollection.Customers,
    user.id,
    user
  );

  return res;
}
