import { Box, Skeleton, Text, VStack } from 'native-base';
import React, { useCallback, useContext, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { t } from '../../../languages';
import { FlatList, RefreshControl } from 'react-native';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import { QueryKeyCache } from '../../../config/query-cache';
import { IJetTypeOfCycle } from '../../../types/type-of-cycle.type';
// import StandardFormNextButton from '../components/StandardFormNextButton';
// import { useStandardRecipeForm } from '../hooks/useStandardRecipeForm';
// import { getTypeOfCycles } from '../services/getTypeOfCycles';
// import { IStandardRecipeForm } from '../types/standardRecipeForm.type';
import MachineCard from '../../washingPlant/components/MachineCard';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { getTypeOfCycles } from '../../standardRecipe/services/getTypeOfCycles';
import { Page } from '../../../components/Page';
import BackHeader from '../../../components/BackHeader';
import {
  createOrEditWashRecipeAggregator,
  editWashRecipeAggregator,
  getWashRecipeAggregator,
} from '../../washRecipeAggregator/services/washRecipeAggregator.service';
import Confirm from '../../../components/Confirm';
import Loading from '../../../../components/Loading';
import { WashRecipeAggregatorContext } from '../../washRecipeAggregator/contexts/WashRecipeAggregatorContext';

type CycleSelectProps = NativeStackScreenProps<HomeStackParamList, 'washRecipeCycleSelect'>;

export const CycleSelect: React.FC<CycleSelectProps> = ({ navigation, route }) => {
  const { setLastTimeChanged, washingCycleCompleteInfoQuery } = useContext(WashRecipeAggregatorContext);
  const [selectedType, setSelectedType] = useState<IJetTypeOfCycle | undefined>(undefined);
  const { washingPlant, washRecipe, washRecipeAggregator } = route.params;
  const { watch, setValue, control, formState } = useForm();
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

  function redirectToAggregatorPage() {
    navigation.navigate('WashRecipeAggregator', { washingPlant, washRecipe });
  }

  const { data, isLoading, refetch, isRefetching } = useQuery(
    QueryKeyCache.GetTypeOfCycles,
    getTypeOfCycles
  );

  const aggregationMutation = useMutation(createOrEditWashRecipeAggregator);

  const isQueryLoading = isLoading || isRefetching || aggregationMutation.isLoading;

  const selectRecipe = (recipe: IJetTypeOfCycle) => {
    setSelectedType(recipe);
    setShowModalConfirm(true);
  };

  const onSubmit = useCallback(() => {
    if (!selectedType?.id) return;
    setShowModalConfirm(false);
    aggregationMutation.mutate(
      {
        data: {
          id: washRecipeAggregator?.id,
          cycle_type: selectedType?.id,
        },
        washingRecipe: washRecipe,
      },
      {
        onSuccess: () => {
          washingCycleCompleteInfoQuery.refetch();
          redirectToAggregatorPage();
        },
      }
    );
  }, [selectedType, washRecipeAggregator]);

  return (
    <Page>
      <BackHeader backTitle onPress={redirectToAggregatorPage} />
      <VStack justifyContent="space-between" flex={1}>
        <Box flex={1}>
          <Text fontSize={22} fontWeight="bold" color="primary.500">
            {t('domain.analysis.step3.title_part_one')}
            <Text>
              {' '}
              <Text fontSize={22} fontWeight={500}>
                {t('domain.analysis.step3.title_part_two')}
              </Text>
            </Text>
          </Text>

          <Box mt={8} display="flex" flexGrow={1}>
            <FlatList
              data={data?.results || []}
              ListEmptyComponent={() => (!isQueryLoading ? <EmptyPlaceholder /> : <Loading />)}
              refreshControl={
                <RefreshControl
                  colors={['#9Bd35A', '#689F38']}
                  refreshing={isQueryLoading}
                  onRefresh={refetch}
                />
              }
              renderItem={({ item, index }) => {
                const isLast = data?.results.length ? index === data?.results.length - 1 : false;

                return (
                  <Box key={`recipe-card-${item.id}`} mx={0.75} mb={'8px'}>
                    <Skeleton isLoaded={!isQueryLoading}>
                      <MachineCard
                        name={`${item.name || ''}`}
                        onPress={() => selectRecipe(item)}
                        isSelected={selectedType?.id === item.id}
                      />
                    </Skeleton>
                    {isLast && <Box height={10} />}
                  </Box>
                );
              }}
            />
          </Box>
        </Box>
        <Box height="24px" />
      </VStack>

      <Confirm
        showButton={false}
        openModal={showModalConfirm}
        onConfirm={onSubmit}
        onCancel={() => setSelectedType(undefined)}
        buttonText={t('domain.analysis.step2.button.remove')}
        button={{ mb: 3 }}
        title={t('domain.analysis.step3.select.title')}
        message={`${t('domain.analysis.step3.select.message')} ${selectedType?.name || ''}`}
        onConfirmToastMessage={t('domain.generic.success')}
        setShowModal={setShowModalConfirm}
        isLoading={aggregationMutation.isLoading}
      />
    </Page>
  );
};

export default CycleSelect;
