import { Box, VStack, Text, Center, Button, Image, Icon, Skeleton } from 'native-base';
import React, { useCallback, useMemo } from 'react';
import { t } from '../../../languages';
import Dryer from '../../../assets/svgs/dryer-big';
import Boiler from '../../../assets/svgs/boiler-big';
import Centrifuge from '../../../assets/svgs/centrifuge-big';
import Machine from '../../../assets/svgs/machine-big';

interface MachineEditCardProps {
  name: string;
  type: 'boiler' | 'dryer' | 'centrifuge' | 'washingMachine';
  onPress: () => void;
  disabled: boolean;
  isLoading: boolean;
}

export const MachineEditCard: React.FC<MachineEditCardProps> = ({
  name,
  type,
  onPress,
  disabled,
  isLoading,
}) => {
  const nameByType = useMemo(() => {
    switch (type) {
      case 'boiler':
        return t('domain.words.boiler');
      case 'dryer':
        return t('domain.words.dryer');
      case 'centrifuge':
        return t('domain.words.centrifuge');
      case 'washingMachine':
        return t('domain.words.washingMachine');
      default:
        return '';
    }
  }, [type]);

  const IconByType = useCallback(() => {
    switch (type) {
      case 'boiler':
        return <Boiler />;
      case 'dryer':
        return <Dryer />;
      case 'centrifuge':
        return <Centrifuge />;
      case 'washingMachine':
        return <Machine />;
      default:
        return null;
    }
  }, [type]);

  return (
    <VStack alignItems="center" space="16px" bgColor="#E5EEED">
      <Box bgColor="#409D27" borderRadius="8px" width="100%">
        <Center p="6px">
          <Text color="white" textTransform="capitalize">
            {nameByType}
          </Text>
        </Center>
      </Box>
      <Box mb="16px" mt="8px">
        <Skeleton isLoaded={!isLoading}>
          <Text fontSize="18px" textTransform="capitalize" textAlign="center">
            {name}
          </Text>
        </Skeleton>
      </Box>
      <Center>
        <IconByType />
      </Center>
      <Box mb="16px" mt="-8px">
        <Button variant="ghost" onPress={onPress} isDisabled={disabled}>
          {t('domain.washingPlants.step.nine.tapToEdit')}
        </Button>
      </Box>
    </VStack>
  );
};

export default MachineEditCard;
