import React from 'react';
import { setLocale } from 'yup';
import { LocaleObject } from 'yup/lib/locale';
import { t } from './config';

interface LanguageProviderProps {}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const translation: LocaleObject = {
    mixed: {
      default: t('domain.form.validation.mixed.default'),
      required: t('domain.form.validation.mixed.required'),
      oneOf: t('domain.form.validation.mixed.oneOf'),
      notOneOf: t('domain.form.validation.mixed.notOneOf'),
      notType: ({ path, type }) =>
        `${path} ${t('domain.form.validation.mixed.notType.must_be_a')} \`${type}\` ${t(
          'domain.form.validation.mixed.notType.type'
        )}.`,
      defined: '${path} must be defined',
    },
    string: {
      length: t('domain.form.validation.string.length'),
      min: t('domain.form.validation.string.min'),
      max: t('domain.form.validation.string.max'),
      email: t('domain.form.validation.string.email'),
      url: t('domain.form.validation.string.url'),
      trim: t('domain.form.validation.string.trim'),
      lowercase: t('domain.form.validation.string.lowercase'),
      uppercase: t('domain.form.validation.string.uppercase'),
    },
    number: {
      min: t('domain.form.validation.number.min'),
      max: t('domain.form.validation.number.max'),
      lessThan: t('domain.form.validation.number.lessThan'),
      moreThan: t('domain.form.validation.number.moreThan'),
      // notEqual: t('domain.form.validation.number.notEqual'),
      positive: t('domain.form.validation.number.positive'),
      negative: t('domain.form.validation.number.negative'),
      integer: t('domain.form.validation.number.integer'),
    },
    date: {
      min: t('domain.form.validation.date.min'),
      max: t('domain.form.validation.date.max'),
    },
    array: {
      min: t('domain.form.validation.array.min'),
      max: t('domain.form.validation.array.max'),
    },
  };

  setLocale(translation);

  return <>{children}</>;
};

export default LanguageProvider;
