import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Box, Button, Image } from 'native-base';
import React, { useLayoutEffect } from 'react';
import { Dimensions, Platform } from 'react-native';
import Onboarding from 'react-native-onboarding-swiper';
import { t } from '../../../../languages';
import { HomeStackParamList } from '../../../../routes/auth.routes';
import Step1 from './1.png';
import Step2 from './2.png';
import Step3 from './3.png';
import Step4 from './4.png';

type WashRecipeCycleOnboardingProps = NativeStackScreenProps<
  HomeStackParamList,
  'washRecipeCycleOnboarding'
>;

export const CycleOnboarding: React.FC<WashRecipeCycleOnboardingProps> = ({
  navigation,
  route,
}) => {
  const windowWidth = Dimensions.get('window').width;
  const { washRecipe, washingPlant } = route.params;

  function skip() {
    navigation.push('washRecipeCycleSelect', {
      washRecipeAggregator: null,
      washingPlant,
      washRecipe,
    });
  }

  useLayoutEffect(() => {
    if (Platform.OS === 'web') skip();
  }, []);

  return (
    <Onboarding
      bottomBarColor="#F2F8F7"
      showSkip
      onDone={() =>
        navigation.push('washRecipeCycleSelect', {
          washRecipeAggregator: null,
          washingPlant,
          washRecipe,
        })
      }
      onSkip={skip}
      DoneButtonComponent={(props) => (
        <Box mr={4}>
          <Button {...props}>{t('domain.generic.start')}</Button>
        </Box>
      )}
      NextButtonComponent={(props) => (
        <Box mr={4}>
          <Button {...props}>{t('domain.generic.next')}</Button>
        </Box>
      )}
      containerStyles={{
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
        marginTop: 0,
      }}
      titleStyles={{
        color: '#409D27',
        fontWeight: 'bold',
        textAlign: 'left',
        paddingLeft: 8,
        paddingRight: 8,
      }}
      subTitleStyles={{
        color: '#6F7370',
        paddingLeft: 8,
        paddingRight: 8,
        marginTop: -10,
        textAlign: 'left',
      }}
      imageContainerStyles={{
        marginTop: -300,
        marginBottom: 30,
        paddingBottom: 0,
        width: '100%',
        backgroundColor: '#C7DCD8',
      }}
      pages={[
        {
          backgroundColor: '#F2F8F7',
          image: <Image width={windowWidth} height={windowWidth} alt="step 1" source={Step1} />,
          title: t('domain.analysis.onboarding.step1.title'),
          subtitle: t('domain.analysis.onboarding.step1.subtitle'),
        },
        {
          backgroundColor: '#F2F8F7',
          image: <Image width={windowWidth} height={windowWidth} alt="step 2" source={Step2} />,
          title: t('domain.analysis.onboarding.step2.title'),
          subtitle: t('domain.analysis.onboarding.step2.subtitle'),
        },
        {
          backgroundColor: '#F2F8F7',
          image: <Image width={windowWidth} height={windowWidth} alt="step 3" source={Step3} />,
          title: t('domain.analysis.onboarding.step3.title'),
          subtitle: t('domain.analysis.onboarding.step3.subtitle'),
        },
        {
          backgroundColor: '#F2F8F7',
          image: <Image width={windowWidth} height={windowWidth} alt="step 4" source={Step4} />,
          title: t('domain.analysis.onboarding.step4.title'),
          subtitle: t('domain.analysis.onboarding.step4.subtitle'),
        },
      ]}
    />
  );
};

export default CycleOnboarding;
