import { MaterialIcons } from '@expo/vector-icons';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  Box,
  Divider,
  FlatList,
  HStack,
  Icon,
  Text,
  VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { RefreshControl } from 'react-native';
import { useQuery } from 'react-query';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import Loading from '../../../../components/Loading';
import BackHeader from '../../../components/BackHeader';
import { CustomButton } from '../../../components/CustomButton';
import { Page } from '../../../components/Page';
import { QueryKeyCache } from '../../../config/query-cache';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { IJetWashRecipe } from '../../../types/wash-recipe.type';
import WashAnalyseCard from '../../home/components/WashAnalyseCard';
import { getWashRecipes } from '../services/getWash';

type Props = NativeStackScreenProps<HomeStackParamList, 'MyWashes'>;

export function MyWash({ route, navigation }: Props) {
  const { washingPlant } = route.params;
  const [divHeight, setDivHeight] = useState(0);

  const { data, isLoading, isRefetching, refetch, dataUpdatedAt } = useQuery(
    `${QueryKeyCache.GetWashes}-${washingPlant.id}`,
    () => getWashRecipes(washingPlant)
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      async function refreshData() {
        await refetch();
      }
      refreshData();
    });
    return unsubscribe;
  }, [navigation]);

  const washRecipes = data?.results || [];

  const isQueryLoading = isLoading || isRefetching;

  const onLayout = (event: any) => {
    const { height } = event.nativeEvent.layout;
    setDivHeight(height);
  };

  return (
    <Page>
      <VStack flex={1} justifyContent="space-between">
        <HStack justifyContent="space-between">
          <BackHeader
            onPress={() => navigation.navigate('Home')}
            mb="32px"
            backTitle
            buttonText={washingPlant.name}
          />

          <Icon
            as={<MaterialIcons name="edit" color="#368D7D" />}
            mt={1}
            onPress={() => navigation.push('WashingPlant', { washingPlant })}
            color="primary.500"
            size={26}
          />
        </HStack>

        <Box>
          <Box mt={6}>
            <Text textAlign="center" fontSize={22} fontWeight={700} color="primary.500">
              {t('domain.wash_recipes.my_analysis')}
            </Text>
            <Box width={'100%'} mt={3}>
              <Divider />
            </Box>
          </Box>
        </Box>

        <Box mt={'16px'} flexGrow={1} onLayout={onLayout}>
          <Box height={divHeight} pb="8px">
            <FlatList
              data={washRecipes}
              keyExtractor={(item) => item.id}
              ListEmptyComponent={() =>
                isQueryLoading ? (
                  <Loading />
                ) : (
                  <EmptyPlaceholder emptyText={t('domain.wash_recipes.empty')} marginTop={'70px'} />
                )
              }
              refreshControl={
                <RefreshControl
                  colors={['#d35a5a', '#9f3838']}
                  refreshing={isLoading || isRefetching}
                  onRefresh={refetch}
                />
              }
              renderItem={({ item, index }: { item: IJetWashRecipe; index: number }) => {
                const isLast = data?.results.length ? index === data?.results.length - 1 : false;
                return (
                  <Box mx={1} pb={'8px'} key={`${index}-washing-plant-card-${item.id}`}>
                    <WashAnalyseCard
                      washingPlant={washingPlant}
                      washRecipe={item}
                      onDelete={refetch}
                    />
                    {isLast && <Box height={10} />}
                  </Box>
                );
              }}
            />
          </Box>
        </Box>

        <Box mt="16px">
          <CustomButton
            color="white"
            variant={'solid'}
            onPress={() =>
              navigation.push('WashRecipeCreateOrEdit', {
                washingPlant,
                washRecipe: null,
              })
            }
          >
            {t('domain.wash_recipes.button.addNew')}
          </CustomButton>
        </Box>
      </VStack>
    </Page>
  );
}

export default MyWash;
