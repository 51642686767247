import { useNavigation } from '@react-navigation/native';
import { Box, View, VStack } from 'native-base';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import BackHeader from '../../../components/BackHeader';
// import FormNextButton from "../form/FormNextButton";
// import FormProgress from "../form/FormProgress";
// import { FormSteps } from "../form/FormSteps";
import { yupResolver } from '@hookform/resolvers/yup';
import { useStandardRecipeForm } from '../hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../types/standardRecipeForm.type';
import { object, SchemaOf, number, string, ObjectSchema, array } from 'yup';
import { FormSteps } from '../form/FormSteps';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { IJetWashRecipe } from '../../../types/wash-recipe.type';
// import FormSubmit from "../form/FormSubmit";

export const standardRecipeSchema: SchemaOf<IStandardRecipeForm> = object({
  washName: string().required(),
  cycles: array().of(object().shape({})),
});

interface Props {
  washingPlant: IJetWashingPlant;
  washRecipe: IJetWashRecipe | null;
}

export const StandardRecipeForm: React.FC<Props> = ({ washingPlant, washRecipe }) => {
  const [availableHeight, setAvailableHeight] = useState<number>(0);
  const { navigate } = useNavigation();
  const methods = useForm<IStandardRecipeForm>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      washName: washRecipe?.name,
      cycles: [
        {
          currentRecipe: {},
          newRecipe: {},
        },
      ],
    },
    resolver: yupResolver(standardRecipeSchema),
  });
  const { step, canShowBackStep, backStep, selectWashingPlant, goToStep } = useStandardRecipeForm(
    methods.control
  );

  useLayoutEffect(() => {
    if (washRecipe) {
      goToStep(2);
    }
  }, []);

  useLayoutEffect(() => {
    selectWashingPlant(washingPlant);
  }, [washingPlant]);

  const handleBackButton = () => {
    if (step === 1) navigate('Home');
    else backStep();
  };

  return (
    <FormProvider {...methods}>
      <VStack height="100%" justifyContent="space-between">
        {canShowBackStep && <BackHeader onPress={handleBackButton} backTitle />}

        {/* <Box width="100%" mb={10} mt={-4}>
          <FormProgress />
        </Box> */}

        <Box
          flexGrow={1}
          onLayout={(event) => {
            const { height } = event.nativeEvent.layout;
            setAvailableHeight(height - 16);
          }}
        >
          <View height={availableHeight}>
            <FormSteps />
          </View>
        </Box>
      </VStack>
    </FormProvider>
  );
};
