import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import AboutUsScreen from '../domains/aboutUs/AboutUs';
import AuthGuard from '../domains/auth/guards/AuthGuard';
import { useAuth } from '../domains/auth/hooks/useAuth';
import ProfileScreen from '../domains/auth/screens/Profile/ProfileScreen';
import CycleSelect from '../domains/cycleSelect/screens/CycleSelect';
import { HomeRoot } from '../domains/home/HomeRoot';
import { EnvironmentsScreen } from '../domains/knowMore/screens/Environments';
import SavingsScreen from '../domains/knowMore/screens/Savings';
import { MyRecipesScreen } from '../domains/standardRecipe/screens/MyRecipesScreen';
import { MyWash } from '../domains/standardRecipe/screens/MyWash';
import RecipeNeedHelp from '../domains/standardRecipe/screens/RecipeNeedHelp';
import StandardRecipeRoot from '../domains/standardRecipe/StandardRecipeRoot';
import WashCycleRoot from '../domains/washCycle/screens/WashCycleRoot';
import WashAuxiliaryRoot from '../domains/washCycle/sections/auxiliary/WashAuxiliaryRoot';
import WashChemicalRoot from '../domains/washCycle/sections/chemicals/WashChemicalRoot';
import WashLaserRoot from '../domains/washCycle/sections/laser/WashLaserRoot';
import WashingPlantSuccessRoot from '../domains/washingPlant/screens/WashingPlantSuccessRoot';
import WashingPlantRoot from '../domains/washingPlant/WashingPlantRoot';
import WashRecipeCreateOrEdit from '../domains/washRecipe/screens/WashRecipeCreateOrEdit';
import CycleOnboarding from '../domains/washRecipeAggregator/components/onboarding/CycleOnboarding';
import WashRecipeAggregatorProvider from '../domains/washRecipeAggregator/contexts/WashRecipeAggregatorContext';
import WashRecipeAggregator from '../domains/washRecipeAggregator/screens/WashRecipeAggregator';
import WashRecipeAggregatorRoot from '../domains/washRecipeAggregator/screens/WashRecipeAggregatorRoot';
import { ISelectRecipe } from '../domains/washRecipeAggregator/services/cycle.service';
import { IJetWashRecipe } from '../types/wash-recipe.type';
import { IJetWashingPlant } from '../types/washing-plants.type';
import { ICompleteWashInfoAggregators, IJetWashCycle } from '../types/wash_cycle.type';
import { IJetWashCycleAuxiliary } from '../types/wash_cycle_auxiliary.type';
import { IJetWashCycleChemical } from '../types/wash_cycle_chemical.type';
import { IJetWashCycleLaser } from '../types/wash_cycle_laser.type';
import {
  IJetWashRecipeAggregator,
  IJetWashRecipeAggregatorComplete,
  IJetWashRecipeAggregatorID,
} from '../types/wash_recipe_aggregator.type';

export type HomeStackParamList = {
  Home?: {};
  WashingPlant: {
    washingPlant: IJetWashingPlant | undefined;
  };
  WashingPlantSuccess: {
    washingPlant: IJetWashingPlant;
  };
  MyWashes: {
    washingPlant: IJetWashingPlant;
  };
  StandardRecipeRoot: {
    washingPlant: IJetWashingPlant;
    washRecipe: IJetWashRecipe | null;
  };
  RecipeHomeScreen: {
    washingPlant: IJetWashingPlant;
  };
  RecipeNeedHelp: {
    washAggregator?: IJetWashRecipeAggregatorID;
    washing_plant: IJetWashingPlant;
  };
  WashRecipeCreateOrEdit: {
    washingPlant: IJetWashingPlant;
    washRecipe: IJetWashRecipe | null;
  };
  WashRecipeAggregator: {
    washingPlant: IJetWashingPlant;
    washRecipe: IJetWashRecipe;
  };
  washRecipeCycleSelect: {
    washingPlant: IJetWashingPlant;
    washRecipe: IJetWashRecipe;
    washRecipeAggregator: IJetWashRecipeAggregator | null;
  };
  washRecipeCycle: {
    washingPlant: IJetWashingPlant;
    washRecipe: IJetWashRecipe;
    washRecipeAggregator: IJetWashRecipeAggregatorComplete;
    iSelectRecipe: ISelectRecipe;
  };
  washRecipeCycleChemicals: {
    cycle: IJetWashCycle;
    chemical: IJetWashCycleChemical | undefined;
    currentAggregator: ICompleteWashInfoAggregators | undefined;
    recipeName: "currentRecipe" | "greenRecipe" | undefined;
  };
  washRecipeCycleAuxiliarys: {
    cycle: IJetWashCycle;
    auxiliary: IJetWashCycleAuxiliary | undefined;
    currentAggregator: ICompleteWashInfoAggregators | undefined;
    recipeName: "currentRecipe" | "greenRecipe" | undefined;
  };
  washRecipeCycleLasers: {
    cycle: IJetWashCycle;
    laser: IJetWashCycleLaser | undefined;
    currentAggregator: ICompleteWashInfoAggregators | undefined;
    recipeName: "currentRecipe" | "greenRecipe" | undefined;
  };
  Profile: undefined;
  washRecipeCycleOnboarding: {
    washingPlant: IJetWashingPlant;
    washRecipe: IJetWashRecipe;
  };
  AboutUs: undefined;
  Savings: {
    totalFuel: number;
    totalEnergy: number;
    totalWater: number;
    totalTime: number;
    totalGreenTime: number;
  };
  Environments: {
    input: number;
    totalFuel: number;
    totalEnergy: number;
    totalWater: number;
    totalTime: number;
    totalGreenTime: number;
  };
};

const HomeStack = createNativeStackNavigator<HomeStackParamList>();

const HomeStackScreen = () => (
  <HomeStack.Navigator screenOptions={{ headerShown: false }}>
    <HomeStack.Screen name="Home" component={HomeRoot} />
    <HomeStack.Screen name="WashingPlant" component={WashingPlantRoot} />
    <HomeStack.Screen name="WashingPlantSuccess" component={WashingPlantSuccessRoot} />
    <HomeStack.Screen name="MyWashes" component={MyWash} />
    <HomeStack.Screen name="RecipeHomeScreen" component={MyRecipesScreen} />
    <HomeStack.Screen name="StandardRecipeRoot" component={StandardRecipeRoot} />
    <HomeStack.Screen name="RecipeNeedHelp" component={RecipeNeedHelp} />

    <HomeStack.Screen name="WashRecipeCreateOrEdit" component={WashRecipeCreateOrEdit} />
    <HomeStack.Screen name="WashRecipeAggregator" component={WashRecipeAggregatorRoot} />
    <HomeStack.Screen name="washRecipeCycleSelect" component={CycleSelect} />
    <HomeStack.Screen name="washRecipeCycleOnboarding" component={CycleOnboarding} />
    <HomeStack.Screen name="washRecipeCycle" component={WashCycleRoot} />
    <HomeStack.Screen name="washRecipeCycleChemicals" component={WashChemicalRoot} />
    <HomeStack.Screen name="washRecipeCycleAuxiliarys" component={WashAuxiliaryRoot} />
    <HomeStack.Screen name="washRecipeCycleLasers" component={WashLaserRoot} />

    <HomeStack.Screen name="Profile" component={ProfileScreen} />
    <HomeStack.Screen name="AboutUs" component={AboutUsScreen} />
    <HomeStack.Screen name="Savings" component={SavingsScreen} />
    <HomeStack.Screen name="Environments" component={EnvironmentsScreen} />
  </HomeStack.Navigator>
);

const AuthenticatedRoutes = () => {
  return (
    <AuthGuard>
      <WashRecipeAggregatorProvider>
        <HomeStackScreen />
      </WashRecipeAggregatorProvider>
    </AuthGuard>
  );
};

export default AuthenticatedRoutes;
