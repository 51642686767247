import React, { createContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import storage from '../../../config/storage';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { AUTH_TOKEN } from '../../../services/axiosService';
import { getUserById } from '../services/getUserById';
import { QueryClient } from 'react-query';
import { UserInterface } from '../types/IJetUser';
// 291120218971347 - 10315
interface AuthProviderInterface {
  user: UserInterface | undefined;
  setUser: React.Dispatch<React.SetStateAction<UserInterface | undefined>>;
  token: string | undefined;
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthProviderInterface>({
  user: undefined,
  setUser: () => console.warn('AuthDispatch is not ready'),
  token: undefined,
  setToken: () => console.warn('setToken is not ready'),
  logout: async () => console.warn('logout is not ready'),
});

const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useLocalStorage<string | undefined>({
    initialValue: undefined,
    key: AUTH_TOKEN,
  });
  const [user, setUser] = useLocalStorage<UserInterface | undefined>({
    initialValue: undefined,
    key: '@auth-user',
  });

  const queryClient = new QueryClient();

  async function logout() {
    await queryClient.invalidateQueries();
    queryClient.removeQueries();
    queryClient.clear();
    await queryClient.resetQueries()
    setToken(undefined);
    setUser(undefined);
    storage.remove({
      key: AUTH_TOKEN,
    });
  }

  useQuery(`user-${user?.id}`, () => getUserById(user?.id), {
    enabled: !!user?.id,
    // refetch in 60 minutes
    refetchInterval: 1000 * 60 * 60,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    onSuccess: (user) => {
      if (!user) logout();
      if (user?.active === false) logout();
    },
  });

  useEffect(() => {
    if (token) {
      storage.save({
        key: AUTH_TOKEN, // Note: Do not use underscore("_") in key!
        data: token,
        expires: null,
      });
    } else {
      queryClient.invalidateQueries();
      storage.remove({
        key: AUTH_TOKEN,
      });
    }
  }, [token]);

  const globals: AuthProviderInterface = {
    user,
    setUser,
    token,
    setToken,
    logout,
  };

  return <AuthContext.Provider value={globals}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
