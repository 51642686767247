import { IJetWashCycle } from "../../types/wash_cycle.type";
import { IJetWashingPlant } from "../../types/washing-plants.type";

export function getWashCycleDefaultValues(washCycle: IJetWashCycle, washingPlant?: IJetWashingPlant){
  return {
    ...washCycle,
    id: washCycle.id || undefined,
    type_of_cycle: washCycle.type_of_cycle || undefined,

    general_bath_ratio: washCycle.general_bath_ratio || undefined,
    general_temperature:
      washCycle.general_temperature || Number(washingPlant?.water_temperature),
    general_time: washCycle.general_time || undefined,

    add_auxiliary_equipment: washCycle.add_auxiliary_equipment || [],
    add_laser_scrapings: washCycle.add_laser_scrapings || [],
    chemicals: washCycle.chemicals || [],

    should_add_drying: washCycle.should_add_drying || false,
    extras_drying_time: washCycle.extras_drying_time || undefined,
    extras_drying_temperature: washCycle.extras_drying_temperature || undefined,

    extras_centrifuge_add: washCycle.extras_centrifuge_add || false,
    extras_centrifuge_pickup: washCycle.extras_centrifuge_pickup || undefined,
    extras_centrifuge_run_time: washCycle.extras_centrifuge_run_time || undefined,

    extras_rinses_add: washCycle.extras_rinses_add || false,
    rinses_amount_bath_ratio: washCycle.rinses_amount_bath_ratio || undefined,
    rinses_amount_time: washCycle.rinses_amount_time || undefined,
    amount_of_rinses: washCycle.amount_of_rinses || undefined,
  }
}