import { Text } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import FormInput from '../../../../../components/Input/FormInput';
import InputAddon from '../../../../../components/Input/InputAddon';
import { QueryKeyCache } from '../../../../../config/query-cache';
import { t } from '../../../../../languages';
import { useStandardRecipeForm } from '../../../hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../../../types/standardRecipeForm.type';

interface Step4TimeInputProps {}

export const Step4TimeInput: React.FC<Step4TimeInputProps> = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { getInputName } = useStandardRecipeForm(methods.control);

  const inputName = getInputName('general_time');

  return (
    <>
      <FormInput
        methods={methods}
        name={inputName}
        label={t('domain.analysis.step4.section.general_information.input.time.label')}
        placeholder={t('domain.analysis.step4.section.general_information.input.time.placeholder')}
        inputProps={{
          keyboardType: 'numeric',
          maxLength: 6,
          InputRightElement: <InputAddon str={t('domain.units.time.minutes')} />,
        }}
      />
    </>
  );
};

export default Step4TimeInput;
