import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Box, FlatList, HStack, ScrollView, Text, View, VStack } from 'native-base';
import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import EmptyCycles from '../../../assets/svgs/empty-cycles';
import BackHeader from '../../../components/BackHeader';
import { Page } from '../../../components/Page';
import { PageWithScroll } from '../../../components/PageWithScroll';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { CardEnvironment, CardEnvironmentProps } from '../components/CardEnvironment';
// import { Tree } from '../svgs/Icons'

type EnvironmentsScreenProps = NativeStackScreenProps<HomeStackParamList, 'Environments'>;

const DAYS_BY_MONTH = 22;
const KW_BY_MONTH = 90;
const DAYS_BY_YEAR = 264;
const GRAMS_BY_YEAR = 21800;
const LITERS_BY_YEAR = 1619000;
const M3_BY_YEAR = LITERS_BY_YEAR / 1000;
const LITERS_BY_KG = 1300;


export const EnvironmentsScreen: React.FC<EnvironmentsScreenProps> = ({ navigation, route }) => {
  const { input, totalEnergy, totalFuel, totalTime, totalWater, totalGreenTime } = route.params;

  const _cards: CardEnvironmentProps[] = [
    {
      calc: ((totalFuel * input * DAYS_BY_YEAR) / GRAMS_BY_YEAR).toFixed(0),
      imageType: 'tree',
      text: t('domain.knowMore.enviroments.cards.tree'),
      savings: (totalFuel * input * DAYS_BY_YEAR / 1000).toFixed() + " Kg of CO2",
      savingsColor: '#8bb995'
    },
    {
      calc: ((totalWater * input * DAYS_BY_YEAR) / LITERS_BY_YEAR).toFixed(0),
      imageType: 'people',
      text: t('domain.knowMore.enviroments.cards.people'),
      savings: ((totalWater * input * DAYS_BY_YEAR) / 1000).toFixed() + " m³ of Water",
      savingsColor: '#79c4ec'
    },
    {
      calc: ((totalWater * input * DAYS_BY_YEAR) / LITERS_BY_KG).toFixed(0),
      imageType: 'wheat',
      unit: 'kg',
      text: t('domain.knowMore.enviroments.cards.wheat'),
      savings: ((totalWater * input * DAYS_BY_YEAR) / 1000).toFixed() + " m³ of Water",
      savingsColor: '#79c4ec'
    },
    {
      calc: ((totalEnergy * input * DAYS_BY_MONTH) / KW_BY_MONTH).toFixed(0),
      imageType: 'lamp',
      text: t('domain.knowMore.enviroments.cards.lamp'),
      savings: (totalEnergy * input * DAYS_BY_YEAR).toFixed() + " kWh of Energy",
      savingsColor: '#f9be7e'
    },
    {
      calc: ((totalTime * input * DAYS_BY_YEAR) / totalGreenTime).toFixed(0),
      imageType: 'washing',
      text: t('domain.knowMore.enviroments.cards.washing'),
      savings: (totalTime * input * DAYS_BY_YEAR / 60).toFixed() + " hours",
      savingsColor: '#be86d8'
    },
  ];

  const cards = _cards.filter(({ calc }) => calc > 0);
  const hascards = cards.length > 0;

  return (
    <PageWithScroll>
      <Box mt={2}>
      <BackHeader backTitle buttonText={t('domain.knowMore.enviroments.backTitle')} mb={5} />
      </Box>
      <VStack justifyContent="flex-start" flex={1}>
        <Box mb={10}>
          <Text fontSize={26} lineHeight={30} fontWeight={500}>
            {t('domain.knowMore.enviroments.title_part_one')}
            <Text>
              {' '}
              <Text fontSize={26} lineHeight={30} fontWeight="bold" color="primary.500">
                {t('domain.knowMore.enviroments.title_part_two')}
              </Text>
            </Text>
          </Text>
          {!hascards && (
            <Box mt={100} mx={5}>
              <EmptyPlaceholder 
                imgComponent={<EmptyCycles />} 
                emptyText={t('domain.knowMore.enviroments.fallback')} 
              /> 
            </Box>
          )}
        </Box>
        {hascards && (
          <FlatList
            height="100%"
            showsVerticalScrollIndicator={true}
            data={cards}
            keyExtractor={(item, index) => `FlatInfo-${index}`}
            ItemSeparatorComponent={() => <Box height="16px" />}
            renderItem={({ item }) => <CardEnvironment
              calc={item.calc}
              imageType={item.imageType}
              text={item.text}
              unit={item.unit}
              savings={convertNumberToDots(item.savings)}
              savingsColor={item.savingsColor}
            />}
          />
        )}
      </VStack>
    </PageWithScroll>
  );
};

export default EnvironmentsScreen;

function convertNumberToDots(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}