import { ICompleteWashInfo } from "../types/wash_cycle.type";
import { IJetWashCycleAuxiliary } from "../types/wash_cycle_auxiliary.type";
import { IJetWashCycleChemical } from "../types/wash_cycle_chemical.type";
import { IJetWashCycleLaser } from "../types/wash_cycle_laser.type";

export function formatDataToUpdateChemicals(
  aggregatorId: string,
  cycle: 'currentRecipe' | 'greenRecipe',
  itemToRemove: IJetWashCycleAuxiliary | IJetWashCycleChemical | IJetWashCycleLaser,
  completeWashInformation: ICompleteWashInfo,
){
  return {
    ...completeWashInformation,
    aggregators: completeWashInformation?.aggregators?.map((aggregator) => {
      return aggregator.aggregator_id === aggregatorId
        ? {
          ...aggregator,
          [cycle]: {
            ...aggregator[cycle],
            chemicals: aggregator[cycle]?.chemicals?.filter(
              (chemical) => chemical !== itemToRemove.id
            ),
            cycle_chemicals_details: aggregator[cycle]?.cycle_chemicals_details?.filter(
              (chemical) => chemical.id !== itemToRemove.id
            ) || [],
          }
        }
        : aggregator;
    }),
  };
}

export function formatDataToUpdateAuxilliary(
  aggregatorId: string,
  cycle: 'currentRecipe' | 'greenRecipe',
  itemToRemove: IJetWashCycleAuxiliary | IJetWashCycleChemical | IJetWashCycleLaser,
  completeWashInformation: ICompleteWashInfo,
){
  return {
    ...completeWashInformation,
    aggregators: completeWashInformation?.aggregators?.map((aggregator) => {
      return aggregator.aggregator_id === aggregatorId
        ? {
          ...aggregator,
          [cycle]: {
            ...aggregator[cycle],
            add_auxiliary_equipment: aggregator[cycle]?.add_auxiliary_equipment?.filter(
              (object) => object !== itemToRemove.id
            ) || [],
            cycle_auxiliary_equipments_details: aggregator[cycle]?.cycle_auxiliary_equipments_details?.filter(
              (item: any) => item.id !== itemToRemove.id
            ) || [],
          }
        }
        : aggregator
    }),
  };
};

export function formatDataToUpdateLaser(
  aggregatorId: string,
  cycle: 'currentRecipe' | 'greenRecipe',
  itemToRemove: IJetWashCycleAuxiliary | IJetWashCycleChemical | IJetWashCycleLaser,
  completeWashInformation: ICompleteWashInfo,
){
  return {
    ...completeWashInformation,
    aggregators: completeWashInformation?.aggregators?.map((aggregator) => {
      return aggregator.aggregator_id === aggregatorId
        ? {
          ...aggregator,
          [cycle]: {
            ...aggregator[cycle],
            add_laser_scrapings: aggregator[cycle]?.add_laser_scrapings?.filter(
              (laser) => laser !== itemToRemove.id
            ),
            cycle_laser_scrapings_details: aggregator[cycle]?.cycle_laser_scrapings_details?.filter(
              (laser) => laser.id !== itemToRemove.id
            ) || [],
          }
        }
        : aggregator
    }),
  };
}

export function formatDataToCalc(completeWashInformation: ICompleteWashInfo){
  return completeWashInformation.aggregators.map((aggregator) => ({
    washingPlant: completeWashInformation.washingPlant,
    country: completeWashInformation.country,
    boiler: completeWashInformation.boiler,
    centrifuge: completeWashInformation.centrifuge,
    washingMachine: completeWashInformation.washingMachine,
    dryer: completeWashInformation.dryer,
    cycle_type: aggregator.cycle_type,
    currentRecipe: aggregator.currentRecipe,
    greenRecipe: aggregator.greenRecipe,
    aggregatorID: aggregator.aggregator_id,
    wash_recipe_info: completeWashInformation.wash_recipe_info
  }))
}