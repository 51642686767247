import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetHocs, IJetHocsID } from '../../../types/hocs';

export interface IJetHocsList extends IJetAdminResponseProtocol<IJetHocs[]> {}

export async function getHoc(id: IJetHocsID): Promise<IJetHocs | undefined> {
  const res = await jetAdmin.retrieve<IJetHocs>(JetAdminCollection.Hocs, id);

  return res;
}

export async function getHocs(): Promise<IJetHocsList> {
  const res = await jetAdmin.list<IJetHocs[]>(JetAdminCollection.Hocs);

  return res;
}
