import { extendTheme } from 'native-base';
import colors from './colors';
import components from './components';
import { fontConfig, fonts } from './fonts';

const customTheme = extendTheme({
  colors,
  fontConfig: {
    ...fontConfig,
  },
  fonts: fonts,
  components: {
    Text: {
      defaultProps: {
        color: '#6F7370',
        // fontFamily: "CircularXX TT",
        fontSize: '18px',
        fontWeight: 450,
        lineHeight: '22px',
        letterSpacing: '0em',
        textAlign: 'left',
      },
    },
    Heading: {
      defaultProps: {
        color: '#409D27',
        // fontFamily: "CircularXX TT",
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '0em',
        textAlign: 'left',
      },
    },
    Link: {
      defaultProps: {
        fontSize: '14px',
      },
    },
    Button: {
      variants: {
        link: {
          _text: {
            textDecorationLine: 'none',
            fontWeight: 700,
          },
          // remove underline
          // textDecorationLine: 'none',
          // fontWeight: 900,
        },
      },
      defaultProps: {
        boxShadow: '0px 0px 5px rgba(60, 65, 120, 0.14)',
      },
    },
  },
});

export default customTheme;

type CustomThemeType = typeof customTheme;

declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}
