import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { deleteUser, getAuth } from 'firebase/auth';
import { Flex, ScrollView, VStack } from 'native-base';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, SchemaOf, string } from 'yup';
import BackHeader from '../../../../components/BackHeader';
import Confirm from '../../../../components/Confirm';
import { CustomButton } from '../../../../components/CustomButton';
import FormInput from '../../../../components/Input/FormInput';
import { Page } from '../../../../components/Page';
import SectionTitle from '../../../../components/SectionTitle';
import { t } from '../../../../languages';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { toast } from '../../../../utils/toast';
import { useAuth } from '../../hooks/useAuth';
import { editUserOnJetAdmin } from '../../services/createUserOnJetAdmin';
import { UserInterface } from '../../types/IJetUser';
import { supabase, supabaseAdmin } from '../../../../../supabase';

interface ProfileScreenProps {}

interface IUserForm
  extends Omit<UserInterface, 'id' | 'active' | 'created_at' | 'updated_at' | 'authenticator_id'> {}

export const ProfileScreen: React.FC<ProfileScreenProps> = () => {
  const { navigate } = useNavigation<NativeStackNavigationProp<GuestRootStackParamList>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

  const schema: SchemaOf<IUserForm> = object({
    name: string()
      .required()
      .label(t('domain.signUp.profile.section.personal_information.name.label')),

    email: string()
      .required()
      .label(t('domain.signUp.profile.section.personal_information.email.label')),

    phone: string()
      .required()
      .label(t('domain.signUp.profile.section.personal_information.phone.label')),
  });

  const { user, logout } = useAuth();

  const methods = useForm<IUserForm>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      name: user?.name || undefined,
      email: user?.email || undefined,
      phone: user?.phone || undefined,
    },
    resolver: yupResolver(schema),
  });

  async function deleteAccount() {
    setIsLoading(true);

    try {
      const { data } = await supabase.auth.getUser();
      await supabaseAdmin.auth.admin.deleteUser(data.user?.id ?? '');
      await editUserOnJetAdmin({ id: user?.id, active: false });
      logout();
    } catch (error) {
      toast(t('domain.signUp.profile.section.delete.modal.error'));
    } finally {
      setIsLoading(false);
      setShowModalConfirm(false);
    }
  }

  return (
    <Page>
      <BackHeader backTitle mb={0} />
      <ScrollView>
        <VStack justifyContent="space-between">
          <Flex>
            <SectionTitle title={t('domain.signUp.profile.section.personal_information.title')} />
            <VStack space="8px">
              <FormInput
                isReadOnly
                methods={methods}
                name={'name'}
                label={t('domain.signUp.profile.section.personal_information.name.label')}
                placeholder={t(
                  'domain.signUp.profile.section.personal_information.name.placeholder'
                )}
                isLoading={isLoading}
              />
              <FormInput
                isReadOnly
                methods={methods}
                name={'email'}
                label={t('domain.signUp.profile.section.personal_information.email.label')}
                placeholder={t(
                  'domain.signUp.profile.section.personal_information.email.placeholder'
                )}
                isLoading={isLoading}
              />
              <FormInput
                isReadOnly
                methods={methods}
                name={'phone'}
                label={t('domain.signUp.profile.section.personal_information.phone.label')}
                placeholder={t(
                  'domain.signUp.profile.section.personal_information.phone.placeholder'
                )}
                isLoading={isLoading}
              />

              {/* <CustomButton
            variant={'solid'}
            onPress={handleSubmit(onSubmit)}
            isDisabled={true}
          >
            {t('domain.generic.save')}
          </CustomButton> */}
            </VStack>

            {/* <SectionTitle title={t('domain.signUp.profile.section.security.title')} /> */}
          </Flex>

          <Flex>
            <SectionTitle title={t('domain.aboutUs.section.aboutUs.title')} />

            <CustomButton
              variant={'outline'}
              bgColor="primary.500"
              onPress={() => navigate('AboutUs')}
              isDisabled={isLoading}
            >
              {`greenofchange®`}
            </CustomButton>
          </Flex>

          <Flex>
            <SectionTitle title={t('domain.signUp.profile.section.delete.title')} />

            <CustomButton
              variant={'solid'}
              bgColor="red.600"
              onPress={() => setShowModalConfirm(true)}
              isDisabled={isLoading}
            >
              {t('domain.signUp.profile.section.delete.button')}
            </CustomButton>
          </Flex>
        </VStack>
      </ScrollView>
      <Confirm
        showButton={false}
        setOpenModal={setShowModalConfirm}
        onConfirm={deleteAccount}
        buttonText={t('domain.signUp.profile.section.delete.modal.title')}
        button={{ mb: 3 }}
        message={`${t('domain.signUp.profile.section.delete.modal.message')}`}
        title={`${t('domain.signUp.profile.section.delete.modal.title')}`}
        onConfirmToastMessage={t('domain.signUp.profile.section.delete.modal.success')}
        setShowModal={setShowModalConfirm}
        openModal={showModalConfirm}
        isLoading={isLoading}
      />
    </Page>
  );
};

export default ProfileScreen;
