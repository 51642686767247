import React from 'react';
import { NativeBaseProvider, StatusBar } from 'native-base';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Localization from 'expo-localization';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import i18n from 'i18n-js';
import { LogBox, Platform, SafeAreaView, StyleSheet } from 'react-native';
import { enUS } from './src/languages/enUS';
import { ptBR } from './src/languages/ptBR';
import { DefaultLanguage, Languages } from './src/languages/config';
import theme from './src/theme';
import Navigation from './src/routes';
import useColorScheme from './src/hooks/useColorScheme';
import {
  useFonts,
  Montserrat_100Thin,
  Montserrat_200ExtraLight,
  Montserrat_300Light,
  Montserrat_400Regular,
  Montserrat_500Medium,
  Montserrat_600SemiBold,
  Montserrat_700Bold,
  Montserrat_800ExtraBold,
  Montserrat_900Black,
  Montserrat_100Thin_Italic,
  Montserrat_200ExtraLight_Italic,
  Montserrat_300Light_Italic,
  Montserrat_400Regular_Italic,
  Montserrat_500Medium_Italic,
  Montserrat_600SemiBold_Italic,
  Montserrat_700Bold_Italic,
  Montserrat_800ExtraBold_Italic,
  Montserrat_900Black_Italic,
} from '@expo-google-fonts/montserrat';
import { RootSiblingParent } from 'react-native-root-siblings';
import useCachedResources from './src/hooks/useCachedResources';
import AppLoading from 'expo-app-loading';
import AuthProvider from './src/domains/auth/contexts/AuthContext';
import LanguageProvider from './src/languages/LanguageProvider';
require("setimmediate")

if (Platform.OS === 'android') {
  // only android needs polyfill
  require('intl'); // import intl object
  require('intl/locale-data/jsonp/en-US');
  require('intl/locale-data/jsonp/pt-BR'); // load the required locale details
}

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: undefined,
        staleTime: 5000,
        // refetchOnMount: Platform.OS === "web" ? false : "always",
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  i18n.defaultLocale = DefaultLanguage;
  i18n.fallbacks = true;

  i18n.translations = {
    [Languages.enUS]: enUS,
    [Languages.ptBR]: ptBR,
  };

  i18n.locale = Localization.locale;

  const styles = StyleSheet.create({
    safeArea: {
      flex: 1,
      color: '#F2F8F7',
      backgroundColor: '#F2F8F7',
    },
  });

  const [fontsLoaded] = useFonts({
    Montserrat_100Thin,
    Montserrat_200ExtraLight,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_600SemiBold,
    Montserrat_700Bold,
    Montserrat_800ExtraBold,
    Montserrat_900Black,
    Montserrat_100Thin_Italic,
    Montserrat_200ExtraLight_Italic,
    Montserrat_300Light_Italic,
    Montserrat_400Regular_Italic,
    Montserrat_500Medium_Italic,
    Montserrat_600SemiBold_Italic,
    Montserrat_700Bold_Italic,
    Montserrat_800ExtraBold_Italic,
    Montserrat_900Black_Italic,
  });

  LogBox.ignoreLogs([
    'Invalid props.style',
    'contrast ratio',
    'timer',
    "Linking requires a build-time setting `scheme` in the project's Expo config (app.config.js or app.json) for production apps, if it's left blank, your app may crash",
    'When server rendering, you must wrap your application in an <SSRProvider> to ensure consistent ids are generated between the client and server.',
    'Non-serializable values were found in the navigation state.',
    "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.",
    'expo-app-loading',
  ]);

  if (!isLoadingComplete || !fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <LanguageProvider>
        <RootSiblingParent>
          <SafeAreaView style={styles.safeArea}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <NativeBaseProvider theme={theme}>
                  <ActionSheetProvider>
                    <Navigation colorScheme={colorScheme} />
                  </ActionSheetProvider>
                  <StatusBar backgroundColor="#F2F8F7" barStyle="dark-content" />
                </NativeBaseProvider>
              </AuthProvider>
            </QueryClientProvider>
          </SafeAreaView>
        </RootSiblingParent>
      </LanguageProvider>
    );
  }
}
