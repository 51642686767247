export const WATER_COST_M3 = 0.4;

// Specific heat capacity of water	4186	J/kg°C
export const WATER_SPEC_HEAT_JKG = 4186;

// Specific heat capacity of air	993	J/kg°C
export const AIR_SPEC_HEAT_JKG = 993;

// Molar Mass (CO2)	44,01	g/mol 
export const CO2_MOLAR_MASS_GMOL = 44.01;

//  Wood CO2 - CO2/g  
export const WOOD_CO2_G = 1.8;

// 1 m3 of Air equals to 	1,2754	Kg
export const AIR_DENSITY_KG = 1.2754;

// Heat of combustion (CH4)	802,34	KJ/mol
export const CH4_HEAT_KJ_MOL = 802.34;

// Molar Mass (CH4)	16,04	g/mol
export const CH4_MOLAR_MASS_GMOL = 16.04;

