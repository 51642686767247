import { SchemaOf, array, boolean, number, object, string, addMethod } from "yup";
import { t } from '../../languages';
import { ICompleteWashInfo, IJetWashCycle } from "../../types/wash_cycle.type";

export function getWashCycleSchema(completeWashInformation: ICompleteWashInfo){
  addMethod(number, "minWaterTemperature", function (errorMessage) {
    return this.test(`test-min-water-temperature`, errorMessage, function (value) {
      const { path, createError } = this;
      const water_temperature = value || 0
  
      return (
        (water_temperature >= Number(completeWashInformation?.washingPlant.water_temperature)) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  addMethod(string, "minAirTemperature", function (errorMessage) {
    return this.test(`test-min-air-temperature`, errorMessage, function (value) {
      const { path, createError } = this;
      const extras_drying_temperature = value || 0

      return (
        (Number(extras_drying_temperature) >= Number(completeWashInformation?.washingPlant.air_temperature)) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const schema: SchemaOf<IJetWashCycle> = object({
    general_bath_ratio: number()
    .required()
    .label(t('domain.analysis.step4.section.general_information.input.bath_ratio.label')),

    general_temperature: number()
      .required()
      .min(0)
      .max(300)
      .minWaterTemperature(`${t('domain.analysis.step4.section.general_information.input.temperature.minTemperatureError')} ${completeWashInformation?.washingPlant?.water_temperature}˚C`)
      .label(t('domain.analysis.step4.section.general_information.input.temperature.label')),

    general_time: number()
      .required()
      .label(t('domain.analysis.step4.section.general_information.input.time.label')),

    extras_centrifuge_add: boolean(),
    extras_centrifuge_run_time: string().nullable().notRequired()
      .when('extras_centrifuge_add', {
        is: true,
        then: string().required().min(0).max(300),
      })
      .label(t('domain.analysis.step4.section.extras.subsection.centrifuge.input.pickup.label')),

    extras_centrifuge_pickup: string().nullable().notRequired()
      .when('extras_centrifuge_add', {
        is: true,
        then: string().required().min(0).max(300),
      })
      .label(t('domain.analysis.step4.section.extras.subsection.centrifuge.input.run_time.label')),

    extras_rinses_add: boolean(),
    rinses_amount_bath_ratio: string().nullable().notRequired()
      .when('extras_rinses_add', {
        is: true,
        then: string().required().min(0).max(300),
      })
      .label(t('domain.analysis.step4.section.extras.subsection.rinses.input.bath_ratio.label')),

    rinses_amount_time: string().nullable().notRequired()
      .when('extras_rinses_add', {
        is: true,
        then: string().required().min(0).max(300),
      })
      .label(t('domain.analysis.step4.section.extras.subsection.rinses.input.time.label')),

    amount_of_rinses: string().nullable().notRequired()
      .when('extras_rinses_add', {
        is: true,
        then: string().required().min(0).max(300),
      })
      .label(t('domain.analysis.step4.section.extras.subsection.rinses.input.rinses_amount.label')),

    should_add_drying: boolean(),
    extras_drying_time: string().nullable().notRequired()
      .when('should_add_drying', {
        is: true,
        then: string().required().min(0).max(300),
      })
      .label(
        t('domain.analysis.step4.section.extras.subsection.centrifuge.drying.input.run_time.label')
      ),

    // extras_drying_temperature < washingPlant?.air_temperature = error
    extras_drying_temperature: string().nullable().notRequired()
      .when('should_add_drying', {
        is: true,
        then: 
          string()
          .min(0)
          .max(300)
          .minAirTemperature(`${t('domain.analysis.step4.section.extras.subsection.centrifuge.drying.input.temperature.minTemperatureError')} ${completeWashInformation?.washingPlant?.air_temperature}˚C`)
          .required()
      })
      .label(
        t(
          'domain.analysis.step4.section.extras.subsection.centrifuge.drying.input.temperature.label'
        )
      ),

    add_auxiliary_equipment: array().notRequired(),
    add_laser_scrapings: array().notRequired(),
    chemicals: array().notRequired(),
    id: string().notRequired(),
    type_of_cycle: string().required(),
  });

  return schema;
};