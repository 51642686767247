import { useNavigation } from '@react-navigation/native';
import { Box, Text, View, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import BackHeader from '../../../components/BackHeader';
import FormNextButton from '../form/FormNextButton';
import FormProgress from '../form/FormProgress';
import { FormSteps } from '../form/FormSteps';
import { yupResolver } from '@hookform/resolvers/yup';
import { useWashingPlantForm } from '../hooks/useWashingPlantForm';
import { IWashingPlantForm } from '../types/washingPlantForm.type';
import { object, SchemaOf, number, string } from 'yup';
import FormSubmit from '../form/FormSubmit';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { t } from '../../../languages';
import { Platform } from 'react-native';

interface IWashingPlantFormProps {
  washingPlant: IJetWashingPlant | undefined;
}

export const WashingPlantForm: React.FC<IWashingPlantFormProps> = ({ washingPlant }) => {
  const [availableHeight, setAvailableHeight] = useState<number>(0);
  const { navigate } = useNavigation();
  const { step, goToStep, backStep } = useWashingPlantForm();

  const washingPlantSchema: SchemaOf<IWashingPlantForm> = object({
    id: string().optional().notRequired(),
    overallLoad: number()
      .required()
      .label(t('domain.washingPlants.step.three.input.overall.label')),
    numberOfGarments: number()
      .min(1)
      .required()
      .label(t('domain.washingPlants.step.three.input.garments.label')),
    washingPlantName: string()
      .required()
      .label(t('domain.washingPlants.step.one.input.placeholder')),
    selectedCountryID: string()
      .required()
      .label(t('domain.washingPlants.step.two.input.placeholder')),
    selectedWashingMachineID: string().required(t('domain.form.validation.error.required')),
    waterTemperature: number().min(0).required(t('domain.form.validation.error.required')),
    airTemperature: number().min(0).required(t('domain.form.validation.error.required')),
    selectedCentrifugeID: string().required(t('domain.form.validation.error.required')),
    selectedBoilerID: string().required(t('domain.form.validation.error.required')),
    selectedDryerID: string().required(t('domain.form.validation.error.required')),
  });

  const methods = useForm<IWashingPlantForm>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      id: washingPlant?.id,
      washingPlantName: washingPlant?.name,
      waterTemperature: washingPlant?.water_temperature || 24,
      airTemperature: washingPlant?.air_temperature || 30,
      numberOfGarments: washingPlant?.number_of_garments,
      overallLoad: washingPlant?.overall_load,
      selectedBoilerID: washingPlant?.boiler,
      selectedCentrifugeID: washingPlant?.centrifuge,
      selectedCountryID: washingPlant?.country,
      selectedDryerID: washingPlant?.dryer,
      selectedWashingMachineID: washingPlant?.washing_machine,
    },
    resolver: yupResolver(washingPlantSchema),
  });

  useEffect(() => {
    if (washingPlant?.id) {
      goToStep(10);
    }
  }, []);

  const handleBackButton = () => {
    if (step === 1) navigate('Home');
    else backStep();
  };

  return (
    <FormProvider {...methods}>
      <VStack height="100%" justifyContent="space-between">
        <BackHeader onPress={handleBackButton} backTitle />

        <Box width="100%" mb={5} mt={-4}>
          <FormProgress />
        </Box>

        <Box
          flexGrow={1}
          mb={Platform.OS === 'web' ? 10 : 0}
          onLayout={(event) => {
            const { height } = event.nativeEvent.layout;
            setAvailableHeight(height - 16);
          }}
          height="80%"
        >
          <View height={step === 10 ? availableHeight : '95%'}>
            <FormSteps />
          </View>
        </Box>

        {Platform.OS === 'web' ? (
          <Box position="fixed" bottom={0} width="100%" justifyContent="center" left={0}>
            {step === 10 ? <FormSubmit /> : <FormNextButton />}
          </Box>
        ) : (
          <>{step === 10 ? <FormSubmit /> : <FormNextButton />}</>
        )}
      </VStack>
    </FormProvider>
  );
};
