import { Text, Box, FormControl } from 'native-base';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '../../../components/Input';
import FormInput from '../../../components/Input/FormInput';
import { t } from '../../../languages';
import { washingPlantSchema } from '../screens/WashingPlantForm';
import { IWashingPlantForm } from '../types/washingPlantForm.type';

interface WashingFormStep1Props {}

export const WashingFormStep1: React.FC<WashingFormStep1Props> = () => {
  const methods = useFormContext<IWashingPlantForm>();

  return (
    <>
      <Box>
        <Text fontSize={22} fontWeight="bold" color="primary.500">
          {t('domain.washingPlants.step.one.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={22} fontWeight={500}>
              {t('domain.washingPlants.step.one.title_part_two')}
            </Text>
          </Text>
        </Text>

        <Box mt={8}>
          <FormInput
            methods={methods}
            name={'washingPlantName'}
            placeholder={t('domain.washingPlants.step.one.input.placeholder')}
          />
        </Box>
      </Box>
    </>
  );
};

export default WashingFormStep1;
