import React from 'react';
import { Text } from 'native-base';
import { StandardRecipeFormStep, useStandardRecipeForm } from '../hooks/useStandardRecipeForm';
import RecipeFormStep1 from './RecipeFormStep1';
import RecipeFormStep2 from './RecipeFormStep2';
import RecipeFormStep3 from './RecipeFormStep3';
import { useFormContext } from 'react-hook-form';
import { IStandardRecipeForm } from '../types/standardRecipeForm.type';
import RecipeFormStep4 from './RecipeFormStep4';
import RecipeFormStep5 from './RecipeFormStep5';
import RecipeFormStep6 from './RecipeFormStep6';
import RecipeFormStep7 from './RecipeFormStep7';

// import RecipeFormStep5 from "./RecipeFormStep5";
// import RecipeFormStep6 from "./RecipeFormStep6";

export const FormSteps: React.FC = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { step } = useStandardRecipeForm(methods.control);

  switch (step) {
    case StandardRecipeFormStep.Step1WashName:
      return <RecipeFormStep1 />;
    case StandardRecipeFormStep.Step2Cycles:
      return <RecipeFormStep2 />;
    case StandardRecipeFormStep.Step3WhatTypeOfCycle:
      return <RecipeFormStep3 />;
    case StandardRecipeFormStep.Step4Cycle:
      return <RecipeFormStep4 />;

    case StandardRecipeFormStep.Step5Chemicals:
      return <RecipeFormStep5 />;
    case StandardRecipeFormStep.Step6AuxiliaryEquipment:
      return <RecipeFormStep6 />;
    case StandardRecipeFormStep.Step7LaserScraping:
      return <RecipeFormStep7 />;

    default:
      return <RecipeFormStep1 />;
  }
};
