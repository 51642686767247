import React, { ReactNode } from 'react';
import { useAuth } from '../hooks/useAuth';

interface GuestGuardInterface {
  children: ReactNode
}

const GuestGuard: React.FC<GuestGuardInterface> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return !isAuthenticated ? <>{children}</> : null;
};

export default GuestGuard;
