import { IJetTAuxiliaryEquipment } from '../../types/auxiliary_equipment.type';
import { IJetCentrifuge } from '../../types/centrifuge.type';
import { IJetCountry } from '../../types/country.type';
import { IJetDryer } from '../../types/dryer.type';
import { IJetTLaserScraping } from '../../types/laser_scraping.type';
import { IJetWashingMachine } from '../../types/washing-machine.type';
import { IJetWashingPlant } from '../../types/washing-plants.type';
import { IJetWashCycle, IJetWashCycleComplete } from '../../types/wash_cycle.type';
import { IJetWashCycleAuxiliary } from '../../types/wash_cycle_auxiliary.type';
import { IJetWashCycleLaser } from '../../types/wash_cycle_laser.type';
import { calcAuxiliaryEnergy } from '../cycle/auxiliary.calc';
import { calcCentrifugeEnergy } from '../cycle/centrifuge.calc';
import { calcDryerEnergy } from '../cycle/dryer.calc';
import { calcLaserEnergy } from '../cycle/laser.calc';
import { calcPercent, DEFAULT_FALLBACK } from './calc-percent.calc';
import { ICalcResponse } from './calc.type';

interface ICalcTotalCostEnergy {
  washMachine: IJetWashingMachine | undefined;
  currentCycle: Partial<IJetWashCycleComplete> | undefined;
  greenCycle: Partial<IJetWashCycleComplete> | undefined;
  washingPlant: IJetWashingPlant | undefined;
  centrifuge: IJetCentrifuge | undefined;
  dryer: IJetDryer | undefined;
  country: IJetCountry | undefined;
}

interface ICalcPerCycle extends ICalcTotalCostEnergy {
  type: 'current' | 'green';
}

export function calcTotalAbsoluteEnergy(props: ICalcTotalCostEnergy): ICalcResponse {
  if (props.currentCycle === undefined || props.greenCycle === undefined) return DEFAULT_FALLBACK;

  try {
    const current = calcAbsoluteEnergyPerCycle({
      ...props,
      type: 'current',
    });

    const green = calcAbsoluteEnergyPerCycle({
      ...props,
      type: 'green',
    });

    const co2 = calcCO2EnergyPerCycle(
      { ...props, type: 'current' },
      props.country,
      props.washingPlant
    );

    const _percent = calcPercent({ current, green });

    return {
      current: current,
      green: green,
      percent: _percent === -1 ? '---%' : `${_percent?.toFixed(0)}%`,
      totalCost: co2,
      total: current - green,
      percentNumber: _percent,
    };
  } catch (error) {
    return DEFAULT_FALLBACK;
  }
}

function calcAbsoluteEnergyPerCycle(props: ICalcPerCycle) {
  if (
    props.washMachine === undefined ||
    props.centrifuge === undefined ||
    props.dryer === undefined ||
    props.washingPlant === undefined
  ) {
    return -1;
  }

  const cycle = props.type === 'current' ? props.currentCycle : props.greenCycle;

  if (!cycle?.cycle_auxiliary_equipments_details || !cycle.cycle_laser_scrapings_details) {
    return -1;
  }

  const _axEnergy = cycle.cycle_auxiliary_equipments_details.reduce(
    (acc, auxEqp) => (acc += calcAuxiliaryEnergy({ auxEqp })),
    0
  );

  const _laser = cycle.cycle_laser_scrapings_details.reduce(
    (acc, laser) => (acc += calcLaserEnergy({ laser, washingPlant: props.washingPlant })),
    0
  );

  // const auxEqp = cycle.cycle_auxiliary_equipments_details[0];
  // const laser = cycle.cycle_laser_scrapings_details[0];
  const _wEnergy = parseFloat(`${props?.washMachine?.energy_kw || 0}`);
  const _wTime = parseFloat(`${cycle?.general_time || 0}`);
  const _rinses = parseFloat(`${cycle?.amount_of_rinses || 0}`);
  const _time = parseFloat(`${cycle?.rinses_amount_time || 0}`);

  // const _axEnergy = calcAuxiliaryEnergy({ auxEqp });

  const _cEnergy = calcCentrifugeEnergy({
    centrifuge: props.centrifuge,
    cycle,
  });

  const _dryer = calcDryerEnergy({
    dryer: props.dryer,
    cycle,
  });

  // const _laser = calcLaserEnergy({ laser, washingPlant: props.washingPlant })
  return (_wEnergy / 60) * (_wTime + _rinses * _time) + _axEnergy + _cEnergy + _dryer + _laser;
}

export function calcCO2EnergyPerCycle(
  props: ICalcPerCycle,
  country: IJetCountry | undefined,
  washingPlant: IJetWashingPlant | undefined
): number {
  const _co2 = parseFloat(`${country?.co2_2022 || 0}`);

  const _current = calcAbsoluteEnergyPerCycle({ ...props, type: 'current' });
  const _green = calcAbsoluteEnergyPerCycle({ ...props, type: 'green' });

  // KG CO2
  return ((_current - _green) * _co2) / 1000;
}
