import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const EmptyWashingPlant = (props: SvgProps) => (
  <Svg {...props} width={122} height={150} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M114.36 2.897H3.335A3.335 3.335 0 0 0 0 6.232v139.636a3.335 3.335 0 0 0 3.335 3.335H114.36a3.335 3.335 0 0 0 3.335-3.335V6.232a3.335 3.335 0 0 0-3.335-3.335Z"
        fill="#368D7D"
      />
      <Path
        d="M117.495.322H6.47a3.335 3.335 0 0 0-3.335 3.335v139.636a3.335 3.335 0 0 0 3.335 3.335h111.025a3.335 3.335 0 0 0 3.335-3.335V3.657a3.335 3.335 0 0 0-3.335-3.335Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M40.74 7.565H15.812v11.588H40.74V7.565ZM31.392 130.507H11.138v8.82h20.254v-8.82ZM98.819 19.154a4.706 4.706 0 1 0 0-9.413 4.706 4.706 0 0 0 0 9.413ZM100.731 64.6h-10.14v22.089h10.14v-22.09ZM14.724 26.576h93.069M11.138 122.517h96.655"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M63.055 106.235c17.5 0 31.688-14.187 31.688-31.688 0-17.501-14.187-31.689-31.688-31.689-17.501 0-31.689 14.188-31.689 31.689 0 17.5 14.188 31.688 31.689 31.688Z"
        fill="#fff"
        stroke="#368D7D"
        strokeMiterlimit={10}
      />
      <Path
        d="M63.055 97.73c12.327 0 22.32-9.993 22.32-22.32 0-12.328-9.993-22.321-22.32-22.321-12.328 0-22.321 9.993-22.321 22.32 0 12.328 9.993 22.321 22.32 22.321Z"
        fill="#368D7D"
      />
      <Path d="M40.74 78.635s14.164-6.065 28.218 2.176" stroke="#E4F4F0" strokeMiterlimit={10} />
      <Path
        d="M85.266 77.644s-16.92-7.61-24.31-.142M64.342 68.135s-.457-4.256 1.449-3.529c1.906.728-1.449 3.529-1.449 3.529ZM61.355 72.763s.644-5.427-2.266-4.616c-2.91.811 2.266 4.616 2.266 4.616Z"
        stroke="#E4F4F0"
        strokeMiterlimit={10}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h121.152v149.203H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default EmptyWashingPlant;
