import { Box, HStack, ScrollView } from 'native-base';
import React, { useContext } from 'react';
import { Grid, Row } from 'react-native-easy-grid';
import { Platform } from 'react-native';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { IJetWashRecipeAggregatorComplete } from '../../../types/wash_recipe_aggregator.type';
import { SummaryIndicator, SummaryLoading } from './SummaryIndicator';
import { stylesSummary } from './CycleSummaryTotal';
import { WashRecipeAggregatorContext } from '../contexts/WashRecipeAggregatorContext';
interface CycleSummaryProps {
  washAggregator: Partial<IJetWashRecipeAggregatorComplete>;
  washingPlant: IJetWashingPlant;
}

export const CycleSummary: React.FC<CycleSummaryProps> = ({ washAggregator, washingPlant }) => {
  const { lastTimeChanged, isLoading, cycles } = useContext(WashRecipeAggregatorContext);

  const cycle = cycles.find((cycle) => cycle.washAggregatorID === washAggregator.id);
  
  if (isLoading) return <SummaryLoading />;
  if (!washAggregator?.id || !cycle) return <></>;

  const {
    totalEnergy,
    totalFuel,
    totalTime,
    totalWater,
  } = cycle;

  if (Platform.OS === 'web') {
    return (
      <HStack space={2} alignItems="center" justifyContent="center" flexWrap={'wrap'}>
        <SummaryIndicator
          isLoading={isLoading}
          title="CO₂"
          unit="Kg"
          bgColor="red"
          chart={{
            rgb: '71, 143, 84',
            data: [totalFuel.percentNumber / 100],
          }}
          subtitle={totalFuel.percent}
          value={totalFuel.total === -1 ? '---' : `${totalFuel.total.toFixed(2)}`}
        />
        <SummaryIndicator
          isLoading={isLoading}
          chart={{
            rgb: '253, 152, 45',
            data: [totalEnergy.percentNumber / 100],
          }}
          title="Energy"
          unit="kWh"
          subtitle={totalEnergy.percent}
          value={totalEnergy.total === -1 ? '---' : `${(totalEnergy?.total || 0).toFixed(2)}`}
          // thirdLine={{
          //   title:
          //     totalEnergy.totalCost === -1
          //       ? "---"
          //       : `${(totalEnergy?.totalCost || 0).toFixed(2)}`,
          //   unit: "Kg (CO₂)",
          // }}
        />
        <SummaryIndicator
          isLoading={isLoading}
          chart={{
            rgb: '40, 162, 229',
            data: [totalWater.percentNumber / 100],
          }}
          title="Water"
          unit="Liters"
          subtitle={totalWater.percent}
          value={totalWater.total === -1 ? '---' : `${totalWater.total.toFixed(2)}`}
        />
        <SummaryIndicator
          isLoading={isLoading}
          chart={{
            rgb: '155, 59, 196',
            data: [totalTime.percentNumber / 100],
          }}
          title="Time"
          unit="Min."
          subtitle={totalTime.percent}
          value={totalTime.total === -1 ? '---' : `${totalTime.total.toFixed(2)}`}
        />
      </HStack>
    );
  }

  return (
    <ScrollView persistentScrollbar>
      <Box>
        <Grid>
          <Row>
            <Grid
              style={{
                ...stylesSummary.empty,
                ...stylesSummary.right,
                ...stylesSummary.bottom,
              }}
            >
              <SummaryIndicator
                isLoading={isLoading}
                title="CO₂"
                unit="Kg"
                bgColor="red"
                chart={{
                  rgb: '71, 143, 84',
                  data: [totalFuel.percentNumber / 100],
                }}
                subtitle={totalFuel.percent}
                value={totalFuel.total === -1 ? '---' : `${totalFuel.total.toFixed(2)}`}
              />
            </Grid>
            <Grid style={{ ...stylesSummary.empty, ...stylesSummary.bottom }}>
              <SummaryIndicator
                isLoading={isLoading}
                chart={{
                  rgb: '253, 152, 45',
                  data: [totalEnergy.percentNumber / 100],
                }}
                title="Energy"
                unit="kWh"
                subtitle={totalEnergy.percent}
                value={totalEnergy.total === -1 ? '---' : `${(totalEnergy?.total || 0).toFixed(2)}`}
                // thirdLine={{
                //   title:
                //     totalEnergy.totalCost === -1
                //       ? "---"
                //       : `${(totalEnergy?.totalCost || 0).toFixed(2)}`,
                //   unit: "Kg (CO₂)",
                // }}
              />
            </Grid>
          </Row>
          <Row>
            <Grid style={{ ...stylesSummary.empty, ...stylesSummary.right }}>
              <SummaryIndicator
                isLoading={isLoading}
                chart={{
                  rgb: '40, 162, 229',
                  data: [totalWater.percentNumber / 100],
                }}
                title="Water"
                unit="Liters"
                subtitle={totalWater.percent}
                value={totalWater.total === -1 ? '---' : `${totalWater.total.toFixed(2)}`}
              />
            </Grid>
            <Grid style={{ ...stylesSummary.empty }}>
              <SummaryIndicator
                isLoading={isLoading}
                chart={{
                  rgb: '155, 59, 196',
                  data: [totalTime.percentNumber / 100],
                }}
                title="Time"
                unit="Min."
                subtitle={totalTime.percent}
                value={totalTime.total === -1 ? '---' : `${totalTime.total.toFixed(2)}`}
              />
            </Grid>
          </Row>
        </Grid>
      </Box>
    </ScrollView>
  );
};

export default React.memo(CycleSummary);
