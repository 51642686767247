import {
  Text,
  VStack,
  Box,
  Divider,
  HStack,
  Button,
  SimpleGrid,
  FlatList,
  Center,
} from 'native-base';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { t } from '../../../languages';
import MachineEditCard from '../components/MachineEditCard';
import { getBoilers } from '../services/getBoilers';
import { getCentrifuges } from '../services/getCentrifuges';
import { getAllCountries } from '../services/getCountries.service';
import { getDryers } from '../services/getDryers';
import { getAllWashingMachines } from '../services/getWashingMachines';
import { IWashingPlantForm } from '../types/washingPlantForm.type';
import { Col, Row, Grid } from 'react-native-easy-grid';
import { useWashingPlantForm } from '../hooks/useWashingPlantForm';
import { QueryKeyCache } from '../../../config/query-cache';
import Loading from '../../../../components/Loading';
import { Platform, ScrollView, TouchableWithoutFeedback } from 'react-native';

interface WashingFormStep9Props {}

interface IEditRow {
  label: string;
  content: string | JSX.Element | undefined | number;
  onPress: () => void;
  disabled: boolean;
}

const EditRow: React.FC<IEditRow> = ({ label, content, onPress, disabled }) => {
  return (
    <VStack space="16px">
      <HStack alignContent="center" alignItems="center" justifyContent="space-between">
        <VStack space={1}>
          <Text fontSize="lg">{label}</Text>
          {typeof content === 'string' ? (
            <Text fontSize="lg" fontWeight="bold" color="black">
              {content}
            </Text>
          ) : (
            <Text fontSize="lg" fontWeight="bold" color="black">{content}</Text>
          )}
        </VStack>
        <Button isDisabled={disabled} variant="ghost" onPress={onPress}>
          {t('domain.generic.edit')}
        </Button>
      </HStack>
      <Divider />
    </VStack>
  );
};

export const WashingFormStep9: React.FC<WashingFormStep9Props> = () => {
  const queryWashingMachines = useQuery(QueryKeyCache.GetAllWashingMachines, getAllWashingMachines);
  const queryBoilers = useQuery(QueryKeyCache.GetBoilers, getBoilers);
  const queryDryers = useQuery(QueryKeyCache.GetDryers, getDryers);
  const queryCentrifuges = useQuery(QueryKeyCache.GetCentrifuges, getCentrifuges);
  const queryCountries = useQuery(QueryKeyCache.GetAllCountries, getAllCountries);

  const { watch, formState } = useFormContext<IWashingPlantForm>();
  const { goToStep, isSubmitting } = useWashingPlantForm();

  const selectedMachine = useMemo(
    () =>
      queryWashingMachines.data?.results.find(
        (machine) => machine.id === watch('selectedWashingMachineID')
      ),
    [queryWashingMachines.data]
  );

  const selectedBoiler = useMemo(
    () => queryBoilers.data?.results.find((boiler) => boiler.id === watch('selectedBoilerID')),
    [queryBoilers.data]
  );

  const selectedDryer = useMemo(
    () => queryDryers.data?.results.find((dryer) => dryer.id === watch('selectedDryerID')),
    [queryDryers.data]
  );

  const selectedCentrifuge = useMemo(
    () =>
      queryCentrifuges.data?.results.find(
        (centrifuge) => centrifuge.id === watch('selectedCentrifugeID')
      ),
    [queryCentrifuges.data]
  );

  const selectedCountry = useMemo(
    () =>
      queryCountries?.data?.results?.find(
        (country) => country.code_numeric === watch('selectedCountryID')
      ),
    [queryCountries.data]
  );

  if (isSubmitting) {
    return (
      <Center display="flex" flexGrow={1} height={'100%'}>
        <Loading loadingText={t('domain.generic.saving')} />
      </Center>
    );
  }

  return (
    <>
      <Box>
        <Text fontSize={24} fontWeight={500}>
          {t('domain.washingPlants.step.nine.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={24} fontWeight="bold" color="primary.500">
              {t('domain.washingPlants.step.nine.title_part_two')}
            </Text>
          </Text>
        </Text>

        <Box my="16px">
          <Divider />
        </Box>

        <TouchableWithoutFeedback>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <VStack space={'16px'} onStartShouldSetResponder={() => true}>
              <EditRow
                label={t('domain.generic.country')}
                content={selectedCountry?.country}
                onPress={() => goToStep(2)}
                disabled={isSubmitting}
              />
              <Grid>
                <Row>
                  <Col>
                    <Box mr={2}>
                      <EditRow
                        label={t('domain.washingPlants.step.nine.review.overall_load')}
                        content={`${watch('overallLoad')} kg`}
                        onPress={() => goToStep(3)}
                        disabled={isSubmitting}
                      />
                    </Box>
                  </Col>
                  <Col>
                    <EditRow
                      label={t('domain.washingPlants.step.nine.review.garments')}
                      content={watch('numberOfGarments')}
                      onPress={() => goToStep(3)}
                      disabled={isSubmitting}
                    />
                  </Col>
                </Row>

                <Box mt={5}>
                  <Row>
                    <Col>
                      <Box mr={2}>
                        <EditRow
                          label={t('domain.washingPlants.step.nine.review.air')}
                          content={`${watch('airTemperature')} ${t(
                            'domain.units.temperature.celsius'
                          )}`}
                          onPress={() => goToStep(6)}
                          disabled={isSubmitting}
                        />
                      </Box>
                    </Col>
                    <Col>
                      <EditRow
                        label={t('domain.washingPlants.step.nine.review.water')}
                        content={`${watch('waterTemperature')} ${t(
                          'domain.units.temperature.celsius'
                        )}`}
                        onPress={() => goToStep(5)}
                        disabled={isSubmitting}
                      />
                    </Col>
                  </Row>
                </Box>
              </Grid>

              <Box mt={'16px'} mb={'56px'}>
                <Grid>
                  <Col style={{ marginRight: 16 }}>
                    <MachineEditCard
                      type={'washingMachine'}
                      name={`${selectedMachine?.brand_detail?.name}\n${selectedMachine?.model}`}
                      onPress={() => goToStep(4)}
                      disabled={isSubmitting}
                      isLoading={
                        queryWashingMachines.isLoading || queryWashingMachines.isRefetching
                      }
                    />
                    <Box height={'16px'} />
                    <MachineEditCard
                      type={'centrifuge'}
                      name={`${selectedCentrifuge?.brand_detail?.name}\n${selectedCentrifuge?.model}`}
                      onPress={() => goToStep(7)}
                      disabled={isSubmitting}
                      isLoading={queryCentrifuges.isLoading || queryCentrifuges.isRefetching}
                    />
                  </Col>
                  <Col>
                    <MachineEditCard
                      type={'boiler'}
                      name={`${selectedBoiler?.brand_detail?.name}\n${selectedBoiler?.model}`}
                      onPress={() => goToStep(8)}
                      disabled={isSubmitting}
                      isLoading={queryBoilers.isLoading || queryBoilers.isRefetching}
                    />
                    <Box height={'16px'} />
                    <MachineEditCard
                      type={'dryer'}
                      name={`${selectedDryer?.brand_detail?.name}\n${selectedDryer?.Model}`}
                      onPress={() => goToStep(9)}
                      disabled={isSubmitting}
                      isLoading={queryDryers.isLoading || queryDryers.isRefetching}
                    />
                  </Col>
                </Grid>
              </Box>
              {Platform.OS === 'web' && <Box height={30} />}
            </VStack>
          </ScrollView>
        </TouchableWithoutFeedback>
      </Box>
    </>
  );
};

export default WashingFormStep9;
