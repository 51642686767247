import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, {
  retries: 3,
  retryCondition: (error) => {
    if (!error?.response?.status) return true;

    return error?.response?.status >= 500;
  },
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 2000; // time interval between retries
  },
});

axios.interceptors.request.use(
  function (config) {
    console.log(config);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    console.log(response);
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export interface IJetAdminResponseProtocol<T> {
  count: number;
  next: null;
  previous: null;
  num_pages: number;
  per_page: number;
  has_more: boolean;
  results: any[];
}

// Create an instance of axios
const baseAPI = axios.create({
  baseURL: 'https://data.jetadmin.io/projects/garmon/prod/jet_database_m86m/models/',
  headers: {
    Authorization: 'Bearer hrkqrycxDhkSytYlj8v1oUd7Eyck1I0rY6hYkEvk',
  },
});

export enum JetAdminCollection {
  BoilerBrands = 'boiler_brand',
  Boilers = 'boiler',
  CarbonCostPerKwhs = 'carbon_cost_per_kw_h',
  Centrifuges = 'centrifuges',
  CentrifugesBrands = 'centrifuges_brands',
  Countries = 'countries',
  CustomerDocuments = 'customer_documents',
  Customers = 'customer',
  DryerBrands = 'dryer_brands',
  Dryers = 'dryers',
  Hocs = 'ho_c',
  Laundries = 'laundry',
  ProductClasses = 'product_class',
  Chemicals = 'products',
  ChemicalsPhotos = 'products_photo',
  RequestHistories = 'request_history',
  WashingMachineBrands = 'washing_machine_brand',
  WashingMachines = 'washing_machines',
  WashingPlants = 'washing_plants',
  TypeOfCycles = 'type_of_cycle',
  Units = 'unit',
  AuxiliaryEquipments = 'auxiliary_machine',
  AuxiliaryEquipmentsBrands = 'auxiliary_machine_brand',
  AuxiliaryEquipmentsTypes = 'auxiliary_machine_type',
  LaserScrapings = 'laser_scraping',
  LaserScrapingsBrands = 'laser_scraping_brand',
  LaserScrapingsTypes = 'laser_scraping_type',
  WashRecipes = 'wash_recipe',
  WashCycles = 'wash_cycle',
  WashRecipeAggregators = 'wash_recipe_aggregator',
  WashRecipeAuxiliaryEquipments = 'wash_recipe_auxiliary_equipment',
  WashRecipeChemicals = 'wash_recipe_chemicals',
  WashRecipeLaserScrapings = 'wash_recipe_laser_scraping',
  AskForHelp = 'ask_for_garmon_help',
  GetAllWashingPlantInfo = 'j5xpg7x7',
  GetAllInfosFromAggregators = 'trtghxyh',
  GetAllInfosFromLasersScraping = 'hgi9iq10',
  getAllInfosFromAuxilliaryMachines = '7fepkhwi',
}

// List

const jetList = async <T>(
  collection: JetAdminCollection,
  queryParams?: Record<string, string | number | string[] | number[] | boolean>
): Promise<IJetAdminResponseProtocol<T>> => {
  try {
    let has_more = true;
    let page = 1;

    let res = await baseAPI.get<IJetAdminResponseProtocol<T>>(`${collection}`, {
      params: { ...queryParams, page, _per_page: 9999 },
    });

    res.data.has_more && page++;
    has_more = res.data.has_more;
    
    while (has_more) {
      const data = await baseAPI.get<IJetAdminResponseProtocol<T>>(`${collection}`, {
        params: { ...queryParams, page, _per_page: 9999 },
      });

      res = {
        ...data,
        data: {
          ...data.data,
          results: [...res.data.results, ...data.data.results]
        }
      };
  
      page++;
      has_more = data.data.has_more;
    };
  
    return res.data;
    // jogar lógica de chamar todas as páginas

  } catch (error) {
    throw error;
  }
};

// Retrieve
const jetRetrieve = async <T>(collection: JetAdminCollection, id: string | number) => {
  // try
  try {
    const response = await baseAPI.get<T>(`${collection}/${id}`);
    return response.data;
  } catch (error) {
    console.log('❌ ~ jetRetrieve', `${collection}/${id}/`);
  }
};

// Create
const jetCreate = async <T>(
  collection: JetAdminCollection,
  data: unknown
): Promise<T | undefined> => {
  // try
  try {
    const response = await baseAPI.post<T>(`${collection}/`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.log('❌ ~ jetCreate', error);
    console.error(error);
  }
};

// Update
const jetUpdate = async <T>(collection: JetAdminCollection, id: string | number, data: any) => {
  // try
  try {
    const response = await baseAPI.patch<T>(`${collection}/${id}`, data);
    return response.data;
  } catch (error) {
    console.log('❌ ~ jetUpdate', error);
  }
};

// Delete
const jetDelete = async (collection: JetAdminCollection, id: string | number) => {
  // try
  try {
    const response = await baseAPI.delete(`${collection}/${id}/`);
    return response.data;
  } catch (error) {
    console.log('❌ ~ jetDelete', error);
    console.error(error);
  }
};

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log(error?.response?.data);

    return Promise.reject(error);
  }
);

export const jetAdmin = {
  list: jetList,
  retrieve: jetRetrieve,
  create: jetCreate,
  update: jetUpdate,
  delete: jetDelete,
};


// primeiro carregar só os ciclos
// depois de carregar só os ciclos, chamar a rota de carregar o conteudo do ciclo