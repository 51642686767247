import { Box, Center, Image, Progress, Spinner, Text, VStack } from 'native-base';
import React from 'react';
import { t } from '../src/languages';

interface LoadingProps {
  loadingText?: string;
}

export const Loading: React.FC<LoadingProps> = ({ loadingText }) => {
  return (
    <Center mt={5}>
      <VStack alignContent="center" alignItems="center">
        <Box mb={4}>
          {/* Loading */}
          <Box width="100%">
            <Spinner size="lg" />
          </Box>
        </Box>
        <Text color="gray.700" fontWeight={500} fontSize={13}>
          {loadingText || t('domain.generic.loading')}
        </Text>
      </VStack>
    </Center>
  );
};

export default Loading;
