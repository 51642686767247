import { Text, Box } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import Loading from '../../../../components/Loading';
import { t } from '../../../languages';
import { IJetBoiler } from '../../../types/boiler.type';
import MachineCard from '../components/MachineCard';
import { getBoilers } from '../services/getBoilers';
import { IWashingPlantForm } from '../types/washingPlantForm.type';
import Boiler from '../../../assets/svgs/boiler';
import { QueryKeyCache } from '../../../config/query-cache';
import { FlatList, RefreshControl } from 'react-native';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';

interface WashingFormStep7Props {}

export const WashingFormStep7: React.FC<WashingFormStep7Props> = () => {
  const { watch, setValue, trigger } = useFormContext<IWashingPlantForm>();
  const { data, isLoading, refetch } = useQuery(QueryKeyCache.GetBoilers, getBoilers);

  const selectBoiler = (boiler: IJetBoiler) => {
    if (watch('selectedBoilerID')) {
      setValue('selectedBoilerID', undefined!);
    } else {
      setValue('selectedBoilerID', boiler.id);
    }

    trigger();
  };

  return (
    <>
      <Box mb="16px">
        <Text fontSize={22} fontWeight={500} mb={4}>
          {t('domain.washingPlants.step.seven.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={22} fontWeight="bold" color="primary.500">
              {t('domain.washingPlants.step.seven.title_part_two')}
            </Text>
          </Text>
        </Text>
        <FlatList
          data={data?.results || []}
          ItemSeparatorComponent={() => <Box mb="8px" />}
          ListEmptyComponent={() => (!isLoading ? <EmptyPlaceholder /> : <Loading />)}
          refreshControl={
            <RefreshControl
              colors={['#9Bd35A', '#689F38']}
              refreshing={isLoading}
              onRefresh={refetch}
            />
          }
          renderItem={({ item, index }) => {
            const isLast = data?.results.length ? index === data?.results.length - 1 : false;

            return (
              <Box key={`boiler-card-${item.id}`} mx={0.75}>
                <MachineCard
                  name={`${item.brand_detail?.name || ''} - ${item.model}`}
                  onPress={() => selectBoiler(item)}
                  isSelected={item.id === watch('selectedBoilerID')}
                  icon={<Boiler />}
                />
                {isLast && <Box height={10} />}
              </Box>
            );
          }}
        />
      </Box>
    </>
  );
};

export default WashingFormStep7;
