import { Text, Box } from 'native-base';
import React from 'react';
import { t } from '../../../languages';
import { StyleSheet } from 'react-native';
import { CountryModal } from '../components/CountryModal';
import { useQuery } from 'react-query';
import { getAllCountries } from '../services/getCountries.service';
import Loading from '../../../../components/Loading';
import { QueryKeyCache } from '../../../config/query-cache';

interface WashingFormStep2Props {}

export const WashingFormStep2: React.FC<WashingFormStep2Props> = () => {
  const { data, isLoading } = useQuery(QueryKeyCache.GetAllCountries, getAllCountries);

  return (
    <>
      <Box>
        <Text fontSize={22} fontWeight="bold" color="primary.500">
          {t('domain.washingPlants.step.two.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={22} fontWeight={500}>
              {t('domain.washingPlants.step.two.title_part_two')}
            </Text>
          </Text>
        </Text>
        {isLoading ? (
          <Box my={20}>
            <Loading />
          </Box>
        ) : (
          <CountryModal isLoading={isLoading} countries={data?.results || []} />
        )}
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: 'grey',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
  },
});

export default WashingFormStep2;
