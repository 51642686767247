import { MaterialIcons } from '@expo/vector-icons';
import { Box, Button, HStack, Icon, Text } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';

interface AddRecipeButtonProps {
  onPress: () => void;
  text: string;
  isAdded: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
}

export const AddRecipeButton: React.FC<AddRecipeButtonProps> = ({
  onPress,
  text,
  isAdded = true,
  children,
  isDisabled = false,
  isLoading = false,
}) => {
  return (
    <Button
      _text={{
        color: '#368D7D',
      }}
      leftIcon={
        isAdded && !isLoading ? (
          <Icon as={<MaterialIcons name={'add'} />} size={4} mr={-1} />
        ) : undefined
      }
      bgColor={'#E5EEED'}
      onPress={onPress}
      borderRadius={'8px'}
      borderWidth={isAdded ? 2 : 1}
      borderColor={isAdded ? '#9BDCD0' : 'transparent'}
      borderStyle={'dashed'}
      padding={'16px'}
      width="100%"
      disabled={isDisabled || isLoading}
      isDisabled={isDisabled || isLoading}
    >
      {isAdded ? text : children}
    </Button>
  );
};

export default AddRecipeButton;
