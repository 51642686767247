import { Box, Checkbox, VStack } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../../../components/Accordion';
import { t } from '../../../../languages';
import { useStandardRecipeForm } from '../../hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../../types/standardRecipeForm.type';
import Step4ExtrasCentrifuge from './inputs/Step4ExtrasCentrifuge';
import Step4ExtrasDrying from './inputs/Step4ExtrasDrying';
import Step4ExtrasRinses from './inputs/Step4ExtrasRinses';

interface RecipeFormStep4ExtrasProps {}

export const RecipeFormStep4Extras: React.FC<RecipeFormStep4ExtrasProps> = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { setValue, watch, clearErrors } = methods;
  const { getInputName } = useStandardRecipeForm(methods.control);

  const inputAddRinses = getInputName('extras_rinses_add');
  const inputAddCentrifuge = getInputName('extras_centrifuge_add');
  const inputAddDrying = getInputName('extras_drying_add');

  const extrasRinsesAmountOfRinses = getInputName('extras_rinses_amount_of_rinses');

  // @ts-ignore: can't type this properly because of dynamic form
  const addRinses = watch(inputAddRinses);
  // @ts-ignore: can't type this properly because of dynamic form
  const addCentrifuge = watch(inputAddCentrifuge);
  // @ts-ignore: can't type this properly because of dynamic form
  const addDrying = watch(inputAddDrying);

  function changeAddRinses(value: boolean) {
    // @ts-ignore: can't type this properly because of dynamic form
    setValue(inputAddRinses, value);
  }

  function changeAddCentrifuge(value: boolean) {
    // @ts-ignore: can't type this properly because of dynamic form
    setValue(inputAddCentrifuge, value);
  }

  function changeAddDrying(value: boolean) {
    // @ts-ignore: can't type this properly because of dynamic form
    setValue(inputAddDrying, value);
  }

  return (
    <VStack p={0.5} space="16px">
      <Accordion
        id={'rinses'}
        left={<Checkbox
          accessibilityLabel="Checkbox"
          onChange={changeAddRinses}
          isChecked={!!addRinses}
          value={`${addRinses}`} />}
        title={t('domain.analysis.step4.section.extras.subsection.rinses.title')}       >
        <Step4ExtrasRinses />
      </Accordion>

      <Accordion
        id={'centrifuge'}
        left={<Checkbox
          accessibilityLabel="Checkbox"
          onChange={changeAddCentrifuge}
          isChecked={!!addCentrifuge}
          value={`${addCentrifuge}`} />}
        title={t('domain.analysis.step4.section.extras.subsection.centrifuge.title')}       >
        <Step4ExtrasCentrifuge />
      </Accordion>

      <Accordion
        id={'drying'}
        left={<Checkbox
          accessibilityLabel="Checkbox"
          onChange={changeAddDrying}
          isChecked={!!addDrying}
          value={`${addDrying}`} />}
        title={t('domain.analysis.step4.section.extras.subsection.centrifuge.drying.title')}       >
        <Step4ExtrasDrying />
      </Accordion>
    </VStack>
  );
};

export default RecipeFormStep4Extras;
