import Toast, { ToastOptions } from 'react-native-root-toast';

export function toast(msg: string, options?: ToastOptions) {
  Toast.show(msg, {
    duration: Toast.durations.LONG,
    position: Toast.positions.BOTTOM,
    hideOnPress: true,
    animation: true,
    ...options,
  });
}
