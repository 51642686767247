export type FirebaseErrorCode = keyof typeof ptBr;

interface IFirebaseErrorCode {
  [key: string]: string;
}

const ptBr: IFirebaseErrorCode = {
  'auth/claims-too-large':
    'O payload de declarações fornecido para setCustomUserClaims() excede o tamanho máximo permitido de 1.000 bytes.',
  'auth/email-already-exists':
    'O e-mail fornecido já está em uso por outro usuário. Cada usuário precisa ter um e-mail exclusivo.',
  'auth/email-already-in-use':
    'O e-mail do usuário já existe, tente outro ou recupere sua senha na tela de login.',
  'auth/id-token-expired': 'O token de código do Firebase provisionado expirou.',
  'auth/id-token-revoked': 'O token de ID do Firebase foi revogado.',
  'auth/insufficient-permission':
    'A credencial usada para inicializar o SDK Admin não tem permissão para acessar o recurso solicitado do Authentication. Consulte Configurar um projeto do Firebase para ver a documentação sobre como gerar uma credencial com as permissões apropriadas e usá-la na autenticação dos SDKs Admin.',
  'auth/internal-error':
    'O servidor do Authentication encontrou um erro inesperado ao tentar processar a solicitação. A mensagem de erro incluirá a resposta do servidor de autenticação com informações adicionais. Se o erro persistir, informe o problema ao nosso canal de suporte de Relatório do bug.',
  'auth/invalid-argument':
    'Um argumento inválido foi fornecido a um método do Authentication. A mensagem de erro precisa conter informações adicionais.',
  'auth/invalid-claims':
    'Os atributos de declaração personalizados fornecidos para setCustomUserClaims() são inválidos.',
  'auth/invalid-continue-uri': 'O URL de confirmação precisa ser uma string de URL válida.',
  'auth/invalid-creation-time': 'O horário da criação precisa ser um string de data UTC válido.',
  'auth/invalid-credential':
    'A credencial usada para autenticar os SDKs Admin não pode ser usada para executar a ação desejada. Determinados métodos de autenticação, como createCustomToken() e verifyIdToken(), requerem que o SDK seja inicializado com uma credencial de certificado em oposição a um token de atualização ou uma credencial padrão do aplicativo. Consulte Inicializar o SDK para ver a documentação sobre como autenticar os Admin SDKs com uma credencial de certificado.',
  'auth/invalid-disabled-field':
    'O valor fornecido para a propriedade do usuário disabled é inválido. Precisa ser um valor booleano.',
  'auth/invalid-display-name':
    'O valor fornecido para a propriedade do usuário displayName é inválido. Precisa ser uma string não vazia.',
  'auth/invalid-dynamic-link-domain':
    'O domínio de link dinâmico fornecido não está configurado ou autorizado para o projeto atual.',
  'auth/invalid-email':
    'O valor fornecido para a propriedade do usuário email é inválido. Precisa ser um endereço de e-mail de string.',
  'auth/invalid-email-verified':
    'O valor fornecido para a propriedade do usuário emailVerified é inválido. Precisa ser um valor booleano.',
  'auth/invalid-hash-algorithm':
    'O algoritmo de hash precisa corresponder a uma das strings na lista de algoritmos compatíveis.',
  'auth/invalid-hash-block-size': 'O tamanho do bloco de hash precisa ser um número válido.',
  'auth/invalid-hash-derived-key-length':
    'O tamanho da chave derivada do hash precisa ser um número válido.',
  'auth/invalid-hash-key': 'A chave de hash precisa ter um buffer de byte válido.',
  'auth/invalid-hash-memory-cost': 'O custo da memória hash precisa ser um número válido.',
  'auth/invalid-hash-parallelization':
    'O carregamento em paralelo do hash precisa ser um número válido.',
  'auth/invalid-hash-rounds': 'O arredondamento de hash precisa ser um número válido.',
  'auth/invalid-hash-salt-separator':
    'O campo do separador de "salt" do algoritmo de geração de hash precisa ser um buffer de byte válido.',
  'auth/invalid-id-token':
    'O token de código informado não é um token de código do Firebase válido.',
  'auth/invalid-last-sign-in-time':
    'O último horário de login precisa ser um string de data UTC válido.',
  'auth/invalid-page-token':
    'O token de próxima página fornecido em listUsers() é inválido. Precisa ser uma string não vazia válida.',
  'auth/invalid-password':
    'O valor fornecido para a propriedade do usuário password é inválido. Precisa ser uma string com pelo menos seis caracteres.',
  'auth/invalid-password-hash': 'O hash da senha precisa ser um buffer de byte válido.',
  'auth/invalid-password-salt': 'O "salt" da senha precisa ser um buffer de byte válido',
  'auth/invalid-phone-number':
    'O valor fornecido para phoneNumber é inválido. Ele precisa ser uma string de identificador compatível com o padrão E.164 não vazio.',
  'auth/invalid-photo-url':
    'O valor fornecido para a propriedade do usuário photoURL é inválido. Precisa ser um URL de string.',
  'auth/invalid-provider-data': 'O providerData precisa ser uma matriz válida de objetos UserInfo.',
  'auth/invalid-provider-id':
    'O providerId precisa ser um string de identificador de provedor compatível válido.',
  'auth/invalid-oauth-responsetype':
    'Apenas um responseType do OAuth deve ser definido como verdadeiro.',
  'auth/invalid-session-cookie-duration':
    'A duração do cookie da sessão precisa ser um número válido em milissegundos entre 5 minutos e 2 semanas.',
  'auth/invalid-uid':
    'O uid fornecido precisa ser uma string não vazia com no máximo 128 caracteres.',
  'auth/invalid-user-import': 'O registro do usuário a ser importado é inválido.',
  'auth/maximum-user-count-exceeded':
    'O número máximo permitido de usuários a serem importados foi excedido.',
  'auth/missing-android-pkg-name':
    'Um nome de pacote Android precisa ser fornecido para a instalação do app Android.',
  'auth/missing-continue-uri': 'Um URL de confirmação válido precisa ser fornecido na solicitação.',
  'auth/missing-hash-algorithm':
    'É necessário fornecer o algoritmo de geração de hash e seus parâmetros para importar usuários com hashes de senha.',
  'auth/missing-ios-bundle-id': 'A solicitação está sem o ID do pacote do iOS.',
  'auth/missing-uid': 'Um identificador uid é necessário para a operação atual.',
  'auth/missing-oauth-client-secret':
    'A chave secreta do cliente de configuração do OAuth é necessária para ativar o fluxo de código do OIDC.',
  'auth/operation-not-allowed':
    'O provedor de login fornecido está desativado para o projeto do Firebase. Ative-o na seção Método de login do Console do Firebase.',
  'auth/phone-number-already-exists':
    'O phoneNumber fornecido já está sendo usado por um usuário existente. É necessário que cada usuário tenha um phoneNumber exclusivo.',
  'auth/project-not-found':
    'Nenhum projeto do Firebase foi encontrado com a credencial usada para inicializar os Admin SDKs. Consulte Configurar um projeto do Firebase para ver a documentação sobre como gerar uma credencial para seu projeto e usá-la na autenticação dos Admin SDKs.',
  'auth/reserved-claims':
    'Uma ou mais declarações de usuário personalizadas fornecidas para setCustomUserClaims() são reservadas. Por exemplo, não use as declarações específicas do OIDC, como sub, iat, iss, exp, aud, auth_time etc., como chaves para declarações personalizadas.',
  'auth/session-cookie-expired': 'O cookie da sessão do Firebase fornecido expirou.',
  'auth/session-cookie-revoked': 'O cookie da sessão do Firebase foi revogado.',
  'auth/uid-already-exists':
    'O uid fornecido já está sendo usado por um usuário existente. É necessário que cada usuário tenha um uid exclusivo.',
  'auth/unauthorized-continue-uri':
    'O domínio da URL de confirmação não está na lista de permissões. Acesse o Console do Firebase para colocar o domínio na lista de permissões.',
  'auth/user-not-found':
    'O e-mail ou a senha estão incorretos, tente novamente.',
  'auth/wrong-password': 
  'O e-mail ou a senha estão incorretos, tente novamente.',
};

const en: IFirebaseErrorCode = {
  'auth/claims-too-large':
    'The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes.',
  'auth/email-already-exists':
    'The provided email is already in use by an existing user. Each user must have a unique email.',
  'auth/email-already-in-use': 'User email already exists, try another or recover your password at Sign in screen.',
  'auth/id-token-expired': 'The provided Firebase ID token is expired.',
  'auth/id-token-revoked': 'The Firebase ID token has been revoked.',
  'auth/insufficient-permission':
    'The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.',
  'auth/internal-error':
    'The Authentication server encountered an unexpected error while trying to process the request. The error message should contain the response from the Authentication server containing additional information. If the error persists, please report the problem to our Bug Report support channel.',
  'auth/invalid-argument':
    'An invalid argument was provided to an Authentication method. The error message should contain additional information.',
  'auth/invalid-claims':
    'The custom claim attributes provided to setCustomUserClaims() are invalid.',
  'auth/invalid-continue-uri': 'The continue URL must be a valid URL string.',
  'auth/invalid-creation-time': 'The creation time must be a valid UTC date string.',
  'auth/invalid-credential':
    'The credential used to authenticate the Admin SDKs cannot be used to perform the desired action. Certain Authentication methods such as createCustomToken() and verifyIdToken() require the SDK to be initialized with a certificate credential as opposed to a refresh token or Application Default credential. See Initialize the SDK for documentation on how to authenticate the Admin SDKs with a certificate credential.',
  'auth/invalid-disabled-field':
    'The provided value for the disabled user property is invalid. It must be a boolean.',
  'auth/invalid-display-name':
    'The provided value for the displayName user property is invalid. It must be a non-empty string.',
  'auth/invalid-dynamic-link-domain':
    'The provided dynamic link domain is not configured or authorized for the current project.',
  'auth/invalid-email':
    'The provided value for the email user property is invalid. It must be a string email address.',
  'auth/invalid-email-verified':
    'The provided value for the emailVerified user property is invalid. It must be a boolean.',
  'auth/invalid-hash-algorithm':
    'The hash algorithm must match one of the strings in the list of supported algorithms.',
  'auth/invalid-hash-block-size': 'The hash block size must be a valid number.',
  'auth/invalid-hash-derived-key-length': 'The hash derived key length must be a valid number.',
  'auth/invalid-hash-key': 'The hash key must a valid byte buffer.',
  'auth/invalid-hash-memory-cost': 'The hash memory cost must be a valid number.',
  'auth/invalid-hash-parallelization': 'The hash parallelization must be a valid number.',
  'auth/invalid-hash-rounds': 'The hash rounds must be a valid number.',
  'auth/invalid-hash-salt-separator':
    'The hashing algorithm salt separator field must be a valid byte buffer.',
  'auth/invalid-id-token': 'The provided ID token is not a valid Firebase ID token.',
  'auth/invalid-last-sign-in-time': 'The last sign-in time must be a valid UTC date string.',
  'auth/invalid-page-token':
    'The provided next page token in listUsers() is invalid. It must be a valid non-empty string.',
  'auth/invalid-password':
    'The provided value for the password user property is invalid. It must be a string with at least six characters.',
  'auth/invalid-password-hash': 'The password hash must be a valid byte buffer.',
  'auth/invalid-password-salt': 'The password salt must be a valid byte buffer.',
  'auth/invalid-phone-number':
    'The provided value for the phoneNumber is invalid. It must be a non-empty E.164 standard compliant identifier string.',
  'auth/invalid-photo-url':
    'The provided value for the photoURL user property is invalid. It must be a string URL.',
  'auth/invalid-provider-data': 'The providerData must be a valid array of UserInfo objects.',
  'auth/invalid-provider-id':
    'The providerId must be a valid supported provider identifier string.',
  'auth/invalid-oauth-responsetype': 'Only exactly one OAuth responseType should be set to true.',
  'auth/invalid-session-cookie-duration':
    'The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.',
  'auth/invalid-uid': 'The provided uid must be a non-empty string with at most 128 characters.',
  'auth/invalid-user-import': 'The user record to import is invalid.',
  'auth/maximum-user-count-exceeded':
    'The maximum allowed number of users to import has been exceeded.',
  'auth/missing-android-pkg-name':
    'An Android Package Name must be provided if the Android App is required to be installed.',
  'auth/missing-continue-uri': 'A valid continue URL must be provided in the request.',
  'auth/missing-hash-algorithm':
    'Importing users with password hashes requires that the hashing algorithm and its parameters be provided.',
  'auth/missing-ios-bundle-id': 'The request is missing a Bundle ID.',
  'auth/missing-uid': 'A uid identifier is required for the current operation.',
  'auth/missing-oauth-client-secret':
    'The OAuth configuration client secret is required to enable OIDC code flow.',
  'auth/operation-not-allowed':
    'The provided sign-in provider is disabled for your Firebase project. Enable it from the Sign-in Method section of the Firebase console.',
  'auth/phone-number-already-exists':
    'The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber.',
  'auth/project-not-found':
    'No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs.',
  'auth/reserved-claims':
    'One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.',
  'auth/session-cookie-expired': 'The provided Firebase session cookie is expired.',
  'auth/session-cookie-revoked': 'The Firebase session cookie has been revoked.',
  'auth/uid-already-exists':
    'The provided uid is already in use by an existing user. Each user must have a unique uid.',
  'auth/unauthorized-continue-uri':
    'The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase Console.',
  'auth/user-not-found': 'The email or password was incorrect, please try again.',
  'auth/wrong-password': 'The email or password was incorrect, please try again.',
};

const italian = {
  'auth/claims-too-large':
    'Il payload delle attestazioni fornito a setCustomUserClaims() supera la dimensione massima consentita di 1000 byte.',
  'auth/email-already-exists':
    "L'e-mail fornita è già utilizzata da un utente esistente. Ogni utente deve avere un'e-mail univoca.",
  'auth/email-already-in-use':
    "L'indirizzo email dell'utente esiste già, provane un altro o recupera la password nella schermata di accesso.",
  'auth/id-token-expired': 'Il token ID Firebase fornito è scaduto.',
  'auth/id-token-revoked': 'Il token ID Firebase è stato revocato.',
  'auth/insufficient-permission':
    "La credenziale utilizzata per inizializzare l'Admin SDK non dispone di autorizzazioni sufficienti per accedere alla risorsa di autenticazione richiesta. Fare riferimento a Configurazione di un progetto Firebase per la documentazione su come generare una credenziale con autorizzazioni appropriate e utilizzarla per autenticare gli SDK di amministrazione.",
  'auth/internal-error':
    "Il server di autenticazione ha riscontrato un errore imprevisto durante il tentativo di elaborare la richiesta. Il messaggio di errore dovrebbe contenere la risposta del server di autenticazione contenente informazioni aggiuntive. Se l'errore persiste, segnala il problema al nostro canale di supporto per le segnalazioni di bug .",
  'auth/invalid-argument':
    'È stato fornito un argomento non valido a un metodo di autenticazione. Il messaggio di errore dovrebbe contenere informazioni aggiuntive.',
  'auth/invalid-claims':
    'Gli attributi di attestazione personalizzati forniti a setCustomUserClaims() non sono validi.',
  'auth/invalid-continue-uri': "L'URL continua deve essere una stringa URL valida.",
  'auth/invalid-creation-time': "L'ora di creazione deve essere una stringa di data UTC valida.",
  'auth/invalid-credential':
    "La credenziale utilizzata per autenticare gli Admin SDK non può essere utilizzata per eseguire l'azione desiderata. Alcuni metodi di autenticazione come createCustomToken() e verifyIdToken() richiedono l'inizializzazione dell'SDK con una credenziale del certificato anziché con un token di aggiornamento o una credenziale predefinita dell'applicazione. Consulta Inizializzare l'SDK per la documentazione su come autenticare gli Admin SDK con una credenziale di certificato.",
  'auth/invalid-disabled-field':
    "Il valore fornito per la proprietà dell'utente disabled non è valido. Deve essere un booleano.",
  'auth/invalid-display-name':
    'Il valore fornito per la proprietà utente displayName non è valido. Deve essere una stringa non vuota.',
  'auth/invalid-dynamic-link-domain':
    'Il dominio di collegamento dinamico fornito non è configurato o autorizzato per il progetto corrente.',
  'auth/invalid-email':
    "Il valore fornito per la proprietà dell'utente di email non è valido. Deve essere un indirizzo email stringa.",
  'auth/invalid-email-verified':
    'Il valore fornito per la proprietà utente emailVerified non è valido. Deve essere un booleano.',
  'auth/invalid-hash-algorithm':
    "L'algoritmo hash deve corrispondere a una delle stringhe nell'elenco degli algoritmi supportati.",
  'auth/invalid-hash-block-size': 'La dimensione del blocco hash deve essere un numero valido.',
  'auth/invalid-hash-derived-key-length':
    "La lunghezza della chiave derivata dall'hash deve essere un numero valido.",
  'auth/invalid-hash-key': 'La chiave hash deve essere un buffer di byte valido.',
  'auth/invalid-hash-memory-cost': 'Il costo della memoria hash deve essere un numero valido.',
  'auth/invalid-hash-parallelization': 'La parallelizzazione hash deve essere un numero valido.',
  'auth/invalid-hash-rounds': 'Gli hash round devono essere un numero valido.',
  'auth/invalid-hash-salt-separator':
    "Il campo del separatore di sale dell'algoritmo di hash deve essere un buffer di byte valido.",
  'auth/invalid-id-token': 'Il token ID fornito non è un token ID Firebase valido.',
  'auth/invalid-last-sign-in-time':
    "L'ora dell'ultimo accesso deve essere una stringa di data UTC valida.",
  'auth/invalid-page-token':
    'Il token della pagina successiva fornito in listUsers() non è valido. Deve essere una stringa valida non vuota.',
  'auth/invalid-password':
    "Il valore fornito per la proprietà dell'utente password non è valido. Deve essere una stringa con almeno sei caratteri.",
  'auth/invalid-password-hash': "L'hash della password deve essere un buffer di byte valido.",
  'auth/invalid-password-salt': 'La password salt deve essere un buffer di byte valido',
  'auth/invalid-phone-number':
    'Il valore fornito per il numero di phoneNumber non è valido. Deve essere una stringa identificativa conforme allo standard E.164 non vuota.',
  'auth/invalid-photo-url':
    'Il valore fornito per la proprietà utente photoURL non è valido. Deve essere una stringa URL.',
  'auth/invalid-provider-data': 'providerData deve essere una matrice valida di oggetti UserInfo.',
  'auth/invalid-provider-id':
    'Il providerId deve essere una stringa identificativa del provider supportata valida.',
  'auth/invalid-oauth-responsetype':
    'Solo esattamente un responseType OAuth deve essere impostato su true.',
  'auth/invalid-session-cookie-duration':
    'La durata del cookie di sessione deve essere un numero valido in millisecondi compreso tra 5 minuti e 2 settimane.',
  'auth/invalid-uid':
    "L' uid fornito deve essere una stringa non vuota con un massimo di 128 caratteri.",
  'auth/invalid-user-import': 'Il record utente da importare non è valido.',
  'auth/maximum-user-count-exceeded':
    'È stato superato il numero massimo consentito di utenti da importare.',
  'auth/missing-android-pkg-name':
    "È necessario fornire un nome pacchetto Android se è necessario installare l'app Android.",
  'auth/missing-continue-uri': 'Nella richiesta deve essere fornito un URL continuo valido.',
  'auth/missing-hash-algorithm':
    "L'importazione di utenti con hash delle password richiede la fornitura dell'algoritmo di hash e dei relativi parametri.",
  'auth/missing-ios-bundle-id': 'Nella richiesta manca un ID bundle.',
  'auth/missing-uid': "Un identificatore uid è richiesto per l'operazione corrente.",
  'auth/missing-oauth-client-secret':
    'Il segreto del client di configurazione OAuth è necessario per abilitare il flusso di codice OIDC.',
  'auth/operation-not-allowed':
    'Il provider di accesso fornito è disabilitato per il tuo progetto Firebase. Abilitalo dalla sezione Metodo di accesso della console Firebase.',
  'auth/phone-number-already-exists':
    'Il numero di phoneNumber fornito è già utilizzato da un utente esistente. Ogni utente deve avere un numero di phoneNumber .',
  'auth/project-not-found':
    'Non è stato trovato alcun progetto Firebase per le credenziali utilizzate per inizializzare gli Admin SDK. Fare riferimento a Configurazione di un progetto Firebase per la documentazione su come generare una credenziale per il progetto e utilizzarla per autenticare gli SDK di amministrazione.',
  'auth/reserved-claims':
    'Una o più attestazioni utente personalizzate fornite a setCustomUserClaims() sono riservate. Ad esempio, attestazioni specifiche OIDC come (sub, iat, iss, exp, aud, auth_time e così via) non devono essere utilizzate come chiavi per attestazioni personalizzate.',
  'auth/session-cookie-expired': 'Il cookie di sessione Firebase fornito è scaduto.',
  'auth/session-cookie-revoked': 'Il cookie di sessione Firebase è stato revocato.',
  'auth/uid-already-exists':
    "L' uid fornito è già utilizzato da un utente esistente. Ogni utente deve avere un uid univoco.",
  'auth/unauthorized-continue-uri':
    "Il dominio dell'URL continua non è autorizzato. Inserisci nella whitelist il dominio nella console di Firebase.",
  'auth/user-not-found':
    "L'e-mail o la password non sono corrette, riprova.",
  'auth/wrong-password': "L'e-mail o la password non sono corrette, riprova.",
};

const spanish = {
  'auth/claims-too-large':
    'La carga útil de la reclamación que se entregó a setCustomUserClaims() supera el tamaño máximo de 1,000 bytes.',
  'auth/email-already-exists':
    'Otro usuario ya está utilizando el correo electrónico proporcionado. Cada usuario debe tener un correo electrónico único.',
  'auth/email-already-in-use':
    'El correo electrónico del usuario ya existe, pruebe con otro o recupere su contraseña en la pantalla de inicio de sesión',
  'auth/id-token-expired': 'El token de ID de Firebase que se proporcionó está vencido.',
  'auth/id-token-revoked': 'Se revocó el token de ID de Firebase.',
  'auth/insufficient-permission':
    'La credencial que se usó para inicializar el SDK de Admin no tiene permisos suficientes para acceder al recurso de Authentication solicitado. Consulta cómo configurar un proyecto de Firebase si necesitas ver la documentación para generar una credencial con los permisos apropiados y usarla a fin de autenticar los SDK de Admin.',
  'auth/internal-error':
    'El servidor de Authentication encontró un error inesperado cuando se intentaba procesar la solicitud. Para obtener información adicional, revisa la respuesta del servidor de autenticación, que debería estar incluida en el mensaje de error. Si el error persiste, avísanos mediante nuestro canal de asistencia de informe de errores.',
  'auth/invalid-argument':
    'Se proporcionó un argumento no válido para un método de autenticación. El mensaje de error debe incluir información adicional.',
  'auth/invalid-claims':
    'Los atributos personalizados del reclamo que se entregaron a setCustomUserClaims() no son válidos.',
  'auth/invalid-continue-uri': 'La URL de continuación debe ser una string de URL válida.',
  'auth/invalid-creation-time':
    'La hora de creación debe ser una string de fecha en formato UTC válida.',
  'auth/invalid-credential':
    'La credencial que se usa para autenticar los SDK de Admin no se puede emplear a fin de realizar la acción deseada. Algunos métodos de autenticación, como createCustomToken() y verifyIdToken(), requieren que los SDK se inicialicen con una credencial de certificado en lugar de un token de actualización o una credencial predeterminada de la aplicación. Consulta Inicializa el SDK para ver documentación sobre cómo autenticar el SDK de Admin con una credencial de certificado.',
  'auth/invalid-disabled-field':
    'El valor que se proporcionó para la propiedad del usuario disabled no es válido. Debe ser un booleano.',
  'auth/invalid-display-name':
    'El valor que se proporcionó para la propiedad del usuario displayName no es válido. Debe ser una string que no esté vacía.',
  'auth/invalid-dynamic-link-domain':
    'El dominio del vínculo dinámico proporcionado no se configuró o no se autorizó para el proyecto actual.',
  'auth/invalid-email':
    'El valor que se proporcionó para la propiedad del usuario email no es válido. Debe ser una dirección de correo electrónico de string.',
  'auth/invalid-email-verified':
    'El valor que se proporcionó para la propiedad del usuario emailVerified no es válido. Debe ser un booleano.',
  'auth/invalid-hash-algorithm':
    'El algoritmo de hash debe coincidir con las strings de la lista de algoritmos compatibles.',
  'auth/invalid-hash-block-size': 'El tamaño del conjunto de hash debe ser un número válido.',
  'auth/invalid-hash-derived-key-length':
    'La longitud de la clave derivada de hash debe ser un número válido.',
  'auth/invalid-hash-key': 'La clave de hash debe ser un búfer de bytes válido.',
  'auth/invalid-hash-memory-cost': 'El costo de la memoria de hash debe ser un número válido.',
  'auth/invalid-hash-parallelization': 'La paralelización de hash debe ser un número válido.',
  'auth/invalid-hash-rounds': 'Las rondas de hash deben ser un número válido.',
  'auth/invalid-hash-salt-separator':
    'El campo del separador de sal del algoritmo de hash debe ser un búfer de bytes válido.',
  'auth/invalid-id-token':
    'El token de ID que se proporcionó no es un token de ID de Firebase válido.',
  'auth/invalid-last-sign-in-time':
    'La hora del último acceso debe ser una string de fecha en formato UTC válida.',
  'auth/invalid-page-token':
    'El token de página siguiente que se entregó en listUsers() no es válido. Debe ser una string válida que no esté vacía.',
  'auth/invalid-password':
    'El valor que se proporcionó para la propiedad del usuario password no es válido. Debe ser una string con al menos seis caracteres.',
  'auth/invalid-password-hash': 'El hash de contraseñas debe ser un búfer de bytes válidos.',
  'auth/invalid-password-salt': 'La contraseña con sal debe ser un búfer de bytes válido.',
  'auth/invalid-phone-number':
    'El valor que se proporcionó para phoneNumber no es válido. Debe ser una string de identificador que no esté vacía y que cumpla con el estándar E.164.',
  'auth/invalid-photo-url':
    'El valor que se proporcionó para la propiedad del usuario photoURL no es válido. Debe ser una URL de string.',
  'auth/invalid-provider-data': 'providerData debe ser una serie de objetos UserInfo.',
  'auth/invalid-provider-id':
    'providerId debe ser una string del identificador del proveedor compatible válida.',
  'auth/invalid-oauth-responsetype':
    'Se debe configurar solo un responseType de OAuth como verdadera.',
  'auth/invalid-session-cookie-duration':
    'La duración de la cookie de sesión debe ser un número válido en milisegundos que vaya entre los 5 minutos y las 2 semanas.',
  'auth/invalid-uid':
    'El uid proporcionado debe ser una string no vacía con un máximo de 128 caracteres.',
  'auth/invalid-user-import': 'El registro de usuarios para importar no es válido.',
  'auth/maximum-user-count-exceeded':
    'Se excedió la cantidad máxima de usuarios permitidos para importar.',
  'auth/missing-android-pkg-name':
    'Si es obligatorio instalar la app para Android, debe proporcionarse un nombre de paquete de Android.',
  'auth/missing-continue-uri':
    'Se debe proporcionar una URL de continuación válida en la solicitud.',
  'auth/missing-hash-algorithm':
    'Para importar usuarios con hash de contraseñas, es necesario proporcionar el algoritmo de hash y sus parámetros.',
  'auth/missing-ios-bundle-id': 'La solicitud debe contener un ID del paquete de iOS.',
  'auth/missing-uid': 'Se requiere un identificador uid para la operación actual.',
  'auth/missing-oauth-client-secret':
    'El secreto de cliente de la configuración de OAuth es obligatorio para habilitar el flujo de código de OIDC.',
  'auth/operation-not-allowed':
    'El proveedor de acceso proporcionado está inhabilitado para tu proyecto de Firebase. Habilítalo en la sección Método de acceso de Firebase console.',
  'auth/phone-number-already-exists':
    'Otro usuario ya utiliza el phoneNumber proporcionado. Cada usuario debe tener un phoneNumber único.',
  'auth/project-not-found':
    'No se encontró ningún proyecto de Firebase para la credencial que se usó para inicializar los SDK de Admin. Consulta cómo configurar un proyecto de Firebase si necesitas ver la documentación para generar la credencial de tu proyecto y usarla a fin de autenticar los SDK de Admin.',
  'auth/reserved-claims':
    'Una o más reclamaciones personalizadas de usuarios que se entregaron a setCustomUserClaims() están reservadas. Por ejemplo, no deben usarse reclamos específicos de OIDC (p. ej., sub, iat, iss, exp, aud o auth_time) como claves para reclamos personalizados.',
  'auth/session-cookie-expired': 'La cookie proporcionada de la sesión de Firebase venció.',
  'auth/session-cookie-revoked': 'Se revocaron las cookies de la sesión de Firebase.',
  'auth/uid-already-exists':
    'Otro usuario ya utiliza el uid proporcionado. Cada usuario debe tener un uid único.',
  'auth/unauthorized-continue-uri':
    'El dominio de la URL de continuación no está en la lista blanca. Inclúyelo en la lista en Firebase console.',
  'auth/user-not-found':
    'El correo electrónico o la contraseña eran incorrectos, inténtelo de nuevo.',
  'auth/wrong-password': 
  'El correo electrónico o la contraseña eran incorrectos, inténtelo de nuevo.'
};

export const firebaseErrorsCodeTranslated = {
  ptBr,
  en,
  spanish,
  italian,
};
