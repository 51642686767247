import { Box, Text } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import Loading from '../../../../components/Loading';
import { t } from '../../../languages';
import { IJetWashingMachineWithBrand } from '../../../types/washing-machine.type';
import MachineCard from '../components/MachineCard';
import { getAllWashingMachines } from '../services/getWashingMachines';
import { IWashingPlantForm } from '../types/washingPlantForm.type';
import Machine from '../../../assets/svgs/machine';
import { FlatList, RefreshControl } from 'react-native';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import { QueryKeyCache } from '../../../config/query-cache';
interface WashingFormStep1Props {}

export const WashingFormStep1: React.FC<WashingFormStep1Props> = () => {
  const { watch, setValue, trigger } = useFormContext<IWashingPlantForm>();
  const { data, isLoading, refetch, isRefetching } = useQuery(
    QueryKeyCache.GetAllWashingMachines,
    getAllWashingMachines
  );
  const selectedMachine = watch('selectedWashingMachineID');

  const selectMachine = (machine: IJetWashingMachineWithBrand) => {
    if (selectedMachine === machine.id) {
      setValue('selectedWashingMachineID', undefined!);
    } else {
      setValue('selectedWashingMachineID', machine.id);
    }

    trigger();
  };

  const isQueryLoading = isLoading || isRefetching;

  return (
    <>
      <Box mb="16px">
        <Text fontSize={22} fontWeight="bold" color="primary.500">
          {t('domain.washingPlants.step.four.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={22} fontWeight={500}>
              {t('domain.washingPlants.step.four.title_part_two')}
            </Text>
          </Text>
        </Text>
      </Box>

      <FlatList
        data={data?.results || []}
        ItemSeparatorComponent={() => <Box mb="8px" />}
        ListEmptyComponent={() => (!isQueryLoading ? <EmptyPlaceholder /> : <Loading />)}
        refreshControl={
          <RefreshControl
            colors={['#9Bd35A', '#689F38']}
            refreshing={isQueryLoading}
            onRefresh={refetch}
          />
        }
        renderItem={({ item }) => {
          return (
            <Box key={`machine-card-${item.id}`} mx={0.75}>
              <MachineCard
                name={`${item.brand_detail?.name || ''} - ${item.model}`}
                onPress={() => selectMachine(item)}
                isSelected={item.id === watch('selectedWashingMachineID')}
                icon={<Machine />}
              />
            </Box>
          );
        }}
      />
    </>
  );
};

export default WashingFormStep1;
