import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import storage from '../config/storage';
// import ExpiredStorage from "expired-storage";

interface IUseLocalStorage<T> {
  key: string;
  id?: string | undefined;
  initialValue: T;
  expire?: number | null | undefined;
}

/**
 * An implement of the same API of react useState but with the difference of
 * localStorage persist. So, you can change your state and when the user reload
 * the browser, the state will persist!
 *
 * @param key name of the key to be stored on localStorage
 * @param initialValue value to be the default, like useState('defaultvalue')
 * @param expire time in seconds to expiry this state, set false to infinite
 */
export function useLocalStorage<T>({
  initialValue,
  key,
  expire,
  id,
}: IUseLocalStorage<T>): [T, Dispatch<SetStateAction<T>>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  useEffect(() => {
    storage
      .load<T>({ key })
      .then((response) => {
        if (response) {
          setStoredValue(response);
        }
      })
      .catch(() => {
        setStoredValue(initialValue);
      });
  }, []);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: SetStateAction<T>): void => {
    try {
      // const expiredStorage = new ExpiredStorage();

      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      // Save state
      setStoredValue(valueToStore);

      // Console log para checar o valor que está sendo armazenado
      if (!!valueToStore) {
        storage.save({
          key,
          id,
          data: valueToStore,
          expires: expire,
        });
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
