import { VStack } from 'native-base';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Grid, Row } from 'react-native-easy-grid';
import FormInput from '../../../components/Input/FormInput';
import InputAddon from '../../../components/Input/InputAddon';
import { t } from '../../../languages';
import { IJetWashCycle } from '../../../types/wash_cycle.type';

interface Step4ExtrasRinsesProps {}

const Step4ExtrasRinses: React.FC<Step4ExtrasRinsesProps> = () => {
  const methods = useFormContext<IJetWashCycle>();

  const inputAmountRinses = 'amount_of_rinses';
  const inputAmountTime = 'rinses_amount_time';
  const inputAmountBathRatio = 'rinses_amount_bath_ratio';

  return (
    <VStack space="24px" mb="24px">
      <FormInput
        methods={methods}
        name={inputAmountRinses}
        label={t(
          'domain.analysis.step4.section.extras.subsection.rinses.input.rinses_amount.label'
        )}
        placeholder={t(
          'domain.analysis.step4.section.extras.subsection.rinses.input.rinses_amount.placeholder'
        )}
        inputProps={{
          keyboardType: 'numeric',
          maxLength: 6,
        }}
      />

      <Grid>
        <Col style={{ marginRight: 16 }}>
          <FormInput
            methods={methods}
            name={inputAmountTime}
            label={t('domain.analysis.step4.section.extras.subsection.rinses.input.time.label')}
            placeholder={t(
              'domain.analysis.step4.section.extras.subsection.rinses.input.time.placeholder'
            )}
            inputProps={{
              keyboardType: 'numeric',
              maxLength: 6,
              InputRightElement: <InputAddon str={t('domain.units.time.minutes')} />,
            }}
          />
        </Col>
        <Col>
          <FormInput
            methods={methods}
            name={inputAmountBathRatio}
            label={t(
              'domain.analysis.step4.section.extras.subsection.rinses.input.bath_ratio.label'
            )}
            placeholder={t(
              'domain.analysis.step4.section.extras.subsection.rinses.input.bath_ratio.placeholder'
            )}
            inputProps={{
              keyboardType: 'numeric',
              maxLength: 6,
            }}
          />
        </Col>
      </Grid>
    </VStack>
  );
};

export default Step4ExtrasRinses;
