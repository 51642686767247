import { IJetCentrifuge } from '../../types/centrifuge.type';
import { IJetWashCycle } from '../../types/wash_cycle.type';

interface ICalcCentrifugeEnergy {
  cycle: Partial<IJetWashCycle> | undefined;
  centrifuge: IJetCentrifuge;
}

export function calcCentrifugeEnergy(props: ICalcCentrifugeEnergy): number {
  const _cTime = parseFloat(`${props?.cycle?.extras_centrifuge_run_time || 0}`);
  const _energy = parseFloat(`${props.centrifuge.energy_kw || 0}`);

  return (_cTime * _energy) / 60;
}
