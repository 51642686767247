import { Box, Container, ScrollView, Stack } from 'native-base';
import React, { useMemo } from 'react';
import { Keyboard, Platform, TouchableWithoutFeedback } from 'react-native';
import BackHeader from './BackHeader';

interface PageWithScrollProps {
  backTitle?: string;
  pageTitle?: string;
  pageSubtitle?: string;
  buttonText?: string;
  pt?: string;
  bgColor?: string;
}

export const PageWithScroll: React.FC<PageWithScrollProps> = ({ children, backTitle, buttonText, pt = '16px', bgColor = '#F2F8F7' }) => {
  const isAllPropsUndefined = !backTitle && !buttonText;

  const paddingX = useMemo(() => {
    if (Platform.OS === 'web') {
      const _width = window.innerWidth;
      // Extra extra large	xxl	≥1400px
      if (_width >= 1400) return '220px';

      // Extra large	xl	≥1200px
      if (_width >= 1200) return '140px';

      // Large	lg	≥992px
      if (_width >= 992) return '80px';

      // Medium	md	≥768px
      return '40px';
    }

    return '16px';
  }, []);

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (Platform.OS !== 'web') Keyboard.dismiss();
      }}
    >
      <>
        <Box flex={1} flexGrow={1} paddingX={paddingX} height="100%" backgroundColor={bgColor}>
          {children}
        </Box>
        <Box height={'16px'} />
      </>
    </TouchableWithoutFeedback>
  );

  // return (
  //   <ScrollView
  //     backgroundColor="#F2F8F7"
  //     contentContainerStyle={{ flexGrow: 1 }}
  //     keyboardShouldPersistTaps="handled"
  //   >
  //     <Stack
  //       paddingTop={isAllPropsUndefined ? 0 : '16px'}
  //       paddingX={'16px'}
  //       height="100%"
  //       justifyContent="space-between">
  //       <BackHeader backTitle={backTitle} buttonText={buttonText} />
  //       {children}
  //     </Stack>
  //   </ScrollView>
  // );
};
