import { Text, Box } from 'native-base';
import { FlatList, RefreshControl } from 'react-native';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import Loading from '../../../../components/Loading';
import { t } from '../../../languages';
import { IJetCentrifuge } from '../../../types/centrifuge.type';
import MachineCard from '../components/MachineCard';
import { getCentrifuges } from '../services/getCentrifuges';
import { IWashingPlantForm } from '../types/washingPlantForm.type';
import Centrifuge from '../../../assets/svgs/centrifuge';
import { QueryKeyCache } from '../../../config/query-cache';

import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
interface WashingFormStep6Props {}

export const WashingFormStep6: React.FC<WashingFormStep6Props> = () => {
  const { watch, setValue, trigger } = useFormContext<IWashingPlantForm>();
  const { data, isLoading, refetch } = useQuery(QueryKeyCache.GetCentrifuges, getCentrifuges);

  const selectCentrifuge = (centrifuge: IJetCentrifuge) => {
    if (watch('selectedCentrifugeID')) {
      setValue('selectedCentrifugeID', undefined!);
    } else {
      setValue('selectedCentrifugeID', centrifuge.id);
    }

    trigger();
  };

  return (
    <>
      <Box mb="16px">
        <Text fontSize={22} fontWeight={500} mb={4}>
          {t('domain.washingPlants.step.six.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={22} fontWeight="bold" color="primary.500">
              {t('domain.washingPlants.step.six.title_part_two')}
            </Text>
          </Text>
        </Text>

        <FlatList
          data={data?.results || []}
          ItemSeparatorComponent={() => <Box mb="8px" />}
          ListEmptyComponent={() => (!isLoading ? <EmptyPlaceholder /> : <Loading />)}
          refreshControl={
            <RefreshControl
              colors={['#9Bd35A', '#689F38']}
              refreshing={isLoading}
              onRefresh={refetch}
            />
          }
          renderItem={({ item, index }) => {
            const isLast = data?.results.length ? index === data?.results.length - 1 : false;

            return (
              <Box key={`centrifuge-card-${item.id}`} mx={0.75}>
                <MachineCard
                  name={`${item.brand_detail?.name || ''} - ${item.model}`}
                  onPress={() => selectCentrifuge(item)}
                  isSelected={item.id === watch('selectedCentrifugeID')}
                  icon={<Centrifuge />}
                />
                {isLast && <Box height={10} />}
              </Box>
            );
          }}
        />
      </Box>
    </>
  );
};

export default WashingFormStep6;
