import { IJetCountry } from '../../types/country.type';
import { IJetDryer } from '../../types/dryer.type';
import { IJetHocs } from '../../types/hocs';
import { IJetWashingPlant } from '../../types/washing-plants.type';
import { IJetWashCycle } from '../../types/wash_cycle.type';
import { WATER_SPEC_HEAT_JKG } from '../constants/contants';
import { calcAirHeat, calcWaterHeat } from './cycle.calc';
import { IJetBoilerComplete } from '../../types/boiler.type';

interface ICalcFuelUsed {
  cycle: Partial<IJetWashCycle>;
  washingPlant: Partial<IJetWashingPlant>;
  country: IJetCountry;
  dryer: IJetDryer;
  hoc: IJetHocs;
  boyler: IJetBoilerComplete;
}

export function calcFuelUsed(props: ICalcFuelUsed): number {
  try {
    const _waterHeat = calcWaterHeat(props.cycle, props.washingPlant);
    const _airHeat = calcAirHeat(props.cycle, props.dryer, props.washingPlant);
    const _thermalEfficiency = parseFloat(`${props.boyler.thermal_efficiency || 0}`);
    const _hoc = parseFloat(`${props.hoc['H.o.C. (kJ/mol)'] || 0}`);
    const _molarMass = parseFloat(`${props.hoc['Molar Mass'] || 0}`);

    // Value 100: valor do pencentual no jetadmin entá inteiro por isso multiplacaremos o numerador por 100
    const _fuelUsedInMol = ((_waterHeat + _airHeat) * 100 / _hoc) / _thermalEfficiency;

    // All combustible that has "formula" filled, should be calc using "mol"
    if (props.hoc['Formula'] !== undefined && props.hoc['Formula'] !== null) return _fuelUsedInMol;

    // Every combustible that does not has "formula" filled, should be calc using "g"
    return _fuelUsedInMol * _molarMass;
  } catch (error) {
    return -1;
  }
}
