import { MaterialIcons } from '@expo/vector-icons';
import { HStack, Text } from 'native-base';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomButton } from '../../../components/CustomButton';
import { t } from '../../../languages';
import { useWashingPlantForm } from '../hooks/useWashingPlantForm';
import { IWashingPlantForm } from '../types/washingPlantForm.type';

interface FormNextButtonProps {}

export const FormNextButton: React.FC<FormNextButtonProps> = () => {
  const { watch, formState: { errors, isValid } } = useFormContext<IWashingPlantForm>(); // retrieve all hook methods
  const { canNext, step, nextStep } = useWashingPlantForm();

  const formValues = watch();

  const canGoNext: boolean = useMemo(() => {
    if (step === 1)
      return formValues['washingPlantName'] !== undefined && formValues['washingPlantName'] !== '';
    if (step === 2)
      return (
        formValues['selectedCountryID'] !== undefined && formValues['selectedCountryID'] !== ''
      );
    if (step === 3)
      return (
        formValues['overallLoad'] !== undefined &&
        formValues['overallLoad'] !== '' &&
        formValues['numberOfGarments'] !== undefined &&
        formValues['numberOfGarments'] !== ''
      );
    if (step === 4)
      return (
        formValues['selectedWashingMachineID'] !== undefined &&
        formValues['selectedWashingMachineID'] !== ''
      );
    if (step === 5)
      return formValues['waterTemperature'] !== undefined && formValues['waterTemperature'] !== '';
    if (step === 6)
      return formValues['waterTemperature'] !== undefined && formValues['waterTemperature'] !== '';
    if (step === 7)
      return (
        formValues['selectedCentrifugeID'] !== undefined &&
        formValues['selectedCentrifugeID'] !== ''
      );
    if (step === 8)
      return formValues['selectedBoilerID'] !== undefined && formValues['selectedBoilerID'] !== '';
    if (step === 9)
      return formValues['selectedDryerID'] !== undefined && formValues['selectedDryerID'] !== '';

    if (step === 10) return isValid;

    return true;
  }, [step, canNext, formValues, isValid, errors]);

  return (
    <CustomButton variant="solid" isDisabled={!canGoNext} mt={10} onPress={nextStep}>
      <HStack>
        <Text color="white">{t('domain.generic.next')}</Text>
        <MaterialIcons name="arrow-forward" size={24} color="white" style={{ marginLeft: 5 }} />
      </HStack>
    </CustomButton>
  );
};

export default FormNextButton;
