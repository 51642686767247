import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Box, Stack, Text } from 'native-base';
import React from 'react';
import BackHeader from '../../components/BackHeader';
import { Page } from '../../components/Page';
import { t } from '../../languages';
import { HomeStackParamList } from '../../routes/auth.routes';
import StandardRecipeProvider from './contexts/StandardRecipeContext';
import { StandardRecipeForm } from './screens/StandardRecipeForm';

type Props = NativeStackScreenProps<HomeStackParamList, 'StandardRecipeRoot'>;

const StandardRecipeRoot: React.FC<Props> = ({ route }) => {
  const { washingPlant, washRecipe } = route.params;
  return (
    <Page>
      <Box flex={1} pt="24px">
        <Stack height="100%">
          <StandardRecipeProvider>
            <StandardRecipeForm washingPlant={washingPlant} washRecipe={washRecipe} />
          </StandardRecipeProvider>
        </Stack>
      </Box>
    </Page>
  );
};

export default StandardRecipeRoot;
