import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Heading, Text, } from 'native-base';
import React, { useState } from 'react';
import BackHeader from '../../../../components/BackHeader';
import { Page } from '../../../../components/Page';
import { t } from '../../../../languages';
import { GuestRootStackParamList } from '../../../../routes/guest.routes';
import { SignUpForm } from '../../../professional/components/SignUpForm';
import { SignUpForm2 } from '../../../professional/components/SignUpForm2';
import { IJetCreateUserStepOne } from '../../services/createUserOnJetAdmin';

interface SignUpInterface {
  navigation: NativeStackNavigationProp<GuestRootStackParamList>;
}

export function SignUp({ navigation }: SignUpInterface) {
  const [formStep, setFormStep] = useState<number>(1);
  const [fieldsStepOne, setFieldsStepOne] = useState<IJetCreateUserStepOne | null>(null);

  function handleGoToStepTwo(data: IJetCreateUserStepOne){
    setFieldsStepOne(data)
    setFormStep(2);
  }

  const _handleOnPress = () => {
    if (formStep === 2) {
      setFormStep(1);
    } else {
      navigation.goBack();
    }
  };

  return (
    <Page>
      <BackHeader backTitle onPress={_handleOnPress} />

      <Heading>{t('domain.signUp.screen1.pageTitle')}</Heading>
      <Text mt="6px">{t('domain.signUp.screen1.subtitle')}</Text>

      {formStep === 1 && (
        <SignUpForm
          fieldsStepOne={fieldsStepOne}
          handleGoToStepTwo={handleGoToStepTwo}
          navigation={navigation}
        />
      )}
      {formStep === 2 && <SignUpForm2 fieldsStepOne={fieldsStepOne} navigation={navigation} />}
    </Page>
  );
}
