import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from '../../../components/Input/FormInput';
import InputAddon from '../../../components/Input/InputAddon';
import { t } from '../../../languages';
import { IJetWashCycle } from '../../../types/wash_cycle.type';
import { useStandardRecipeForm } from '../../standardRecipe/hooks/useStandardRecipeForm';
import { IStandardRecipeForm } from '../../standardRecipe/types/standardRecipeForm.type';

interface Step4BathRatioInputProps {}

export const Step4BathRatioInput: React.FC<Step4BathRatioInputProps> = () => {
  const methods = useFormContext<IJetWashCycle>();

  const inputName = 'general_bath_ratio';

  return (
    <FormInput
      methods={methods}
      name={inputName}
      label={t('domain.analysis.step4.section.general_information.input.bath_ratio.label')}
      placeholder={t(
        'domain.analysis.step4.section.general_information.input.bath_ratio.placeholder'
      )}
      inputProps={{
        keyboardType: 'numeric',
        maxLength: 6,
        InputLeftElement: (
          <InputAddon borderLeft={0} borderRight={1} str={t('domain.units.other.oneTo')} />
        ),
      }}
    />
  );
};

export default Step4BathRatioInput;
