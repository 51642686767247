import { FormControl, Checkbox } from 'native-base';
import React, { useMemo } from 'react';
import {
  Controller,
  UseFormReturn,
} from 'react-hook-form';

interface FormInputProps<T> {
  methods: UseFormReturn<any, any>;
  name: string;
  label?: string;
  isDisabled?: boolean;
  showErrorMessage?: boolean;
}

const ControlledCheckbox: React.FC<FormInputProps<unknown>> = ({
  methods,
  name,
  label,
  isDisabled = false,
  showErrorMessage = false,
}) => {
  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <FormControl isInvalid={!!errors[name]} style={{ width: 'auto' }}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            {label && <FormControl.Label>{label}</FormControl.Label>}
            <Checkbox
              name={name}
              value={value}
              isChecked={value}
              onChange={onChange}
              accessibilityState={{ checked: Boolean(name) }}
              accessibilityLabel="Use terms and private policy agreement"
            />
            {showErrorMessage && (
              <FormControl.ErrorMessage>
                {errors[name]?.message}
              </FormControl.ErrorMessage>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default ControlledCheckbox;
