import * as React from 'react';
import Svg, { SvgProps, Circle, Mask, G, Path } from 'react-native-svg';

const EmptyUserAvatar = (props: SvgProps) => (
  <Svg {...props} width={32} height={32} fill="none">
    <Circle opacity={0.1} cx={16} cy={16} r={16} fill="#368D7D" />
    <Mask id="a" x={0} y={0} width={32} height={32}>
      <Circle cx={16} cy={16} r={16} fill="#C4C4C4" />
    </Mask>
    <G mask="url(#a)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.226 20.439a7.2 7.2 0 1 0-6.452 0C7.714 21.848 4 26.49 4 32h24c0-5.51-3.713-10.152-8.774-11.561Z"
        fill="#368D7D"
      />
    </G>
  </Svg>
);

export default EmptyUserAvatar;
