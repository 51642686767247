import React, { MutableRefObject } from 'react';
import { IInputProps, Input as NativeBaseInput } from 'native-base';
import {
  NativeSyntheticEvent,
  ReturnKeyTypeOptions,
  TextInputSubmitEditingEventData,
  KeyboardTypeOptions,
} from 'react-native';
import { Control } from 'react-hook-form';

interface InputProps extends IInputProps {
  value: string;
  ref?: MutableRefObject<IInputProps | undefined>;
  placeholder: string;
  type?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  returnKeyType?: ReturnKeyTypeOptions | undefined;
  onSubmitEditing?:
    | ((e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void)
    | undefined;
  blurOnSubmit?: boolean;
  onChangeText: (text: string) => void;
  inputRightElement?: JSX.Element | JSX.Element[] | null;
  inputLeftElement?: JSX.Element | JSX.Element[] | null;
  multiline?: boolean;
  numberOfLines?: number;
  minHeight?: number | string;
  keyboardType?: KeyboardTypeOptions;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  maxLength?: number;
  flex?: number;
  border?: boolean;
  control?: Control;
}

export const Input = React.forwardRef((props: InputProps, ref) => (
  <NativeBaseInput
    autoCapitalize={props.autoCapitalize}
    keyboardType={props.keyboardType}
    type={props.type}
    placeholder={props.placeholder}
    autoCorrect={false}
    value={props.value}
    ref={ref}
    isInvalid={props.isInvalid}
    isDisabled={props.isDisabled}
    onChangeText={props.onChangeText}
    InputRightElement={props.inputRightElement || <></>}
    InputLeftElement={props.inputLeftElement || <></>}
    returnKeyType={props.returnKeyType}
    onSubmitEditing={props.onSubmitEditing}
    blurOnSubmit={props.blurOnSubmit}
    numberOfLines={props.numberOfLines}
    multiline={props.multiline}
    minHeight={props.minHeight}
    maxLength={props.maxLength}
    flex={props.flex}
    background="#E4EEED"
    padding={'16px'}
    borderRadius={8}
    fontStyle={'normal'}
    fontWeight={400}
    fontSize={'16px'}
    borderWidth={props.border ? 1 : 0}
    placeholderTextColor={'rgba(165, 178, 178, 1)'}
    color={'#368D7D'}
  />
));
