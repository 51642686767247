export const ptBR = {
  form: {
    validation: {
      error: {
        required: 'This field is required',
        minLength: 'This field must be at least {minLength} characters',
        maxLength: 'This field must be at most {maxLength} characters',
        pattern: 'This field must match the pattern {pattern}',
        number: 'This field must be a number',
        email: 'This field must be a valid email',
        url: 'This field must be a valid url',
        date: 'This field must be a valid date',
        dateISO: 'This field must be a valid date (ISO)',
        digits: 'This field must be digits',
        password: 'This field must be a valid password',
        passwordMatch: 'This field must match the password field',
        generic: 'There are some errors',
        error_unexpected: 'Unexpected error',
      },
      mixed: {
        default: '${path} It is invalid',
        required: '${path} It is a mandatory field',
        oneOf: '${path} must be one of the following values: ${values}',
        notOneOf: '${path} cannot be one of the following values: ${values}',
        notType: {
          must_be_a: 'must be a',
          type: 'type',
        },
        defined: '${path} must be defined',
      },
      string: {
        length: '${path} must have exactly ${length} characters',
        min: '${path} must have at least ${min} characters',
        max: '${path} Must have at most ${max} characters',
        email: '${path} It has the invalid email format',
        url: '${path} must have a valid URL format',
        trim: '${path} It should not contain spaces at the beginning or end.',
        lowercase: '${path} It must be in lowercase',
        uppercase: '${path} Must be in uppercase',
      },
      number: {
        min: '${path} must be at least ${min}',
        max: '${path} must be at most ${max}',
        lessThan: '${path} must be smaller than ${less}',
        moreThan: '${path} It must be greater than ${more}',
        notEqual: '${path} cannot be equal to ${notEqual}',
        positive: '${path} must be a positive number',
        negative: '${path} must be a negative number',
        integer: '${path} must be an integer',
      },
      date: {
        min: '${path} must be greater than the date ${min}',
        max: '${path} must be less than the date ${max}',
      },
      array: {
        min: '${path} must have at least ${min} items',
        max: '${path} Must have at most ${max} items',
      },
    },
  },
  default: {
    confirm: {
      title: 'Watch out!',
      confirm_text: "Yes, I'm sure",
      cancel_text: 'Cancel',
      message: 'This operation cannot be undone. Are you sure you want to continue?',
    },
  },
  units: {
    time: {
      minutes: 'Min',
      seconds: 'Sec',
    },
    temperature: {
      celsius: '°C',
    },
    other: {
      kg: 'Kg',
      units: 'Units',
      percent: '%',
      oneTo: '1:',
    },
  },
  words: {
    boiler: 'boiler',
    dryer: 'dryer',
    centrifuge: 'centrifuge',
    washingMachine: 'washing machine',
  },
  generic: {
    ok: 'OK',
    cancel: 'Cancel',
    logout: 'Logout',
    yes: 'Yes',
    no: 'No',
    close: 'Close',
    save: 'Save',
    delete: 'Delete',
    delete_message: 'Are you sure you want to delete?',
    removeSuccessfully: 'Removed successfully',
    edit: 'Edit',
    create: 'Create',
    update: 'Update',
    search: 'Search',
    confirm: 'Confirm',
    reset: 'Reset',
    back: 'Back',
    next: 'Next',
    previous: 'Previous',
    loading: 'Loading...',
    saving: 'Saving...',
    error: 'Error',
    success: 'Success',
    warning: 'Warning',
    info: 'Info',
    empty: 'Oops, nothing to see here!',
    try_again: 'Try again',
    refresh: 'Refresh',
    country: 'Country',
    start: 'Get Started',
  },
  aboutUs: {
    section: {
      aboutUs: {
        title: 'About us',
        description:
          "greenofchange® is Garmon's platform to enhance chemical safety and to improve environmental performance in garments treatment",
        link: 'https://www.greenofchange.com/',
        linkTitle: 'https://www.greenofchange.com/',
      },
      copyright: {
        title: 'Copyright',
        description:
          '© Kemin Industries, Inc. and its group of companies 2023 All rights reserved. ® ™ Trademarks of Kemin Industries, Inc., USA Certain statements may not be applicable in all geographical regions. Product labeling and associated claims may differ based upon government requirements.',
      },
      version: 'App version',
    },
  },
  home: {
    welcome: 'Hello',
    search: {
      placeholder: 'Search Washing Plant',
    },
    washingPlants: 'Washing Plants',
    washingPlantsEmpty: "You don't have any washing plant yet",
    button: {
      addNew: 'Add New Plant',
      know_more: 'Know more',
    },
    tabs: {
      my_cycles: 'My cycles',
      summary: 'Summary',
      report: 'Reports',
    },
  },
  washingPlants: {
    step: {
      one: {
        title_part_one: 'What is the name',
        title_part_two: 'of your Washing Plant?',
        input: {
          placeholder: 'Washing Plant Name',
        },
      },
      two: {
        title_part_one: 'Where',
        title_part_two: 'is your laundry located?',
        input: {
          placeholder: 'Select your country',
        },
      },
      three: {
        title_part_one: 'What',
        title_part_two: 'is the',
        title_part_three: 'Washing Load?',
        input: {
          overall: {
            placeholder: 'Overall Load',
            label: 'Overall load',
          },
          garments: {
            placeholder: 'N° of garments treated',
            label: 'N° of garments treated',
          },
        },
      },
      four: {
        title_part_one: 'Which one is your',
        title_part_two: 'Washing Machine?',
        input: {
          fallback: "I don't know, use the standard machine",
        },
      },
      five: {
        title_part_one: 'What is the',
        title_part_two: 'Feed Water Temperature',
        title_part_three: 'of the washing machine?',
        washingMachine: 'Washing Machine',
      },
      six: {
        title_part_one: 'What is your',
        title_part_two: 'Centrifuge?',
        fallback: "I don't know, use the standard machine",
      },
      // seven: Boiler
      seven: {
        title_part_one: 'What is your',
        title_part_two: 'Boiler?',
        fallback: "I don't know, use the standard machine",
      },
      eight: {
        title_part_one: 'What is your',
        title_part_two: 'Dryer?',
        fallback: "I don't know, use the standard machine",
      },
      nine: {
        title_part_one: 'Review your',
        title_part_two: 'Washing Plant',
        overallLoad: 'Overall Load (kg of fabric)',
        garments: 'N° of garments treated',
        washingMachine: 'Washing Machine',
        centrifuge: 'Centrifuge',
        boiler: 'Boiler',
        dryer: 'Dryer',
        tapToEdit: 'Tap to edit',
        submit: 'Confirm',
        backHome: 'Back to Home',
        review: {
          garments: 'N° of garments',
          overall_load: 'Overall Load',
          water: 'Water Temp.',
          air: 'Air Temp.',
        },
      },
      ten: {
        title_part_one: 'What is the',
        title_part_two: 'Air Temperature',
        title_part_three: 'of the washing plant?',
        washingMachine: 'Washing Machine',
      },
      success: {
        title: 'Your Washing Plant is ready',
        subtitle: 'You can now add new Recipes to your Washing Plant',
        backTitle: 'Washing Plant Created',
        button: {
          home: 'Back to Home',
          addNew: 'Add New Recipe',
        },
      },
    },
  },
  wash_recipes: {
    my_analysis: 'My Analysis',
    washing_plant_details: 'Washing Plant Details',
    empty: "You don't have any wash recipes yet",
    button: {
      addNew: 'Add New Analysis',
    },
  },
  analysis: {
    onboarding: {
      step1: {
        title: 'Cycle setup',
        subtitle:
          'You have just started a new recipe! To obtain results, please, include the cycles you perfom in this recipe. Remember: For each cycle you will need to include your current standard wash and the proposed greenofchange recipe.',
      },
      step2: {
        title: 'Current Cycle',
        subtitle:
          "For the Current Cycle, you should fill out exactly like you do in your laundry. You'll be able to add the chemicals, auxiliary equipments and more!",
      },
      step3: {
        title: 'greenofchange Cycle',
        subtitle:
          "In this step, you will create a more sustainable version of your cycle! Don't worry, if you don't know how to do it, you can ask Garmon to help you.",
      },
      step4: {
        title: 'Get the results!',
        subtitle:
          'After setting up the two cycles, you will get the results of how much you can save by applying the greenofchange recipe!',
      },
    },
    step1: {
      title_part_one: 'What is the',
      title_part_two: 'name of your wash?',
      input: {
        placeholder: 'Wash Name',
      },
    },
    step2: {
      title_part_one: 'Wash Analysis',
      cycle: 'Cycle',
      add_cycle: 'Add Cycle',
      no_cycles_empty: "You don't have any cycle yet",
      no_cycle_type_selected: 'Cycle without type selected',
      my_cycles: 'My Cycles',
      you_saved: 'You saved',
      your_recipe: 'Current recipe',
      green_recipe: 'greenofchange recipe',
      green_recipe_ask_helped: 'A Garmon specialist will contact you soon!',
      modal: {
        title: 'greenofchange recipe',
        errorTitle: 'No Current Recipe',
        subtitle: 'Do you already know how to fill out the greenofchange recipe?',
        errorSubtitle: 'Please fill Current Recipe first.',
        subtitle_asked_help:
          'A Garmon specialist will contact with you soon, but you can edit the greenofchange recipe if you want!',
        button: {
          yes: 'Yes, I do',
          no: 'No, I need help',
          help_asked: 'Help asked',
          continue: 'Continue',
          continueError: 'Ok',
        },
        options: {
          blank_recipe: 'Yes, start blank recipe',
          copy_standard: 'Yes, copy current recipe',
          need_help: 'No, I need help!',
        },
      },
      modal_help: {
        title: 'We will be in touch',
        subtitle:
          'A Garmon representative will contact you to help you fill out your sustainable recipe',
        button: {
          close: 'Close',
        },
        submiting: 'Send an email to Garmon, please wait...',
        error: 'Something went wrong, please try again',
      },
      toast: {
        removeSuccessfully: 'Cycle removed successfully',
        message: 'Are you sure you want to remove the cycle',
      },
      button: {
        add_current_recipe: 'Setup current recipe',
        add_new_recipe: 'Setup greenofchange recipe',
        remove: 'Remove',
      },
      input: {
        placeholder: 'Wash Name',
      },
    },
    step3: {
      title_part_one: 'What type of cycle',
      title_part_two: 'do you want to add?',
      select: {
        title: 'Select a type',
        message: 'Do you want to select: ',
      },
      modal: {
        title: 'Standard recipe',
        subtitle: 'Do you want to use the standard recipe for',
        button: {
          use_standard: 'Yes, use the standard recipe',
          use_own_recipe: 'No, I want to fill my own recipe',
        },
      },
    },
    step4: {
      header: {
        your_recipe: 'Current Recipe',
        green_recipe: 'greenofchange recipe',
      },
      section: {
        general_information: {
          title: 'General Information',
          input: {
            time: {
              label: 'Time',
              placeholder: 'Insert time',
            },
            bath_ratio: {
              label: 'Bath Ratio',
              placeholder: 'Insert bath ratio',
            },
            temperature: {
              label: 'Temperature',
              placeholder: 'Insert temperature',
              hint: 'The above value starts with the water temperature of your washing plant.',
              minTemperatureError: "ATTENTION: This temperature cannot be lower than the washing plant's water feed temperature."
            },
          },
        },
        add: {
          title: 'Add',
          subsection: {
            chemicals: {
              title: 'Chemicals',
              button: {
                add: 'Add Chemical',
                remove: 'Remove',
              },
            },
            auxiliary_equipment: {
              title: 'Auxiliary Equipment',
              button: {
                add: 'Add Auxiliary Equipment',
                remove: 'Remove',
              },
            },
            laser_scraping: {
              title: 'Laser Scraping',
              button: {
                add: 'Add Laser Scraping',
                remove: 'Remove',
              },
            },
          },
        },
        extras: {
          title: 'Extras',
          subsection: {
            rinses: {
              title: 'Add Rinses',
              input: {
                rinses_amount: {
                  label: 'Amount of Rinses',
                  placeholder: 'Insert amount',
                },
                time: {
                  label: 'Time',
                  placeholder: 'Insert time',
                },
                bath_ratio: {
                  label: 'Bath Ratio',
                  placeholder: 'Insert bath ratio',
                },
              },
            },
            centrifuge: {
              title: 'Add Centrifuge',
              input: {
                run_time: {
                  label: 'Run Time',
                  placeholder: 'Insert run time',
                },
                pickup: {
                  label: 'Pickup',
                  placeholder: 'Insert pickup',
                },
              },
              drying: {
                title: 'Add Drying',
                input: {
                  run_time: {
                    label: 'Run Time',
                    placeholder: 'Insert run time',
                  },
                  temperature: {
                    label: 'Temperature',
                    placeholder: 'Insert temperature',
                    minTemperatureError: "ATTENTION: This temperature cannot be lower than the washing plant's air temperature."
                  },
                },
              },
            },
          },
        },
      },
    },
    step5: {
      title_part_one: 'Which',
      title_part_two: 'chemical',
      title_part_three: 'do you want to add?',
      fallback: {
        chemical_without_name: 'No chemical selected',
      },
      back: {
        modal: {
          message:
            "Your chemical is incomplete, if you go back you'll lose your chemical. Are you sure you want to go back?",
        },
      },
      input: {
        chemical: {
          label: 'Chemical',
          placeholder: 'Select the chemical',
          searchPlaceholder: 'Search chemicals',
        },
        dose: {
          label: 'Dose',
          placeholder: 'Insert dose',
        },
        unit: {
          label: 'Unit',
          placeholder: 'Select unit',
        },
        time: {
          label: 'Time',
          placeholder: 'Insert time',
        },
        bath_ratio: {
          label: 'Bath Ratio',
          placeholder: 'Insert bath ratio',
        },
        temperature: {
          label: 'Temperature',
          placeholder: 'Insert temperature',
        },
      },
    },
    step6: {
      title_part_one: 'Which',
      title_part_two: 'auxiliary equipment',
      title_part_three: 'do you want to add?',
      fallback: {
        auxiliary_equipment_without_name: 'No auxiliary equipment selected',
      },
      back: {
        modal: {
          message:
            'Your auxiliary equipment is incomplete, if you go back you will lose your auxiliary equipment. Are you sure you want to go back?',
        },
      },
      input: {
        auxiliary_equipment: {
          label: 'Auxiliary equipment',
          placeholder: 'Select the auxiliary equipment',
        },
        time: {
          label: 'Time',
          placeholder: 'Insert time',
        },
        parallel: {
          label: 'Auxiliary equipment runs before, or after, the washing process',
        },
      },
    },
    step7: {
      title_part_one: 'Which',
      title_part_two: 'laser',
      title_part_three: 'do you want to add?',
      fallback: {
        laser_scraping_without_name: 'No laser selected',
      },
      back: {
        modal: {
          message:
            'Your laser scraping is incomplete, if you go back you will lose your laser scraping. Are you sure you want to go back?',
        },
      },
      input: {
        laser_scraping: {
          label: 'Laser scraping',
          placeholder: 'Select the laser scraping',
        },
        machine_operation_time: {
          label: 'Machine Operation Time',
          placeholder: 'Insert operation time',
        },
        labor_operation_time: {
          label: 'Labor Operation Time',
          placeholder: 'Insert operation time',
        },
      },
    },
  },
  signUp: {
    screen1: {
      backTitle: 'New account',
      pageTitle: 'Create a New Account',
      subtitle: 'Please fill in this form to create an account.',
      heading: 'Welcome',
      text: 'We need some information from you to create an account',
      inputLabel: 'Full Name',
      inputPlaceholder: 'Full Name',
      inputPlaceholder2: 'Company',
      inputPlaceholder3: 'Contact Phone',
      inputPlaceholder4: 'E-mail',
      inputPlaceholder5: 'Country',
      agreement1: 'I agree with the',
      agreement2: 'Use terms and conditions',
      agreement3: 'and',
      agreement4: 'Privacy Policy',
      buttonNext: 'Create Account',
      buttonFb: 'Continue with Facebook',
      haveAccount: 'I already have an account.',
      buttonLogin: 'Sign in',
      acceptTermsRequiredMessage: 'You must accept the terms of use and privacy terms to continue.',
      alreadyExistsEmail: "User email already exists, try another or recover your password at Sign in screen",
    },
    profile: {
      back: 'Profile',
      section: {
        personal_information: {
          title: 'Personal Information',
          name: {
            label: 'Name',
            placeholder: 'Your name',
          },
          email: {
            label: 'Email',
            placeholder: 'Your email',
          },
          phone: {
            label: 'Phone',
            placeholder: 'Your phone',
          },
          company: {
            label: 'Company',
            placeholder: 'Your company',
          },
        },
        security: {
          title: 'Security',
          change_password: 'Change password',
        },
        delete: {
          title: 'Data management',
          button: 'Delete my account',
          modal: {
            title: 'Delete account',
            message: 'Are you sure you want to delete your account? This operation is irreversible',
            yes: 'Yes',
            no: 'Cancel',
            error: "Can't delete user, try again in a few minutes or contact us",
            success: 'Account successfully deleted',
          },
        },
      },
    },
    waitingApproval: {
      title: 'Waiting for approval',
      subtitle: 'We are analyzing your registration and soon you will be able to access the app.',
      button: 'Back to Home',
    },
    screen3: {
      heading: 'Protect your account',
      text: 'Create a password to protect your account',
      passwordInputLabel: 'Password',
      passwordInputPlaceholder: 'Password',
      confirmPasswordInputLabel: 'Confirm Password',
      confirmPasswordInputPlaceholder: 'Confirm Password',
      buttonSubmit: 'Create my account',
      errorMsg: 'Unable to create user',
    },
  },
  signIn: {
    user_not_active: 'Your user is awaiting for Garmon approval',
    backTitle: 'Login',
    pageTitle: 'Hello!',
    dontHaveAccount: "I don't have an account.",
    signUp: 'Sign up',
    subtitle: 'Welcome to the greenofchange App. Please login with your credentials.',
    inputs: {
      email: {
        label: 'Email',
        placeholder: 'Email',
      },
      password: {
        label: 'Password',
        placeholder: 'Password',
      },
    },
    buttons: {
      signIn: 'Login',
      signUp: 'Sign up',
      forgotPassword: 'Oops... I forgot my password',
      facebook: 'Continue with Facebook',
      google: 'Continue with Google',
      twitter: 'Continue with Twitter',
    },
  },

  resetPassword: {
    backTitle: 'Reset password',
    backToSignIn: 'Back to Sign In',
    pageTitle: 'Reset your password',
    subtitle: 'Please enter your registered email to reset your password',
    reset_password_subtitle: 'Enter your new account password',
    invalid_token_recovery: 'Invalid or expired password recovery token.',
    inputLabel: 'Email',
    inputPlaceholder: 'Email',
    successMessage: 'Password changed successfully',
    emailNotFound: 'This email address was not found. Do you want',
    emailNotFoundLink: 'create an account?',
    successHeading: 'Check your email',
    successText: 'We have sent you a reset password link on',
    inputs: {
      email: {
        label: 'Email',
        placeholder: 'Email',
      },
    },
    buttons: {
      reset: 'Reset Password',
    },
  },
  knowMore: {
    backTitle: 'Your Savings',
    title_part_one: 'Know more about your',
    title_part_two: 'Savings',
    info_part_one: 'Know how much your savings could',
    info_part_two: 'reduce the impact in the environment',
    inputLabel_part_one: 'How many',
    inputLabel_part_two: 'batches',
    inputLabel_part_three: 'of this recipe do you process in 1 day of work?',
    inputSearchPlaceholder: 'Insert batches',
    button: 'Know more about your savings',
    enviroments: {
      backTitle: 'Environment Impact',
      title_part_one: 'Look at the positive impact your savings could make',
      title_part_two: 'to the world in 1 year!',
      cards: {
        tree: 'trees to absorb the same amount of CO2',
        people: 'people could have clean water in Brazil',
        wheat: 'Kg of wheat could be produced for food',
        lamp: 'houses in India could be powered',
        washing: 'Extra batches could be run for this wash',
      },
    },
  },
  month: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  camera: {
    launchCamera: 'Launch camera',
    launchImageLibrary: 'Launch Image Library',
  },
  test: {
    testText: 'Test text',
  },
};
