import { Box, Button, Center, HStack, Spinner, Text, VStack } from 'native-base';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Platform } from 'react-native';
import InputSpinner from 'react-native-input-spinner';
import { useQuery } from 'react-query';
import Machine from '../../../assets/svgs/machine';
import { QueryKeyCache } from '../../../config/query-cache';
import { t } from '../../../languages';
import { getAllWashingMachines } from '../services/getWashingMachines';
import { IWashingPlantForm } from '../types/washingPlantForm.type';

interface WashingFormStep5Props {}

export const WashingFormStep5: React.FC<WashingFormStep5Props> = () => {
  const { data, isLoading } = useQuery(QueryKeyCache.GetAllWashingMachines, getAllWashingMachines);
  const { watch, setValue, trigger } = useFormContext<IWashingPlantForm>();

  const selectedMachine = useMemo(() => {
    const machineId = watch('selectedWashingMachineID');
    if (machineId) {
      return data?.results.find((machine) => machine.id === machineId);
    }
  }, [data]);

  const changeTemperature = (value: unknown) => {
    setValue('waterTemperature', parseInt(`${value || 0}`));
    trigger();
  };

  return (
    <>
      <Box>
        <Text fontSize={22} fontWeight={500}>
          {t('domain.washingPlants.step.five.title_part_one')}
          <Text>
            {' '}
            <Text fontSize={22} fontWeight="bold" color="primary.500">
              {t('domain.washingPlants.step.five.title_part_two')}
              <Text fontSize={22} fontWeight={500}>
                {' ' + t('domain.washingPlants.step.five.title_part_three')}
              </Text>
            </Text>
          </Text>
        </Text>

        <Box mt="32px">
          <HStack alignContent="center" alignItems="center">
            <Machine />
            <Box ml={2}>
              <VStack>
                <Text fontSize="md">{t('domain.washingPlants.step.five.washingMachine')}</Text>
                <Text fontSize="lg" fontWeight="bold" color="primary.500">
                  {`${selectedMachine?.brand_detail?.name} - ${selectedMachine?.model}`}
                </Text>
              </VStack>
            </Box>
          </HStack>
        </Box>

        <Box mt={32}>
          <InputSpinner
            max={99}
            min={0}
            step={1}
            colorMax={'#D13D3D'}
            colorMin={'#40c5f4'}
            color={'#409D27'}
            fontSize={62}
            value={watch('waterTemperature')}
            autoFocus={true}
            decimalSeparator={'.'}
            style={{
              width: '50%',
              alignSelf: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
            inputStyle={{ width: '50%', height: 64 }}
            buttonStyle={{ marginLeft: '30%', marginRight: '30%' }}
            onChange={changeTemperature}
          >
            {/* // align on top */}
            <HStack space={2} mb={10}>
              {Platform.OS === 'web' && (
                <Text fontSize={Platform.OS === 'web' ? 48 : 28}>{watch('waterTemperature')}</Text>
              )}
              <Text fontSize={Platform.OS === 'web' ? 32 : 20}>°C</Text>
            </HStack>
          </InputSpinner>
        </Box>
      </Box>
    </>
  );
};

export default WashingFormStep5;
