import { Box, HStack, VStack } from 'native-base';
import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';
import Step4BathRatioInput from '../inputs/Step4BathRatioInput';
import Step4TemperatureInput from '../inputs/Step4TemperatureInput';
import Step4TimeInput from '../inputs/Step4TimeInput';

interface WashCycleGeneralInformationProps {}

export const WashCycleGeneralInformation: React.FC<WashCycleGeneralInformationProps> = () => {
  return (
    <>
      <VStack space={'24px'}>
        <Grid>
          <Col style={{ marginRight: 16 }}>
            <Step4TimeInput />
          </Col>
          <Col>
            <Step4BathRatioInput />
          </Col>
        </Grid>

        <Step4TemperatureInput />
      </VStack>
    </>
  );
};

export default WashCycleGeneralInformation;
