import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Center, Flex } from 'native-base';
import React, { useContext } from 'react';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import BackHeader from '../../../components/BackHeader';
import { Page } from '../../../components/Page';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import WashCycleForm from '../WashCycleForm';
import { WashRecipeAggregatorContext } from '../../washRecipeAggregator/contexts/WashRecipeAggregatorContext';

type WashCycleRootProps = NativeStackScreenProps<HomeStackParamList, 'washRecipeCycle'>;

const WashCycleRoot: React.FC<WashCycleRootProps> = ({ route }) => {
  const { washRecipeAggregator, iSelectRecipe } = route.params;
  const { completeWashInformation } = useContext(WashRecipeAggregatorContext);

  const currentAggregatorToEdit = completeWashInformation?.aggregators.find((item) => item.aggregator_id === washRecipeAggregator.id);
  const currentCycleToEdit = iSelectRecipe === 'current_recipe'
    ? currentAggregatorToEdit?.currentRecipe
    : currentAggregatorToEdit?.greenRecipe;

  if (!currentCycleToEdit)
    return (
      <Page>
        <BackHeader backTitle mb={0} />
        <Flex style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Center>
            <EmptyPlaceholder emptyText={t('domain.form.validation.error.error_unexpected')} />
          </Center>
        </Flex>
      </Page>
    );

  return (
    <WashCycleForm
      washRecipeAggregator={washRecipeAggregator}
      washCycle={currentCycleToEdit}
      iSelectRecipe={iSelectRecipe}
    />
  );
};

export default WashCycleRoot;
