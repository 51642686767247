import { useNavigation } from '@react-navigation/native';
import { Box, Button, Image, Text } from 'native-base';
import React from 'react';
import Img from '../assets/svgs/back';
import { t } from '../languages';

interface BackHeaderProps {
  backTitle?: boolean;
  buttonText?: string;
  onPress?: () => void;
  mb?: number | string;
}

export const BackHeader: React.FC<BackHeaderProps> = ({
  backTitle,
  buttonText,
  onPress,
  mb = '47px',
}) => {
  const { goBack, canGoBack } = useNavigation();

  return (
    <>
      {backTitle && (
        <Box mb={mb} justifyContent="flex-start">
          <Button
            onPress={() => {
              if (onPress) {
                onPress();
                return;
              }

              if (canGoBack()) {
                goBack();
              }
            }}
            bgColor="transparent"
            variant="ghost"
            alignSelf={'flex-start'}
            startIcon={
              <Box mr={'20px'} ml={-2}>
                <Img />
              </Box>
            }
          >
            <Box ml={-3}>
              <Text>{buttonText || t('domain.generic.back')} </Text>
            </Box>
          </Button>
        </Box>
      )}
    </>
  );
};

export default BackHeader;
