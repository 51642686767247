import { MaterialIcons } from '@expo/vector-icons';
import { HStack, Text } from 'native-base';
import React from 'react';
import { CustomButton } from '../../../components/CustomButton';
import { t } from '../../../languages';

interface StandardFormNextButtonProps {
  isDisabled?: boolean;
  onPress: () => void;
}

const StandardFormNextButton: React.FC<StandardFormNextButtonProps> = ({
  isDisabled = false,
  onPress,
}) => {
  return (
    <CustomButton variant="solid" isDisabled={isDisabled} mt={10} onPress={onPress}>
      <HStack>
        <Text color="white">{t('domain.generic.next')}</Text>

        <MaterialIcons name="arrow-forward" size={24} color="white" style={{ marginLeft: 5 }} />
      </HStack>
    </CustomButton>
  );
};

export default StandardFormNextButton;
