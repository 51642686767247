import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import {
  IJetTLaserScraping,
  IJetTLaserScrapingBrand,
  IJetTLaserScrapingID,
  IJetTLaserScrapingType,
} from '../../../types/laser_scraping.type';

export interface IJetLaserScrapingsList
  extends IJetAdminResponseProtocol<IJetLaserScrapingsComplete[]> {}

export interface IJetLaserScrapingsComplete extends IJetTLaserScraping {
  brand_detail?: IJetTLaserScrapingBrand;
  type_detail?: IJetTLaserScrapingType;
}

export async function getLaserScraping(
  id: IJetTLaserScrapingID | undefined
): Promise<IJetTLaserScraping | undefined> {
  if (!id) return undefined;
  const response = await jetAdmin.retrieve<IJetTLaserScraping>(
    JetAdminCollection.LaserScrapings,
    id
  );

  return response;
}

export async function getLaserScrapings(): Promise<IJetLaserScrapingsList> {
  const resLaserScrapings = await jetAdmin.list<IJetTLaserScraping[]>(
    JetAdminCollection.GetAllInfosFromLasersScraping
  );

  // VALIDAR POR QUE LASER SCRAPING NÃO TEM MODEL PARA EXIBIR (ESTOU EXIBINDO NAME NO LUGAR)

  const data = resLaserScrapings.results.map((item) => ({
    id: item.laser.id,
    model: item.laser.name,
    name: item.laser.name,
    active: item.laser.active,
    laser_energy_kw: item.laser.laser_energy_kw,
    laser_pcs_per_time: item.laser.laser_pcs_per_time,
    laser_swap_time: item.laser.laser_swap_time,
    laser_scraping_brand: item.laser.laser_scraping_brand,
    laser_scraping_type: item.laser.laser_scraping_type,
    brand_detail: item.brand,
    type_detail: item.laser_type,
  }));

  return {
    ...resLaserScrapings,
    results: data,
  };
}
