import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { calcPercent } from '../../../calcs/total/calc-percent.calc';
import { ICalcResponse } from '../../../calcs/total/calc.type';
import { calcTotalCostFuelCO2 } from '../../../calcs/total/total-co2-fuel.calc';
import { calcTotalCostCycleTime } from '../../../calcs/total/total-cost-cycle-time';
import { calcTotalAbsoluteEnergy } from '../../../calcs/total/total-cost-energy.calc';
import { calcTotalCostWater } from '../../../calcs/total/total-cost-water.calc';
import { QueryKeyCache } from '../../../config/query-cache';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { getAllInfo, IGetEverythingResponse } from '../../../services/getEverything';
import { IJetWashRecipe } from '../../../types/wash-recipe.type';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { IJetWashRecipeAggregatorComplete, IJetWashRecipeAggregatorID } from '../../../types/wash_recipe_aggregator.type';
import { getTypeOfCycles, IJetTypeOfCyclesList } from '../../standardRecipe/services/getTypeOfCycles';
import { ICompleteWashInfo, ICompleteWashInfoAggregators } from '../../../types/wash_cycle.type';
import { IJetTChemical } from '../../../types/chemical.type';
import { IJetTUnit } from '../../../types/unit.type';
import { IJetAuxiliaryEquipmentsComplete } from '../../standardRecipe/services/getAuxiliaryEquipments';
import { IJetLaserScrapingsComplete } from '../../standardRecipe/services/getLaserScrapings';
import { IJetWashCycleChemical } from '../../../types/wash_cycle_chemical.type';
import { IJetWashCycleAuxiliary } from '../../../types/wash_cycle_auxiliary.type';
import { IJetWashCycleLaser } from '../../../types/wash_cycle_laser.type';
import { formatDataToCalc, formatDataToUpdateAuxilliary, formatDataToUpdateChemicals, formatDataToUpdateLaser } from '../../../utils/formats';

export interface ICycleCalculated {
  totalWater: ICalcResponse;
  totalEnergy: ICalcResponse;
  totalFuel: ICalcResponse;
  totalTime: ICalcResponse;
  washAggregatorID: IJetWashRecipeAggregatorID;
}
interface WashRecipeAggregatorProviderInterface {
  aggregators: IJetWashRecipeAggregatorComplete[];
  cycleTypesQuery: UseQueryResult<IJetTypeOfCyclesList, unknown>;
  washingCycleCompleteInfoQuery: UseQueryResult<IGetEverythingResponse[], unknown>;
  washingPlant: IJetWashingPlant | undefined,
  initializeProvider: (washingPlant: IJetWashingPlant, washRecipe: IJetWashRecipe) => void;
  setLastTimeChanged: React.Dispatch<React.SetStateAction<Date | undefined>>;
  updateAggregatorInfo: (data: ICompleteWashInfoAggregators) => void;
  lastTimeChanged: Date | undefined;
  isLoading: boolean;
  cycleSummary: {
    totalWater: number;
    totalWaterPercent: number;
    totalEnergy: number;
    totalEnergyPercent: number;
    totalTime: number;
    totalGreenTime: number;
    totalTimePercent: number;
    totalFuel: number;
    totalFuelPercent: number;
  };
  cycles: ICycleCalculated[];
  allChemicals: IJetTChemical[],
  allUnits: IJetTUnit[],
  allAuxilliaryEquipments: IJetAuxiliaryEquipmentsComplete[],
  allLasersScrapings: IJetLaserScrapingsComplete[];
  setAllChemicals: (data: IJetTChemical[]) => void;
  setAllUnits: (data: IJetTUnit[]) => void;
  setAllAuxilliaryEquipments: (data: IJetAuxiliaryEquipmentsComplete[]) => void;
  setAllLasersScrapings: (data: IJetLaserScrapingsComplete[]) => void;
  removeChemicalFromCompleteInfo: (
    aggregatorId: string,
    cycle: 'currentRecipe' | 'greenRecipe',
    chemicalToRemove: IJetWashCycleChemical
  ) => void;
  removeAuxiliaryFromCompleteInfo: (
    aggregatorId: string,
    cycle: 'currentRecipe' | 'greenRecipe',
    auxiliaryToRemove: IJetWashCycleAuxiliary
  ) => void;
  removeLaserFromCompleteInfo: (
    aggregatorId: string,
    cycle: 'currentRecipe' | 'greenRecipe',
    laserToRemove: IJetWashCycleLaser
  ) => void;
  completeWashInformation: ICompleteWashInfo | undefined;
}

export const WashRecipeAggregatorContext = createContext<WashRecipeAggregatorProviderInterface>({
  aggregators: [],
  cycleTypesQuery: {} as UseQueryResult<IJetTypeOfCyclesList, unknown>,
  washingCycleCompleteInfoQuery: {} as UseQueryResult<IGetEverythingResponse[], unknown>,
  initializeProvider: () => { },
  setLastTimeChanged: () => { },
  updateAggregatorInfo: () => { },
  washingPlant: {} as IJetWashingPlant,
  lastTimeChanged: undefined,
  isLoading: false,
  cycleSummary: {
    totalWater: -1,
    totalWaterPercent: -1,
    totalEnergy: -1,
    totalEnergyPercent: -1,
    totalTime: -1,
    totalGreenTime: -1,
    totalTimePercent: -1,
    totalFuel: -1,
    totalFuelPercent: -1,
  },
  cycles: [],
  allChemicals: [],
  allUnits: [],
  allAuxilliaryEquipments: [],
  allLasersScrapings: [],
  setAllChemicals: () => { },
  setAllUnits: () => { },
  setAllAuxilliaryEquipments: () => { },
  setAllLasersScrapings: () => { },
  removeChemicalFromCompleteInfo: () => { },
  removeAuxiliaryFromCompleteInfo: () => { },
  removeLaserFromCompleteInfo: () => { },
  completeWashInformation: undefined,
});

const WashRecipeAggregatorProvider: React.FC = ({ children }: any) => {
  const [lastTimeChanged, setLastTimeChanged] = useState<Date | undefined>(undefined);
  const [washingPlant, setWashingPlant] = useState<IJetWashingPlant | undefined>(undefined);
  const [washRecipe, setWashRecipe] = useState<IJetWashRecipe | undefined>(undefined);
  const [completeWashInformation, setCompleteWashInformation] = useState<ICompleteWashInfo | undefined>(undefined);
  const [allChemicals, setAllChemicals] = useState<IJetTChemical[]>([]);
  const [allUnits, setAllUnits] = useState<IJetTUnit[]>([]);
  const [allAuxilliaryEquipments, setAllAuxilliaryEquipments] =
    useState<IJetAuxiliaryEquipmentsComplete[]>([]);
  const [allLasersScrapings, setAllLasersScrapings] = useState<IJetLaserScrapingsComplete[]>([]);

  const initializeProvider = useCallback((washingPlant: IJetWashingPlant, washRecipe: IJetWashRecipe) => {
    setWashingPlant(washingPlant);
    setWashRecipe(washRecipe);
  }, []);

  const cycleTypesQuery = useQuery(`${QueryKeyCache.GetTypeOfCycles}`, getTypeOfCycles, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: true,
  });

  ///////////////////////////////////////////////////
  // Cycle Summary Total
  ///////////////////////////////////////////////////
  const queryKey = `cycle-summary-${washingPlant?.id}`;

  const [totalWater, setTotalWater] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalWater` });
  const [totalWaterPercent, setTotalWaterPercent] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalWaterPercent` });
  const [totalEnergy, setTotalEnergy] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalEnergy` });
  const [totalEnergyPercent, settotalEnergyPercent] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalEnergyPercent` });
  const [totalTime, setTotalTime] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalTime` });
  const [totalGreenTime, setTotalGreenTime] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalGreenTime` });
  const [totalTimePercent, setTotalTimePercent] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalTimePercent` });
  const [totalFuel, setTotalFuel] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalFuel` });
  const [totalFuelPercent, setTotalFuelPercent] = useLocalStorage<number>({ initialValue: -1, key: `${queryKey}-totalFuelPercent` });

  const [cycles, setCycles] = useLocalStorage<ICycleCalculated[]>({ initialValue: [], key: `${queryKey}-cycles` });
  const washingCycleCompleteInfoQuery = useQuery(
    `washing-cycle-complete-info-${washRecipe?.id}`,
    () =>
    getAllInfo({
        washingPlantID: washingPlant?.id,
        washRecipeId: washRecipe?.id || '',  
      }),
    {
      onSuccess: (res) => {
        const completeWashInfo = formatCompleteWashInfo(res);
        setCompleteWashInformation(completeWashInfo as ICompleteWashInfo);
        calcAllInfos(res)
      },
      enabled: Boolean(washingPlant?.id)
    }
  );

  function calcAllInfos(res: IGetEverythingResponse[]){
    setTotalWater(calcTotalWater(res));
    setTotalWaterPercent(calcTotalWaterPercent(res));
    setTotalEnergy(calcTotalEnergy(res));
    settotalEnergyPercent(calctotalEnergyPercent(res));

    setTotalTime(calcTotalTime(res));
    setTotalGreenTime(calcTotalGreenTime(res));
    setTotalTimePercent(calctotalTimePercent(res));

    setTotalFuel(calcTotalFuel(res));
    setTotalFuelPercent(calctotalFuelPercent(res));

    const cycles: ICycleCalculated[] = res.map((res) => {
      if (!res || !washingPlant) return {
        totalEnergy: FALLBACK,
        totalFuel: FALLBACK,
        totalWater: FALLBACK,
        totalTime: FALLBACK,
        washAggregatorID: '',
      }

      const {
        boiler,
        country,
        centrifuge,
        currentRecipe,
        dryer,
        greenRecipe,
        washingMachine,
      } = res;

      const totalWater = calcTotalCostWater(currentRecipe, greenRecipe, washingPlant);

      const totalEnergy = calcTotalAbsoluteEnergy({
        washMachine: washingMachine,
        centrifuge: centrifuge,
        country: country,
        dryer: dryer,
        currentCycle: currentRecipe,
        greenCycle: greenRecipe,
        washingPlant,
      });

      const totalFuel = calcTotalCostFuelCO2({
        boyler: boiler,
        country,
        dryer,
        currentCycle: currentRecipe,
        greenCycle: greenRecipe,
        washingPlant,
      });

      const totalTime = calcTotalCostCycleTime({
        centrifuge,
        dryer,
        currentCycle: currentRecipe,
        greenCycle: greenRecipe,
        washingPlant,
        washMachine: washingMachine,
      });

      return {
        totalWater,
        totalEnergy,
        totalFuel,
        totalTime,
        washAggregatorID: res.aggregatorID,
      };
    });

    setCycles(cycles);
  }

  const updateAggregatorInfo = (data: ICompleteWashInfoAggregators) => {
    const newCompleteWashInformation: ICompleteWashInfo = {
      ...completeWashInformation,
      aggregators: completeWashInformation?.aggregators.map((item) => {
        return item.aggregator_id === data.aggregator_id
          ? data
          : item
      }) || [],
      aggregators_minimal: completeWashInformation?.aggregators_minimal?.map((item) => {
        return item.id === data.aggregator_id
          ? {
              id: item.id,
              cycle_type: item.cycle_type,
              current_recipe: data.currentRecipe?.id,
              new_recipe: data?.greenRecipe?.id || ""
            }
          : item
      }) || [],
    } as ICompleteWashInfo;

    setCompleteWashInformation(newCompleteWashInformation);
  }
  

  function removeChemicalFromCompleteInfo(
    aggregatorId: string,
    cycle: 'currentRecipe' | 'greenRecipe',
    chemicalToRemove: IJetWashCycleChemical
  ){
    const newCompleteInfo = formatDataToUpdateChemicals(
      aggregatorId,
      cycle,
      chemicalToRemove,
      completeWashInformation || {} as ICompleteWashInfo,
    );

    setCompleteWashInformation(newCompleteInfo as ICompleteWashInfo);
  }

  function removeAuxiliaryFromCompleteInfo(
    aggregatorId: string,
    cycle: 'currentRecipe' | 'greenRecipe',
    auxiliaryToRemove: IJetWashCycleAuxiliary
  ){
    const newCompleteInfo = formatDataToUpdateAuxilliary(
      aggregatorId,
      cycle,
      auxiliaryToRemove,
      completeWashInformation || {} as ICompleteWashInfo,
    );

    setCompleteWashInformation(newCompleteInfo);
  }

  function removeLaserFromCompleteInfo(
    aggregatorId: string,
    cycle: 'currentRecipe' | 'greenRecipe',
    laserToRemove: IJetWashCycleLaser
  ){
    const newCompleteInfo = formatDataToUpdateLaser(
      aggregatorId,
      cycle,
      laserToRemove,
      completeWashInformation || {} as ICompleteWashInfo,
    );

    setCompleteWashInformation(newCompleteInfo as ICompleteWashInfo);
  }

  useEffect(() => {
    if (lastTimeChanged && completeWashInformation !== undefined) {
      const data = formatDataToCalc(completeWashInformation as ICompleteWashInfo);
      calcAllInfos(data);
      // washingCycleCompleteInfoQuery.refetch();
    }
  }, [lastTimeChanged]);

  const isLoading = washingCycleCompleteInfoQuery.isLoading || washingCycleCompleteInfoQuery?.isRefetching;

  const values: WashRecipeAggregatorProviderInterface = {
    aggregators: completeWashInformation?.aggregators_minimal || [],
    completeWashInformation,
    washingCycleCompleteInfoQuery,
    updateAggregatorInfo,
    allChemicals,
    allUnits,
    setAllChemicals,
    allAuxilliaryEquipments,
    setAllAuxilliaryEquipments,
    allLasersScrapings,
    setAllLasersScrapings,
    removeChemicalFromCompleteInfo,
    removeAuxiliaryFromCompleteInfo,
    removeLaserFromCompleteInfo,
    setAllUnits,
    washingPlant,
    cycleTypesQuery,
    initializeProvider,
    setLastTimeChanged,
    lastTimeChanged,
    isLoading,
    cycles,
    cycleSummary: {
      totalWater,
      totalWaterPercent,
      totalEnergy,
      totalEnergyPercent,
      totalTime,
      totalGreenTime,
      totalTimePercent,
      totalFuel,
      totalFuelPercent,
    },
  };

  return <WashRecipeAggregatorContext.Provider value={values}>{children}</WashRecipeAggregatorContext.Provider>;
};

export default WashRecipeAggregatorProvider;


function calcTotalWater(data: (IGetEverythingResponse | undefined)[]): number {
  return data.reduce((acc, val) => {
    if (!val) return -1;
    const { currentRecipe, greenRecipe, washingPlant } = val;
    const result = calcTotalCostWater(currentRecipe, greenRecipe, washingPlant).total;
    return result !== -1 ? (acc += result) : acc;
  }, 0);
}

function calcTotalWaterPercent(data: (IGetEverythingResponse | undefined)[]): number {
  const _greenTotal = data.reduce((acc, val) => {
    if (!val) return (acc += 0);

    const { currentRecipe, greenRecipe, washingPlant } = val;
    const result = calcTotalCostWater(currentRecipe, greenRecipe, washingPlant).green;
    return result !== -1 ? (acc += result) : acc;
  }, 0);

  const _currentTotal = data.reduce((acc, val) => {
    if (!val) return (acc += 0);

    const { currentRecipe, greenRecipe, washingPlant } = val;
    const result = calcTotalCostWater(currentRecipe, greenRecipe, washingPlant).current;
    return result !== -1 ? (acc += result) : acc;
  }, 0);

  return calcPercent({ current: _currentTotal, green: _greenTotal });
}

function formatCompleteWashInfo(res: IGetEverythingResponse[]) {
  const aggregators_minimal = res.filter(item => item.aggregatorID !== null)?.map((info: IGetEverythingResponse) => ({
    id: info?.aggregatorID || '',
    current_recipe: info?.currentRecipe?.id || '',
    new_recipe: info?.greenRecipe?.id || '',
    cycle_type: info?.cycle_type?.id || '',
  }));

  const complete_aggregators_info = res.filter?.((item: IGetEverythingResponse) => item.aggregatorID !== null)?.map((item: IGetEverythingResponse) => ({
    aggregator_id: item.aggregatorID,
    currentRecipe: item.currentRecipe,
    greenRecipe: item.greenRecipe,
    cycle_type: item?.cycle_type,
  }));

  return {
    aggregators_minimal: aggregators_minimal || [],
    boiler: res[0].boiler,
    centrifuge: res[0].centrifuge,
    country: res[0].country,
    dryer: res[0].dryer,
    washingMachine: res[0].washingMachine,
    washingPlant: res[0].washingPlant,
    aggregators: complete_aggregators_info?.filter(item => item) || [],
    wash_recipe_info: res[0].wash_recipe_info,
  };
}

function calcTotalEnergy(data: (IGetEverythingResponse | undefined)[]): number {
  return data.reduce((acc, val) => {
    if (!val) return -1;

    const totalFuel = calcTotalAbsoluteEnergy({
      washMachine: val.washingMachine,
      centrifuge: val.centrifuge,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
      country: val.country,
    });

    return totalFuel.total !== -1 ? (acc += totalFuel.total) : acc;
  }, 0);
}

function calctotalEnergyPercent(data: (IGetEverythingResponse | undefined)[]): number {
  const _greenTotal = data.reduce((acc, val) => {
    if (!val) return (acc += 0);

    const result = calcTotalAbsoluteEnergy({
      washMachine: val.washingMachine,
      centrifuge: val.centrifuge,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
      country: val.country,
    }).green;

    return result !== -1 ? (acc += result) : acc;
  }, 0);

  const _currentTotal = data.reduce((acc, val) => {
    if (!val) return (acc += 0);

    const result = calcTotalAbsoluteEnergy({
      washMachine: val.washingMachine,
      centrifuge: val.centrifuge,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
      country: val.country,
    }).current;

    return result !== -1 ? (acc += result) : acc;
  }, 0);

  return calcPercent({ current: _currentTotal, green: _greenTotal });
}

function calcTotalFuel(data: (IGetEverythingResponse | undefined)[]): number {
  return data.reduce((acc, val) => {
    if (!val) return -1;

    const totalFuel = calcTotalCostFuelCO2({
      boyler: val.boiler,
      country: val.country,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
    });

    return totalFuel.total !== -1 ? (acc += totalFuel.total) : acc;
  }, 0);
}

function calctotalFuelPercent(data: (IGetEverythingResponse | undefined)[]): number {
  const _greenTotal = data.reduce((acc, val) => {
    if (!val) return (acc += 0);

    let result = calcTotalCostFuelCO2({
      boyler: val.boiler,
      country: val.country,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
    }).green;

    return result !== -1 ? (acc += result) : acc;
  }, 0);

  const _currentTotal = data.reduce((acc, val) => {
    if (!val) return (acc += 0);

    let result = calcTotalCostFuelCO2({
      boyler: val.boiler,
      country: val.country,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
    }).current;

    return result !== -1 ? (acc += result) : acc;
  }, 0);

  return calcPercent({ current: _currentTotal, green: _greenTotal });
}

function calcTotalTime(data: (IGetEverythingResponse | undefined)[]): number {
  return data.reduce((acc, val) => {
    if (!val) return -1;

    const totalTime = calcTotalCostCycleTime({
      centrifuge: val.centrifuge,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
      washMachine: val.washingMachine,
    });

    return (acc += totalTime.total);
  }, 0);
}

function calcTotalGreenTime(data: (IGetEverythingResponse | undefined)[]): number {
  return data.reduce((acc, val) => {
    if (!val) return -1;

    const totalTime = calcTotalCostCycleTime({
      centrifuge: val.centrifuge,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
      washMachine: val.washingMachine,
    });

    const _green = totalTime.green

    return _green !== -1 ? (acc += _green) : acc;
  }, 0);
}

function calctotalTimePercent(data: (IGetEverythingResponse | undefined)[]): number {
  const _greenTotal = data.reduce((acc, val) => {
    if (!val) return (acc += 0);

    let result = calcTotalCostCycleTime({
      centrifuge: val.centrifuge,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
      washMachine: val.washingMachine,
    }).green;

    return result !== -1 ? (acc += result) : acc;
  }, 0);

  const _currentTotal = data.reduce((acc, val) => {
    if (!val) return (acc += 0);

    let result = calcTotalCostCycleTime({
      centrifuge: val.centrifuge,
      dryer: val.dryer,
      currentCycle: val.currentRecipe,
      greenCycle: val.greenRecipe,
      washingPlant: val.washingPlant,
      washMachine: val.washingMachine,
    }).current;

    return result !== -1 ? (acc += result) : acc;
  }, 0);

  return calcPercent({ current: _currentTotal, green: _greenTotal });
}

const FALLBACK: ICalcResponse = {
  current: 0,
  green: 0,
  percent: '---%',
  totalCost: 0,
  total: 0,
  percentNumber: 0,
}