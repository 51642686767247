import { Center, Flex, Text } from 'native-base';
import React from 'react';

interface InputAddonProps {
  str: string;
  bgColor?: string;
  color?: string;
  borderLeft?: number;
  borderRight?: number;
}

const InputAddon: React.FC<InputAddonProps> = ({
  str,
  bgColor = '#E4EEED',
  color = '#368D7D',
  borderLeft = 1,
  borderRight,
}) => {
  return (
    <Flex
      background={bgColor}
      alignContent="center"
      justifyContent="center"
      py={'19px'}
      // px='12px'
      width="50px"
      // borderColor='red'
      borderWidth={0}
      borderColor={color}
      borderLeftWidth={borderLeft}
      borderRightWidth={borderRight}
    >
      <Center>
        <Text
          fontSize="md"
          color={color}
          style={{ backgroundColor: bgColor }}
          bgColor={bgColor}
          p={0}
          m={0}
        >
          {str}
        </Text>
      </Center>
    </Flex>
  );
};

export default InputAddon;
