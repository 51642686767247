import {
  IJetAdminResponseProtocol,
  jetAdmin,
  JetAdminCollection,
} from '../../../services/jetService';
import { IJetCountry } from '../../../types/country.type';

export interface IJetCountryList extends IJetAdminResponseProtocol<IJetCountry[]> {}

export async function getCountry(id: string): Promise<IJetCountry | undefined> {
  const res = await jetAdmin.retrieve<IJetCountry>(JetAdminCollection.Countries, id);

  return res;
}

export async function getAllCountries(): Promise<IJetAdminResponseProtocol<IJetCountry[]>> {
  const response = await jetAdmin.list<IJetCountry[]>(JetAdminCollection.Countries, { _order_by: 'country' });

  return response;
}
