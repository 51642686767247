import { HStack, ScrollView, Skeleton, Text, VStack } from 'native-base';
import React, { useMemo } from 'react';
import {
  DeepPartial,
  FieldPathValues,
  useFieldArray,
  UseFieldArrayProps,
  useFormContext,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { ISelectValues } from '../../../../components/SelectWithModal';
import { QueryKeyCache } from '../../../../config/query-cache';
import { t } from '../../../../languages';
import AddRecipeButton from '../../components/AddRecipeButton';
import { StandardRecipeFormStep, useStandardRecipeForm } from '../../hooks/useStandardRecipeForm';
import { getAuxiliaryEquipments } from '../../services/getAuxiliaryEquipments';
import { IStandardRecipeForm } from '../../types/standardRecipeForm.type';
import { getInputName } from '../utils/getFormInputName';

interface AuxiliaryEquipmentsListProps {}

export type IAuxiliaryEquipmentsArrInputName =
  | `cycles.${number}.currentRecipe.add_auxiliary_equipment`
  | `cycles.${number}.newRecipe.add_auxiliary_equipment`;

const AuxiliaryEquipmentsList: React.FC<AuxiliaryEquipmentsListProps> = () => {
  const methods = useFormContext<IStandardRecipeForm>();
  const { getInputName, goToDeepStep, goToStep, createNewAddCycle } = useStandardRecipeForm(
    methods.control
  );

  const auxiliaryEquipmentsQuery = useQuery(
    QueryKeyCache.getAuxiliaryEquipments,
    getAuxiliaryEquipments
  );

  const auxiliaryEquipmentsInputName = getInputName(
    'add_auxiliary_equipment'
  ) as IAuxiliaryEquipmentsArrInputName;
  const { fields, append } = useFieldArray({
    control: methods.control,
    name: auxiliaryEquipmentsInputName,
  });

  if (auxiliaryEquipmentsQuery.isLoading) {
    return (
      <VStack space={'8px'}>
        <Skeleton h={12} />
        <Skeleton h={12} />
      </VStack>
    );
  }

  return (
    <ScrollView>
      <VStack space="8px">
        {fields.map((auxiliaryEquipment, index) => {
          const _auxiliaryEquipment = auxiliaryEquipmentsQuery.data?.results.find(
            ({ id }) => id === auxiliaryEquipment.auxiliary_equipment_id
          );
          const auxiliaryEquipmentName = `${_auxiliaryEquipment?.brand_detail?.name} ${_auxiliaryEquipment?.model}`;

          return (
            <AddRecipeButton
              key={`AuxiliaryEquipmentList-${index}`}
              isAdded={false}
              text={`${auxiliaryEquipment.auxiliary_equipment_id}`}
              onPress={() => goToDeepStep(StandardRecipeFormStep.Step6AuxiliaryEquipment, index)}
            >
              <HStack justifyContent="space-between">
                <Text mr={2} fontSize={14} color="#368D7D" fontWeight={'normal'}>
                  {auxiliaryEquipmentName ??
                    t('domain.analysis.step6.fallback.auxiliary_equipment_without_name')}
                </Text>
                <Text textAlign="right" fontSize={14} color="#409D27">
                  {t('domain.generic.edit')}
                </Text>
              </HStack>
            </AddRecipeButton>
          );
        })}

        <AddRecipeButton
          text={t('domain.analysis.step4.section.add.subsection.auxiliary_equipment.button.add')}
          onPress={() => {
            createNewAddCycle('add_auxiliary_equipment');
            goToStep(StandardRecipeFormStep.Step6AuxiliaryEquipment);
          }}
          isAdded={true}
        />
      </VStack>
    </ScrollView>
  );
};

export default AuxiliaryEquipmentsList;
