import { jetAdmin, JetAdminCollection } from '../../../services/jetService';
import { IJetTAuxiliaryEquipment } from '../../../types/auxiliary_equipment.type';
import { IJetWashCycle } from '../../../types/wash_cycle.type';
import {
  IJetWashCycleAuxiliary,
  IJetWashCycleAuxiliaryID,
  IJetWashCycleAuxiliaryComplete,
  IJetWashCycleAuxiliaryCreate,
} from '../../../types/wash_cycle_auxiliary.type';
import { getAuxiliaryEquipment } from '../../standardRecipe/services/getAuxiliaryEquipments';
import { editWashCycle, getWashCycle } from '../../washRecipeAggregator/services/cycle.service';

interface ICreateWashCycleAuxiliaryParams {
  data: IJetWashCycleAuxiliary | IJetWashCycleAuxiliaryCreate;
  washCycle: IJetWashCycle;
}

export async function getWashCycleAuxiliary(
  id: IJetWashCycleAuxiliaryID
): Promise<IJetWashCycleAuxiliaryComplete | undefined> {
  const response = await jetAdmin.retrieve<IJetWashCycleAuxiliary>(
    JetAdminCollection.WashRecipeAuxiliaryEquipments,
    id
  );

  const auxEqp = await getAuxiliaryEquipment(response?.auxiliary_equipment_id);

  return {
    ...response,
    auxiliary_equipment: auxEqp,
  };
}

export async function listWashCycleAuxiliarys(washCycle: IJetWashCycle) {
  try {
    const cyclesIDS = washCycle.add_auxiliary_equipment;
    const cycleIDPromiseArr = cyclesIDS?.map((cycleID) => getWashCycleAuxiliary(cycleID));
    if (!cycleIDPromiseArr || cycleIDPromiseArr.length === 0) return [];
    const cyclesResponse = await Promise.all(cycleIDPromiseArr);
    // remove all undefined values from the array
    const cycles = cyclesResponse.filter((cycle) => !!cycle) as IJetWashCycleAuxiliaryComplete[];

    return cycles;
  } catch (error) {
    return [];
  }
}

export async function createOrEditWashCycleAuxiliary(
  params: ICreateWashCycleAuxiliaryParams
): Promise<IJetWashCycleAuxiliary | undefined> {
  const { data, washCycle } = params;

  // @ts-ignore
  if (data?.id) {
    const res = await editWashCycleAuxiliary(data);
    return res;
  } else {
    return await createWashCycleAuxiliary({ data, washCycle });
  }
}

export async function createWashCycleAuxiliary(
  props: ICreateWashCycleAuxiliaryParams
): Promise<IJetWashCycleAuxiliary | undefined> {
  const { data, washCycle } = props;
  const washCycleAuxiliaryCreate = convertWashCycleAuxiliaryFormToWashCycleAuxiliaryCreate(data);

  const res = await jetAdmin.create<IJetWashCycleAuxiliary>(
    JetAdminCollection.WashRecipeAuxiliaryEquipments,
    washCycleAuxiliaryCreate
  );

  if (!res?.id) return res;

  await editWashCycle({
    ...washCycle,
    extras_centrifuge_pickup: washCycle.extras_centrifuge_pickup || undefined,
    extras_centrifuge_run_time: washCycle.extras_centrifuge_run_time || undefined,
    extras_drying_temperature: washCycle.extras_drying_temperature || undefined,
    extras_drying_time: washCycle.extras_drying_time || undefined,
    add_auxiliary_equipment: [...washCycle.add_auxiliary_equipment || [], res.id],
  });

  return res;
}

export async function editWashCycleAuxiliary(
  props: Partial<IJetWashCycleAuxiliary>
): Promise<IJetWashCycleAuxiliary | undefined> {
  if (!props.id) throw new Error('Wash recipe id is required');

  const res = await jetAdmin.update<IJetWashCycleAuxiliary>(
    JetAdminCollection.WashRecipeAuxiliaryEquipments,
    props.id,
    props
  );

  return res;
}

function convertWashCycleAuxiliaryFormToWashCycleAuxiliaryCreate(
  data: IJetWashCycleAuxiliaryCreate
): IJetWashCycleAuxiliaryCreate {
  return {
    ...data,
    auxiliary_equipment_id: data.auxiliary_equipment_id,
    auxiliary_time: data.auxiliary_time || undefined,
  };
}

interface IDeleteWashCycleAuxiliary {
  auxiliary: IJetWashCycleAuxiliary;
  cycle: IJetWashCycle;
}

export async function deleteWashCycleAuxiliary(props: IDeleteWashCycleAuxiliary): Promise<void> {
  const { auxiliary, cycle } = props;
  if (!auxiliary.id || !cycle.id) throw new Error('Wash recipe id is required');

  const _cycle = await getWashCycle(cycle.id);
  if (!_cycle) throw new Error('Wash cycle not found');

  // Remove only the id from washCycleAuxiliaryIDs array
  const _newArr = _cycle?.add_auxiliary_equipment?.filter(
    (washCycleAuxiliaryID) => washCycleAuxiliaryID !== props.auxiliary.id
  );

  await editWashCycle({
    ..._cycle,
    add_auxiliary_equipment: _newArr,
  });

  await jetAdmin.delete(JetAdminCollection.WashRecipeAuxiliaryEquipments, props.auxiliary.id || '');
}
