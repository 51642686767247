import { MaterialIcons } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Button, HStack, Progress, Text, View } from 'native-base';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Platform } from 'react-native';
import { useMutation, useQuery } from 'react-query';
import { CustomButton } from '../../../components/CustomButton';
import { t } from '../../../languages';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { toast } from '../../../utils/toast';
import { useAuth } from '../../auth/hooks/useAuth';
import { useWashingPlantForm } from '../hooks/useWashingPlantForm';
import { createOrEditWashingPlant } from '../services/createWashingPlant';
import { IWashingPlantForm } from '../types/washingPlantForm.type';

interface FormSubmitProps {}

export const FormSubmit: React.FC<FormSubmitProps> = () => {
  const { navigate } = useNavigation<NavigationProp<HomeStackParamList>>();
  const { user } = useAuth();
  const {
    trigger,
    formState: { isValid, errors },
    handleSubmit,
  } = useFormContext<IWashingPlantForm>(); // retrieve all hook methods
  const { step, setIsSubmitting, isSubmitting } = useWashingPlantForm();

  const mutation = useMutation(createOrEditWashingPlant, {
    onMutate: () => setIsSubmitting(true),
    onSuccess: (response) => {
      response && navigate('WashingPlantSuccess', { washingPlant: response });
    },
    onError: () => toast('Error creating washing plant'),
    onSettled: () => setIsSubmitting(false),
  });

  async function onSubmit(data: IWashingPlantForm) {
    await mutation.mutate({ data, user: user! });
  }

  useEffect(() => {
    trigger();
  }, [step]);

  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: '#f2f8f7',
        justifyContent: 'space-around',
        paddingTop: 15,
        paddingBottom: Platform.OS === 'web' ? 15 : 0,
      }}
    >
      <CustomButton
        color="primary.500"
        variant={'outline'}
        mt={10}
        onPress={() => navigate('Home')}
      >
        {t('domain.washingPlants.step.nine.backHome')}
      </CustomButton>
      <CustomButton
        background={'#00a680'}
        variant="solid"
        isDisabled={!isValid || isSubmitting}
        mt={10}
        isLoading={isSubmitting}
        isLoadingText={t('domain.generic.loading')}
        onPress={handleSubmit(onSubmit)}
      >
        <HStack>
          <Text color="white">{t('domain.washingPlants.step.nine.submit')}</Text>
        </HStack>
      </CustomButton>
    </View>
  );
};

export default FormSubmit;
