import { Box, Center, Heading, Text, Image, VStack, HStack, Button } from 'native-base';
import React from 'react';
import { Page } from '../../../components/Page';
import { t } from '../../../languages';
import Img from '../../../assets/images/reset-sucess.png';
import { IJetWashingPlant } from '../../../types/washing-plants.type';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { HomeStackParamList } from '../../../routes/auth.routes';
import { CustomButton } from '../../../components/CustomButton';

type Props = NativeStackScreenProps<HomeStackParamList, 'WashingPlantSuccess'>;

const WashingPlantSuccessRoot: React.FC<Props> = ({ route, navigation }) => {
  const { washingPlant } = route.params;

  return (
    <Page>
      <Box flex={1} pt="24px">
        <VStack justifyContent="space-between" height="100%">
          <Box />

          <VStack space="16px" alignContent="center" alignItems="center" mb={20}>
            <Image source={Img} alt="reset sucess img" />
            <Text color="primary.500" fontWeight="bold" fontSize="22px">
              {t('domain.washingPlants.step.success.title')}
            </Text>
            <Text textAlign="center" fontSize="sm">
              {t('domain.washingPlants.step.success.subtitle')}
            </Text>
          </VStack>

          <HStack width="100%" justifyContent="space-evenly" mb={5}>
            <Button variant="ghost" colorScheme="primary" onPress={() => navigation.push('Home')}>
              {t('domain.washingPlants.step.success.button.home')}
            </Button>

            <Button
              variant="solid"
              color="primary"
              onPress={() => navigation.push('MyWashes', { washingPlant })}
            >
              {t('domain.washingPlants.step.success.button.addNew')}
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Page>
  );
};

export default WashingPlantSuccessRoot;
